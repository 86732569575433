import PropTypes from "prop-types"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Cell, Pie, PieChart, Tooltip } from "recharts"

import styles from "./Chart.module.scss"
import PieChartTooltip from "./internal/PieChartTooltip"

import { CHART_COLOR } from "../../utils/GlobalConstants"

const PieChartComponent = ({ data }) => {
  const { t } = useTranslation(["chart"])
  const [xoffset, setXoffset] = useState(0)
  
  const handleMouseEnter = (entry) => {
    if (entry?.label === "acknowledged") {
      setXoffset(-7 - (String(entry?.count)?.length * 3))
    } else {
      setXoffset(-17 - (String(entry?.count)?.length * 3))
    }
  }

  return (
    <>
      <div className={ styles.chartSection }>
        <PieChart width={ 100 } height={ 100 }>
          <Pie
            data={ data } 
            cx={ 50 } 
            cy={ 50 } 
            outerRadius={ 40 } 
            startAngle={ 90 } 
            endAngle={ -270 } 
            stroke="" 
            dataKey="value"
          >
            { data.map((entry) => (
              <Cell
                key={ entry.label }
                onMouseEnter={ () => handleMouseEnter(entry) }
                fill={ CHART_COLOR[entry.label] }
                style={ { outline: "none" } }
              />
            )) }
          </Pie>
          <Tooltip
            position={ { x: xoffset, y: 94.5 } }
            content={ <PieChartTooltip t={ t } /> }
          />
        </PieChart>
      </div>
      <div className={ styles.label }>
        { data.map((entry) => {
          return (
            <span key={ entry.label } className={ styles.labelContainer }>
              <span className={ styles.badge } style={ { backgroundColor: CHART_COLOR[entry.label] } } />
              <span>
                { t(`${entry.label}`) } { entry.value }%
              </span>
            </span>
          )
        }) }
      </div>
    </>
  )
}

PieChartComponent.propTypes = {
  data: PropTypes.array
}

export default PieChartComponent
