import { idamServer } from "../../config/serverConfig"
import { setDecodedTokenData } from "../../contexts/Auth/auth"
import { ERROR_MESSAGE } from "../../utils/Auth/auth"
import { getCodeChallengeAndVerifier } from "../../utils/Auth/codeChallengeGenerator"
import { getUserSession, setUserSession } from "../../utils/Auth/token"
import { generateUUID } from "../../utils/Common/common"
import { CLIENT_ID, REDIRECT_URI, SCOPES } from "../../utils/Constants/Auth/Auth"
import { StorageKeys, clearSession, getSession, setSession } from "../../utils/SessionHelper/session"

export const IDAM_API_ENDPOINT = {
  Login: idamServer + "/oauth2/authorize",
  Logout: idamServer + "/oidc/logout",
  Token: idamServer + "/oauth2/token",
  Token_Revoke: idamServer + "/oauth2/revoke"
}

export const getLoginParams = () => {
  const { codeChallenge, codeVerifier } = getCodeChallengeAndVerifier()
  const state = generateUUID()
  setSession(StorageKeys.AUTH_STATE, state)
  setSession(StorageKeys.CODE_VERIFIER, codeVerifier)

  const params = {
    client_id: CLIENT_ID,
    code_challenge: codeChallenge,
    code_challenge_method: "S256",
    redirect_uri: REDIRECT_URI,
    response_type: "code",
    scope: SCOPES,
    state
  }
  return getURLEncodedQuery(IDAM_API_ENDPOINT.Login, params)
}

export const getLogoutParams = () => {
  const { idToken } = getUserSession()
  const uuid = generateUUID()

  const params = {
    id_token_hint: idToken,
    post_logout_redirect_uri: REDIRECT_URI,
    state: uuid
  }
  return getURLEncodedQuery(IDAM_API_ENDPOINT.Logout, params)
}

export const loginUser = () => {
  const query = getLoginParams()
  window.open(query, "_self")
}

export const logoutUser = async () => {
  await revokeAccessToken()
  const query = getLogoutParams()
  window.open(query, "_self")
}

export const generateToken = async (authCode, authState) => {
  const state = getSession(StorageKeys.AUTH_STATE)
  const verifier = getSession(StorageKeys.CODE_VERIFIER)
  clearSession(StorageKeys.AUTH_STATE)
  clearSession(StorageKeys.CODE_VERIFIER)

  if (authState !== state) {
    return {
      error: new Error(ERROR_MESSAGE.InvalidState)
    }
  }

  const params = {
    client_id: CLIENT_ID,
    code: authCode,
    code_verifier: verifier,
    grant_type: "authorization_code",
    redirect_uri: REDIRECT_URI
  }

  return fetch(IDAM_API_ENDPOINT.Token, {
    body: getURLEncodedFormData(params),
    headers: getTokenRequestHeaders(),
    method: "POST"
  }).then(transformTokenResponse)
    .then(setTokenResponse)
    .catch(handleTokenError)
}

export const revokeAccessToken = async () => {
  const { accessToken, refreshToken } = getUserSession()
  const accessTokenParams = { client_id: CLIENT_ID, token: accessToken }
  const refreshTokenParams = { client_id: CLIENT_ID, token: refreshToken }

  const accessTokenFormBody = getURLEncodedFormData(accessTokenParams)
  const refreshTokenFormBody = getURLEncodedFormData(refreshTokenParams)
  const params = [accessTokenFormBody, refreshTokenFormBody]

  return Promise.allSettled(
    params.map(async param => {
      await fetch(IDAM_API_ENDPOINT.Token_Revoke, {
        body: param,
        headers: getTokenRequestHeaders(),
        method: "POST"
      })
    })
  )
}

export const renewUserSession = async () => {
  const { refreshToken } = getUserSession()
  const params = {
    client_id: CLIENT_ID,
    grant_type: "refresh_token",
    refresh_token: refreshToken
  }
  
  return fetch(IDAM_API_ENDPOINT.Token, {
    body: getURLEncodedFormData(params),
    headers: getTokenRequestHeaders(),
    method: "POST"
  }).then(transformTokenResponse)
    .then(setTokenResponse)
    .catch(handleTokenError)
}

const transformTokenResponse = (response) => {
  if(!response.ok) {
    return response.text().then(text => {
      const err = JSON.parse(text)
      throw new Error(err?.error_description)
    })
  }
  else {
    return response.json()
  }
}

const setTokenResponse = (data) => {
  setUserSession(data)
  setDecodedTokenData(null)
  return {
    data
  }
}

const handleTokenError = (err) => {
  return {
    error: err
  }
}

const getURLEncodedQuery = (apiUrl, params) => {
  return  apiUrl + "?" +
    Object.keys(params)
      .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k])).join("&")
}

export const getURLEncodedFormData = (params) => {
  return Object.keys(params)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])).join("&")
}

export const getTokenRequestHeaders = () => {
  return {
    "Content-Type": "application/x-www-form-urlencoded",
    "Strict-Transport-Security": "max-age=31536000; includeSubDomains"
  }
}
