/* eslint-disable jsx-a11y/interactive-supports-focus */
import { PropTypes } from "prop-types"
import React, { Fragment } from "react"
import { Draggable } from "react-beautiful-dnd"

import styles from "./DataTable.module.scss"
import GridRowCell from "./GridRowCell"

import {
  applyGroupBorder,
  getAssetTableRowStyle
} from "../../pages/SiteView/FleetsSummary/fleetsDndStyles"
import { classNames } from "../../utils/styles/helper"
import { useDataTableContext } from "../NestedDataTable/NestedDataTableContext"

const GridBody = ({
  virtualRows,
  rows,
  onRowClick,
  state,
  componentProps,
  tableProps,
  setCurrentRowState,
  setCustomDefaultInterminentRows,
  disableVirtualization
}) => {
  const { rowRefs } = useDataTableContext()

  const toggleRowSelection = (
    row,
    isAllSelected,
    isSomeSeleted,
    childTableRows,
    isAllRowsDisabled
  ) => {
    if (isAllSelected || isAllRowsDisabled) {
      row.toggleSelected(true)
      setCurrentRowState((prevState) => {
        return {
          ...prevState,
          [row.id]: {
            ...{ selectedrows: childTableRows },
            ...(isAllRowsDisabled ? { disabled: true } : {})
          }
        }
      })
    } else if (isSomeSeleted) {
      row.toggleSelected(false)
      setCurrentRowState((prevState) => {
        return {
          ...prevState,
          [row.id]: {
            checked: false,
            indeterminate: true,
            selectedrows: childTableRows
          }
        }
      })
    } else {
      row.toggleSelected(false)
      setCurrentRowState((prevState) => {
        return {
          ...prevState,
          [row.id]: { selectedrows: childTableRows }
        }
      })
    }
  }

  const defaultInderminateAndDisabled = (
    row,
    isAllSelected,
    isSomeSeleted,
    isAllRowsDisabled,
    manualSomeSelected
  ) => {
    if (isAllSelected) {
      row.toggleSelected(true)
      setCustomDefaultInterminentRows((prevState) => {
        return {
          ...prevState,
          [row.id]: { checked: false, disabled: isAllRowsDisabled }
        }
      })
    } else if (isSomeSeleted || manualSomeSelected) {
      row.toggleSelected(false)
      setCustomDefaultInterminentRows((prevState) => {
        return {
          ...prevState,
          [row.id]: { checked: false, indeterminate: true }
        }
      })
    }
  }

  const renderSubRows = (row) => {
    return componentProps?.subComponent?.({
      defaultInderminateAndDisabled: (
        isAllSelected,
        isSomeSeleted,
        isAllRowsDisabled,
        manualSomeSelected
      ) =>
        defaultInderminateAndDisabled(
          row,
          isAllSelected,
          isSomeSeleted,
          isAllRowsDisabled,
          manualSomeSelected
        ),
      dispatch: tableProps.dispatch,
      ref: rowRefs,
      row,
      toggleRowSelection: (
        isAllSelected,
        isSomeSeleted,
        childRows,
        isAllRowsDisabled
      ) =>
        toggleRowSelection(
          row,
          isAllSelected,
          isSomeSeleted,
          childRows,
          isAllRowsDisabled
        )
    })
  }

  return (disableVirtualization ? rows : virtualRows).map(
    (virtualRow, index) => {
      let row = {}
      let prevRow = null
      let nextRow = null
      if (disableVirtualization) {
        row = virtualRow
        prevRow = rows[index - 1]
        nextRow = rows[index + 1]
      } else {
        row = rows[virtualRow.index]
        prevRow = rows[virtualRow.index - 1]
        nextRow = rows[virtualRow.index + 1]
      }
      if (componentProps?.draggableRows) {
        return (
          <Draggable index={ index } key={ row.id } draggableId={ row.original.id }>
            { (provided, snapshot) => (
              <>
                <div
                  ref={ provided.innerRef }
                  { ...provided.draggableProps }
                  style={ getAssetTableRowStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style,
                    componentProps.highlightSelectedRows &&
                      !snapshot.isDragging &&
                      (row?.getIsSelected() || row?.state?.isSomeSelected)
                      ? {
                        backgroundColor: "#E7E5F0",
                        height: `${virtualRow.size - 2}px`
                      }
                      : { height: `${virtualRow.size - 2}px` },
                    Boolean(
                      componentProps.highlightSelectedRows &&
                        !snapshot.isDragging &&
                        row?.getIsSelected()
                    ),
                    prevRow,
                    nextRow
                  ) }
                  key={ `row_${row.id}` }
                  className={ classNames(
                    styles.tableRow,
                    snapshot.isDragging && styles.draggingRow,
                    componentProps.rowHoverEffect && styles.tableRowHover
                  ) }
                >
                  { row.getVisibleCells().map((cell) => {
                    return (
                      <GridRowCell
                        tableProps={ tableProps }
                        { ...componentProps }
                        key={ `gridRow_${cell.column.id}` }
                        cell={ cell }
                        dndProvider={ provided }
                      />
                    )
                  }) }
                </div>
                { snapshot.isDragging && (
                  <div
                    className={ styles.dragRowPlaceHolder }
                    style={ {
                      height: `${virtualRow.size - 2}px`
                    } }
                  >
                    { row.getVisibleCells().map((cell) => {
                      return (
                        <GridRowCell
                          { ...componentProps }
                          key={ `gridRow_${cell.column.id}` }
                          cell={ cell }
                          dndProvider={ provided }
                          tableProps={ tableProps }
                        />
                      )
                    }) }
                  </div>
                ) }
              </>
            ) }
          </Draggable>
        )
      } else {
        return (
          <Fragment key={ `row_${row.id}` }>
            <div
              role={ "row" }
              className={ classNames(
                styles.tableRow,
                componentProps.rowHoverEffect && styles.tableRowHover,
                componentProps.showActiveRow &&
                  state()?.activeRow?.id === row.id &&
                  styles.activeRow,
                row.getIsExpanded() &&
                  !componentProps.parentTableProps &&
                  styles.expandedRow
              ) }
              style={ {
                ...applyGroupBorder(
                  componentProps.highlightSelectedRows &&
                    (row.getIsSelected() || row?.getIsSomeSelected()),
                  prevRow,
                  nextRow
                ),
                ...(disableVirtualization
                  ? {}
                  : { height: `${virtualRow.size - 2}px` })
              } }
              onClick={ () => onRowClick(row) }
              onKeyDown={ () => onRowClick(row) }
            >
              { row.getVisibleCells().map((cell) => {
                return (
                  <GridRowCell
                    tableProps={ tableProps }
                    key={ `gridRow_${cell.column.id}` }
                    { ...componentProps }
                    cell={ cell }
                  />
                )
              }) }
            </div>
            { componentProps?.canExpandRow?.(row?.original) && (
              <div
                role="row"
                key={ `subRow_${row.id}` }
                className={ classNames(
                  styles.tableRow,
                  "subRow",
                  !componentProps.parentTableProps && "firstChildTable"
                ) }
                style={
                  row.getIsExpanded()
                    ? { display: "table-row" }
                    : { display: "none" }
                }
              >
                <td colSpan={ row.getVisibleCells()?.length || 5 }>
                  <div className="container">{ renderSubRows(row) }</div>
                </td>
              </div>
            ) }
          </Fragment>
        )
      }
    }
  )
}

GridBody.propTypes = {
  componentProps: PropTypes.object
}

export default GridBody
