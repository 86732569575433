import PropTypes from "prop-types"
import React from "react"

import { decodeURIString } from "../../../utils/Common/common"
import { DATE_FORMAT } from "../../../utils/Constants/TimeZones"
import { formatDate } from "../../../utils/helper"
import IconLabel from "../../IconLabel/IconLabel"
import styles from "../NotesPopUp.module.scss"

const NotesReceived = ({ data }) => {
  return (
    <>
      <div className={ styles.userNameInitials }>
        { data?.username ? `${data?.username?.charAt(0)}${data?.username?.charAt(
          data?.username?.indexOf(".") + 1
        )} ` : "" }
      </div>
      <div className={ styles.userDetails }>
        <span className={ styles.usernameTimestampData }>
          <IconLabel
            displayLabelText={ true }
            displayTooltip={ true }
            tooltipStyles={ styles.tooltip }
            tooltipPopperProps={ {
              className: "NotesPopUp"
            } }
            label={ data?.username }
            labelStyles={ styles.username }
          />
          <span className={ styles.timestamp }>
            { formatDate(data?.timestamp, DATE_FORMAT.dateTime) }
          </span>
        </span>
        <div className={ styles.note }>{ decodeURIString(data?.note) }</div>
      </div>
    </>
  )
}

NotesReceived.propTypes = {
  data: PropTypes.object
}

export default NotesReceived
