import colors from "../../../utils/styles/jsExports/colorExport.module.scss"

export const getFleetStyle = (isDraggingOver) => {
  if (isDraggingOver) {
    return {
      backgroundColor: colors["white"],
      border: `1px solid ${colors["secondary"]}`,
      borderRadius: "8px",
      marginBottom: "8px",
      maxHeight: "52px",
      position: "relative"
    }
  }
  return { marginBottom: "8px" }
}

export const getItemStyle = (isDragging, draggableStyle) => ({
  backgroundColor: colors["white"],
  borderRadius: "8px",
  boxShadow: isDragging ? `0px 3px 8px 0px ${colors["dragAssetBoxShadow"]}` : "unset",
  userSelect: "none",
  width: isDragging ? "calc(25% - 7px)" : "",

  ...draggableStyle
})

export const applyGroupBorder = (canApplySelectedRowStyle, prevRow, nextRow) => {
  if (prevRow) {
    prevRow.isSelected = prevRow?.getIsSelected()
    prevRow.isSomeSelected = prevRow?.getIsSomeSelected()
  }
  if (nextRow) {
    nextRow.isSelected = nextRow?.getIsSelected()
    nextRow.isSomeSelected = nextRow?.getIsSomeSelected()
  }
  if (canApplySelectedRowStyle) {
    let styleObject = {
      backgroundColor: colors["lightGray"]
    }
    if ((prevRow?.isSelected || prevRow?.isSomeSelected) && (nextRow?.isSelected || nextRow?.isSomeSelected)) {
      styleObject.borderLeft = `1px solid ${colors["tableSelectedBorder"]}`
      styleObject.borderRight = `1px solid ${colors["tableSelectedBorder"]}`
    } else if (prevRow?.isSelected || prevRow?.isSomeSelected) {
      styleObject.borderBottom = `1px solid ${colors["tableSelectedBorder"]}`
      styleObject.borderLeft = `1px solid ${colors["tableSelectedBorder"]}`
      styleObject.borderRight = `1px solid ${colors["tableSelectedBorder"]}`
    } else if (nextRow?.isSelected || nextRow?.isSomeSelected) {
      styleObject.borderLeft = `1px solid ${colors["tableSelectedBorder"]}`
      styleObject.borderRight = `1px solid ${colors["tableSelectedBorder"]}`
      styleObject.borderTop = `1px solid ${colors["tableSelectedBorder"]}`
    } else {
      styleObject.border = `1px solid ${colors["tableSelectedBorder"]}`
    }
    return styleObject
  } else if (nextRow?.isSelected || nextRow?.isSomeSelected) {
    return { borderBottom: "unset" }
  }
  return {}
}

export const getAssetTableRowStyle = (
  isDragging,
  draggableStyle,
  otherStyles,
  canApplySelectedRowStyle,
  prevRow,
  nextRow
) => {
  const borderStyle = applyGroupBorder(canApplySelectedRowStyle, prevRow, nextRow)
  return {
    ...{
      backgroundColor: isDragging ? colors["white"] : "",
      userSelect: "none",

      ...draggableStyle,
      ...otherStyles,
      ...borderStyle
    },
    ...(isDragging
      ? { boxShadow: "0px -1px 3px 0px rgba(30, 31, 32, 0.16), 0px 3px 8px 0px rgba(30, 31, 32, 0.28)" }
      : {})
  }
}
