import { React } from "react"
import { Link } from "react-router-dom"

import styles from "./Help.module.scss"
import {  HELP_CONTACT_US, HELP_DATA, TYPES } from "./HelpUtils"

import { useHelp } from "../../contexts/help/HelpContext"
import { CLOSE } from "../../utils/GlobalConstants"
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationPopup"
import IconLabel from "../IconLabel/IconLabel"

const Help = () => {
  const {
    state: { isOpen },
    dispatch,
    handleDownload,
    t
  } = useHelp()

  const handleClose = () => {
    dispatch({ payload: { isOpen: false }, type: TYPES.closePopup })
  }

  return (
    <div className={ styles.popupContainer }>
      <ConfirmationPopup
        isOpen={ isOpen }
        popupStyles={ styles.popupStyles }
        heading={ t("popupTitle") }
        onSubmit={ handleClose }
        confirm={ CLOSE }
      >
        <span className={ styles.helpContent }>
          <span className={ styles.topSection }>
            { HELP_DATA?.map((data) => (
              <span className={ styles.contentSection } key={ data.id }>
                <label className={ styles.label }>{ t(`helpData.${data.label}`) }</label>
                <Link className={ styles.link } onClick={ () => handleDownload(data) } target="_self">
                  { t(`helpData.${data.link}`) }
                </Link>
              </span>
            )) }
          </span>
          <span className={ styles.divider }></span>
          <span className={ styles.bottomSection }>
            <span className={ styles.contactUsHeader }>{ t("contactUsHeader") }</span>
            <span className={ styles.contactContent }>
              { HELP_CONTACT_US.map((iconLabel) => (
                <span key={ iconLabel.id } className={ styles.contacts }>
                  <IconLabel
                    iconName={ iconLabel.icon }
                    iconStyles={ styles.contactIcon }
                    label={ t(`contactUsInfo.${iconLabel.label}`) }
                    labelStyles={ styles.contactLabel }
                  />
                </span>
              )) }
            </span>
          </span>
        </span>
      </ConfirmationPopup>
    </div>
  )
}

export default Help
