import { QueryClient } from "react-query"

export const queryClientForTests = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
      retry: false,
      staleTime: 0
    }
  }
})

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 5 * 60 * 1000,
      enabled: true,
      refetchInterval: Infinity,
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 0
    }
  }
})
