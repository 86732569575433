/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"

import style from "./ConfigFilterPopup.module.scss"

import { useToaster } from "../../contexts/ToastContext"
import {
  fleetGetSVC,
  fleetSVCKeys
} from "../../services/reactQueries/fleetsvc"
import { useLazyQuery } from "../../utils/CustomHooks/reactQuery"
import useConfigFilterContext from "../../utils/CustomHooks/useConfigFilterContext"
import {
  APPLY,
  CANCEL,
  COMPARE_VALUE,
  CONFIG_CONTEXT_TYPE,
  FILTER_HEADING,
  RESET,
  ROUTE_CONFIG,
  getDropdownPlaceholder
} from "../../utils/GlobalConstants"
import { classNames } from "../../utils/styles/helper"
import MultipleSelectDropdown from "../Dropdown/MultipleSelectDropdown"
import SingleSelectDropdown from "../Dropdown/SingleSelectDropdown"
import PopupButtonGroup from "../PopupButtonGroup/PopupButtonGroup"

const prepareSelectedFilter = (_filters, data) => {
  const prevSelectedFilter = {}
  data?.forEach((option) => {
    if (option.key == "softwareVersion") {
      const values = _filters
        .filter((filter) => filter.type === option.key)
        .map(
          (filter) =>
            option.options.find((each) => each.label == filter.label)?.id
        )
      prevSelectedFilter[option.key] = values
    } else {
      const values = _filters
        .filter((filter) => filter.type === option.key)
        .map((filter) => filter.value)
      prevSelectedFilter[option.key] = option.multiple ? values : values[0]
    }
  })
  return prevSelectedFilter
}

const ConfigFilterPopup = ({
  data,
  isPopupOpen,
  onSubmit,
  onCancel,
  setIsFilterOpen,
  popupStyles,
  stateCarryForward,
  isFullScreenOverlay,
  type,
  customLoadingStyle,
  showToastError,
  setFilterAPIError,
  isModelMandatory
}) => {
  const { t } = useTranslation(["configuration"])
  const { t: common } = useTranslation(["common"])
  const {
    filter: filters,
    setIsFilterSet,
    setFilter
  } = useConfigFilterContext(type)
  const queryParams = useParams()
  const { displayMediumErrorToast } = useToaster()
  const siteId = queryParams[ROUTE_CONFIG.SITE.param]
  const [filterData, setFilterData] = useState(data)
  const [isFilterModified, setIsFilterModified] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState(
    prepareSelectedFilter(filters, data)
  )

  useEffect(() => {
    setFilterData(data)
  }, [data])

  const onModalityDataLoadCompleted = (_data) => {
    const modalities = _data?.data?.modalities
    setFilterOptionData(modalities, COMPARE_VALUE.modality)
  }

  const {
    data: modalityDropdownList,
    isFetched: isModalityFetched,
    isFetching
  } = useQuery(
    [fleetSVCKeys.MODALITY_VALUES_BY_SITE, siteId],
    () => fleetGetSVC.getModalityValuesBySite(siteId),
    {
      onError: () => {
        if (showToastError)
          displayMediumErrorToast(common("errorInFilterOptionsApi"))
        setFilterAPIError?.(true)
      },
      onSuccess: onModalityDataLoadCompleted
    }
  )

  const modalityDropdownData = modalityDropdownList?.data?.modalities

  const onModelDataLoadCompleted = (_data) => {
    const models = _data?.data?.models
    setFilterOptionData(models, COMPARE_VALUE.model)
  }

  const setFilterOptionData = (_data, compareValue) => {
    const options = _data?.map((ele, index) => {
      return {
        id: index + 1,
        label:
          compareValue === COMPARE_VALUE.model
            ? ele.split("|")[1]?.trim()
            : ele,
        parent:
          compareValue === COMPARE_VALUE.model
            ? ele.split("|")[0]?.trim()
            : ele
      }
    })
    const _filterData = [...filterData]
    const idx = _filterData?.findIndex((item) => item.key === compareValue)
    if (idx > -1) {
      _filterData[idx].options = options
      setFilterData(_filterData)
    }
    if (filters?.length > 0 && !isFilterModified)
      setSelectedFilter(prepareSelectedFilter(filters, filterData))
  }

  const [getModels] = useLazyQuery(
    [fleetSVCKeys.MODEL_VALUES_BY_SITE, siteId],
    () =>
      fleetGetSVC.getModelValuesBySiteAndModality(
        siteId,
        modalityDropdownData,
        [selectedFilter[COMPARE_VALUE.modality]]
      ),
    {
      onError: () => {
        if (showToastError)
          displayMediumErrorToast(common("errorInFilterOptionsApi"))
        setFilterAPIError?.(true)
      },
      onSuccess: onModelDataLoadCompleted
    }
  )

  useEffect(() => {
    if (selectedFilter[COMPARE_VALUE.modality] > 0 && isModalityFetched)
      getModels()
  }, [selectedFilter[COMPARE_VALUE.modality], isModalityFetched])

  const getLabel = (key, value) => {
    const dropdown = filterData.find(
      (forEachOption) => forEachOption.key === key
    )
    const option = dropdown.options.find((option) => option.id == value)
    return option?.label
  }

  const getDisabled = (key) => {
    const dropdown = filterData.find((option) => option.key === key)
    return dropdown.disabled
  }

  const getParent = (key) => {
    return filterData
      .find((forEachOption) => forEachOption.key === key && forEachOption)
      .options.find((ele) => ele).parent
  }

  const handleSubmit = () => {
    if (selectedFilter.modality) {
      setIsFilterOpen(false)
      const newFilters = []
      Object.entries(selectedFilter).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((_val) => {
            newFilters.push({
              disabled: getDisabled(key),
              label: getLabel(key, _val),
              parent: key === COMPARE_VALUE.model ? getParent(key) : "",
              type: key,
              value: _val
            })
          })
        } else if (value) {
          newFilters.push({
            disabled: getDisabled(key),
            label: getLabel(key, value),
            type: key,
            value
          })
        }
      })
      setFilter(newFilters)
      setIsFilterSet(true)
      onSubmit?.(newFilters)
    }
  }

  const handleClose = () => {
    setSelectedFilter(prepareSelectedFilter(filters, filterData))
    onCancel()
  }

  const handleReset = () => {
    if (stateCarryForward) {
      const { filter: getFilter } = stateCarryForward
      setSelectedFilter(prepareSelectedFilter(getFilter, filterData))
      setIsFilterSet(true)
      return
    }
    setFilter([])
    setSelectedFilter({})
    setIsFilterSet(false)
  }

  const handleSelectedItem = (value, key) => {
    setIsFilterModified(true)
    if (key === COMPARE_VALUE.modality) {
      setFilterOptionData([], COMPARE_VALUE.model)
      if (value.length === 0) {
        setSelectedFilter({})
      }
      setSelectedFilter((prevState) => {
        return { ...prevState, ...{ [COMPARE_VALUE.model]: [] } }
      })
    }
    setSelectedFilter((prevState) => {
      return { ...prevState, ...{ [key]: value } }
    })
  }

  if (!isPopupOpen) {
    return null
  }

  return (
    <div
      className={ classNames(
        style.container,
        isFullScreenOverlay && style.fullScreenContainer,
        popupStyles
      ) }
      data-testid="filter-popup"
    >
      <span
        className={ classNames(
          style.overlay,
          isFullScreenOverlay && style.fullScreenOverlay
        ) }
      ></span>
      <PopupButtonGroup
        resetButton={ true }
        confirm={ APPLY }
        cancel={ filters.length > 0 ? CANCEL : null }
        formClose={ !filters.length > 0 }
        reset={ RESET }
        popupStyles={ style.popupStyles }
        heading={ FILTER_HEADING }
        onCancel={ handleClose }
        onReset={ handleReset }
        onSubmit={ handleSubmit }
        isConfirmEnable={
          isModelMandatory
            ? !selectedFilter?.modality || !selectedFilter?.model?.length > 0
            : !selectedFilter?.modality
        }
        disableReset={ !selectedFilter?.modality }
        isCancelDisabled={ !filters.length > 0 }
        isContentLoading={ isFetching }
        customLoadingStyle={ customLoadingStyle }
      >
        <span className={ style.filterPopupContent }>
          { filterData?.map((option, index) => {
            const Dropdown = option.multiple
              ? MultipleSelectDropdown
              : SingleSelectDropdown
            const modalityPlaceholder =
              option.key === COMPARE_VALUE.modality &&
              (type === CONFIG_CONTEXT_TYPE.ASSET_FAULTS ||
                type === CONFIG_CONTEXT_TYPE.CENTRAL_CONFIG ||
                type === CONFIG_CONTEXT_TYPE.CENTRAL_SOFTWARE)
                ? t("filterPopup.selectRequired")
                : t("filterPopup.filterBy")
            const placeholder = getDropdownPlaceholder(
              option,
              selectedFilter,
              modalityPlaceholder
            )
            return (
              <Dropdown
                key={ option.key }
                { ...option }
                displayLabelText
                width={ 264 }
                placeholder={ placeholder }
                required={
                  isModelMandatory ? index === 0 || index === 1 : index === 0
                }
                value={ selectedFilter[option?.key] || option.defaultValue }
                menuheight={ { maxHeight: 76 } }
                disabled={
                  option.disabled ||
                  (option.key === COMPARE_VALUE.model &&
                    selectedFilter.modality === undefined) ||
                  (option.key === COMPARE_VALUE.severity &&
                    selectedFilter.modality === undefined) ||
                  (option.key === COMPARE_VALUE.acknowledgement &&
                    selectedFilter.modality === undefined)
                }
                onChange={ (value) => handleSelectedItem(value, option.key) }
              />
            )
          }) }
        </span>
      </PopupButtonGroup>
    </div>
  )
}

ConfigFilterPopup.propTypes = {
  customLoadingStyle: PropTypes.any,
  data: PropTypes.array,
  isFullScreenOverlay: PropTypes.bool,
  isModelMandatory: PropTypes.bool,
  isPopupOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onReset: PropTypes.func,
  onSubmit: PropTypes.func,
  popupStyles: PropTypes.any,
  selectedFilterItem: PropTypes.func,
  setFilterAPIError: PropTypes.any,
  setIsFilterOpen: PropTypes.func,
  showToastError: PropTypes.bool,
  stateCarryForward: PropTypes.any,
  type: PropTypes.string
}

export default ConfigFilterPopup
