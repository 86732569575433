import { EMPTY_CELL_DEFAULT_VALUE } from "../../../utils/Constants/DataTable"
import { prepareFilterDropdownSupportedValues } from "../../../utils/helper"
import { mockAvailableUpdates } from "../../SiteView/SiteUtils"

export const transformAssets = (data = []) => {
  const assets = []

  for (const [index, asset] of data.entries()) {
    assets.push({
      assetNote: asset.assetNote ?? "--",
      availableUpdates: mockAvailableUpdates(asset?.softwareVersion, index),
      ...asset,
      configStatus: asset?.configStatus?.toLowerCase(),
      connection: asset.connectionType,
      deviceId: asset.serialNumber,
      fleet: asset.fleetName || EMPTY_CELL_DEFAULT_VALUE,
      id: asset.id,
      ipAddress: asset.ipAddress,
      lastConnected: asset.lastConnected,
      lastLocation: "Minnesota",
      mute: asset?.muted,
      name: asset?.friendlyName,
      scheduledTime: index === 4 ? new Date() : null,
      severity: asset.faultSeverity,
      status: asset?.connectivityStatus || "Offline",
      uuid: asset.id
    })
  }
  return assets
}

export const transformFilterData = (swVerionsList) => {
  return [
    {
      defaultValue: "",
      disabled: true,
      key: "modality",
      label: "Modality",
      multiple: false,
      options: []
    },
    {
      defaultValue: [],
      key: "model",
      label: "Model",
      multiple: true,
      options: []
    },
    {
      defaultValue: [],
      key: "softwareVersion",
      label: "SW ver.",
      multiple: true,
      options: prepareFilterDropdownSupportedValues(swVerionsList) || []
    },
    {
      defaultValue: [],
      key: "configStatus",
      label: "Config status",
      multiple: true,
      options: [
        {
          id: 1,
          label: "Cancelled"
        },
        {
          id: 2,
          label: "Failed"
        },
        {
          id: 3,
          label: "Rejected"
        },
        {
          id: 4,
          label: "In install"
        },
        {
          id: 5,
          label: "In download"
        },
        {
          id: 6,
          label: "Success"
        },
        {
          id: 7,
          label: "Paused"
        }
      ]
    }
  ]
}

export const shouldDisplaySearchError = (searchError, searchInput, results) =>
  searchError || (searchInput && results.length == 0)

export const getAssetSelectionText = (assets, selectedAssets, searchInput, t) => {
  let selectionText = ""
  if (searchInput) {
    if (selectedAssets?.length > 0) {
      selectionText = `${t("selectedSearchAssetCount", {
        searchResultsCount: assets.length,
        selectedCount: selectedAssets.length
      })}`
    } else {
      selectionText = `${t("searchAssetsCount", {
        searchResultsCount: assets.length
      })}`
    }
  } else {
    selectionText = `${t("selectedAssetsCount", {
      selectedCount: selectedAssets.length
    })}`
  }
  return selectionText
}

export const getCanDisplaySelectionText = (isFetching, selectedAssets, searchError, searchInput) =>
  !isFetching && (selectedAssets?.length > 0 || (!searchError && searchInput?.length >= 3))
