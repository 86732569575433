import PropTypes from "prop-types"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import styles from "./MuteUnmutePopup.module.scss"

import { useToaster } from "../../contexts/ToastContext"
import { getMuteUnmutePayload } from "../../pages/AssetView/AssetUtils"
import { fleetMutationSVC } from "../../services/reactQueries/fleetsvc"
import { useMutationWithHandlers } from "../../utils/CustomHooks/reactQuery"
import {
  CANCEL,
  ICON_NAME,
  MUTE,
  TEXT_FIELD_MAX_CHAR,
  UNMUTE,
  specialCharactersNotAllowedForSearch
} from "../../utils/GlobalConstants"
import { removeEmoji } from "../../utils/InputValidator/validator"
import { classNames } from "../../utils/styles/helper"
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationPopup"
import Icon from "../Icon/Icon"

export default function MuteUnmutePopup({
  assetDetails,
  isOpen,
  onCancel,
  onSubmit,
  popupHeaderMsg,
  type
}) {
  const { t } = useTranslation("asset")
  const [note, setNote] = useState(null)
  const { displayMediumErrorToast } = useToaster()
  const popType = type === MUTE ? MUTE : UNMUTE
  const popHeader =
    type === MUTE
      ? t("muteUnmuteMessage.mutePopupHeading")
      : t("muteUnmuteMessage.unMutePopupHeading")

  const onMutationError = () => {
    const errorMessage = t("muteUnmuteMessage.muteError")
    displayMediumErrorToast(errorMessage)
  }

  const { requestMutation: muteUnmuteMutation } = useMutationWithHandlers({
    onErrorCallback: onMutationError,
    queryFn: fleetMutationSVC.editAssetMuteStatus,
    refetchQueries: [
      { id: assetDetails.assetId, key: "deviceDetails-byDeviceID" }
    ]
  })

  const handleOnChangeTextMsg = (event) => {
    const value = event.target.value.replace(
      specialCharactersNotAllowedForSearch,
      ""
    )
    let msg = removeEmoji(value)
    setNote(msg)
  }
  const handleSubmit = () => {
    muteUnmuteMutation({
      payload: {
        ...getMuteUnmutePayload(
          assetDetails.assetId,
          !assetDetails.muted,
          note
        )
      }
    })
    onSubmit?.({ note })
    setNote(null)
  }

  const handleCancel = () => {
    onCancel?.()
    setNote(null)
  }

  return (
    <ConfirmationPopup
      popupStyles={ styles.popupStyles }
      heading={ popHeader }
      isOpen={ isOpen }
      confirm={ popType }
      cancel={ CANCEL }
      onSubmit={ handleSubmit }
      onCancel={ handleCancel }
      isConfirmEnable={ type === MUTE ? !note : null }
    >
      { popupHeaderMsg && (
        <div className={ styles.messageContainer }>{ popupHeaderMsg }</div>
      ) }
      <div className={ styles.textFieldContainer }>
        <span className={ styles.addNote }>
          { type === MUTE
            ? t("muteUnmuteMessage.muteHeading")
            : t("muteUnmuteMessage.unMuteHeading") }
          { type === MUTE && (
            <Icon icon={ ICON_NAME.required } iconStyles={ styles.reqIcon } />
          ) }
        </span>
        <textarea
          data-testid="textField"
          className={ classNames("textArea", styles.textField) }
          maxLength={ TEXT_FIELD_MAX_CHAR }
          value={ note || "" }
          onChange={ handleOnChangeTextMsg }
        />
        <span className={ styles.instructionText }>
          { t("muteUnmuteMessage.maxChar") }
        </span>
      </div>
    </ConfirmationPopup>
  )
}

MuteUnmutePopup.propTypes = {
  assetDetails: PropTypes.shape({
    assetId: PropTypes.string,
    muted: PropTypes.bool
  }),
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  popupHeaderMsg: PropTypes.string,
  type: PropTypes.string
}
