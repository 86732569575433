import { Button } from "@mui/material"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import styes from "./Action.module.scss"

import AuthGuard from "../../../../components/AuthGuard/AuthGuard"
import DateRangePicker from "../../../../components/DateRangePicker/DateRangePicker"
import { ExportCSV } from "../../../../components/ExportCSV/ExportCSV"
import Icon from "../../../../components/Icon/Icon"
import { FaultsAuthElement } from "../../../../utils/Constants/Auth/faults"
import { EXPORT_CSV_TYPES } from "../../../../utils/Constants/ExportCSVConstants"
import { ACKNOWLEDGE, ICON_NAME, UNACKNOWLEDGE } from "../../../../utils/GlobalConstants"

const HeadSection = ({
  assetFaults,
  selectedAssetFaults,
  assetFaultsDateRange,
  setAssetFaultsDateRange,
  fileName,
  ackUnackState,
  onExportClick,
  exportRef
}) => {
  const { t } = useTranslation(["asset"])
  const [buttonValues, setButtonValues] = useState({
    isAcknowledge: true,
    isDisabled: false,
    value: t("assetFaults.buttons.acknowledge")
  })
  // const [count, setCount] = useState(0)

  const handleButtonStateUpdate = () => {
    const isAcknowledge = selectedAssetFaults.filter((item) => !item.isAcknowledge).length
    const isUnAcknowledge = selectedAssetFaults.filter((item) => item.isAcknowledge).length
    if (isAcknowledge === selectedAssetFaults.length) {
      setButtonValues({
        isAcknowledge: true,
        isDisabled: false,
        value: t("assetFaults.buttons.acknowledge")
      })
    } else if (isUnAcknowledge === selectedAssetFaults.length) {
      setButtonValues({
        isAcknowledge: false,
        isDisabled: false,
        value: t("assetFaults.buttons.unAcknowledge")
      })
    } else
      setButtonValues({
        isAcknowledge: true,
        isDisabled: true,
        value: t("assetFaults.buttons.acknowledge")
      })
  }

  useEffect(() => {
    handleButtonStateUpdate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssetFaults])

  const handleSubmit = () => {
    if (buttonValues.isAcknowledge && !buttonValues.isDisabled) {
      ackUnackState({
        faults: selectedAssetFaults,
        isOpen: true,
        message: "",
        type: ACKNOWLEDGE
      })
    } else if (!buttonValues.isAcknowledge && !buttonValues.isDisabled) {
      ackUnackState({
        faults: selectedAssetFaults,
        isOpen: true,
        message: t("assetFaults.unAcknowledgeMsg"),
        type: UNACKNOWLEDGE
      })
    }
  }

  return (
    <>
      <div className={ styes.buttonContainer }>
        { selectedAssetFaults.length > 0 && (
          <>
            <span className={ styes.selectedItemCount }>
              { selectedAssetFaults.length + " " + t("assetFaults.faultSelected") }
            </span>
            <AuthGuard
              actionType={
                buttonValues.isAcknowledge ? FaultsAuthElement.AckAssetFault : FaultsAuthElement.UnkAssetFault
              }
            >
              { !buttonValues.isDisabled && (
                <Button
                  variant="text"
                  color={ "secondary" }
                  onClick={ handleSubmit }
                  startIcon={
                    <Icon
                      icon={ buttonValues.isAcknowledge ? ICON_NAME.acknowledgeButton : ICON_NAME.unAcknowledgeButton }
                    />
                  }
                  disabled={ buttonValues.isDisabled }
                  aria-label={ buttonValues.value }
                >
                  <span>{ buttonValues.value }</span>
                </Button>
              ) }
            </AuthGuard>
          </>
        ) }
        <AuthGuard actionType={ FaultsAuthElement.ExportAssetFault }>
          <ExportCSV
            asyncDownload
            fileName={ fileName }
            type={ EXPORT_CSV_TYPES.ASSET_FAULTS }
            isDisabled={ assetFaults.length === 0 }
            ref={ exportRef }
          >
            <Button
              onClick={ onExportClick }
              startIcon={ <Icon icon={ assetFaults.length === 0 ? ICON_NAME.exportDisabled : ICON_NAME.export } /> }
              variant="text"
              color={ "secondary" }
              data-testid="exportButton"
              disabled={ assetFaults.length === 0 }
              aria-label="export"
            >
              { t("assetFaults.buttons.export") }
            </Button>
          </ExportCSV>
        </AuthGuard>
      </div>
      <DateRangePicker
        startDate={ assetFaultsDateRange.startDate }
        endDate={ assetFaultsDateRange.endDate }
        onChange={ (value) => setAssetFaultsDateRange((prevState) => ({ ...prevState, ...value })) }
      />
    </>
  )
}

HeadSection.propTypes = {
  ackUnackState: PropTypes.func,
  assetFaults: PropTypes.array,
  assetFaultsDateRange: PropTypes.object,
  exportRef: PropTypes.any,
  fileName: PropTypes.string,
  onExportClick: PropTypes.func,
  selectedAssetFaults: PropTypes.array,
  setAssetFaultsDateRange: PropTypes.func
}

export default HeadSection
