import { Button } from "@mui/material"
import React, { useMemo } from "react"

import styles from "./ManageAccess.module.scss"

import IconLabel from "../../../../../components/IconLabel/IconLabel"
import Loading from "../../../../../components/Loading/Loading"
import NestedDataTable from "../../../../../components/NestedDataTable/NestedDataTable"
import PromptModal from "../../../../../components/PromptModal/PromptModal"
import { useUserManagementContext } from "../../../../../contexts/userManagement/UserManagement"
import { ICON_NAME } from "../../../../../utils/GlobalConstants"
import { CURRENTLY_SELECTED_TYPES } from "../../../UserManagementUtils"
import {
  ManageAccessColumnConfig,
  ManageAccessFleetColumnConfig,
  ManageAccessSubFleetColumnConfig
} from "../../tableConfigurations/ManageAccessColumnConfig"

const ManageAccess = () => {
  const {
    getAccessAssociatedQueryResults,
    userManagementState,
    t,
    handleCloseManageAccessPopup,
    closeConfirmAccessPrompt,
    handleOnSave,
    handleChangeAccessSelection,
    getSelectionDetailsText,
    saveAccessMutationResults,
    getManageAccessQueryKey
  } = useUserManagementContext()
  const { data, isFetching, isError } = getAccessAssociatedQueryResults

  const enableLoader = isFetching || saveAccessMutationResults?.isLoading
  const isUser =
    userManagementState?.currentlySelectedType ===
    CURRENTLY_SELECTED_TYPES.USER

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const rowSelectionTooltipText = useMemo(
    () => t("manageAccess.manageByGroupTooltipMessage"),
    [t]
  )
  return (
    <>
      <div className={ styles.container }>
        <div className="heading">
          <span className="left">
            { userManagementState?.selectedRowDetails?.name }
          </span>
          <span className="right">
            <IconLabel
              displayLabelText
              label="Manage Access"
              iconName={ ICON_NAME.arrowRight }
            />
          </span>
        </div>
        <div className="topSection">
          <span className="tableSelectionText">
            { getSelectionDetailsText &&
              t("manageAccess.tableSelectionText", {
                selectedDetails: getSelectionDetailsText
              }) }
          </span>
        </div>

        <div className="tableSection">
          { enableLoader && <Loading /> }
          { !enableLoader && (
            <NestedDataTable
              rowHoverEffect
              rowSelectionTooltipText={ rowSelectionTooltipText }
              disableRowSelection={ (row) => row?.inherited && isUser }
              isError={ isError }
              queryKey={ getManageAccessQueryKey }
              selectedRows={ data?.data?.sites?.filter((row) => row.assigned) }
              selectableRows
              internalSelectAll
              selectedRowsWatcher={ (selectedSites) =>
                handleChangeAccessSelection(selectedSites)
              }
              tableData={ data?.data?.sites || [] }
              columnsData={ ManageAccessColumnConfig() }
              canExpandRow={ (row) => Boolean(row.fleets?.length > 0) }
              customSelectedRowsKeyName={ "sites" }
              subComponent={ ({
                row: siteRow,
                toggleRowSelection,
                ref,
                ...rest
              }) => {
                return fleetsTable({
                  isUser,
                  ref,
                  rest,
                  rowSelectionTooltipText,
                  siteRow,
                  toggleRowSelection
                })
              } }
            />
          ) }
        </div>
        { !enableLoader && (
          <div className="buttonGroup">
            <Button
              variant="outlined"
              size="large"
              onClick={ handleCloseManageAccessPopup }
              data-testid="cancelButton"
            >
              { t("cancelButton") }
            </Button>
            <span className="saveButton">
              <Button
                variant="contained"
                color="primary"
                size="large"
                data-testid="confirmButton"
                onClick={ () => handleOnSave(false) }
              >
                { t("saveButton") }
              </Button>
            </span>
          </div>
        ) }
      </div>
      <PromptModal
        { ...userManagementState?.confirmAccessPrompt }
        confirm={ t("okButton") }
        cancel={ t("cancelButton") }
        onSubmit={ () => handleOnSave(true) }
        onCancel={ closeConfirmAccessPrompt }
      />
    </>
  )
}

ManageAccess.propTypes = {}

export default ManageAccess

const fleetsTable = ({
  siteRow,
  toggleRowSelection,
  rowSelectionTooltipText,
  isUser,
  rest,
  ref
}) => {
  return (
    <NestedDataTable
      rowHoverEffect
      rowSelectionTooltipText={ rowSelectionTooltipText }
      disableRowSelection={ (row) => !!row?.inherited && isUser }
      customSelectedRowsKeyName={ "fleets" }
      selectedRows={ siteRow?.original?.fleets?.filter((row) => row.assigned) }
      dispatch={ rest.dispatch }
      parentRef={ ref }
      parentRefId={ siteRow.id }
      selectableRows
      canExpandRow={ (row) => Boolean(row.subFleets?.length > 0) }
      tableData={ siteRow?.original?.fleets || [] }
      columnsData={ ManageAccessFleetColumnConfig() }
      parentTableProps={ {
        row: siteRow,
        toggleRowSelection: toggleRowSelection
      } }
      subComponent={ ({
        row: fleetRow,
        toggleRowSelection: toggleRowSelectionA,
        ref: refA,
        ...rest
      }) => {
        return subFleetsTable({
          fleetRow,
          isUser,
          refA,
          rest,
          rowSelectionTooltipText,
          toggleRowSelectionA
        })
      } }
    />
  )
}

const subFleetsTable = ({
  fleetRow,
  toggleRowSelectionA,
  refA,
  rest,
  isUser,
  rowSelectionTooltipText
}) => {
  return (
    <NestedDataTable
      rowHoverEffect
      rowSelectionTooltipText={ rowSelectionTooltipText }
      disableRowSelection={ (row) => !!row?.inherited && isUser }
      dispatch={ rest.dispatch }
      customSelectedRowsKeyName={ "subFleets" }
      selectedRows={ fleetRow?.original?.subFleets?.filter(
        (row) => row.assigned
      ) }
      parentRef={ refA }
      parentRefId={ fleetRow.id }
      selectableRows
      parentTableProps={ {
        row: fleetRow,
        toggleRowSelection: toggleRowSelectionA
      } }
      tableData={ fleetRow?.original?.subFleets || [] }
      columnsData={ ManageAccessSubFleetColumnConfig() }
    />
  )
}
