import { Tooltip } from "@mui/material"
import PropTypes from "prop-types"
import React, { useEffect, useId, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import styles from "./AcknowledgeUnAcknowledgePopUp.module.scss"

import { useToaster } from "../../contexts/ToastContext"
import { faultSVC } from "../../services/reactQueries/faultsvc"
import { encodeURIString } from "../../utils/Common/common"
import { useMutationWithHandlers } from "../../utils/CustomHooks/reactQuery"
import {
  ACKNOWLEDGE,
  CANCEL,
  TEXT_FIELD_MAX_CHAR,
  UNACKNOWLEDGE,
  specialCharactersNotAllowedForSearch
} from "../../utils/GlobalConstants"
import { removeEmoji } from "../../utils/InputValidator/validator"
import { StorageKeys, getSession } from "../../utils/SessionHelper/session"
import { classNames } from "../../utils/styles/helper"
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationPopup"

const AcknowledgeUnAcknowledgePopUp = ({
  message,
  type,
  handleSubmit,
  handleCancel,
  isOpen,
  queryKey,
  faults
}) => {
  const id = useId()
  const { t } = useTranslation("assetFault")
  const popType = type === ACKNOWLEDGE ? ACKNOWLEDGE : UNACKNOWLEDGE
  const [showInfoTooltip, setShowInfoTooltip] = useState(false)
  const [notes, setNotes] = useState("")
  const textElementRef = useRef(null)
  const { displayMediumErrorToast } = useToaster()
  const siteUcmId = getSession(StorageKeys.SITE_UCM_ID)

  const compareSize = () => {
    const compare =
      textElementRef?.current?.scrollHeight >
      textElementRef?.current?.offsetHeight
    setShowInfoTooltip(compare)
  }

  useEffect(() => {
    setNotes("")
    compareSize()
    window.addEventListener("resize", compareSize)
  }, [isOpen])

  const getFaultCode = () => {
    return faults?.map((fault) => fault?.code)?.join(", ")
  }

  const getFaultCodeContainer = () => {
    return (
      <div aria-labelledby={ id } ref={ textElementRef }>
        { getFaultCode() }
      </div>
    )
  }

  const onMutationError = () => {
    const errorMessage =
      type === ACKNOWLEDGE
        ? t("acknowledgeAndUnAcknowledge.acknowledgeAPIError")
        : t("acknowledgeAndUnAcknowledge.unAcknowledgeAPIError")
    displayMediumErrorToast(errorMessage)
    handleSubmit()
  }

  const { requestMutation, isLoading } = useMutationWithHandlers({
    onCompletedCallback: () => handleSubmit(),
    onErrorCallback: () => onMutationError(),
    queryFn: faultSVC.acknowledgeFaults,
    refetchQueries: queryKey
      ? [
        { id: queryKey[1], key: queryKey[0] },
        { id: siteUcmId, key: "statistics-bySiteID" }
      ]
      : null
  })

  const onSubmit = () => {
    const faultsIds = []
    let faultsRequest = {}
    for (const fault of faults) {
      faultsIds.push(fault.id)
    }
    faultsRequest = {
      acknowledged: !faults[0].acknowledged,
      ids: [...faultsIds],
      note: encodeURIString(notes)
    }
    requestMutation({
      payload: {
        faults: { ...faultsRequest }
      },
      showToast: true
    })
  }

  const handleOnChangeTextMsg = (event) => {
    const value = event.target.value.replace(specialCharactersNotAllowedForSearch, "")
    let msg = removeEmoji(value)
    setNotes(msg)
  }

  return (
    <ConfirmationPopup
      popupStyles={ classNames(
        styles.popupStyles,
        type === ACKNOWLEDGE ? styles.ackOverlay : styles.unAckOverlay
      ) }
      heading={ popType }
      isOpen={ isOpen }
      confirm={ popType }
      cancel={ CANCEL }
      onSubmit={ onSubmit }
      onCancel={ handleCancel }
      isLoading={ isLoading }
    >
      <div className={ styles.faultCodeWrapper }>
        <label id={ id } className={ styles.faultCodeLabel }>
          { t("acknowledgeAndUnAcknowledge.faultCode") }
        </label>
        <span className={ styles.faultCode }>
          <Tooltip
            title={ <div className={ styles.tooltipTitle }>{ getFaultCode() }</div> }
            interactive="true"
            disableHoverListener={ !showInfoTooltip }
            arrow
            placement="bottom-end"
            PopperProps={ { className: "AckFaultCodeTooltip" } }
          >
            { getFaultCodeContainer() }
          </Tooltip>
        </span>
      </div>
      { message && <div className={ styles.messageContainer }>{ message }</div> }
      <div className={ styles.textFieldContainer }>
        <div className={ styles.addNote }>
          { t("acknowledgeAndUnAcknowledge.addNote") }
        </div>
        <textarea
          className={ classNames("textArea", styles.textField) }
          maxLength={ TEXT_FIELD_MAX_CHAR }
          value={ notes }
          onChange={ handleOnChangeTextMsg }
        />
        <div className={ styles.instructionText }>
          { t("acknowledgeAndUnAcknowledge.maxLength", {
            maxLength: TEXT_FIELD_MAX_CHAR
          }) }
        </div>
      </div>
    </ConfirmationPopup>
  )
}

AcknowledgeUnAcknowledgePopUp.propTypes = {
  faults: PropTypes.array,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  isOpen: PropTypes.bool,
  message: PropTypes.string,
  queryKey: PropTypes.array,
  type: PropTypes.string
}

export default AcknowledgeUnAcknowledgePopUp
