import React from "react"

import UsersColumnConfig from "./tableConfigurations/UsersColumnConfig"

import DataTable from "../../../components/DataTable/DataTable"
import LabelDataCountAccordion from "../../../components/LabelDataCountAccordion/LabelDataCountAccordion"
import Loading from "../../../components/Loading/Loading"
import SearchErrorPage from "../../../components/SearchErrorPage/SearchErrorPage"
import { useUserManagementContext } from "../../../contexts/userManagement/UserManagement"
import { TABLE_INSTANCES } from "../../../utils/Constants/DataTable"
import { ICON_NAME, SEARCH_DEFAULT_MIN_CHARACTERS } from "../../../utils/GlobalConstants"
import styles from "../UserManagement.module.scss"
import { CURRENTLY_SELECTED_TYPES } from "../UserManagementUtils"

const Users = () => {
  const { userManagementState, usersData, handleClickUser, t } = useUserManagementContext()

  let searchError =
    (typeof userManagementState.searchString == "string" &&
      userManagementState.searchString?.length < SEARCH_DEFAULT_MIN_CHARACTERS) ||
    (userManagementState.searchString?.length >= SEARCH_DEFAULT_MIN_CHARACTERS && (usersData?.users || []).length == 0)
  return (
    <div className={ styles.usersAccordion }>
      <LabelDataCountAccordion
        id="users-accordion"
        label={ t("users") }
        itemCount={ searchError ? 0 : usersData?.users?.length || 0 }
        icon={ ICON_NAME.users }
        customClass={ styles.accordionCustomClass }
      >
        { usersData?.isUsersFetched ? (
          <div className={ styles.userGroupTable }>
            { searchError ? (
              <div className={ styles.searchErrorContainer }>
                <SearchErrorPage />
              </div>
            ) : (
              <DataTable
                showActiveRow={ 
                  [CURRENTLY_SELECTED_TYPES.USER, ""].includes(userManagementState?.currentlySelectedType) }
                tableData={ usersData?.users || [] }
                columnsData={ UsersColumnConfig() }
                disableCellTooltip
                onRowClickCallback={ (row) => handleClickUser(row) }
                type={ TABLE_INSTANCES.USER_MANAGEMENT_NO_USERS }
                rowHoverEffect
              />
            ) }
          </div>
        ) : (
          <Loading />
        ) }
      </LabelDataCountAccordion>
    </div>
  )
}

export default Users
