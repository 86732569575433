const getCustomerRequestPayload = (value) => {
  return {
    customerAliasName: value
  }
}

const getSiteRequestPayload = (value) => {
  return {
    siteAliasName: value
  }
}

const getDeviceRequestPayload = (value, notes) => {
  return {
    friendlyName: value,
    note: notes
  }
}

const getFleetOrSubFleetRequestPayload = (value) => {
  return {
    description: "",
    name: value
  }
}

const getCreateFleetRequestPayload = (value, fleetId, siteId) => {
  return {
    fleet: {
      description: "",
      name: value
    },
    siteId: siteId
  }
}

const getCreateSubFleetRequestPayload = (value, fleetId, siteId) => {
  return {
    fleet: {
      description: "",
      name: value
    },
    parentFleetId: fleetId,
    siteId: siteId
  }
}

export const PAYLOAD_DATA = {
  assetName: getDeviceRequestPayload,
  customerName: getCustomerRequestPayload,
  fleetName: getFleetOrSubFleetRequestPayload,
  siteName: getSiteRequestPayload,
  subfleet: getFleetOrSubFleetRequestPayload
}

export const CREATE_PAYLOAD_DATA = {
  fleetName: getCreateFleetRequestPayload,
  subfleet: getCreateSubFleetRequestPayload
}
