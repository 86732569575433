/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types"
import React, { useContext, useMemo } from "react"

import { getUserSession, parseJwt } from "../../utils/Auth/token"
import { CommonAuthElement } from "../../utils/Constants/Auth/common"

let DECODED_TOKEN_DATA = null

export const getDecodedTokenData = () => {
  if (!DECODED_TOKEN_DATA) {
    const { accessToken } = getUserSession()
    DECODED_TOKEN_DATA = parseJwt(accessToken)
  }
  return DECODED_TOKEN_DATA
}

export const setDecodedTokenData = (data) => {
  DECODED_TOKEN_DATA = data
}

const AuthContext = React.createContext(null)
export const AuthProvider = ({ children }) => {

  const getTokenData = () => {
    return getDecodedTokenData() ?? null
  }
  
  const getUserScopes = () => {
    const userData = getTokenData()
    return userData?.scopes
  }

  const getAccess = (key) => {
    if (key === CommonAuthElement.Any) return true
    const userScopes = getUserScopes()
    if (Array.isArray(key)) {
      return key?.some(scope => userScopes?.includes(scope))
    }
    return userScopes?.includes(key)
  }

  const AuthValue = useMemo(() => ({
    getAccess,
    getTokenData
  }), [])

  return (
    <AuthContext.Provider value={ AuthValue }>
      { children }
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const useAuthContext = () => {
  return useContext(AuthContext)
}
