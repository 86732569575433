import { Tooltip } from "@mui/material"
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

import styles from "./AssetNameTooltip.module.scss"

const AssetNameTooltip = ({
  children,
  assetName,
  assetNote,
  customStyles,
  tooltipPlacement
}) => {
  const { t } = useTranslation(["asset"])

  const tooltipTitle = () => {
    return (
      <span className={ styles.tooltipTitleContainer }>
        <span className={ styles.labelStyles }>
          { t("tooltipLabel.name") }<span className={ styles.valueStyles }>{ assetName }</span>
        </span>
        <span className={ styles.labelStyles }>
          { t("tooltipLabel.note") }<span className={ styles.valueStyles }>{ assetNote }</span>
        </span>
      </span>
    )
  }

  return (
    <Tooltip
      title={ <span className={ "tooltipTitle" }>{ tooltipTitle() }</span> }
      interactive="true"
      classes={ { tooltip: customStyles } }
      placement={ tooltipPlacement }
      arrow
    >
      { children }
    </Tooltip>
  )
}

AssetNameTooltip.propTypes = {
  assetName: PropTypes.string,
  assetNote: PropTypes.string,
  children: PropTypes.node,
  customStyles: PropTypes.any,
  tooltipPlacement: PropTypes.string
}

export default AssetNameTooltip
