import PropTypes from "prop-types"
import React from "react"

import DateRangePicker from "../../../../components/DateRangePicker/DateRangePicker"

const HeadSection = ({
  dateRange,
  setDateRange
}) => {
  return (
    <DateRangePicker
      startDate={ dateRange.startDate }
      endDate={ dateRange.endDate }
      onChange={ (value) => setDateRange((prevState) => ({ ...prevState, ...value })) }
    />
  )
}

HeadSection.propTypes = {
  dateRange: PropTypes.object,
  setDateRange: PropTypes.func
}

export default HeadSection
