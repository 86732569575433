import React from "react"
import { DragDropContext, Droppable } from "react-beautiful-dnd"

import { BREADCRUMB_ROUTE } from "../../../../components/Breadcrumb/BreadcrumbUtils"
import FleetExpandedCard from "../../../../components/FleetExpandedCard/FleetExpandedCard"
import PageLink from "../../../../components/PageLink/PageLink"
import { useSiteViewContext } from "../../../../contexts/siteView/SiteView"
import { ROUTE_CONFIG } from "../../../../utils/GlobalConstants"
import { classNames } from "../../../../utils/styles/helper"
import style from "../../SiteView.module.scss"
import AssetsNotInFleetContainer from "../AssetsNotInFleetContainer/AssetsNotInFleetContainer"
import { getFleetStyle } from "../fleetsDndStyles"
import cssStyles from "../FleetsSummary.module.scss"
const GroupByFleet = () => {
  const currentURL = window.location.pathname
  const {
    handleConfirmPopup,
    fleetListCardRef,
    assetContainerRef,
    siteViewState,
    setSelectedAssets,
    handleOnDragEnd,
    setIsEdit
  } = useSiteViewContext()

  const fleets = siteViewState?.contents

  return (
    <DragDropContext onDragEnd={ handleOnDragEnd }>
      <div className={ classNames(style.fleets, cssStyles.fleet) }>
        <div ref={ fleetListCardRef }>
          { fleets?.map((fleet) => {
            return (
              <Droppable key={ fleet.fleetId } droppableId={ fleet.fleetId }>
                { (provided, snapshot) => (
                  <PageLink
                    className={ cssStyles.removeLinkStyles }
                    to={ currentURL.concat(
                      `/${ROUTE_CONFIG.FLEET.routeName}/${fleet?.[siteViewState.groupByMappingObject.idKey]}`
                    ) }
                    type={ BREADCRUMB_ROUTE.FLEET }
                    pageName={ fleet?.[siteViewState.groupByMappingObject.titleKey] }
                  >
                    <div
                      { ...provided.droppableProps }
                      ref={ provided.innerRef }
                      style={ getFleetStyle(snapshot.isDraggingOver) }
                    >
                      <FleetExpandedCard
                        titleKey={ siteViewState.groupByMappingObject.titleKey }
                        setIsEdit={ setIsEdit }
                        fleetDetails={ fleet }
                        setIsConfirmPopUp={ handleConfirmPopup }
                        isConfirmPopUp={ siteViewState?.confirmPopup }
                      />
                    </div>
                    <div style={ { display: "none" } }>{ provided.placeholder }</div>
                  </PageLink>
                ) }
              </Droppable>
            )
          }) }
        </div>
        <Droppable
          isCombineEnabled={ false }
          isDropDisabled={ true }
          key={ "assetsNotInFleet" }
          droppableId="assetsNotInFleet"
        >
          { (provided) => (
            <>
              <div { ...provided.droppableProps } ref={ provided.innerRef }>
                <AssetsNotInFleetContainer
                  fleetCount={ siteViewState?.contents?.length }
                  setSelectedAssets={ setSelectedAssets }
                  assetsNotInFleetData={ siteViewState?.assetsNotInFleet }
                  isCardView={ siteViewState?.isCardView }
                  ref={ assetContainerRef }
                />
              </div>
              { provided.placeholder }
            </>
          ) }
        </Droppable>
      </div>
    </DragDropContext>
  )
}

GroupByFleet.propTypes = {}

export default GroupByFleet
