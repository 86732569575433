import PropTypes from "prop-types"
import { useState } from "react"

const useCheckBoxSelection = ({
  data,
  onSelectRowCallback,
  onSelectAllCallback,
  onReset,
  uniqueKey = "id"
}) => {
  const [selectedRows, setSelectedRows] = useState([])

  const handleSelectRow = (checked, row) => {
    if (checked) {
      setSelectedRows((prevState) => {
        onSelectRowCallback?.([...prevState, row])
        return [...prevState, row]
      })
    } else {
      setSelectedRows((selectedRowsState) => {
        onSelectRowCallback?.(
          selectedRowsState.filter((currentSelection) => currentSelection[uniqueKey] !== row[uniqueKey])
        )
        return selectedRowsState.filter(
          (currentSelection) => currentSelection[uniqueKey] !== row[uniqueKey]
        )
      })
    }
  }

  const handleSelectAll = (checked, _data) => {
    if (_data && checked) {
      if (selectedRows.length === 0) {
        setSelectedRows(_data)
        onSelectAllCallback?.(_data)
      } else {
        setSelectedRows([])
        onSelectAllCallback?.([])
      }
    } else if (checked && selectedRows.length === 0) {
      setSelectedRows(data)
      onSelectAllCallback?.(data)
    } else {
      onSelectAllCallback?.([])
      setSelectedRows([])
    }
  }

  const setDefaultSelectedRows = (data) => {
    setSelectedRows(data)
    onSelectRowCallback?.(data)
  }

  const resetSelection = () => {
    onReset?.()
    setSelectedRows([])
  }

  return {
    allRowsSelected: data?.length > 0 && selectedRows.length === data.length,
    handleSelectAll,
    handleSelectRow,
    resetSelection,
    selectedRows,
    setDefaultSelectedRows
  }
}

useCheckBoxSelection.propTypes = {
  data: PropTypes.array.isRequired,
  onSelectAllCallback: PropTypes.func,
  onSelectRowCallback: PropTypes.func,
  uniqueKey: PropTypes.string
}

export default useCheckBoxSelection
