import Button from "@mui/material/Button"
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import AuthGuard from "../../../../components/AuthGuard/AuthGuard"
import SingleSelectDropdown from "../../../../components/Dropdown/SingleSelectDropdown"
import Icon from "../../../../components/Icon/Icon"
import SearchBar from "../../../../components/SearchBar/SearchBar"
import SelectedFilters from "../../../../components/SelectedFilterItem/SelectedFilters"
import { FleetAuthElement } from "../../../../utils/Constants/Auth/fleet"
import { SEARCH_INSTANCES } from "../../../../utils/Constants/Search"
import useAccess from "../../../../utils/CustomHooks/useAccess"
import {
  COMPARE_VALUE,
  DROPDOWN_OPTIONS,
  GROUP_BY,
  ICON_NAME,
  ROUTE_CONFIG
} from "../../../../utils/GlobalConstants"
import { StorageKeys, setSession } from "../../../../utils/SessionHelper/session"
import { SITE_ACCESS } from "../../SiteUtils"
import styles from "../SiteGroupAssetView.module.scss"

const HeadSection = ({
  setIsCardView,
  isCardView,
  setOpenFilter,
  filter,
  searchResult,
  searchError,
  setSelectedFilter,
  selectedAssets,
  groupOption,
  setGroupByOption,
  handleMoveFleet,
  handleSearch,
  fleetCount
}) => {
  const { t } = useTranslation("site")
  const navigate = useNavigate()
  const queryParamIds = useParams()
  const customerId = queryParamIds[ROUTE_CONFIG.CUSTOMER.param]
  const siteId = queryParamIds[ROUTE_CONFIG.SITE.param]

  const isMoveButtonOn = useAccess(SITE_ACCESS.moveAsset) && selectedAssets?.length > 0 && fleetCount > 0

  const handleAddFleetClick = () => {
    setSession(StorageKeys.SITE_CREATE_FLEET, JSON.stringify(true))
    setSession(StorageKeys.SITE_GROUP_SELECTED_ASSETS, JSON.stringify(selectedAssets))
    navigate(`/${ROUTE_CONFIG.CUSTOMER.routeName}/${customerId}/${ROUTE_CONFIG.SITE.routeName}/${siteId}`)
  }

  const searchResultText = () => {
    return searchResult.length === 0
      ? t("noSearchResult")
      : `${t("headSection.assetsSearchedCountText", {
        searchCount: searchResult.length
      })}`
  }
  const disableFilterItem = (filter) => {
    return filter.disabled === true
      ? filter.type === COMPARE_VALUE.modality || filter.type === COMPARE_VALUE.model
      : null
  }

  return (
    <div className={ styles.headSection }>
      <div className={ styles.leftSection }>
        <div>
          <SingleSelectDropdown
            options={ DROPDOWN_OPTIONS.SUB_FLEET_VIEW_GROUP_BY }
            optionBy={ GROUP_BY }
            value={ groupOption }
            onChange={ setGroupByOption }
            disabled
          />
        </div>
        { Object.keys(filter).length > 0 && (
          <div className={ styles.filterBlock }>
            <SelectedFilters
              handleClearAll={ () => setSelectedFilter([]) }
              filters={ filter }
              setSelectedFilterItem={ setSelectedFilter }
              selectedFilterItem={ Object.values(filter) }
              filterDisabled={ true }
              disableFilter={ disableFilterItem }
            />
          </div>
        ) }
      </div>
      <div className={ styles.rightSection }>
        { searchResult ? (
          <span role={ "contentinfo" } className={ styles.assetsCountText }>
            { selectedAssets?.length > 0
              ? `${t("headSection.assetsSelectedCountText", {
                searchCount: searchResult.length,
                selectedCount: selectedAssets?.length
              })}`
              : searchResultText() }
          </span>
        ) : (
          selectedAssets?.length > 0 && (
            <span className={ styles.selectedCountText }>
              { `${t("headSection.selectedCountText", {
                selectedCount: selectedAssets?.length
              })}` }
            </span>
          )
        ) }
        { isMoveButtonOn && (
          <Button
            variant="text"
            color="secondary"
            startIcon={ <Icon icon={ ICON_NAME.moveTo } /> }
            onClick={ () => handleMoveFleet() }
            aria-label="Move to"
          >
            { t("move-to") }
          </Button>
        ) }
        <AuthGuard actionType={ FleetAuthElement.CreateFleet }>
          { (!searchResult || selectedAssets?.length > 0) && !searchError && (
            <Button
              variant="text"
              color="secondary"
              startIcon={ <Icon icon={ ICON_NAME.addFleet } /> }
              onClick={ handleAddFleetClick }
              aria-label="Add fleet"
            >
              { t("add-fleet") }
            </Button>
          ) }
        </AuthGuard>
        <button
          className={ styles.iconButton }
          onClick={ () => setOpenFilter(true) }
          aria-label="Filter"
        >
          {
            <Icon
              icon={
                Object.keys(filter).length > 0
                  ? ICON_NAME.filterBadge
                  : ICON_NAME.filter
              }
              iconStyles={ Object.keys(filter).length > 0 && styles.iconStyles }
            />
          }
        </button>
        <button
          aria-label="Toggle View"
          className={ !isCardView ? styles.toggleButtonList : styles.toggleButtonCard }
          onClick={ () => setIsCardView(!isCardView) }
        >
          {
            <Icon
              icon={ ICON_NAME.gridView }
            />
          }
        </button>
        <span className={ styles.searchBar }>
          <SearchBar type={ SEARCH_INSTANCES.siteView } onHandleSubmit={ handleSearch }  />
        </span>
      </div>
    </div>
  )
}

HeadSection.propTypes = {
  filter: PropTypes.array,
  fleetCount: PropTypes.number,
  groupOption: PropTypes.number,
  handleMoveFleet: PropTypes.func,
  handleSearch: PropTypes.func,
  isCardView: PropTypes.bool,
  searchError: PropTypes.any,
  searchResult: PropTypes.array,
  selectedAssets: PropTypes.array,
  setGroupByOption: PropTypes.func,
  setIsCardView: PropTypes.func,
  setOpenFilter: PropTypes.func,
  setSelectedFilter: PropTypes.func
}

export default HeadSection
