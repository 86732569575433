import { DATE_FORMAT } from "../../../utils/Constants/TimeZones"
import { formatDate } from "../../../utils/helper"

const columns = (muted) => [
  {
    accessorKey: "displayName",
    className: muted && "opacityForText",
    header: "Name",
    size: 300
  },
  {
    accessorKey: "value",
    className: muted && "opacityForText",
    header: "Value",
    size: 450
  },
  {
    accessorKey: "updatedOn",
    cell: ({ cell }) => formatDate(cell.getValue(), DATE_FORMAT.dateTime),
    className: muted && "opacityForText",
    defaultCanSort: true,
    header: "Last updated",
    sortDesc: true
  }
]

export default columns
