import PropTypes from "prop-types"
import React from "react"
import { useIsFetching } from "react-query"

import AssetView from "./AssetView"
import ConnectionType from "./ConnectionType"
import ModalityType from "./ModalityType"
import Model from "./Model"
import SubFleetView from "./SubFleetView"

import Loading from "../../../components/Loading/Loading"
import SearchErrorPage from "../../../components/SearchErrorPage/SearchErrorPage"
import { useFleetViewContext } from "../../../contexts/fleetView/FleetView"
import { QUERY_KEYS } from "../../../utils/Constants/Queries"
import styles from "../FleetView.module.scss"

const GroupByView = ({ handleSelectRow, handleSelectAll, handleAddSubFleet }) => {
  const { fleetViewState, groupByAssetsRef } = useFleetViewContext()

  const isFetchingData = useIsFetching({
    queryKey: [QUERY_KEYS.FLEET_VIEW_GROUP_BY, fleetViewState.fleetId]
  })

  if (isFetchingData || fleetViewState?.loadingDeviceList) {
    return <Loading />
  } else if (
    (fleetViewState?.currentAPIGroupBy === "assets" &&
      fleetViewState?.searchInput &&
      fleetViewState?.subFleets?.length === 0) ||
    fleetViewState?.searchError
  ) {
    return (
      <div className={ styles.fleets }>
        <SearchErrorPage />
      </div>
    )
  } else if (fleetViewState.groupBySelectedValue === 1) {
    return <SubFleetView handleAddSubFleet={ handleAddSubFleet } />
  } else if (fleetViewState.groupBySelectedValue === 2) {
    return <ModalityType modalities={ fleetViewState?.subFleets } />
  } else if (fleetViewState.groupBySelectedValue === 3) {
    return <Model models={ fleetViewState?.subFleets } />
  } else if (fleetViewState.groupBySelectedValue === 6) {
    return <ConnectionType connections={ fleetViewState?.subFleets } />
  } else if (fleetViewState.groupBySelectedValue === 7) {
    return (
      <AssetView
        ref={ groupByAssetsRef }
        assets={ fleetViewState?.subFleets }
        handleSelectRow={ handleSelectRow }
        handleSelectAll={ handleSelectAll }
        selectedAssets={ fleetViewState.selectedAssets }
      />
    )
  } else return null
}

GroupByView.propTypes = {
  handleAddSubFleet: PropTypes.func,
  handleSelectAll: PropTypes.func,
  handleSelectRow: PropTypes.func
}

export default GroupByView
