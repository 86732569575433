/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import styles from "./ConfirmDeployConfig.module.scss"

import { getConfigTabDeploymentPayload } from "../../pages/AssetView/Configuration/ConfigurationUtils"
import { fileSVC, fileSVCKeys } from "../../services/reactQueries/filesvc"
import { fleetSVCKeys } from "../../services/reactQueries/fleetsvc"
import { useMutationWithHandlers } from "../../utils/CustomHooks/reactQuery"
import {
  CANCEL,
  ICON_NAME,
  OK,
  ROUTE_CONFIG
} from "../../utils/GlobalConstants"
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationPopup"
import Icon from "../Icon/Icon"

const ConfirmDeployConfig = ({
  isOpen,
  onSubmit,
  popupStyles,
  heading,
  onCancel,
  warning,
  message,
  type,
  selectedAssets,
  serialNumber,
  selectedFileId,
  fileName
}) => {
  const queryParamIds = useParams()
  const siteId = queryParamIds[ROUTE_CONFIG.SITE.param]
  const [refetchQueries, setRefetchQueries] = useState([])

  useEffect(() => {
    const refetchQueriesData = []
    if (type === "centralConfigDeployment") {
      refetchQueriesData.push({ id: siteId, key: fleetSVCKeys.ASSETS_FOR_DEPLOYMENT })
    } else if (type === "deploy") {
      refetchQueriesData.push( { id: serialNumber, key: fileSVCKeys.GET_ASSET_CONFIG })
    }
    setRefetchQueries(refetchQueriesData)
  }, [type])

  const { t } = useTranslation(["confirmDeploy"])

  const { requestMutation: deployConfiguration } = useMutationWithHandlers({
    onCompletedCallback: () => onSubmit({ isOpen: false }),
    onErrorCallback: () => onSubmit({ isOpen: false }),
    queryFn: fileSVC.deployConfig,
    refetchQueries
  })

  const handleOnSubmit = () => {
    if (type === "centralConfigDeployment") {
      let successMessage = ""
      if (selectedAssets.length > 1) {
        successMessage =  `${t("centralMultipleDeploymentSuccess", {
          count: selectedAssets?.length,
          fileName
        })}`
      } else {
        successMessage = `${t("centralSingleDeploymentSuccess", {
          assetName: selectedAssets[0]?.name,
          fileName
        })}`
      }
      const serialNumbers = selectedAssets?.map(asset => asset.serialNumber)
      deployConfiguration({
        errorMessage: t("deploymentError"),
        payload: getConfigTabDeploymentPayload(serialNumbers, selectedFileId),
        successMessage
      })
    } else if (type === "deploy") {
      deployConfiguration({
        errorMessage: t("deploymentError"),
        payload: getConfigTabDeploymentPayload([serialNumber], selectedFileId),
        successMessage: t("deploymentSuccess")
      })
    }
  }

  return (
    <ConfirmationPopup
      heading={ heading }
      isOpen={ isOpen }
      onSubmit={ handleOnSubmit }
      onCancel={ onCancel }
      confirm={ OK }
      cancel={ type && CANCEL }
      popupStyles={ popupStyles }
    >
      <span className={ styles.confirmContent }>{ message }</span>

      { warning && (
        <span className={ styles.warningSection }>
          <Icon icon={ ICON_NAME.alertRed } iconStyles={ styles.warningIcon } />
          <span className={ styles.confirmText }>{ warning }</span>
        </span>
      ) }
    </ConfirmationPopup>
  )
}

ConfirmDeployConfig.propTypes = {
  fileName: PropTypes.string,
  heading: PropTypes.string,
  isOpen: PropTypes.bool,
  message: PropTypes.string,
  onCancel: PropTypes.any,
  onSubmit: PropTypes.any,
  popupStyles: PropTypes.any,
  selectedAssets: PropTypes.array,
  selectedFileId: PropTypes.string,
  serialNumber: PropTypes.string,
  type: PropTypes.string,
  warning: PropTypes.string
}

export default ConfirmDeployConfig
