import { Accordion, AccordionDetails, AccordionSummary, Chip, IconButton } from "@mui/material"
import PropTypes from "prop-types"
import React, { useState } from "react"

import styles from "./LabelDataCountAccordion.module.scss"

import { ICON_NAME } from "../../utils/GlobalConstants"
import { classNames } from "../../utils/styles/helper"
import Icon from "../Icon/Icon"
import IconLabel from "../IconLabel/IconLabel"

const LabelDataCountAccordion = ({
  id,
  label,
  itemCount,
  icon,
  className,
  children,
  onChange,
  customClass,
  selectedAssets,
  actionClick
}) => {
  const [expanded, setExpanded] = useState(true)

  const toggleAccordion = () => {
    onChange?.(!expanded)
    setExpanded((prev) => !prev)
  }

  return (
    <Accordion
      expanded={ expanded }
      className={ classNames(styles.accordionLayout, customClass) }
      sx={ { border: "none", marginBottom: "16px", position: "unset" } }
      data-testid={ `mui-accordion-${id}` }
    >
      <AccordionSummary
        onClick={ toggleAccordion }
        className={ classNames(
          expanded ? "expanded" : "collapsed",
          "centralPages",
          (selectedAssets?.length > 0 || actionClick) && "accordionCheckBoxClass"
        ) }
        expandIcon={
          <IconButton
            data-testid={ `expand-button-${id}` }
          >
            <Icon icon={ ICON_NAME.expandMoreIcon } />
          </IconButton>
        }
        data-testid={ `mui-accordion-summary-${id}` }
      >
        <div className={ styles.accordionLabel }>
          <IconLabel
            iconName={ icon }
            iconStyles={ styles.icon }
            label={ label }
            labelStyles={ classNames(styles.labelStyles, styles.textEllipsis) }
          />
          <Chip
            label={ itemCount }
            color="primary"
            size="small"
            className="CountChip"
            data-testid={ `asset-count-${id}` }
          />
        </div>
      </AccordionSummary>
      <AccordionDetails
        className={ classNames(styles.accordionDetailsStyles, className) }
        sx={ { padding: 0 } }
        data-testid={ `accordion-details-${id}` }
      >
        { children }
      </AccordionDetails>
    </Accordion>
  )
}

LabelDataCountAccordion.propTypes = {
  actionClick: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  customClass: PropTypes.any,
  icon: PropTypes.string,
  id: PropTypes.string,
  itemCount: PropTypes.number,
  label: PropTypes.string,
  onChange: PropTypes.func,
  selectedAssets: PropTypes.array
}

export default LabelDataCountAccordion
