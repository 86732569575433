import IconButton from "@mui/material/IconButton"
import PropTypes from "prop-types"
import React, { forwardRef, useImperativeHandle, useMemo, useRef } from "react"
import { useTranslation } from "react-i18next"

import ColumnConfiguration from "./internals/ColumnConfiguration"

import DataTable from "../../../../components/DataTable/DataTable"
import SingleSelectDropdown from "../../../../components/Dropdown/SingleSelectDropdown"
import Icon from "../../../../components/Icon/Icon"
import Loading from "../../../../components/Loading/Loading"
import SearchBar from "../../../../components/SearchBar/SearchBar"
import SearchErrorPage from "../../../../components/SearchErrorPage/SearchErrorPage"
import SelectedFilters from "../../../../components/SelectedFilterItem/SelectedFilters"
import { TABLE_INSTANCES } from "../../../../utils/Constants/DataTable"
import { SEARCH_INSTANCES } from "../../../../utils/Constants/Search"
import useCheckBoxSelection from "../../../../utils/CustomHooks/useCheckBoxSelection"
import {
  ALERT_CONFIG_STATUS,
  CONFIG_DEPLOY_DROPDOWN_OPTIONS,
  GROUP_BY,
  ICON_NAME
} from "../../../../utils/GlobalConstants"
import { formatStatus } from "../../../../utils/helper"
import { useConfigContext } from "../../Context/ConfigurationContext"
import { getAssetSelectionText, getCanDisplaySelectionText, shouldDisplaySearchError } from "../ConfigDeploymentUtils"
import styles from "../ConfigurationDeployment.module.scss"

// eslint-disable-next-line react/display-name
const DeploymentAssetsSummary = forwardRef(
  (
    {
      assets,
      isFetching,
      searchError,
      searchInput,
      setSearchError,
      setSearchInput,
      searchInputRef,
      setIsFilterOpen,
      stateCarryForward,
      setSelectedAssets,
      setSelectedFilters
    },
    ref
  ) => {
    const assetsTableRef = useRef(null)

    useImperativeHandle(ref, () => ({
      resetCheckboxSelection() {
        resetSelection()
        assetsTableRef?.current?.toggleAll(false)
      }
    }))
    const { t } = useTranslation(["configDeployment"])

    const {
      selectedRows: selectedAssets,
      handleSelectRow,
      handleSelectAll,
      resetSelection
    } = useCheckBoxSelection({
      data: assets,
      onReset: () => setSelectedAssets([]),
      onSelectAllCallback: setSelectedAssets,
      onSelectRowCallback: setSelectedAssets
    })

    const setIsSearch = (value, closeAction, hasError) => {
      setSearchInput(value)
      if (hasError || closeAction) {
        setSearchError(hasError)
      } else {
        resetSelection()
        setSearchError(false)
      }
    }

    const handleFilterOpen = () => {
      setIsFilterOpen(true)
    }
    const { filter, setFilter } = useConfigContext()

    const disableFilterItem = (filter) => {
      return filter.type === "modality"
    }

    const handleFilters = (newFilters) => {
      setSelectedFilters(newFilters)
      setFilter(newFilters)
    }

    const assetSelectionText = useMemo(
      () => getAssetSelectionText(assets, selectedAssets, searchInput, t),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [assets, selectedAssets, searchInput]
    )

    const isSearchError = useMemo(
      () => shouldDisplaySearchError(searchError, searchInput, assets),
      [searchError, searchInput, assets]
    )

    const displaySelectionText = useMemo(
      () => getCanDisplaySelectionText(isFetching, selectedAssets, searchError, searchInput),
      [isFetching, selectedAssets, searchError, searchInput]
    )

    return (
      <>
        <div className={ styles.filtersSection }>
          <div className={ styles.pageContentLeft }>
            <div>
              <SingleSelectDropdown
                defaultOption={ 1 }
                options={ CONFIG_DEPLOY_DROPDOWN_OPTIONS.SITE_VIEW_GROUP_BY }
                optionBy={ GROUP_BY }
                value={ 1 }
                disabled
              />
            </div>
            { Object.keys(filter).length > 0 && (
              <div className={ styles.filterBlock }>
                <SelectedFilters
                  filters={ filter }
                  setSelectedFilterItem={ handleFilters }
                  selectedFilterItem={ Object.values(filter) }
                  disableFilter={ disableFilterItem }
                  stateCarryForward={ stateCarryForward }
                  isPageLoading={ isFetching }
                />
              </div>
            ) }
            { displaySelectionText && (
              <div role={ "contentinfo" } className={ styles.searchResultsText }>
                { searchError && searchInput && searchInput.length >= 3 ? t("noSearchResult") : assetSelectionText }
              </div>
            ) }
          </div>
          <div className={ styles.pageContentRight }>
            <IconButton aria-label="Filter Button" 
              onClick={ () => handleFilterOpen() } className={ styles.filterButton }>
              <Icon icon={ Object.keys(filter).length > 0 ? ICON_NAME.filterBadge : ICON_NAME.filter } />
            </IconButton>
            <SearchBar
              ref={ searchInputRef }
              type={ SEARCH_INSTANCES.centralConfigDeployment }
              onHandleSubmit={ setIsSearch }
            />
          </div>
        </div>
        { isSearchError ? (
          <div className={ styles.searchErrorContainer }>
            <SearchErrorPage />
          </div>
        ) : (
          <>
            { isFetching ? (
              <Loading />
            ) : (
              <div className={ styles.assetsSearchTable }>
                <DataTable
                  selectableRows
                  displayRowColorBar
                  internalSelectAll
                  rowHoverEffect
                  highlightSelectedRows
                  disableRowSelection={ (row) => {
                    const configStatus = formatStatus(row.configStatus)
                    return row.mute ||
                    configStatus === formatStatus(ALERT_CONFIG_STATUS.INDOWNLOAD.TEXT) ||
                    configStatus === formatStatus(ALERT_CONFIG_STATUS.ININSTALL.TEXT)
                  } }
                  tableData={ assets }
                  selectedRows={ selectedAssets }
                  columnsData={ ColumnConfiguration() }
                  onSelectAllCallBack={ (checked, selectableAssets) => handleSelectAll(checked, selectableAssets) }
                  onSelectRowCallBack={ ({ checked, data }) => handleSelectRow(checked, data) }
                  type={ TABLE_INSTANCES.CONFIG_DEPLOYMENT }
                  ref={ assetsTableRef }
                />
              </div>
            ) }
          </>
        ) }
      </>
    )
  }
)

DeploymentAssetsSummary.propTypes = {
  assets: PropTypes.array,
  isFetching: PropTypes.bool,
  searchError: PropTypes.bool,
  searchInput: PropTypes.string,
  searchInputRef: PropTypes.any,
  setIsFilterOpen: PropTypes.func,
  setSearchError: PropTypes.func,
  setSearchInput: PropTypes.func,
  setSelectedAssets: PropTypes.func,
  setSelectedFilters: PropTypes.func,
  stateCarryForward: PropTypes.any
}

export default DeploymentAssetsSummary
