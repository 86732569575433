/* eslint-disable react-hooks/exhaustive-deps */
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import PropTypes from "prop-types"
import React, { useState } from "react"

import styles from "./AssetsNotInFleet.module.scss"

import { CommonAuthElement } from "../../utils/Constants/Auth/common"
import useAccess from "../../utils/CustomHooks/useAccess"
import { ASSETS_NOT_IN_FLEET, EXPANDED_CARD_DISTRIBUTION, ICON_NAME } from "../../utils/GlobalConstants"
import { classNames } from "../../utils/styles/helper"
import CheckBoxLabel from "../CheckBoxLabel/CheckBoxLabel"
import Distribution from "../Distribution/Distribution"
import Icon from "../Icon/Icon"
import IconLabel from "../IconLabel/IconLabel"

const AssetsNotInFleet = ({
  assetsInformation,
  children,
  onChange,
  onSelect,
  isChecked,
  isExpanded,
  isSelectable,
  customClass,
  displayBars,
  displayLabelText,
  distributionData
}) => {
  const authorizedSelect = useAccess(CommonAuthElement.Any)

  const [expanded, setExpanded] = useState(isExpanded)

  const handleCheckBox = (e) => {
    onSelect?.(e.target.checked)
  }

  const toggleAccordion = () => {
    onChange?.(!expanded)
    setExpanded((prev) => !prev)
  }

  return (
    <Accordion
      data-testid="mui-accordion"
      expanded={ expanded }
      className={ classNames(
        styles.accordionLayout,
        !authorizedSelect && styles.unAuthorizedSelectCheckbox,
        customClass
      ) }
    >
      <AccordionSummary
        onClick={ toggleAccordion }
        className={ classNames(isExpanded ? "expanded" : "collapsed", "siteView", 
          isChecked && "accordionCheckBoxClass") }
        expandIcon={
          <button className={ styles.button } data-testid="accordionExpandButton">
            <Icon icon={ ICON_NAME.expandMoreIcon } iconStyles={ styles.expandIcon } />
          </button>
        }
      >
        <span className={ styles.card }>
          <span className={ classNames(styles.iconLabel, "NotInFleet_IconLabel") }>
            { expanded && isSelectable ? (
              <CheckBoxLabel
                isChecked={ isChecked }
                onSelect={ handleCheckBox }
                iconStyles={ styles.icon }
                label={ assetsInformation.label }
                labelStyles={ styles.textEllipsis }
              />
            ) : (
              <IconLabel
                iconName={ ASSETS_NOT_IN_FLEET.icon }
                iconStyles={ styles.icon }
                label={ assetsInformation.label }
                labelStyles={ styles.textEllipsis }
              />
            ) }
          </span>
          <span className={ styles.distributionPanel }>
            { (distributionData ?? EXPANDED_CARD_DISTRIBUTION)?.map((data) => (
              <Distribution
                key={ data.type }
                type={ data.type }
                displayLabelText={ displayLabelText }
                displayBars={ displayBars }
                distribution={ data.distribution }
                customStyles={ styles.distributionBar }
              />
            )) }
          </span>
        </span>
      </AccordionSummary>
      <AccordionDetails
        data-testid="accordion-details"
        className={ styles.accordionDetailsStyles }
        style={ { padding: "0px" } }
        onClick={ (e) => e.stopPropagation() }
      >
        { children }
      </AccordionDetails>
    </Accordion>
  )
}

AssetsNotInFleet.propTypes = {
  assetsInformation: PropTypes.shape({
    distributionData: PropTypes.arrayOf(
      PropTypes.shape({
        distribution: PropTypes.array,
        type: PropTypes.oneOf(["assets", "alerts", "updates", "swpackages", "configs"])
      })
    ),
    label: PropTypes.string
  }),
  children: PropTypes.node,
  customClass: PropTypes.any,
  displayBars: PropTypes.any,
  displayLabelText: PropTypes.bool,
  distributionData: PropTypes.array,
  isChecked: PropTypes.string,
  isExpanded: PropTypes.bool,
  isSelectable: PropTypes.bool,
  onChange: PropTypes.func,
  onSelect: PropTypes.func
}

Distribution.defaultProps = {
  displayBars: true,
  displayLabelText: true
}

export default AssetsNotInFleet
