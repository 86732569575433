/* eslint-disable react-hooks/exhaustive-deps */
import { PropTypes } from "prop-types"
import React from "react"

import useAccess from "../../utils/CustomHooks/useAccess"

const AuthGuard = ({ actionType, children, fallbackElement }) => {
  const userHasAccess = useAccess(actionType)

  return <>{ userHasAccess ? children : fallbackElement }</>
}

AuthGuard.propTypes = {
  actionType: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  children: PropTypes.node,
  fallbackElement: PropTypes.any
}

export default AuthGuard
