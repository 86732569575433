import { Accordion, AccordionDetails, AccordionSummary, IconButton } from "@mui/material"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import styles from "./NavigatorLabelAccordion.module.scss"

import { useBreadcrumbContext } from "../../../../../contexts/breadcrumb/breadcrumb"
import { ICON_NAME } from "../../../../../utils/GlobalConstants"
import { classNames } from "../../../../../utils/styles/helper"
import Icon from "../../../../Icon/Icon"
import IconLabel from "../../../../IconLabel/IconLabel"
import PageLink from "../../../../PageLink/PageLink"
import { BREADCRUMB_ROUTE } from "../../../BreadcrumbUtils"

const NavigatorLabelAccordion = ({
  id,
  label,
  itemCount,
  handleLabelClick,
  route,
  active,
  childActive,
  isExpanded,
  breadcrumbType,
  navLabelStyles,
  showExpandButton,
  parentSite,
  parentFleet,
  children
}) => {
  const { updateBreadcrumbData } = useBreadcrumbContext()
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    setExpanded(isExpanded)
  }, [isExpanded])

  const handleLinkClick = () => {
    if (breadcrumbType === BREADCRUMB_ROUTE.FLEET) {
      updateBreadcrumbData({
        key: BREADCRUMB_ROUTE.SITE,
        name: parentSite?.aliasName ?? parentSite?.name
      })
    } else if (breadcrumbType === BREADCRUMB_ROUTE.SUB_FLEET) {
      updateBreadcrumbData({
        key: BREADCRUMB_ROUTE.SITE,
        name: parentSite?.aliasName ?? parentSite?.name
      })
      updateBreadcrumbData({
        id: parentFleet?.id,
        key: BREADCRUMB_ROUTE.FLEET,
        name: parentFleet?.name
      })
    }
    handleLabelClick()
  }

  return (
    <div className={ styles.accordionLayout }>
      <Accordion
        expanded={ expanded ?? false }
        sx={ { border: "none", position: "unset" } }
        data-testid={ `mui-accordion-${id}` }
        className={ styles.accordionContainer }
      >
        <AccordionSummary
          className={ classNames(
            styles.accordionSummary, 
            "SiteNavigatorSummary",
            `${breadcrumbType}`,
            !showExpandButton && styles.noExpand
          ) }
          expandIcon={
            showExpandButton ? (
              <IconButton
                className={ styles.expandIcon }
                onClick={ () => setExpanded((prev) => !prev) }
                aria-label={ `Expand button ${id}` }
              >
                <Icon icon={ ICON_NAME.expandMoreIcon } />
              </IconButton>
            ) : false
          }
          data-testid={ `mui-accordion-summary-${id}` }
        >
          <PageLink
            className={ classNames(
              styles.accordionLabel,
              active && styles.active,
              (childActive && !expanded) && styles.active,
              expanded && styles.expanded
            ) }
            to={ route }
            onClick={ handleLinkClick }
            type={ breadcrumbType }
            pageName={ label }
          >
            <IconLabel
              displayTooltip={ true }
              tooltipStyles={ styles.tooltip }
              tooltipPopperProps={ {
                className: "SiteNavigator"
              } }
              iconStyles={ styles.icon }
              label={ label }
              labelStyles={ classNames(styles.labelStyles, styles.textEllipsis, navLabelStyles) }
            />
            &nbsp;
            <span className={ styles.count }>{ `(${itemCount})` }</span>
          </PageLink>
        </AccordionSummary>
        <AccordionDetails
          className={ styles.accordionDetailsStyles }
          sx={ { padding: 0 } }
          data-testid={ `accordion-details-${id}` }
        >
          { children }
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

NavigatorLabelAccordion.propTypes = {
  active: PropTypes.bool,
  breadcrumbType: PropTypes.oneOf(Object.values(BREADCRUMB_ROUTE)),
  childActive: PropTypes.bool,
  children: PropTypes.node,
  handleLabelClick: PropTypes.func,
  id: PropTypes.string,
  isExpanded: PropTypes.bool,
  itemCount: PropTypes.number,
  label: PropTypes.string,
  navLabelStyles: PropTypes.any,
  parentFleet: PropTypes.object,
  parentSite: PropTypes.object,
  route: PropTypes.string,
  showExpandButton: PropTypes.bool
}

export default NavigatorLabelAccordion
