import PropTypes from "prop-types"
import React, { useState } from "react"
import { useQueryClient } from "react-query"

import styles from "./FleetExpandedCard.module.scss"

import { fleetGetSVC, fleetSVCKeys } from "../../services/reactQueries/fleetsvc.js"
import { FleetAuthElement } from "../../utils/Constants/Auth/fleet.js"
import { EDITABLE_FIELD_TYPE, FLEET_CARD, UPDATE } from "../../utils/GlobalConstants.js"
import { classNames } from "../../utils/styles/helper"
import AuthGuard from "../AuthGuard/AuthGuard.jsx"
import Distribution from "../Distribution/Distribution"
import FleetCardMenu from "../FleetCardMenu/FleetCardMenu.jsx"
import IconLabel from "../IconLabel/IconLabel"

const FleetExpandedCard = ({ setIsEdit, fleetDetails, setIsConfirmPopUp, showCardAction, titleKey }) => {
  const queryClient = useQueryClient()
  const [selected, setSelected] = useState(false)
  const onMutationComplete = (devices) => {
    if (devices.length > 0) {
      setIsConfirmPopUp({
        deleteFleetDetails: fleetDetails,
        heading: "Warning: Delete fleet",
        isOpen: true,
        message: "Before deleting a fleet, all assets within the fleet must be removed. ",
        type: ""
      })
    } else {
      setIsConfirmPopUp({
        deleteFleetDetails: fleetDetails,
        heading: "Confirm delete fleet",
        isOpen: true,
        message: `Delete fleet ${fleetDetails?.[titleKey]} ?`,
        type: "delete"
      })
    }
  }

  const onClickAction = async (action) => {
    if (action === "delete") {
      const { data: subFleets } = await queryClient.fetchQuery(
        [fleetSVCKeys.FLEET_DETAILS_BY_ID, fleetDetails.fleetId],
        () => fleetGetSVC.getFleetDetailsByFleetId(fleetDetails.fleetId),
        { staleTime: 5000 }
      )
      onMutationComplete(subFleets?.assets)
    } else if (action === "edit") {
      setIsEdit({
        isOpen: true,
        name: fleetDetails.fleetName,
        type: {
          action: UPDATE,
          id: fleetDetails?.fleetId,
          type: EDITABLE_FIELD_TYPE.FLEET_CARD
        }
      })
    }
  }

  return (
    <div className={ classNames(styles.root, selected && styles.fleetSelected) }>
      <div className={ styles.card }>
        <span className={ styles.fleetData }>
          <IconLabel
            iconName={ FLEET_CARD.icon }
            label={ fleetDetails?.[titleKey] }
            labelStyles={ styles.nameLabel }
            iconStyles={ styles.nameIcon }
            displayTooltip={ true }
          />
        </span>
        <span className={ styles.distributionPanel }>
          { fleetDetails?.distributionData?.map((data) => (
            <Distribution
              key={ data.type }
              type={ data.type }
              displayLabelText={ true }
              distribution={ data.distribution }
              customStyles={ styles.distributionCards }
            />
          )) }
        </span>
      </div>
      { showCardAction && (
        <AuthGuard
          actionType={ [FleetAuthElement.UpdateFleet, FleetAuthElement.DeleteFleet] }
          fallbackElement={ <span className={ styles.viewIconFallback }></span> }
        >
          <span className={ classNames(styles.viewIcon, "FleetMenu") }>
            <FleetCardMenu
              fleetId={ fleetDetails.fleetId }
              onClick={ onClickAction }
              selected={ selected }
              setSelected={ setSelected }
            />
          </span>
        </AuthGuard>
      ) }
    </div>
  )
}

FleetExpandedCard.propTypes = {
  fleetDetails: PropTypes.shape({
    distributionData: PropTypes.arrayOf(
      PropTypes.shape({
        distribution: PropTypes.array,
        type: PropTypes.oneOf(["assets", "alerts", "updates", "swpackages", "configs"])
      })
    ),
    fleetId: PropTypes.string,
    fleetName: PropTypes.string,
    id: PropTypes.string,
    siteId: PropTypes.string,
    title: PropTypes.string
  }),
  setIsConfirmPopUp: PropTypes.func,
  setIsEdit: PropTypes.func,
  showCardAction: PropTypes.bool,
  titleKey: PropTypes.string
}

FleetExpandedCard.defaultProps = {
  showCardAction: true,
  titleKey: "title"
}

export default FleetExpandedCard
