import colors from "../../styles/jsExports/colorExport.module.scss"
import fonts from "../../styles/jsExports/fontExport.module.scss"
import margins from "../../styles/jsExports/marginExport.module.scss"

export const InputBase = {
  styleOverrides: {
    root: {
      "&.promoteInput": {
        "& .Mui-disabled": {
          backgroundColor: colors["dropdown-lightDisabledBackground"],
          borderRadius:  margins["marginInPixel-px4"],
          marginBottom:  margins["marginInPixel-px16"],
          paddingLeft:  margins["marginInPixel-px12"]
        }
      },
      background: "none",
      border: "none",
      fontSize: fonts["size-px16"],
      height: margins["marginInPixel-px28"],
      outline: "none",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: margins["marginInPercentage-per100"]
    }
  }
}
