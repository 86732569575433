import { EMPTY_CELL_DEFAULT_VALUE } from "../../utils/Constants/DataTable"
import { DATE_FORMAT } from "../../utils/Constants/TimeZones"
import { formatDate } from "../../utils/helper"

export const transformAssetDetails = (data) => {
  if (!data) return {}

  return {
    assetDetails: {
      assetId: data?.id,
      assetImage: data.model,
      assetNote: data.assetNote ?? EMPTY_CELL_DEFAULT_VALUE,
      fleet: data.fleetName ?? EMPTY_CELL_DEFAULT_VALUE,
      hardwareVersion: data.hardwareVersion ?? EMPTY_CELL_DEFAULT_VALUE,
      id: data.serialNumber ?? EMPTY_CELL_DEFAULT_VALUE,
      ipAddress: data.ipAddress ?? EMPTY_CELL_DEFAULT_VALUE,
      lastConnected: formatDate(data.lastConnected, DATE_FORMAT.dateTime) || EMPTY_CELL_DEFAULT_VALUE,
      lastDeployedConfig: formatDate(data.lastConfig, DATE_FORMAT.dateTime) || EMPTY_CELL_DEFAULT_VALUE,
      lastLocation: data.lastLocation ?? EMPTY_CELL_DEFAULT_VALUE,
      latestMuteNote: data.latestMuteNote,
      modality: data.modality,
      model: data.model ?? EMPTY_CELL_DEFAULT_VALUE,
      muted: data.muted,
      mutedDate: formatDate(data.mutedDate, DATE_FORMAT.dateTime) || EMPTY_CELL_DEFAULT_VALUE,
      name: data.friendlyName,
      severity: data.faultSeverity ?? "critical",
      softwareVersion: data.softwareVersion ?? EMPTY_CELL_DEFAULT_VALUE,
      status: data?.connectivityStatus || "Offline"
    }
  }
}

export const transformPropertiesSectionAttributes = (assetProperties) => {
  if (!assetProperties) return []
  let index = 1
  for (const property of assetProperties) {
    property.id = index++
  }
  return assetProperties
}

export const transformAssetStatus = (assetStatus) => {
  if (!assetStatus) return []
  let index = 1
  for (const status of assetStatus) {
    status.id = index++
  }
  return assetStatus
}

export const getMuteUnmutePayload = (assetId, mute, latestMuteNote) => {
  return {
    assetId,
    variables: { mute, note: latestMuteNote }
  }
}

export const modelImage = (model) => {
  const path = "/assets/images/assetModels/"
  const imageName = model?.replace(" ", "").toLowerCase() + ".svg"
  return path + imageName
}

export const assetViewReducer = (newState, action) => {
  switch (action.type) {
  case ASSET_ACTION_TYPES.OPEN_EDIT_ASSET_POPUP:
    return {
      ...newState,
      ...{
        editAssetPopup: action.payload
      }
    }
  case ASSET_ACTION_TYPES.CLOSE_EDIT_ASSET_POPUP:
    return {
      ...newState,
      ...{
        editAssetPopup: ASSET_VIEW_STATE.editAssetPopup
      }
    }
    /* istanbul ignore next */
  default:
    return newState
  }
}

export const ASSET_ACTION_TYPES = {
  CLOSE_EDIT_ASSET_POPUP: "closeEditAssetPopup",
  OPEN_EDIT_ASSET_POPUP: "openEditAssetPopup"
}

export const ASSET_VIEW_STATE = {
  editAssetPopup: {
    isOpen: false,
    name: "",
    notes: "",
    type: { action: "", id: "", type: "" }
  }
}
