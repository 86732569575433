export const AssetStatus = {
  ACKNOWLEDGED: "Acknowledged",
  UNACKNOWLEDGED: "Un-Acknowledged"
}

export const AssetFaultsFilterData = [
  {
    defaultValue: "",
    key: "modality",
    label: "Modality",
    multiple: false,
    options: []
  },
  {
    defaultValue: [],
    key: "model",
    label: "Model",
    multiple: true,
    options: []
  },
  {
    defaultValue: [],
    key: "severity",
    label: "Severity",
    multiple: true,
    options: [
      {
        id: 1,
        label: "Critical"
      },
      {
        id: 2,
        label: "Moderate"
      },
      {
        id: 3,
        label: "Low"
      }
    ]
  },
  {
    defaultValue: "",
    key: "acknowledgement",
    label: "Acknowledged status",
    multiple: false,
    options: [
      {
        id: 1,
        label: AssetStatus.ACKNOWLEDGED
      },
      {
        id: 2,
        label: AssetStatus.UNACKNOWLEDGED
      }
    ]
  }
]
