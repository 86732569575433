import { IconButton, Tooltip } from "@mui/material"
import { PropTypes } from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

import styles from "./Action.module.scss"

import Icon from "../../../../components/Icon/Icon"
import { TABLE_ACTION } from "../../../../utils/Constants/AssetsConfig"
import useAccess from "../../../../utils/CustomHooks/useAccess"
import { ACKNOWLEDGE, ICON_NAME, UNACKNOWLEDGE } from "../../../../utils/GlobalConstants"

function Action({ row, handleRowClickActions, ackAccessKey, unAckAccessKey, hasSomeAckFaults, hasSomeUnAckFaults }) {
  const { t } = useTranslation(["asset"])
  const hasAckAccess = useAccess(row?.original?.isAcknowledge ? unAckAccessKey : ackAccessKey)
  const hasAckScopeAccess = useAccess(ackAccessKey)
  const hasUnAckScopeAccess = useAccess(unAckAccessKey)
  const showBlankDiv = (hasAckScopeAccess && hasSomeUnAckFaults) || (hasUnAckScopeAccess && hasSomeAckFaults)

  return (
    <div className={ styles.actionsCell }>
      { hasAckAccess && 
        <Tooltip
          arrow
          title={ row?.original?.isAcknowledge ? t("assetFaults.unAcknowledge") : t("assetFaults.acknowledge") }
        >
          <IconButton
            aria-label={
              row?.original?.id + "-" + `${row?.original?.isAcknowledge ? UNACKNOWLEDGE : ACKNOWLEDGE}`
            }
            onClick={ () =>
              handleRowClickActions(
                row?.original?.isAcknowledge ? TABLE_ACTION.UNACKNOWLEDGE : TABLE_ACTION.ACKNOWLEDGE,
                row?.original)
            }
          >
            <Icon
              icon={ row?.original?.isAcknowledge ? ICON_NAME.unAcknowledge : ICON_NAME.acknowledge }
              iconStyles={ styles.actionIcon }
            />
          </IconButton>
        </Tooltip>
      }
      { (!hasAckAccess && showBlankDiv) && <IconButton disableRipple></IconButton> }
      <Tooltip arrow title={ t("assetFaults.notes") }>
        <IconButton
          aria-label={ row?.original?.id + "-notes" }
          onClick={ () => handleRowClickActions(TABLE_ACTION.NOTES, row?.original) }
        >
          <Icon icon={ ICON_NAME.notes } iconStyles={ styles.actionIcon } />
        </IconButton>
      </Tooltip>
    </div>
  )
}

Action.propTypes = {
  ackAccessKey: PropTypes.string,
  handleRowClickActions: PropTypes.any,
  hasSomeAckFaults: PropTypes.bool,
  hasSomeUnAckFaults: PropTypes.bool,
  row: PropTypes.any,
  unAckAccessKey: PropTypes.string

}

export default Action
