export const calculateWidth = (el) => {
  const { marginLeft, marginRight, borderLeft, borderRight, width, paddingLeft, paddingRight } =
    window.getComputedStyle(el)
  return (
    parseFloat(marginLeft, 10) +
    parseFloat(marginRight, 10) +
    parseFloat(borderLeft, 10) +
    parseFloat(borderRight, 10) +
    parseFloat(paddingLeft, 10) +
    parseFloat(paddingRight, 10) +
    parseFloat(width, 10)
  )
}
