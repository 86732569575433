import { StorageKeys, setSession } from "../../utils/SessionHelper/session"

const getDistributionData = (data) => {
  return [
    {
      distribution: [
        data?.assets?.available ?? 0,
        data?.assets?.inUse ?? 0,
        data?.assets?.offline ?? 0
      ],
      type: "assets"
    },
    {
      distribution: [
        data?.faults?.critical ?? 0,
        data?.faults?.moderate ?? 0,
        data?.faults?.low ?? 0
      ],
      type: "alerts"
    },
    {
      distribution: [
        data?.configs?.inInstall ?? 0,
        data?.configs?.inDownload ?? 0,
        data?.configs?.failed ?? 0
      ],
      type: "configs"
    },
    {
      distribution: [
        data?.updates?.inProgress ?? 0,
        data?.updates?.pending ?? 0,
        data?.updates?.failed ?? 0
      ],
      type: "updates"
    }
  ]
}

export const transformSiteStatistics = (data) => {
  for (const site of data || []) {
    const statistics = site?.statistics
    site["distribution"] = getDistributionData(statistics)
  }
  return { sites: data }
}

export const setSiteUCMID = (siteUcmId) => {
  setSession(StorageKeys.SITE_UCM_ID, siteUcmId ?? "")
}
