import PropTypes from "prop-types"
import React from "react"

import Error from "./Error"
import styles from "./Error.module.scss"

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: "" }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error: error }
  }

  // componentDidCatch(error, errorInfo) {
  //   // You can also log the error to an error reporting service
  //   // logErrorToMyService(error, errorInfo)
  // }

  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      return (
        <div className={ styles.errorBoundaryContainer }>
          <Error error={ this.state.error } />
        </div>
      )
    }
    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element
}

export default ErrorBoundary
