export const appReducer = (newState, action) => {
  if (action.type === TYPES.SET_APP_ERROR) {
    return {
      ...newState,
      ...{
        showAppError: action.payload.showError,
        showAppErrorMessage: action.payload.showErrorMessage
      }
    }
  }
  return newState
}

export const TYPES = {
  SET_APP_ERROR: "setAppError"
}

export const APP_INITIAL_STATE = {
  showAppError: false,
  showAppErrorMessage: ""
}
