import i18n from "i18next"

import { TABLE_COLUMN_SPECIAL_ID_ACCESSORS } from "../../../utils/Constants/DataTable"
import { DEVICE_RESOLUTION_KEYS, currentDeviceResolution } from "../../../utils/styles/helper"

const mediaColumnSizes = () => {
  const resolution = currentDeviceResolution()
  const resolutions = {
    [DEVICE_RESOLUTION_KEYS.MEDIUM]: {
      [TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_NAME]: {
        size: 110
      },
      [TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_ID]: {
        size: 115
      },
      [TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_MODALITY]: {
        size: 90
      },
      [TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_MODEL]: {
        size: 65
      },
      [TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_IP_ADDRESS]: {
        size: 90
      },
      [TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_SUB_FLEET]: {
        size: 85
      },
      [TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_SOFTWARE_VERSION]: {
        size: 80
      },
      [TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_SOFTWARE_STATUS]: {
        size: 160
      },
      [TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_CONFIG_STATUS]: {
        size: 140
      },
      [TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_LAST_CONNECTED]: {
        size: 100
      }
    },
    [DEVICE_RESOLUTION_KEYS.LARGE]: {
      [TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_NAME]: {
        size: 140
      },
      [TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_ID]: {
        size: 140
      },
      [TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_MODALITY]: {
        size: 130
      },
      [TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_MODEL]: {
        size: 130
      },
      [TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_IP_ADDRESS]: {
        size: 130
      },
      [TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_SUB_FLEET]: {
        size: 100
      },
      [TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_SOFTWARE_VERSION]: {
        size: 120
      },
      [TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_SOFTWARE_STATUS]: {
        size: 160
      },
      [TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_CONFIG_STATUS]: {
        size: 140
      },
      [TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_LAST_CONNECTED]: {
        size: 140
      }
    },
    [DEVICE_RESOLUTION_KEYS.EXTRA_LARGE]: {
      [TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_NAME]: {
        size: 254
      },
      [TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_ID]: {
        size: 254
      },
      [TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_MODALITY]: {
        size: 160
      },
      [TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_MODEL]: {
        size: 160
      },
      [TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_IP_ADDRESS]: {
        size: 160
      },
      [TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_SUB_FLEET]: {
        size: 120
      },
      [TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_SOFTWARE_VERSION]: {
        size: 120
      },
      [TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_SOFTWARE_STATUS]: {
        size: 200
      },
      [TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_CONFIG_STATUS]: {
        size: 200
      },
      [TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_LAST_CONNECTED]: {
        size: 200
      }
    }
  }
  return resolutions[resolution]
}

const resolutionSizeObject = mediaColumnSizes()

const FleetGroupByColumnsData = () => [
  {
    accessorKey: "severity",
    disableTooltip: true,
    header: "",
    id: TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_SEVERITY,
    maxSize: 40,
    size: 40
  },
  {
    accessorKey: "name",
    defaultCanSort: true,
    header: i18n.t("asset:table.headerLabels.name"),
    id: TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_NAME,
    minSize: 72,
    ...resolutionSizeObject[TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_NAME]
  },
  {
    accessorKey: "deviceId",
    header: i18n.t("asset:table.headerLabels.assetId"),
    ...resolutionSizeObject[TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_ID]
  },
  {
    accessorKey: "modality",
    header: i18n.t("asset:table.headerLabels.modality"),
    ...resolutionSizeObject[TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_MODALITY]
  },
  {
    accessorKey: "model",
    header: i18n.t("asset:table.headerLabels.type"),
    ...resolutionSizeObject[TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_MODEL]
  },

  {
    accessorKey: "ipAddress",
    header: i18n.t("asset:table.headerLabels.ipAddress"),
    id: TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_IP_ADDRESS,
    ...resolutionSizeObject[TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_IP_ADDRESS]
  },
  {
    accessorKey: "subFleet",
    header: i18n.t("asset:table.headerLabels.subFleet"),
    ...resolutionSizeObject[TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_SUB_FLEET]
  },
  {
    accessorKey: "softwareVersion",
    disableTooltip: true,
    header: i18n.t("asset:table.headerLabels.swVer"),
    id: TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_SOFTWARE_VERSION,
    ...resolutionSizeObject[TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_SOFTWARE_VERSION]
  },
  {
    accessorKey: "swStatus",
    disableTooltip: true,
    header: i18n.t("asset:table.headerLabels.swStatus"),
    id: TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_SOFTWARE_STATUS,
    minSize: 160,
    size: 160,
    ...resolutionSizeObject[TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_SOFTWARE_STATUS]
  },
  {
    accessorKey: "configStatus",
    disableTooltip: true,
    header: i18n.t("asset:table.headerLabels.configStatus"),
    id: TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_CONFIG_STATUS,
    minSize: 140,
    size: 140,
    ...resolutionSizeObject[TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_CONFIG_STATUS]
  },
  {
    accessorKey: "lastConnected",
    header: i18n.t("asset:table.headerLabels.lastConnected"),
    id: TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_LAST_CONNECTED,
    ...resolutionSizeObject[TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_LAST_CONNECTED]
  }
]

export default FleetGroupByColumnsData
