export const FLEET_SERVICE_ERRORS = {
  ASSET_ALREADY_UNASSIGNED: "E9004-FLT",
  ASSET_ID_NOT_FOUND: ["E9005-FLT", "E9007-FLT", "E9060-FLT"],
  CUSTOMER_NOT_FOUND: "E9014-FLT",
  DUPLICATE_CUSTOMER_NAME: "E9013-FLT",
  DUPLICATE_FLEET_NAME: "E9020-FLT",
  DUPLICATE_FRIENDLY_NAME: "E9023-FLT",
  DUPLICATE_SITE_NAME: "E9044-FLT",
  FLEET_NOT_FOUND: "E9022-FLT",
  FORBIDDEN_OR_INSUFFICIENT_PERMISSIONS: ["E9012-FLT", "E9022-FLT"],
  GIVEN_CHILD_FLEET: "E9024-FLT",
  MODALITY_COUNT_EXCEED: "E9037-FLT",
  SITE_NOT_FOUND: ["E9043-FLT", "E9045-FLT"]
}

export const ALL_DUPLICATE_NAME_ERROR_CODES = [
  FLEET_SERVICE_ERRORS.DUPLICATE_CUSTOMER_NAME,
  FLEET_SERVICE_ERRORS.DUPLICATE_SITE_NAME,
  FLEET_SERVICE_ERRORS.DUPLICATE_FLEET_NAME,
  FLEET_SERVICE_ERRORS.DUPLICATE_FRIENDLY_NAME
]

export const FAULT_SERVICE_ERRORS = {}

export const FILE_SERVICE_ERRORS = {
  INTERNAL_SERVER_EXCEPTION: "E9051-CFG"
}

export const PROPERTY_SERVICE_ERRORS = {}

export const TELEMETRY_SERVICE_ERRORS = {}

export const RESOURCE_SERVICE_ERRORS = {
  FACILITIES_NOT_FOUND: "E9003-RES",
  LANGUAGE_NOT_FOUND: "E9117-US",
  USER_GROUP_NOT_FOUND: "E9071-RES",
  USER_NOT_FOUND: "E9046-RES"
}
