/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types"
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState
} from "react"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { useParams } from "react-router-dom"

import { getAndUpdateAssetBreadcrumb } from "../../../components/Breadcrumb/BreadcrumbUtils"
import Loading from "../../../components/Loading/Loading"
import { useApp } from "../../../contexts/app/AppContext"
import { useBreadcrumbContext } from "../../../contexts/breadcrumb/breadcrumb"
import { faultSVC } from "../../../services/reactQueries/faultsvc"
import {
  fleetGetSVC,
  fleetSVCKeys
} from "../../../services/reactQueries/fleetsvc"
import { useLazyQuery, useQuery } from "../../../utils/CustomHooks/reactQuery"
import { ROUTE_CONFIG } from "../../../utils/GlobalConstants"
import { intialState } from "../../AssetView/AssetFaults/AssetFaultsUtils"
import {
  INITIAL_STATE,
  TYPES,
  siteAssetFaultsReducer
} from "../AssetFaultUtils"

export const AssetFaultsContext = createContext()

export const AssetFaultsProvider = ({ children, value = { filter: [] } }) => {
  const queryClient = useQueryClient()
  const [filter, setFilter] = useState(value?.filter)
  const [isFilterSet, setIsFilterSet] = useState(false)
  const [state, dispatch] = useReducer(siteAssetFaultsReducer, INITIAL_STATE)
  const [acknowledgeUnAcknowledgePopUp, setAcknowledgeUnAcknowledgePopUp] =
    useState(intialState)

  const { updateBreadcrumbData } = useBreadcrumbContext()
  const assetSummaryRef = useRef(null)
  const searchBarRef = useRef(null)

  const [exportData, setExportData] = useState({})

  const { t } = useTranslation(["assetFault"])
  const [faults, setFaults] = useState([])
  let extractFaults = []

  const { app: appState } = useApp()
  const queryParamIds = useParams()
  const siteId = queryParamIds[ROUTE_CONFIG.SITE.param]
  const assetId = queryParamIds[ROUTE_CONFIG.ASSET.param]
  const queryKey = ["site-AssetFaultsByID", siteId]

  const { data, isFetching } = useQuery(
    [fleetSVCKeys.GET_SITES],
    fleetGetSVC.getSites
  )

  const sites = useMemo(() => data?.data?.sites || [], [data])

  const currentSite = useMemo(
    () => sites.find((eachSite) => eachSite.id == siteId),
    [sites, siteId]
  )

  const siteName = currentSite?.siteAliasName ?? currentSite?.siteName

  const { data: faultsStatisticsData } = useQuery(
    ["statistics-bySiteID", currentSite?.siteUcmId],
    () => faultSVC.getAssetFaultsStatistics(currentSite?.siteUcmId)
  )

  const faultsStatistics = faultsStatisticsData?.data?.statistics || []

  const onGetFaultsCompleted = (data) => {
    setExportData({})
    extractFaults = []
    const faultList = data?.data?.faultList
    faultList.map((ele) =>
      ele?.faults.map((item) => {
        extractFaults.push(item)
      })
    )
    setFaults(extractFaults)
    if (state.searchString && data?.data?.faultList?.length === 0) {
      dispatch({ payload: true, type: TYPES.SET_SEARCH_ERROR })
    } else {
      dispatch({ payload: false, type: TYPES.SET_SEARCH_ERROR })
    }
  }

  const [getFaults, getFaultsQueryResults] = useLazyQuery(
    queryKey,
    () =>
      faultSVC.getAssetFaultsBysiteUcmId({
        siteUcmId: currentSite?.siteUcmId,
        ...state
      }),
    { onSuccess: onGetFaultsCompleted }
  )

  useEffect(() => {
    return () => getFaultsQueryResults.remove()
  }, [])

  useEffect(() => {
    getAndUpdateAssetBreadcrumb(queryClient, assetId, updateBreadcrumbData)
  }, [assetId])

  useEffect(() => {
    if (
      !state.searchError &&
      currentSite?.siteUcmId &&
      state.filters?.length > 0
    )
      getFaults()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.filters,
    state.groupBy,
    state.dateRange,
    state.searchString,
    state.searchError
  ])

  const setFilters = (payload) => {
    setFilter(payload)
    dispatch({ payload, type: TYPES.SET_FILTERS })
    searchBarRef.current?.reset()
  }

  const setGroupBy = (payload) => {
    dispatch({ payload, type: TYPES.SET_GROUPBY })
    setExportData({})
    handleFilterSubmit()
  }

  const handleFilterSubmit = () => {
    assetSummaryRef?.current?.resetCheckboxSelection()
  }

  const setDateRange = (payload) => {
    dispatch({ payload, type: TYPES.SET_DATE_RANGE })
    searchBarRef.current?.reset()
    handleFilterSubmit()
  }

  const setSearchString = (payload, closeAction) => {
    dispatch({ closeAction, payload, type: TYPES.SET_SEARCH_STRING })
    handleFilterSubmit()
    setFaults(extractFaults)
  }

  const setSelectedAssets = (payload) => {
    dispatch({ payload, type: TYPES.SET_SELECTED_ASSETS })
  }

  const AssetFaultsValue = useMemo(() => ({
    acknowledgeUnAcknowledgePopUp,
    appState,
    assetSummaryRef,
    exportData,
    faults,
    faultsStatistics,
    filter,
    getFaults,
    getFaultsQueryResults,
    handleFilterSubmit,
    isFilterSet,
    queryKey,
    searchBarRef,
    setAcknowledgeUnAcknowledgePopUp,
    setDateRange,
    setExportData,
    setFilter: setFilters,
    setGroupBy,
    setIsFilterSet,
    setSearchString,
    setSelectedAssets,
    siteName,
    state,
    t
  }))

  if (isFetching) return <Loading />

  return (
    <AssetFaultsContext.Provider value={ AssetFaultsValue }>
      { children }
    </AssetFaultsContext.Provider>
  )
}

AssetFaultsProvider.propTypes = {
  children: PropTypes.any,
  value: PropTypes.object
}

export const useAssetFaultsContext = () => {
  return useContext(AssetFaultsContext)
}
