import PropTypes from "prop-types"
import React, { useRef } from "react"

import styles from "./AssetName.module.scss"

import AssetNameTooltip from "../AssetNameTooltip/AssetNameTooltip"
import Mute from "../Mute/Mute"

const AssetName = ({ isMuted, name, assetNote }) => {
  const textElementRef = useRef()

  return (
    <div className={ styles.assetNameCell }>
      { isMuted && <Mute status /> }
      <AssetNameTooltip
        assetName={ name }
        assetNote={ assetNote || "--" }
        customStyles={ "assetNameTooltip" }
      >
        <span ref={ textElementRef }>{ name }</span>
      </AssetNameTooltip>
    </div>
  )
}

AssetName.propTypes = {
  assetNote: PropTypes.string,
  isMuted: PropTypes.bool,
  name: PropTypes.string
}

export default AssetName
