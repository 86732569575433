import { ERROR_MESSAGE, FIELD_CHAR_LENGTH, REG_EXP } from "../GlobalConstants"

export const validateInput = (value, type) => {
  if (value) {
    if (!value.match(REG_EXP.checkSPLChar)) {
      if (value.length > FIELD_CHAR_LENGTH[type]) {
        return ERROR_MESSAGE[type].characterLength
      } else {
        return ""
      }
    } else {
      return ERROR_MESSAGE[type].specialCharactersNotAllowed
    }
  } else {
    return ERROR_MESSAGE[type].invalidValue
  }
}

export const removeEmoji = (input) =>{
  // eslint-disable-next-line max-len
  return input.replaceAll(/(?![*#0-9]+)[\p{Emoji}\p{Emoji_Modifier}\p{Emoji_Component}\p{Emoji_Modifier_Base}\p{Emoji_Presentation}]/gu, "")
}
