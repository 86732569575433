import { Button, Tab, Tabs, Tooltip } from "@mui/material"
import React from "react"

import Icon from "../../../../../components/Icon/Icon"
import NestedDataTable from "../../../../../components/NestedDataTable/NestedDataTable"
import TabPanel from "../../../../../components/TabPanel/TabPanel"
import { useUserManagementContext } from "../../../../../contexts/userManagement/UserManagement"
import { resourceSVCKeys } from "../../../../../services/reactQueries/resourcesvc"
import { UsersAuthElement } from "../../../../../utils/Constants/Auth/user"
import { TABLE_INSTANCES } from "../../../../../utils/Constants/DataTable"
import useAccess from "../../../../../utils/CustomHooks/useAccess"
import { ICON_NAME } from "../../../../../utils/GlobalConstants"
import { classNames } from "../../../../../utils/styles/helper"
import styles from "../../../UserManagement.module.scss"
import AccessColumnConfig, {
  AccessFleetColumnConfig,
  AccessSubFleetColumnConfig
} from "../../tableConfigurations/AccessColumnConfig"

const UserTabs = () => {
  const hasManageAccess = useAccess(UsersAuthElement.ManageUser)
  const {
    getUserSiteAccessDetailsQueryResults,
    t,
    handleOpenManageAccessPopup,
    selectedRowDetails
  } = useUserManagementContext()

  const { data, isError } = getUserSiteAccessDetailsQueryResults
  const userAccessData = data?.data?.sites
  const enableManageAccess = data?.data?.enableManageAccess

  const queryKey = [resourceSVCKeys.getUserAccess, selectedRowDetails?.id]

  return (
    <>
      <div>
        <Tabs value={ 0 }>
          <Tab
            label={ t("access", {
              siteCount: userAccessData?.length || 0
            }) }
            { ...a11yProps(0) }
          />
        </Tabs>
      </div>

      <TabPanel className={ styles.tabDetails } value={ 0 } index={ 0 }>
        <div className={ styles.manageAccessButton }>
          <span
            className={ classNames(
              styles.manageButton,
              !enableManageAccess && "btn-disabled"
            ) }
          >
            { hasManageAccess && (
              <Tooltip
                title={
                  <span className={ "tooltipTitle" }>
                    { t("table.access.controlAccessByViewAllScopeTooltip") }
                  </span>
                }
                interactive="true"
                disableHoverListener={ enableManageAccess }
                arrow
                placement="bottom-start"
              >
                <Button
                  variant="contained"
                  className={ !enableManageAccess && "btn-disabled" }
                  disableTouchRipple={ !enableManageAccess }
                  startIcon={
                    <Icon
                      icon={ ICON_NAME.edit }
                      iconStyles={ styles.manageAccessIcon }
                    />
                  }
                  onClick={
                    enableManageAccess ? handleOpenManageAccessPopup : null
                  }
                >
                  { t("manageAccessButton") }
                </Button>
              </Tooltip>
            ) }
          </span>
        </div>
        <div className={ styles.sitesTable }>
          <NestedDataTable
            rowHoverEffect
            isError={ isError }
            queryKey={ queryKey }
            tableData={ userAccessData || [] }
            columnsData={ AccessColumnConfig() }
            type={ TABLE_INSTANCES.USER_MANAGEMENT_NO_ACCESS }
            canExpandRow={ (row) => Boolean(row.fleets) }
            subComponent={ ({ row: siteRow, toggleRowSelection }) => {
              return fleetsTable({ siteRow, toggleRowSelection })
            } }
          />
        </div>
      </TabPanel>
    </>
  )
}

export default UserTabs

const a11yProps = (index) => {
  return {
    "aria-controls": `tabpanel-${index}`,
    id: `tab-${index}`
  }
}

const fleetsTable = ({ siteRow, toggleRowSelection }) => {
  return (
    <NestedDataTable
      rowHoverEffect
      canExpandRow={ (row) => Boolean(row.subFleets) }
      tableData={ siteRow?.original?.fleets || [] }
      columnsData={ AccessFleetColumnConfig() }
      parentTableProps={ {
        toggleRowSelection: toggleRowSelection
      } }
      subComponent={ ({
        row: fleetRow,
        toggleRowSelection: toggleRowSelectionA
      }) => {
        return subFleetsTable({ fleetRow, toggleRowSelectionA })
      } }
    />
  )
}

const subFleetsTable = ({ fleetRow, toggleRowSelectionA }) => {
  return (
    <NestedDataTable
      rowHoverEffect
      parentTableProps={ {
        toggleRowSelection: toggleRowSelectionA
      } }
      tableData={ fleetRow?.original?.subFleets || [] }
      columnsData={ AccessSubFleetColumnConfig() }
    />
  )
}
