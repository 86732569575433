import { getDecodedTokenData } from "../../contexts/Auth/auth"

const TOKEN_REFRESH_MINUTES_BEFORE_EXPIRE = 1

export const checkTokenIsExpired = () => {
  const tokenData = getDecodedTokenData()
  const tokenExpTime = tokenData.exp - 60 * TOKEN_REFRESH_MINUTES_BEFORE_EXPIRE
  const currentTime = Date.now() / 1000
  return tokenExpTime < currentTime
}
