import React from "react"

import FleetViewContainer from "./FleetViewContainer"

import { FleetViewProvider } from "../../contexts/fleetView/FleetView"

const FleetView = () => {
  return (
    <FleetViewProvider>
      <FleetViewContainer />
    </FleetViewProvider>
  )
}

FleetView.propTypes = {}

export default FleetView
