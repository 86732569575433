/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"

import styles from "./CentralConfiguration.module.scss"
import {
  configFilterData,
  getPromoteDemoteFilePayload,
  intialState,
  transformConfigData,
  transformStatistics
} from "./CentralConfigurationUtils"
import ConfigurationAssetsSummary from "./ConfigurationAssetsSummary/ConfigurationAssetsSummary"

import BarChart from "../../../components/ChartStatisticCard/BarChart"
import ConfigFilterPopup from "../../../components/ConfigFilterPopup/ConfigFilterPopup"
import DistributionStatisticsCard from "../../../components/DistributionStatisticsCard/DistributionStatisticsCard"
import Error from "../../../components/Error/Error"
import PromoteDemotePopUp from "../../../components/PromoteDemotePopUp/PromoteDemotePopUp"
import PromptModal from "../../../components/PromptModal/PromptModal"
import { fileSVC, fileSVCKeys } from "../../../services/reactQueries/filesvc"
import { TABLE_ACTION } from "../../../utils/Constants/AssetsConfig"
import { useMutationWithHandlers } from "../../../utils/CustomHooks/reactQuery"
import {
  CANCEL,
  CONFIG_CONTEXT_TYPE,
  DEMOTE,
  GLOBAL_THROTTLE_TIME,
  OK,
  SEARCH_DEFAULT_MIN_CHARACTERS
} from "../../../utils/GlobalConstants"
import { deviceORQueryFormatter, throttle } from "../../../utils/helper"
import { StorageKeys, getSession } from "../../../utils/SessionHelper/session"
import { DEFAULT_DATE_RANGE } from "../../CentralSoftware/CentralSoftwareUpdate/CentralSoftwareUpdatesUtils"
import { ConfigurationProvider } from "../Context/ConfigurationContext"

const CentralConfiguration = () => {
  const { t } = useTranslation(["configuration"])
  const { t: common } = useTranslation(["common"])
  const [isFilterOpen, setIsFilterOpen] = useState(true)
  const [selectedFilter, setSelectedFilter] = useState([])
  const [promoteDemotePopUp, setPromoteDemotePopUp] = useState(intialState)
  const [searchInput, setSearchInput] = useState(null)
  const [searchResult, setSearchResult] = useState(null)
  const [searchError, setSearchError] = useState(false)
  const [searchClose, setSearchClose] = useState(false)
  const [promptModal, setIsPromptModal] = useState(intialState)
  const [transformedConfigData, setTransformedConfigData] = useState([])
  const [configDateRange, setConfigDateRange] = useState({
    endDate: DEFAULT_DATE_RANGE.endDate,
    maxDate: DEFAULT_DATE_RANGE.maxDate,
    startDate: DEFAULT_DATE_RANGE.startDate
  })
  const searchBarRef = useRef(null)
  const siteUcmId = getSession(StorageKeys.SITE_UCM_ID)
  const [filterAPIError, setFilterAPIError] = useState(false)

  useEffect(() => {
    if (selectedFilter?.length > 0) getFilterConfigFiles(selectedFilter, true)
  }, [configDateRange])

  const onMutationCompleted = (data) => {
    const configData = data?.data?.configurationList
    setTransformedConfigData(transformConfigData(configData))
    if (searchInput) setSearchResult(configData)
  }

  const {
    requestMutation: getConfigFiles,
    isError,
    isLoading
  } = useMutationWithHandlers({
    onCompletedCallback: onMutationCompleted,
    queryFn: fileSVC.getConfigFilesBySiteId
  })

  const getFilterConfigFiles = (value, resetSearchField) => {
    deviceORQueryFormatter(value)
    let searchInputData = searchInput
    if (resetSearchField) {
      resetSearch()
      searchInputData = null
    }
    getConfigFiles({
      payload: {
        configDateRange,
        filterValue: value,
        searchInput: searchInputData,
        siteId: siteUcmId
      },
      showToast: false
    })
  }

  const { data: configStatistics } = useQuery([fileSVCKeys.GET_CENTRAL_CONFIG_STATISTICS, siteUcmId], () =>
    fileSVC.getConfigStatistics(siteUcmId)
  )
  const configStatisticsData = configStatistics?.data?.statistics
  const { distributionData, modalityChartData } = transformStatistics(configStatisticsData)

  const handleRowClickActions = (actionType, payload) => {
    if (actionType === TABLE_ACTION.PROMOTE) {
      if (!payload?.deleted) {
        setPromoteDemotePopUp({
          ...promoteDemotePopUp,
          fileDetails: payload,
          isOpen: true,
          message: t("configurations.popup.demotePopup.heading", {
            fileName: payload.fileName,
            model: payload.model
          }),
          name: payload.fileName,
          type: DEMOTE
        })
      } else if (payload?.deleted) {
        setIsPromptModal({
          ...promptModal,
          fileDetails: payload,
          heading: t("configurations.popup.deletePopup.heading"),
          isOpen: true,
          message: t("configurations.popup.deletePopup.message", {
            fileName: payload.fileName
          })
        })
      }
    }
  }

  const throttleActions = useCallback(throttle(handleRowClickActions, GLOBAL_THROTTLE_TIME), [])

  const updateFilterItem = (filters) => {
    if (filters.length > 0) {
      setSelectedFilter(filters)
      getFilterConfigFiles(filters, true)
    }
    setSelectedFilter(filters)
  }

  const { requestMutation: promoteDemoteMutation } = useMutationWithHandlers({
    onCompletedCallback: () => getFilterConfigFiles(selectedFilter, false),
    queryFn: fileSVC.promoteLockActions
  })

  const handlePromoteDemoteFile = (note) => {
    const fileDetails = promoteDemotePopUp?.fileDetails || promptModal?.fileDetails
    const newPromoteState = !fileDetails?.isPromoted
    promoteDemoteMutation({
      errorMessage: t(`configurations.toaster.${!newPromoteState?.deleted ? "demoteFile" : "deleteFile"}.error`),
      payload: {
        ...getPromoteDemoteFilePayload(fileDetails?.assetSerialNumber, fileDetails?.id, !fileDetails.promoted, note)
      },
      successMessage: t(
        `centralConfigurations.toaster.${!fileDetails?.deleted ? "demoteFile" : "deleteFile"}.success`,
        {
          configurationDate: fileDetails.configurationDate,
          fileName: fileDetails.fileName
        }
      )
    })
    setPromoteDemotePopUp(intialState)
    setIsPromptModal(intialState)
  }

  const handleSearch = (value, closeAction, hasError) => {
    setSearchClose(closeAction)
    setSearchInput(value)
    if ((closeAction && !hasError) || (!closeAction && value.length >= SEARCH_DEFAULT_MIN_CHARACTERS)) {
      setSearchResult(null)
      setSearchError(false)
      getConfigFiles({
        payload: {
          configDateRange,
          filterValue: selectedFilter,
          searchInput: value,
          siteId: siteUcmId
        }
      })
    } else {
      setSearchError(true)
      setSearchResult(null)
    }
  }

  const resetSearch = () => {
    setSearchResult(null)
    setSearchError(false)
    setSearchInput(null)
    setSearchClose(false)
    searchBarRef.current?.reset()
  }

  if (filterAPIError) return <Error error={ { message: common("errorInFilterOptionsApi") } } />

  return (
    <ConfigurationProvider>
      <div className={ styles.statisticsSection }>
        { distributionData.map((eachDistribution) => (
          <DistributionStatisticsCard
            key={ eachDistribution.type }
            { ...eachDistribution }
          />
        )) }
        { modalityChartData?.map((chart) => {
          const { type, value, title } = chart
          return <BarChart key={ type } value={ value } type={ type } title = { title } />
        }) }
      </div>
      <div className={ styles.configAssets }>
        <ConfigurationAssetsSummary
          isLoading={ isLoading }
          isError={ isError }
          configData={ transformedConfigData }
          setIsFilterOpen={ setIsFilterOpen }
          isFilterOpen={ isFilterOpen }
          throttleActions={ throttleActions }
          selectedFilter={ selectedFilter }
          setSelectedFilter={ updateFilterItem }
          handleSearch={ handleSearch }
          searchInput={ searchInput }
          searchResult={ transformConfigData(searchResult) }
          handleResetSearch={ resetSearch }
          searchError={ searchError }
          searchClose={ searchClose }
          searchBarRef={ searchBarRef }
          configDateRange={ configDateRange }
          setConfigDateRange={ setConfigDateRange }
          setConfigData={ setTransformedConfigData }
        />
        { isFilterOpen && (
          <ConfigFilterPopup
            isPopupOpen={ isFilterOpen }
            onCancel={ setIsFilterOpen }
            onSubmit={ updateFilterItem }
            setIsFilterOpen={ setIsFilterOpen }
            data={ configFilterData }
            popupStyles={ styles.overlay }
            type={ CONFIG_CONTEXT_TYPE.CENTRAL_CONFIG }
            customLoadingStyle={ styles.contentLoading }
            showToastError={ false }
            setFilterAPIError={ setFilterAPIError }
            isModelMandatory={ true }
          />
        ) }
      </div>

      <PromoteDemotePopUp
        { ...promoteDemotePopUp }
        type={ promoteDemotePopUp.type }
        isOpen={ promoteDemotePopUp.isOpen }
        handleCancel={ () => setPromoteDemotePopUp(intialState) }
        name={ promoteDemotePopUp.name }
        handleSubmit={ handlePromoteDemoteFile }
      />

      <PromptModal
        { ...promptModal }
        confirm={ OK }
        cancel={ CANCEL }
        onSubmit={ handlePromoteDemoteFile }
        onCancel={ () => setIsPromptModal({}) }
      />
    </ConfigurationProvider>
  )
}

export default CentralConfiguration
