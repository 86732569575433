/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types"
import React, { useCallback, useContext, useEffect, useMemo, useReducer, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import { BREADCRUMB_ROUTE } from "../../components/Breadcrumb/BreadcrumbUtils"
import {
  FLEET_VIEW_ACTION_TYPES,
  FLEET_VIEW_STATE,
  GROUP_BY_TYPES,
  fleetViewReducer,
  transformFilterData,
  transformFleetInformation,
  transformFleetStatistics
} from "../../pages/FleetView/FleetUtils"
import { getAddRemoveAssetsPayload } from "../../pages/SiteView/SiteUtils"
import { fleetGetSVC, fleetMutationSVC, fleetSVCKeys } from "../../services/reactQueries/fleetsvc"
import { QUERY_KEYS } from "../../utils/Constants/Queries"
import { useLazyQuery, useMutationWithHandlers, useQuery } from "../../utils/CustomHooks/reactQuery"
import { ROUTE_CONFIG } from "../../utils/GlobalConstants"
import { useBreadcrumbContext } from "../breadcrumb/breadcrumb"

const FleetView = React.createContext({})

export const FleetViewProvider = ({ children }) => {
  const { t } = useTranslation(["fleet"])
  const navigate = useNavigate(null)
  const { updateBreadcrumbData } = useBreadcrumbContext()

  const groupByAssetsRef = useRef(null)
  const assetsNotInSubfleetContainerRef = useRef(null)
  const subFleetSummaryRef = useRef(null)
  const ṣearchInputRef = useRef(null)
  const [state, dispatch] = useReducer(fleetViewReducer, FLEET_VIEW_STATE)

  const queryParamIds = useParams()
  const fleetId = queryParamIds[ROUTE_CONFIG.FLEET.param]
  const assetsQueryKey = [QUERY_KEYS.FLEET_VIEW_ASSETS, fleetId]
  const currentURL = window.location.pathname

  const onDataLoadCompleted = (data) => {
    const assets = data?.data?.[state.groupByMappingObject.responseKey]
    if (state.searchInput && state.groupByMappingObject.serviceName === "assets") {
      if (assets.length === 1) {
        const asset = assets[0]
        updateBreadcrumbData({
          id: asset?.id,
          key: BREADCRUMB_ROUTE.ASSET,
          name: asset?.friendlyName
        })
        const path = window.location.pathname.concat(`/${ROUTE_CONFIG.ASSET.routeName}/${asset?.id}`)
        navigate(path)
      } else {
        dispatch({
          payload: assets,
          type: FLEET_VIEW_ACTION_TYPES.SEARCH_RESULTS
        })
      }
    } else if (state.selectedFilters?.length > 0 && state.groupByMappingObject.serviceName === "assets") {
      dispatch({
        payload: data?.data?.data?.contents,
        type: FLEET_VIEW_ACTION_TYPES.FILTER_RESULT
      })
    } else if (state.groupByMappingObject.serviceName === "assets") {
      if (assets?.length === 0) {
        setGroupBySelectedValue(1)
      }
    } else if (state.groupByMappingObject.serviceName === "") {
      dispatch({
        payload: data?.data?.enabledGrouping,
        type: FLEET_VIEW_ACTION_TYPES.ENABLE_GROUP_BY_DROPDOWN
      })
      const fleet = data?.data?.parentFleet
      updateBreadcrumbData({
        id: fleet?.id,
        key: BREADCRUMB_ROUTE.FLEET,
        name: fleet?.name
      })
      if (data?.data?.[state.groupByMappingObject.responseKey]?.length === 0) toggleAccordionExpand(true)
    } else {
      toggleAccordionExpand(false)
    }
  }

  const { data: fleetStatistics } = useQuery([fleetSVCKeys.GET_STATISTICS_BY_FLEET_ID, fleetId], () =>
    fleetGetSVC.getFleetStatisticsByFleetId(fleetId)
  )

  const fleetStatisticsData = fleetStatistics?.data?.statistics

  const [getGroupByFleetData, { isFetched: fetchSubFleet, data: subFleetResponse, isError }] = useLazyQuery(
    [fleetSVCKeys.FLEET_VIEW_GROUP_BY, fleetId],
    () =>
      fleetGetSVC.getSubFleetsByFleetId(
        fleetId,
        state.groupByMappingObject.serviceName,
        state.searchInput,
        state.selectedFilters
      ),
    { onSuccess: onDataLoadCompleted }
  )

  const allGroupByCommonData = subFleetResponse?.data?.[state.groupByMappingObject.responseKey]

  const site = fetchSubFleet && subFleetResponse?.data?.data?.fleet
  const currentAPIGroupBy = state.groupByMappingObject.serviceName

  useEffect(() => {
    if (!state.searchError && GROUP_BY_TYPES[state.groupBySelectedValue]) {
      getGroupByFleetData()
    }
  }, [
    fleetId,
    state.searchInput,
    state.searchError,
    state.selectedFilters,
    state.groupBySelectedValue,
    state.groupByMappingObject
  ])

  const {
    isFetched: fetchDevice,
    data: assetsNotInSubFleetResponse,
    isError: isAssetError
  } = useQuery([fleetSVCKeys.GET_DESCRETE_ASSETS_BY_FLEET_ID, fleetId], () =>
    fleetGetSVC.getDescreteAssetsByFleetId(fleetId)
  )
  const devices = fetchDevice && assetsNotInSubFleetResponse?.data?.assets
  const isLoading = !(fetchSubFleet && fetchDevice)
  const assetsNotInSubFleetStatistics = subFleetResponse?.data?.discreteAssetsStatistics
  const data = { assetsNotInSubFleetStatistics, devices, site, subFleets: allGroupByCommonData }

  const { distributionData } = transformFleetStatistics(fleetStatisticsData)
  const { subFleets, assetsNotInSubFleet } = useCallback(transformFleetInformation(data), [data])

  const fleetViewFilterData = transformFilterData()

  const resetDrag = () => {
    dispatch({ payload: {}, type: FLEET_VIEW_ACTION_TYPES.DRAG_DETAILS })
    subFleetSummaryRef?.current?.resetSelection()
  }

  const { requestMutation: addDeviceToFleet, ...addDeviceMutationResults } = useMutationWithHandlers({
    onCompletedCallback: resetDrag,
    onErrorCallback: resetDrag,
    queryFn: fleetMutationSVC.addAssets,
    refetchQueries: [
      { id: fleetId, key: fleetSVCKeys.GET_DESCRETE_ASSETS_BY_FLEET_ID },
      { id: fleetId, key: fleetSVCKeys.FLEET_VIEW_GROUP_BY }
    ]
  })

  const handleOpenMoveFleet = (payload) => {
    dispatch({ payload, type: FLEET_VIEW_ACTION_TYPES.OPEN_MOVE_FLEET_POPUP })
  }

  const handleConfirmPopup = (payload) => {
    dispatch({ payload, type: FLEET_VIEW_ACTION_TYPES.CONFIRM_POPUP })
  }

  const toggleView = () => {
    dispatch({ type: FLEET_VIEW_ACTION_TYPES.TOGGLE_VIEW })
  }
  const toggleAccordionExpand = (payload) => {
    dispatch({
      payload,
      type: FLEET_VIEW_ACTION_TYPES.TOGGLE_ACCORDION_EXPAND
    })
  }

  const setSelectedAssets = (payload) => {
    dispatch({ payload, type: FLEET_VIEW_ACTION_TYPES.SET_SELECTED_ASSETS })
  }
  const handleCreateUpdatePopup = (payload) => {
    dispatch({ payload, type: FLEET_VIEW_ACTION_TYPES.CREATE_UPDATE_POPUP })
  }

  const setGroupBySelectedValue = (payload) => {
    dispatch({ payload, type: FLEET_VIEW_ACTION_TYPES.SET_GROUP_BY_DROPDOWN })
  }

  const setGroupByChangeMethod = (payload) => {
    dispatch({
      payload,
      type: FLEET_VIEW_ACTION_TYPES.SET_GROUP_BY_CHANGE_METHOD
    })
  }

  const updateFilters = async (value) => {
    dispatch({ payload: value, type: FLEET_VIEW_ACTION_TYPES.FILTER_INPUT })
    handleSearchError("", false)
  }

  const handleSearchAssets = (value, closeAction) => {
    dispatch({
      payload: { closeAction, value },
      type: FLEET_VIEW_ACTION_TYPES.SEARCH_INPUT
    })
  }

  const handleSearchError = (value, hasError) => {
    dispatch({
      payload: { hasError, value },
      type: FLEET_VIEW_ACTION_TYPES.SEARCH_ERROR
    })
  }

  const setShowNewFleetSection = (payload) => {
    dispatch({
      payload,
      type: FLEET_VIEW_ACTION_TYPES.SET_NEW_FLEET_SELECTION
    })
  }

  const handleFilterState = (payload) => {
    dispatch({ payload, type: FLEET_VIEW_ACTION_TYPES.FILTER_POPUP_STATE })
  }

  const handleOnDragEnd = (result) => {
    if (result.draggableId && result.destination?.droppableId) {
      const {
        draggableId,
        destination: { droppableId }
      } = result

      let droppedFleetDetails = (subFleets || []).find(
        (eachFleet) => eachFleet[state.groupByMappingObject.idKey] === droppableId
      )

      if (droppedFleetDetails) {
        droppedFleetDetails.deviceId = draggableId
      }
      let draggedAssetDetails = (assetsNotInSubFleet?.assets || []).find((eachAsset) => eachAsset.id === draggableId)
      handleAddAssets(draggedAssetDetails, droppedFleetDetails, draggableId, droppableId)
    }
  }

  const handleAddAssets = (draggedAsset, droppedFleet, assetId, fleetId) => {
    const successMessage = `${draggedAsset.name} asset moved to ${droppedFleet[state.groupByMappingObject.titleKey]}`
    addDeviceToFleet({
      payload: getAddRemoveAssetsPayload([assetId], fleetId),
      successMessage
    })
  }

  const disableToggle = useMemo(() => {
    let disableToggle = false

    if (state.groupBySelectedValue == 1) {
      if (assetsNotInSubFleet?.assets?.length === 0 || state.searchError || !state.isAssetsNotInFleetExpanded) {
        disableToggle = true
      }
    } else if (state?.groupBySelectedValue === 7) {
      if (subFleets?.length == 0 || state.searchError) {
        disableToggle = true
      }
    } else {
      disableToggle = true
    }
    return disableToggle
  }, [
    state.searchError,
    state.searchInput,
    state.groupBySelectedValue,
    assetsNotInSubFleet?.assets,
    subFleets,
    state.isAssetsNotInFleetExpanded
  ])

  const onMoveToCompleted = (dataIN) => {
    handleConfirmPopup(dataIN)
    subFleetSummaryRef?.current?.resetSelection()
  }

  const siteViewContextValue = useMemo(() => ({
    addDeviceMutationResults,
    assetsNotInSubfleetContainerRef,
    currentURL,
    disableToggle,
    fleetViewState: {
      ...state,
      ...{
        assetsNotInSubFleet,
        assetsQueryKey,
        currentAPIGroupBy,
        distributionData,
        fetchDevice,
        fleetId,
        fleetStatisticsData,
        fleetViewFilterData,
        isAssetError,
        isError,
        isLoading,
        subFleets
      }
    },
    groupByAssetsRef,
    handleConfirmPopup,
    handleCreateUpdatePopup,
    handleFilterState,
    handleOnDragEnd,
    handleOpenMoveFleet,
    handleSearchAssets,
    onMoveToCompleted,
    setGroupByChangeMethod,
    setGroupBySelectedValue,
    setSearchError: handleSearchError,
    setSelectedAssets,
    setShowNewFleetSection,
    subFleetSummaryRef,
    toggleAccordionExpand,
    toggleView,
    translation: t,
    updateFilters,
    ṣearchInputRef
  }))

  return <FleetView.Provider value={ siteViewContextValue }>{ children }</FleetView.Provider>
}

FleetViewProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const useFleetViewContext = () => {
  return useContext(FleetView)
}
