import PropTypes from "prop-types"
import React, { useId } from "react"

import styles from "./DistributionData.module.scss"

import Bar from "../../Bar/Bar"

const DistributionData = ({ title, value, color, barWidth }) => {
  const id = useId()
  return (
    <div className={ styles.statusFrame }>
      <span className={ styles.statusTypeData }>
        <span className={ styles.statusType } id={ id }>
          { title }
        </span>
        <span className={ styles.statusType } aria-labelledby={ id }>
          { value || 0 }
        </span>
      </span>
      <span className={ styles.bar }>
        <Bar color={ color } barStyles={ { width: `${barWidth}%` } } />
      </span>
    </div>
  )
}

DistributionData.propTypes = {
  barWidth: PropTypes.number,
  color: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.number
}

export default DistributionData
