import PropTypes from "prop-types"
import React from "react"

import { decodeURIString } from "../../../utils/Common/common"
import { DATE_FORMAT } from "../../../utils/Constants/TimeZones"
import { formatDate } from "../../../utils/helper"
import styles from "../NotesPopUp.module.scss"

const NotesSend = ({ data }) => {
  return (
    <>
      <span className={ styles.currentDateTime }>{ formatDate(data?.timestamp, DATE_FORMAT.dateTime) }</span>
      <div className={ styles.note }>{ decodeURIString(data?.note) }</div>
    </>
  )
}

NotesSend.propTypes = {
  data: PropTypes.object
}

export default NotesSend
