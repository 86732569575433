import { IconButton } from "@mui/material"
import PropTypes from "prop-types"
import { React } from "react"

import styles from "./ToggleSwitch.module.scss"

import { ICON_NAME } from "../../utils/GlobalConstants"
import Icon from "../Icon/Icon"
import IconLabel from "../IconLabel/IconLabel"

const ToggleSwitch = ({
  id,
  isChecked,
  label,
  labelIcon,
  iconTooltipLabel,
  displayIconTooltip,
  handleOnClick
}) => {
  return (
    <div className={ styles.labelSwitch }>
      { label && (
        <IconLabel
          iconName={ labelIcon }
          label={ label }
          iconStyles={ styles.muteIcon }
          labelStyles={ styles.label }
          displayIconTooltip={ displayIconTooltip }
          iconTooltipLabel={ iconTooltipLabel }
        />
      ) }
      <IconButton
        aria-label={ id }
        onClick={ () => handleOnClick(!isChecked) }
        disableRipple
        className={ `iconButtonToggle ${isChecked ? "checked" : "unChecked"}` }
      >
        <Icon
          icon={ isChecked ? ICON_NAME.toggleMute : ICON_NAME.toggleUnmute }
        />
      </IconButton>
    </div>
  )
}

ToggleSwitch.propTypes = {
  displayIconTooltip: PropTypes.bool,
  handleOnClick: PropTypes.func,
  iconTooltipLabel: PropTypes.string,
  id: PropTypes.string,
  isChecked: PropTypes.bool,
  label: PropTypes.string,
  labelIcon: PropTypes.any
}

export default ToggleSwitch
