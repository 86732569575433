/* eslint-disable react-hooks/exhaustive-deps */
import { AwsRum } from "aws-rum-web"
import PropTypes from "prop-types"
import React, { createContext, useContext, useMemo } from "react"

import { rumServerDetails } from "../config/serverConfig"

let awsRum = null
try {
  const config = {
    allowCookies: true,
    enableXRay: false,
    endpoint: rumServerDetails.server,
    identityPoolId: rumServerDetails.poolID,
    sessionSampleRate: 1,
    telemetries: ["performance", "errors", "http"]
  }

  const APPLICATION_ID = rumServerDetails.appID
  const APPLICATION_VERSION = "1.0.0"
  const APPLICATION_REGION = rumServerDetails.region

  // eslint-disable-next-line no-unused-vars
  awsRum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config)
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
  // eslint-disable-next-line no-console
  console.log(error)
}

const customeLogger = (msg) => {
  awsRum.recordError(msg)
}

const AwsAppLoggerContext = createContext({})

export const AwsAppLoggerProvider = ({ children }) => {
  const LoggerValue = useMemo(() => ({ customeLogger }))
  return <AwsAppLoggerContext.Provider value={ LoggerValue }>{ children }</AwsAppLoggerContext.Provider>
}

AwsAppLoggerProvider.propTypes = {
  children: PropTypes.any
}

export const useAwsAppLoggerContext = () => {
  return useContext(AwsAppLoggerContext)
}
