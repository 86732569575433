/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState
} from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"

import HeadSection from "./internals/HeadSection"
import styles from "./SoftwarePackages.module.scss"
import SoftwarePackagesColumnConfig from "./SoftwarePackagesColumnConfig"
import {
  DEFAULT_DATE_RANGE,
  SOFTWARE_TYPE,
  intialState,
  transformSoftwarePackages
} from "./SoftwarePackagesUtils"

import DataTable from "../../../components/DataTable/DataTable"
import Error from "../../../components/Error/Error"
import Loading from "../../../components/Loading/Loading"
import SoftwarePackagesOverlay from "../../../components/SoftwarePackagesOverlay/SoftwarePackagesOverlay"
import SWPackageCancelDeployModal from "../../../components/SWPackageCancelDeployModal/SWPackageCancelDeployModal"
import SWPackageDeployModal from "../../../components/SWPackageDeployModal/SWPackageDeployModal"
import { useAssetViewContext } from "../../../contexts/assetView/assetView"
import { softwareSVC, softwareSVCKeys } from "../../../services/reactQueries/softwaresvc"
import { TABLE_ACTION } from "../../../utils/Constants/AssetsConfig"
import {
  ACTION_TYPES,
  assetSWPackageInitialStage,
  reducer
} from "../../../utils/Constants/AssetSWPakackages"
import { TABLE_INSTANCES } from "../../../utils/Constants/DataTable"
import { DATE_FORMAT } from "../../../utils/Constants/TimeZones"
import { GLOBAL_THROTTLE_TIME } from "../../../utils/GlobalConstants"
import { convertToServerTimeZone, throttle } from "../../../utils/helper"

const SoftwarePackages = () => {
  const {
    assetDetails: { muted, serialNumber }
  } = useAssetViewContext()

  const { t } = useTranslation(["asset"])
  const queryKey = [softwareSVCKeys.GET_SOFTWARE_PACKAGES_BY_SERIAL_NUMBER, serialNumber]
  const [softwareOverlay, setSoftwareOverlay] = useState(intialState)

  const tableRef = useRef(null)
  const [dateRange, setDateRange] = useState({
    endDate: DEFAULT_DATE_RANGE.endDate,
    startDate: DEFAULT_DATE_RANGE.startDate
  })

  const [state, dispatch] = useReducer(reducer, assetSWPackageInitialStage)

  const { isLoading, data, isError, refetch } = useQuery(queryKey, () =>
    softwareSVC.getSoftwarePackagesByAssetSerialNumber(
      serialNumber,
      convertToServerTimeZone(dateRange?.startDate, DATE_FORMAT.date),
      convertToServerTimeZone(dateRange?.endDate, DATE_FORMAT.date)
    )
  )

  const softwarePackagesRaw = !isLoading && data?.data?.packageInfo
  const softwarePackages = useMemo(
    () => transformSoftwarePackages(softwarePackagesRaw),
    [softwarePackagesRaw]
  )

  useEffect(() => {
    refetch()
  }, [dateRange])

  const handleRowClickActions = (actionType, payload) => {
    switch (actionType) {
    case TABLE_ACTION.INFO:
      setSoftwareOverlay({
        ...softwareOverlay,
        isOpen: true,
        packageDetails: payload
      })
      break
    case TABLE_ACTION.DEPLOY:
      dispatch({
        payload,
        type: ACTION_TYPES.OPEN_DEPLOY_MODAL
      })
      break
    case TABLE_ACTION.EDIT_SCHEDULE_DEPLOY:
      dispatch({
        payload,
        type: ACTION_TYPES.OPEN_EDIT_SCHEDULED_MODAL
      })
      break
    case TABLE_ACTION.CANCEL_DEPLOY:
      dispatch({
        payload,
        type: ACTION_TYPES.OPEN_CANCEL_DEPLOY_MODAL
      })
      break
      /* istanbul ignore next */
    default:
      break
    }
  }

  const handleCancelDeploy = () => {
    dispatch({
      type: ACTION_TYPES.CLOSE_DEPLOY_MODAL
    })
  }

  const handleSubmitDeploy = () => {
    handleCancelDeploy()
  }

  const handleCloseEditDeploy = () => {
    dispatch({
      type: ACTION_TYPES.CLOSE_EDIT_SCHEDULED_MODAL
    })
  }

  const handleSubmitEditDeploy = () => {
    handleCloseEditDeploy()
  }

  const handleCloseCancelDeploy = () => {
    dispatch({
      type: ACTION_TYPES.CLOSE_CANCEL_DEPLOY_MODAL
    })
  }

  const handleSubmitCancelDeploy = () => {
    handleCloseCancelDeploy()
  }

  const throttleActions = useCallback(
    throttle(handleRowClickActions, GLOBAL_THROTTLE_TIME),
    []
  )

  if (isLoading)
    return (
      <div className={ styles.loader }>
        <Loading />
      </div>
    )

  if (isError) return <Error error={ { message: "" } } />

  return (
    <>
      <div className={ styles.topSection }>
        <HeadSection dateRange={ dateRange } setDateRange={ setDateRange } />
      </div>
      <div className={ styles.softwareTable }>
        <DataTable
          ref={ tableRef }
          rowHoverEffect
          tableData={ softwarePackages }
          columnsData={ SoftwarePackagesColumnConfig(throttleActions, muted) }
          type={ TABLE_INSTANCES.SOFTWARE_PACKAGES }
          disableCellTooltip
          queryKey={ queryKey }
        />
      </div>
      { state?.deployModal?.isOpen && (
        <SWPackageDeployModal
          t={ t }
          { ...state.deployModal }
          type={ TABLE_ACTION.DEPLOY }
          pageType={ SOFTWARE_TYPE.ASSET_VIEW }
          onCancel={ handleCancelDeploy }
          onSubmit={ handleSubmitDeploy }
          assetSerialNumber={ serialNumber }
        />
      ) }

      { state?.editScheduledModal?.isOpen && (
        <SWPackageDeployModal
          t={ t }
          { ...state.editScheduledModal }
          type={ TABLE_ACTION.EDIT_SCHEDULE_DEPLOY }
          pageType={ SOFTWARE_TYPE.ASSET_VIEW }
          onCancel={ handleCloseEditDeploy }
          onSubmit={ handleSubmitEditDeploy }
          assetSerialNumber={ serialNumber }
        />
      ) }

      { state?.cancelDeployModal?.isOpen && (
        <SWPackageCancelDeployModal
          t={ t }
          { ...state.cancelDeployModal }
          serialNumber={ serialNumber }
          popupStyles={ styles.cancelDeployPopup }
          onCancel={ handleCloseCancelDeploy }
          onSubmit={ handleSubmitCancelDeploy }
        />
      ) }

      <SoftwarePackagesOverlay
        { ...softwareOverlay }
        isOpen={ softwareOverlay.isOpen }
        handleCancel={ () => setSoftwareOverlay(intialState) }
      />
    </>
  )
}

export default SoftwarePackages
