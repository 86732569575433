import {
  BREADCRUMB_ROUTE,
  BREADCRUMB_ROUTE_ID
} from "../../components/Breadcrumb/BreadcrumbUtils"
import { ROUTE_CONFIG } from "../../utils/GlobalConstants"
import axiosClient from "../axios/axios"

const URLS = {
  [BREADCRUMB_ROUTE.FLEET]: "/fleetsvc/v1/fleets/by/fleetIds",
  [BREADCRUMB_ROUTE.ASSET]: "/fleetsvc/v1/assets"
}

const getCurrentPath = (params, location) => {
  const formatedRoute = Object.entries(params).reduce((path, [key, value]) => {
    return path.replace(`/${value}`, `/:${key}`)
  }, location.pathname)
  const currentRouteParam = formatedRoute.substring(
    formatedRoute.lastIndexOf("/") + 1, formatedRoute.length
  )?.replace(":", "")
  if (
    currentRouteParam === ROUTE_CONFIG.FLEET.param ||
    currentRouteParam === ROUTE_CONFIG.SUB_FLEET.param
  ) return ""
  return currentRouteParam
}

const checkIgnoredAPICallRoutes = (key) => 
  key === BREADCRUMB_ROUTE.SITE_MODALITY ||
  key === BREADCRUMB_ROUTE.SITE_ASSET_TYPE ||
  key === BREADCRUMB_ROUTE.SITE_CONNECTION ||
  key === BREADCRUMB_ROUTE.FLEET_MODALITY ||
  key === BREADCRUMB_ROUTE.FLEET_ASSET_TYPE ||
  key === BREADCRUMB_ROUTE.FLEET_CONNECTION

const prepareSubFleetRoute = (urls, queryId) => {
  const idx = urls?.findIndex(url => url.type === BREADCRUMB_ROUTE.FLEET)
  if (idx > -1) {
    const str = urls[idx].url
    const fleetId = window.atob(str?.substring(str?.lastIndexOf("=") + 1, str?.length) || "")
    urls[idx].url = URLS[BREADCRUMB_ROUTE.FLEET] + `?base64siteIds=${window.btoa(fleetId + "," + queryId)}`
  }
  return urls
}

const checkQueryAvailableInSession = (key, queryId, sessionBreadcrumbs) => {
  const sessionFleetId = sessionBreadcrumbs?.find(breadcrumb => breadcrumb.key === key)?.id
  return sessionFleetId === queryId
}

let breadcrumbData = []
const setBreadcrumbRoutesData = (key, urls, sessionBreadcrumbs, queryId) => {
  let url = ""
  if (key === BREADCRUMB_ROUTE.FLEET) {
    if (checkQueryAvailableInSession(key, queryId, sessionBreadcrumbs)) return urls
    url = URLS[key] + `?base64siteIds=${window.btoa(queryId)}`
  } else if (key === BREADCRUMB_ROUTE.SUB_FLEET) {
    if (checkQueryAvailableInSession(key, queryId, sessionBreadcrumbs)) return urls
    urls = prepareSubFleetRoute(urls, queryId)
  } else if (checkIgnoredAPICallRoutes(key)) {
    breadcrumbData.push({
      key: BREADCRUMB_ROUTE[key],
      name: queryId
    })
    return urls
  } else if (key === BREADCRUMB_ROUTE.ASSET) {
    if (checkQueryAvailableInSession(key, queryId, sessionBreadcrumbs)) return urls
    url = URLS[key] + `/${queryId}`
  }
  if (url) urls.push({ type: key, url })
  return urls
}

const getBreadcrumbRequests = (sessionBreadcrumbs, queryParams, location) => {
  let urls = []
  const currentRouteParam = getCurrentPath(queryParams, location)
  for (const [key, value] of Object.entries(BREADCRUMB_ROUTE_ID)) {
    const queryId = queryParams[value]
    if (queryId && value !== currentRouteParam) {
      urls = setBreadcrumbRoutesData(key, urls, sessionBreadcrumbs, queryId)
    }
  }
  return urls
}

export const getBreadcrumb = async (sessionBreadcrumbs, queryParams, location) => {
  breadcrumbData = []
  const requestsData = getBreadcrumbRequests(sessionBreadcrumbs, queryParams, location)
  const requests = requestsData.map(({ url }) => axiosClient.get(url))

  return Promise.allSettled(requests).then((res) => {
    const fleetIdx = requestsData.findIndex(request => request.type ===  BREADCRUMB_ROUTE.FLEET)
    const assetIdx = requestsData.findIndex(request => request.type ===  BREADCRUMB_ROUTE.ASSET)

    if (fleetIdx > -1 && res[fleetIdx]?.status === "fulfilled") {
      const fleets = res[fleetIdx]?.value?.data?.fleets
      const fleetData = fleets?.find(fleet => fleet.id === queryParams[ROUTE_CONFIG.FLEET.param])
      const subFleetData = fleets?.find(fleet => fleet.id === queryParams[ROUTE_CONFIG.SUB_FLEET.param])

      breadcrumbData.push({
        id: fleetData?.id,
        key: BREADCRUMB_ROUTE.FLEET,
        name: fleetData?.fleetName
      })
      breadcrumbData.push({
        id: subFleetData?.id,
        key: BREADCRUMB_ROUTE.SUB_FLEET,
        name: subFleetData?.fleetName
      })
    }
    if (assetIdx > -1 && res[assetIdx]?.status === "fulfilled") {
      const asset = res[assetIdx]?.value?.data
      breadcrumbData.push({
        id: asset?.id,
        key: BREADCRUMB_ROUTE.ASSET,
        name: asset?.friendlyName
      })
    }
    return breadcrumbData
  })
}
