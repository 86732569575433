import { prepareFilterDropdownSupportedValues, softwareStatus } from "../../../utils/helper"

export const transformAssets = (data) => {
  const assets = []
  for (const asset of data) {
    assets.push({
      ...asset,
      assetType: asset.model,
      deviceId: asset.serialNumber,
      fleet: asset.fleetName,
      id: asset.id,
      ipAddress: asset?.ipAddress,
      lastConnected: asset?.lastConnected,
      model: asset.model,
      mute: asset.muted,
      name: asset?.friendlyName,
      severity: asset?.faultSeverity,
      softwareVersion: asset?.softwareVersion,
      status: asset?.connectivityStatus || "Offline",
      swStatus: softwareStatus(asset?.swStatus?.toLowerCase(), asset?.swInProgressStatus?.toLowerCase()),
      uuid: asset.id
    })
  }
  return assets
}

export const transformFilterData = (swVerionsList) => {
  return [
    {
      defaultValue: "",
      disabled: true,
      key: "modality",
      label: "Modality",
      multiple: false,
      options: []
    },
    {
      defaultValue: [],
      key: "model",
      label: "Model",
      multiple: true,
      options: []
    },
    {
      defaultValue: [],
      key: "swVersion",
      label: "SW ver.",
      multiple: true,
      options: prepareFilterDropdownSupportedValues(swVerionsList) || []
    },
    {
      defaultValue: [],
      key: "swUpdateStatus",
      label: "SW update status",
      multiple: true,
      options: [
        {
          id: 1,
          label: "Scheduled"
        },
        {
          id: 2,
          label: "Success"
        },
        {
          id: 3,
          label: "In progress"
        },
        {
          id: 4,
          label: "Paused"
        },
        {
          id: 5,
          label: "Cancelled"
        },
        {
          id: 6,
          label: "Rejected"
        },
        {
          id: 7,
          label: "Failed"
        }
      ]
    }
  ]
}

export const getAssetSelectionText = (assets, selectedAssets, searchInput, t) => {
  let selectionText = ""
  if (searchInput) {
    if (selectedAssets?.length > 0) {
      selectionText = `${t("selectedSearchAssetCount", {
        searchResultsCount: assets?.length,
        selectedCount: selectedAssets?.length
      })}`
    } else if (assets?.length === 1) {
      selectionText = `${t("searchSingleAssetsCount", {
        searchResultsCount: assets?.length
      })}`
    } else {
      selectionText = `${t("searchAssetsCount", {
        searchResultsCount: assets?.length
      })}`
    }
  } else {
    selectionText = `${t("selectedAssetsCount", {
      selectedCount: selectedAssets?.length
    })}`
  }
  return selectionText
}

export const getCanDisplaySelectionText = (selectedAssets, searchError, searchInput) =>
  selectedAssets?.length > 0 || searchInput?.length >= 3
