import React from "react"

import style from "./FleetView.module.scss"
import SubFleetsSummary from "./SubFleetsSummary/SubFleetsSummary"
import SubFleetSummaryStatistics from "./SubFleetSummaryStatistics/SubFleetSummaryStatistics"

import ConfirmMoveSubFleet from "../../components/ConfirmMoveSubFleet/ConfirmMoveSubFleet"
import Error from "../../components/Error/Error"
import Loading from "../../components/Loading/Loading"
import MoveFleet from "../../components/MoveFleet/MoveFleet"
import { useFleetViewContext } from "../../contexts/fleetView/FleetView"

const FleetViewContainer = () => {
  const {
    fleetViewState,
    translation,
    subFleetSummaryRef,
    handleConfirmPopup,
    handleOpenMoveFleet,
    onMoveToCompleted
  } = useFleetViewContext()

  if (fleetViewState?.isLoading) return <Loading />
  if (fleetViewState?.isError) return <Error error={ { message: translation("groupByErrorMessage") } } />

  return (
    <>
      <div className={ style.staticCards }>
        <SubFleetSummaryStatistics
          distributionData={ fleetViewState.distributionData }
        />
      </div>
      <div className={ style.fleetsSummary }>
        <SubFleetsSummary ref={ subFleetSummaryRef } />
      </div>
      { fleetViewState.moveFleet && (
        <MoveFleet
          isPopupOpen={ fleetViewState.moveFleet }
          onCancel={ handleOpenMoveFleet }
          setIsConfirmPopUp={ handleConfirmPopup }
        />
      ) }
      <ConfirmMoveSubFleet
        popupStyles={ style.confirmationPopUp }
        selectedDevice={ fleetViewState.selectedAssets }
        onSubmit={ onMoveToCompleted }
        onCancel={ (value) => handleConfirmPopup({ isOpen: value }) }
        { ...fleetViewState?.confirmPopup }
      />
    </>
  )
}

export default FleetViewContainer
