import { createTheme } from "@mui/material"

import { Accordion } from "./Accordion/AccordionTheme"
import { Buttons, IconButton } from "./Buttons/ButtonTheme"
import { Checkbox } from "./Checkbox/CheckboxTheme"
import { Chip } from "./Chip/ChipTheme"
import { OutlinedInput } from "./CreateUpdateOverlay/CreateUpdateOverlayTheme"
import { Calender, PickersPopperPaper, TextField } from "./DatePicker/DatePickerTheme"
import { FormLabel, List, Menu, MenuItem, Paper, Select } from "./Dropdown/DropdownTheme"
import { InputBase } from "./InputBase/InputBaseTheme"
import { RadioButton } from "./RadioButton/RadioButtonTheme"
import { SearchBar } from "./SearchBar/SearchBarTheme"
import { Snackbar } from "./Snackbar/SnackbarTheme"
import { Tabs } from "./Tabs/TabsTheme"
import { ToggleSwitch } from "./ToggleSwitch/ToggleSwitchTheme"
import { Tooltip } from "./Tooltip/TooltipTheme"


export const theme = createTheme({
  components: {
    MuiAccordion: Accordion,
    MuiButton: Buttons,
    MuiCalendarOrClockPicker: Calender,
    MuiCheckbox: Checkbox,
    MuiChip: Chip,
    MuiFormControl: SearchBar,
    MuiFormLabel: FormLabel,
    MuiIconButton: IconButton,
    MuiInputBase: InputBase,
    MuiList: List,
    MuiMenu: Menu,
    MuiMenuItem: MenuItem,
    MuiOutlinedInput: OutlinedInput,
    MuiPaper: Paper,
    MuiPickersPopper: PickersPopperPaper,
    MuiRadio: RadioButton,
    MuiSelect: Select,
    MuiSnackbar: Snackbar,
    MuiSwitch: ToggleSwitch,
    MuiTabs:Tabs,
    MuiTextField: TextField,
    MuiTooltip: Tooltip
  },
  typography: {
    fontFamily: `
      "GE Inspira Sans",
      "GE Sans Pro Sans",
      "GE Sans Pro Bold",
      "GE Sans Pro BoldItalic",
      "GE Sans Pro Italic",
      "GE Sans Pro Light Italic",
      "GE Sans Pro Light",
      "GE Sans Pro Extra Light",
      "GE Sans Pro Extra Light Italic",
      "GE Sans Pro Black",
      "GE Sans Pro Black Italic",
      "GE Sans Pro Semi Bold",
      "GE Sans Pro Semi Bold Italic",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "sans-serif",
      "Apple Color Emoji",
      "Segoe UI Emoji",
      "Segoe UI Symbol"
    `
  }
})
