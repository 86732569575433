import { COMPARE_VALUE } from "../../utils/GlobalConstants"
import { arrayGroupBy, formatStatus } from "../../utils/helper"
import axiosClient from "../axios/axios"

const PAGE_SIZE = 1000

export const fleetGetSVC = {
  getAllFleetsBySiteId: (siteId) =>
    axiosClient.get(`/fleetsvc/v1/sites/${siteId}/fleets-subfleets`),
  getAssetDetailsByAssetId: (assetId) => {
    return axiosClient.get(`/fleetsvc/v1/assets/${assetId}`)
  },
  getAssetsForConfigFileDeployment: (
    siteId,
    filters,
    searchString
  ) => {
    const url = `/fleetsvc/v1/sites/${siteId}/assets`
    const searchStringTemplate = searchString
      ? `&searchString=${searchString}`
      : ""
    let query = `?page=1&size=${PAGE_SIZE}&includeChild=true${searchStringTemplate}`
    if (filters && filters?.length > 0) {
      Object.entries(arrayGroupBy(filters, "type")).forEach((eachFilter) => {
        query += `&${eachFilter[0]}=${eachFilter[1]
          .map((each) =>
            eachFilter[0] === COMPARE_VALUE.configStatus
              ? API_STATUS_MAP[formatStatus(each?.label)]
              : each.label
          )
          .join(",")}`
      })
    }
    return axiosClient.get(url + query)
  },
  getDescreteAssetsByFleetId: (fleetId) => {
    return axiosClient.get(
      `/fleetsvc/v1/fleets/${fleetId}/discreteAssets?page=1&size=${PAGE_SIZE}`
    )
  },

  getDescreteAssetsBySiteId: (siteId) => {
    return axiosClient.get(
      `/fleetsvc/v1/sites/${siteId}/discreteAssets?page=1&size=${PAGE_SIZE}`
    )
  },

  // Will return subfleets and assets belong to the fleet
  getFleetDetailsByFleetId: (fleetId) =>
    axiosClient.get(`/fleetsvc/v1/fleets/${fleetId}`),

  getFleetDevicesByGroupBy: (
    fleetId,
    modality,
    model,
    connectionType,
    searchString,
    filters
  ) => {
    let key = ""
    let value = ""
    if (modality) {
      key = "modality"
      value = modality
    } else if (model) {
      key = "model"
      value = model
    } else if (connectionType) {
      key = "connectionType"
      value = connectionType
    }

    const url = `/fleetsvc/v1/fleets/${fleetId}/assets`
    const searchStringTemplate = searchString
      ? `&searchString=${searchString}`
      : ""
    let query = `?page=1&size=${PAGE_SIZE}${searchStringTemplate}`
    query += `&${key}=${value}`
    query += prepareFilterQueryForGroupByPage(filters, modality, model)
    return axiosClient.get(url + query)
  },
  getFleetStatisticsByFleetId: (fleetId) =>
    axiosClient.get(`/fleetsvc/v1/fleets/${fleetId}/statistics`),
  getModalityByModel: (model) => {
    return axiosClient.get(`/fleetsvc/v1/models/${model}/modality`)
  },
  getModalityValuesBySite: (siteId) => {
    return axiosClient.get(`/fleetsvc/v1/sites/${siteId}/modality-names`)
  },
  getModelValuesBySiteAndModality: (siteId, modalities, selectedModalities) => {
    let list = ""
    for (const modality of selectedModalities) {
      list = list + (list ? "," : "") + modalities[modality - 1]
    }
    return axiosClient.get(
      `/fleetsvc/v1/sites/${siteId}/model-names?modalities=${list}`
    )
  },
  getSWVersionsBySiteAndModality: (siteId, modality) => {
    return axiosClient.get(
      `/fleetsvc/v1/sites/${siteId}/software-versions?modalities=${modality}`
    )
  },
  getSiteDevicesByGroupBy: (
    siteId,
    modality,
    model,
    connections,
    searchString,
    filters
  ) => {
    let key = ""
    let value = ""
    if (modality) {
      key = "modality"
      value = modality
    } else if (model) {
      key = "model"
      value = model
    } else if (connections) {
      key = "connectionType"
      value = connections
    }

    const url = `/fleetsvc/v1/sites/${siteId}/assets`
    const searchStringTemplate = searchString
      ? `&searchString=${searchString}`
      : ""
    let query = `?page=1&size=${PAGE_SIZE}${searchStringTemplate}`
    query += `&${key}=${value}`
    query += prepareFilterQueryForGroupByPage(filters, modality, model)
    return axiosClient.get(url + query)
  },
  getSiteGroupBy: (siteId, groupBy, searchString, filters) => {
    const url = `/fleetsvc/v1/sites/${siteId}/${groupBy}`
    const searchStringTemplate = searchString
      ? `&searchString=${searchString}`
      : ""
    let query = `?page=1&size=${PAGE_SIZE}&includeChild=true${searchStringTemplate}`
    
    if (filters && filters?.length > 0) {
      Object.entries(arrayGroupBy(filters, "type")).forEach((eachFilter) => {
        query += `&${eachFilter[0]}=${eachFilter[1]
          .map((each) => FILTER_STATUS[formatStatus(each.label)] || each.label)
          .join(",")}`
      })
    }
    if (groupBy === "fleets") {
      query = query + "&sortBy=fleetName&sortDirection=ASC"
    }
    return axiosClient.get(url + query)
  },

  getSiteStatistics: (siteId) => {
    return axiosClient.get(`/fleetsvc/v1/sites/${siteId}/statistics`)
  },

  getSites: () => {
    return axiosClient.get(
      `/fleetsvc/v1/users/loggedInUser/sites?page=1&size=${PAGE_SIZE}`
    )
  },

  // eslint-disable-next-line no-unused-vars
  getSubFleetGroupBy: (siteId, fleetId, groupBy, searchString, filters) => {
    if (!groupBy) {
      groupBy = "assets"
    }
    const url = `/fleetsvc/v1/fleets/${fleetId}/${groupBy}`
    const searchStringTemplate = searchString
      ? `&searchString=${searchString}`
      : ""
    let query = `?page=1&size=${PAGE_SIZE}${searchStringTemplate}`

    if (filters && filters?.length > 0) {
      Object.entries(arrayGroupBy(filters, "type")).forEach((eachFilter) => {
        query += `&${eachFilter[0]}=${eachFilter[1]
          .map((each) => FILTER_STATUS[formatStatus(each.label)] || each.label)
          .join(",")}`
      })
    }
    return axiosClient.get(url + query)
  },
  // eslint-disable-next-line no-unused-vars
  getSubFleetsByFleetId: (fleetId, groupBy, searchString, filters = []) => {
    const groupByTemplate = groupBy ? `/${groupBy}` : ""
    const url = `/fleetsvc/v1/fleets/${fleetId}${groupByTemplate}`
    const searchStringTemplate = searchString
      ? `&searchString=${searchString}`
      : ""
    let query = `?page=1&size=1000&includeChild=true${searchStringTemplate}`

    if (filters && filters?.length > 0) {
      Object.entries(arrayGroupBy(filters, "type")).forEach((eachFilter) => {
        query += `&${eachFilter[0]}=${eachFilter[1]
          .map((each) => FILTER_STATUS[formatStatus(each.label)] || each.label)
          .join(",")}`
      })
    }
    if (groupBy === "") {
      query = query + "&sortBy=fleetName&sortDirection=ASC"
    }
    return axiosClient.get(url + query)
  }
}

export const fleetMutationSVC = {
  addAssets: ({ fleetId, variables }) =>
    axiosClient.post(`/fleetsvc/v1/fleets/${fleetId}/assets`, variables),
  createFleetSubFleet: ({ variables }) =>
    axiosClient.post("/fleetsvc/v1/fleets", variables),
  deleteFleetByFleetId: ({ fleetId }) => {
    return axiosClient.delete(
      `/fleetsvc/v1/fleets/${fleetId}?forceDelete=true`
    )
  },
  editAssetMuteStatus: ({ assetId, variables }) => {
    return axiosClient.patch(`/fleetsvc/v1/assets/${assetId}/mute`, variables)
  },
  editCustomerName: ({ id, variables }) => {
    return axiosClient.patch(`/fleetsvc/v1/customers/${id}`, variables)
  },
  editFleetSubFleetName: ({ id, variables }) => {
    return axiosClient.put(`/fleetsvc/v1/fleets/${id}`, variables)
  },
  editSiteName: ({ id, variables }) => {
    return axiosClient.patch(`/fleetsvc/v1/sites/${id}`, variables)
  },
  removeDevice: ({ fleetId, variables }) =>
    axiosClient.delete(
      `/fleetsvc/v1/fleets/${fleetId || 0}/assets?ids=${variables}`
    ),
  updateAssetName: ({ id, variables }) => {
    return axiosClient.patch(`/fleetsvc/v1/assets/${id}`, variables)
  }
}

const prepareFilterQueryForGroupByPage = (filters, modality, model) => {
  let filterQuery = ""
  if (filters?.length > 0) {
    Object.entries(arrayGroupBy(filters, "type")).forEach((eachFilter) => {
      if (
        (modality && eachFilter[0] === "modality") ||
        (model && eachFilter[0] === "model")
      )
        return
      filterQuery += `&${eachFilter[0]}=${eachFilter[1]
        .map((each) => FILTER_STATUS[formatStatus(each.label)] || each.label
        )
        .join(",")}`
    })
  }
  return filterQuery
}

export const fleetSVCKeys = {
  ASSETS_FOR_DEPLOYMENT: "assetsForDeployment",
  ASSET_TYPES_BY_SITE_ID: "Asset_Types_By_SITE_Id",
  FLEET_DETAILS_BY_ID: "fleet-details-by-id",
  FLEET_VIEW_GROUP_BY: "fleet-byId",
  FLEET_VIEW_GROUP_BY_PAGES: "device-byFleetId-groupByPage",
  GET_ALL_FLEETS_BY_SITE_ID: "getAllSite-bySiteId",
  GET_DESCRETE_ASSETS_BY_FLEET_ID: "deviceByFleetId",
  GET_DESCRETE_ASSETS_BY_SITE_ID: "deviceBySiteId",
  GET_FLEET_LEVEL_STATISTICS: "getFleetLevelStatistics",
  GET_MODALITY_NAMES_BY_SITE_ID: "getModalityFilterOptions",
  GET_MODEL_NAME_BY_SITE_ID: "getModelFilterOptions",
  GET_SITES: "Sites_By_Customer",
  GET_STATISTICS_BY_FLEET_ID: "getStatisticsByFleetID",
  GET_STATISTICS_BY_SITE_ID: "getStatisticsBySiteID",
  GET_SW_VERSION_BY_SITE_AND_MODALITY: "gerSWVersionsBySiteAndModality",
  MODALITYS_BY_SITE_ID: "Modality_By_Site_Id",
  MODALITY_VALUES_BY_SITE: "modality-values-by-site",
  MODEL_VALUES_BY_SITE: "models-values-by-site",
  SITE_VIEW_GROUP_BY: "site-byID",
  SITE_VIEW_GROUP_BY_PAGES: "device-bySiteId-groupByPage",
  SUB_FLEET_VIEW_GROP_BY: "subFleet-byId"
}

const API_STATUS_MAP = {
  CANCELLED: "canceled",
  FAILED: "failed",
  INDOWNLOAD: "inDownload",
  ININSTALL: "inInstall",
  PAUSED: "paused",
  REJECTED: "rejected",
  SUCCESS: "success"
}

const FILTER_STATUS = {
  AVAILABLE: "available",
  CRITICAL: "Critical",
  INUSE: "inUse",
  LOW:"Low",
  MODERATE:" Moderate",
  OFFLINE: "offline"
}
