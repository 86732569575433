import { PropTypes } from "prop-types"
import React, { useId } from "react"

import style from "./NumberDistributionBar.module.scss"

import {
  BAR_CONFIG,
  defaultDistributionBarData
} from "../../utils/GlobalConstants"
import { classNames } from "../../utils/styles/helper"
import Bar from "../Bar/Bar"

const NumberDistributionBar = ({
  type,
  distribution,
  isDefaultFleet,
  customStyles
}) => {
  const id = useId()
  const distributionData = isDefaultFleet
    ? defaultDistributionBarData
    : distribution

  const setProgressWidth = (count) => {
    return count.map(function (d) {
      return ((100 * d) / count.reduce((a, b) => a + b, 0)) | 0
    })
  }

  return (
    <div
      className={ classNames(style.barsWrapper, customStyles) }
      data-testid={ type }
    >
      { distribution.map((progress, index) => {
        const progressWidth = setProgressWidth(distributionData)[index]
        return progressWidth ? (
          <Bar
            key={ id + progress + index }
            color={
              isDefaultFleet
                ? BAR_CONFIG.defaults[index]
                : BAR_CONFIG[type.toLowerCase()].colors[index]
            }
            barStyles={ {
              width: `${progressWidth}%`
            } }
            toolTipMessages={ BAR_CONFIG[type].toolTipMessages[index] + progress }
          />
        ) : null
      }) }
    </div>
  )
}

NumberDistributionBar.propTypes = {
  customStyles: PropTypes.object,
  distribution: PropTypes.arrayOf(PropTypes.number),
  isDefaultFleet: PropTypes.bool,
  type: PropTypes.oneOf([
    "assets",
    "alerts",
    "updates",
    "swpackages",
    "configs"
  ]).isRequired
}

NumberDistributionBar.defaultProps = {
  distribution: [],
  isDefaultFleet: false
}

export default NumberDistributionBar
