import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

import CloseButton from "./CloseButton"
import FilterItem from "./FilterItem"

import { ICON_NAME } from "../../../utils/GlobalConstants"
import styles from "../SelectedFilters.module.scss"

const FilterDropdown = ({ hiddenFilters, removeItem, hasDisabledFilter, handleClearAll, isPageLoading }) => {
  const { t } = useTranslation(["selectedFilter"])
  return (
    <div className={ styles.dropdown }>
      { hiddenFilters.map((options) => (
        <div key={ options.label } className={ styles.options }>
          <div className={ styles.filterMenuItem }>
            <FilterItem filter={ options } />
            {
              !options.disabled && 
                <CloseButton
                  icon={ ICON_NAME.bgClose }
                  label={ options.label }
                  closeButtonStyle={ styles.closeButton }
                  removeItem={ removeItem }
                  closeIconStyle={ styles.closeIcon }
                  aria-label={ options.label }
                  isPageLoading={ isPageLoading }
                />
            }
          </div>
        </div>
      )) }
      { !hasDisabledFilter && (
        <button className={ styles.clearAllButton } onClick={ handleClearAll } disabled={ isPageLoading }>
          { t("clearAll") }
        </button>
      ) }
    </div>
  )
}

FilterDropdown.propTypes = {
  handleClearAll: PropTypes.func,
  hasDisabledFilter: PropTypes.bool,
  hiddenFilters: PropTypes.array,
  isPageLoading: PropTypes.bool,
  removeItem: PropTypes.func
}

export default FilterDropdown
