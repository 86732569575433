import PropTypes from "prop-types"
import React from "react"

import UserGroupTabs from "./UserGroupTabs"

import IconLabel from "../../../../../components/IconLabel/IconLabel"
import Loading from "../../../../../components/Loading/Loading"
// import ReadOnlyField from "../../../../../components/ReadOnlyField/ReadOnlyField"
import { useUserManagementContext } from "../../../../../contexts/userManagement/UserManagement"
// import { EMPTY_CELL_DEFAULT_VALUE } from "../../../../../utils/Constants/DataTable"
import { classNames } from "../../../../../utils/styles/helper"
import styles from "../../../UserManagement.module.scss"

const UserGroupAccessManagement = () => {
  const {
    getUserGroupAccessDetailsQueryResults,
    // t,
    userManagementState,
    getMemberResults
  } = useUserManagementContext()

  const { isFetching } = getUserGroupAccessDetailsQueryResults
  const { isFetching: isFetchingMembers } = getMemberResults


  if (isFetching || isFetchingMembers) {
    return <Loading customStyles={ styles.loader } />
  }

  return (
    <>
      <div className={ styles.rightUpperContainer }>
        <IconLabel
          label={ userManagementState?.selectedRowDetails?.name }
          labelStyles={ classNames(styles.labelStyles, styles.textEllipsis) }
        />
        { /* <div className={ styles.descriptionSection }>
          { userManagementState?.selectedRowDetails?.description || "This is a description placeholder" }
        </div>
        <div className={ styles.detailsSection }>
          <span className={ styles.innerDetails }>
            <ReadOnlyField
              label={ t("orgLabel") }
              value={ userManagementState?.selectedRowDetails?.organizationName || EMPTY_CELL_DEFAULT_VALUE }
            />
          </span>
          <span className={ styles.innerDetails }>
            <ReadOnlyField
              label={ t("roleLabel") }
              value={ userManagementState?.selectedRowDetails?.role || EMPTY_CELL_DEFAULT_VALUE }
            />
          </span>
        </div> */ }
      </div>
      <div className={ styles.rightLowerContainer }>
        <UserGroupTabs />
      </div>
    </>
  )
}

UserGroupAccessManagement.propTypes = {
  isSelected: PropTypes.string,
  rowId: PropTypes.string
}

export default UserGroupAccessManagement
