import PropTypes from "prop-types"
import React from "react"
import { Navigate } from "react-router-dom"

import useAccess from "../utils/CustomHooks/useAccess"
import { ROUTE_CONFIG } from "../utils/GlobalConstants"
import { StorageKeys, getSession } from "../utils/SessionHelper/session"

const ProtectedRoutes = ({ pageType, element }) => {
  const hasAccess = useAccess(pageType)

  if (!getSession(StorageKeys.USER_SESSION)) return <Navigate to={ `/${ROUTE_CONFIG.LOGIN.routeName}` } />
  if (!hasAccess) return <Navigate to={ `/${ROUTE_CONFIG.ACCESS_DENIED.routeName}` } replace={ true } />
  return element
}

ProtectedRoutes.propTypes = {
  element: PropTypes.node,
  pageType: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
}
  
export default ProtectedRoutes
