import i18n from "i18next"

import { TABLE_COLUMN_SPECIAL_ID_ACCESSORS } from "../../../../utils/Constants/DataTable"
import { DATE_FORMAT } from "../../../../utils/Constants/TimeZones"
import { formatDate } from "../../../../utils/helper"

const ReportsSWPackageTableColumnConfig = () => [
  {
    accessorKey: "initiatedOn",
    cell: ({ cell }) => formatDate(cell.getValue(), DATE_FORMAT.dateTime),
    header: i18n.t("reports:swPackageTab.tableHeaders.initiatedOn")
  },
  {
    accessorKey: "name",
    header: i18n.t("reports:swPackageTab.tableHeaders.name")
  },
  {
    accessorKey: "description",
    header: i18n.t("reports:swPackageTab.tableHeaders.description"),
    size: 100
  },
  {
    accessorKey: "version",
    header: i18n.t("reports:swPackageTab.tableHeaders.version"),
    size: 80
  },
  {
    accessorKey: "assetName",
    header: i18n.t("reports:swPackageTab.tableHeaders.assetName"),
    size: 100
  },
  {
    accessorKey: "serialNumber",
    header: i18n.t("reports:swPackageTab.tableHeaders.serialNo"),
    size: 100
  },
  {
    accessorKey: "status",
    disableTooltip: true,
    header: i18n.t("reports:swPackageTab.tableHeaders.status"),
    id: TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_SOFTWARE_STATUS,
    minSize: 160,
    size: 160
  },
  {
    accessorKey: "lastUpdated",
    cell: ({ cell }) => formatDate(cell.getValue(), DATE_FORMAT.dateTime),
    defaultCanSort: true,
    header: i18n.t("reports:swPackageTab.tableHeaders.lastUpdated"),
    size: 80,
    sortDesc: true
  },
  {
    accessorKey: "user",
    header: i18n.t("reports:swPackageTab.tableHeaders.user"),
    size: 80
  },
  {
    accessorKey: "notes",
    header: i18n.t("reports:swPackageTab.tableHeaders.notes"),
    size: 80
  }
]

export default ReportsSWPackageTableColumnConfig
