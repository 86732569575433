export const mutationDataReducer = (newState, action) => {
  switch (action.type) {
  case "add":
    return { ...newState, ...action.payload }
  case "clear":
    return { ...newState, ...INITIAL_MUTATION_STATE, ...action.payload }
    /* istanbul ignore next */
  default:
    return newState
  }
}
  
export const INITIAL_MUTATION_STATE = { 
  errorMessage: "",
  showToast: true,
  successMessage: ""
}
