import IconButton from "@mui/material/IconButton"
import PropTypes from "prop-types"
import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useIsFetching } from "react-query"

import ColumnConfiguration from "./internals/ColumnConfiguration"

import DataTable from "../../../../components/DataTable/DataTable"
import SingleSelectDropdown from "../../../../components/Dropdown/SingleSelectDropdown"
import Icon from "../../../../components/Icon/Icon"
import SearchBar from "../../../../components/SearchBar/SearchBar"
import SearchErrorPage from "../../../../components/SearchErrorPage/SearchErrorPage"
import SelectedFilters from "../../../../components/SelectedFilterItem/SelectedFilters"
import { useSoftwareUpdateContext } from "../../../../contexts/swUpdate/SoftwareUpdateContext"
import { TABLE_INSTANCES } from "../../../../utils/Constants/DataTable"
import { SEARCH_INSTANCES } from "../../../../utils/Constants/Search"
import useCheckBoxSelection from "../../../../utils/CustomHooks/useCheckBoxSelection"
import {
  ALERT_SW_STATUS,
  CONFIG_DEPLOY_DROPDOWN_OPTIONS,
  GROUP_BY,
  ICON_NAME,
  globalSearch
} from "../../../../utils/GlobalConstants"
import { formatStatus } from "../../../../utils/helper"
import styles from "../CentralSoftwareDeployment.module.scss"
import { getAssetSelectionText, getCanDisplaySelectionText } from "../CentralSoftwareDeploymentUtils"

// eslint-disable-next-line react/display-name
const DeploymentAssetsSummary = forwardRef(({
  assets,
  setIsFilterOpen,
  stateCarryForward,
  selectedAssets,
  setSelectedAssets,
  setSelectedFilters,
  queryKey
}, ref) => {
  const { t } = useTranslation(["software"])
  const assetsSearchTableRef = useRef(null)
  const [searchInput, setSearchInput] = useState("")
  const [searchedAssets, setSearchedAssets] = useState(null)
  const [searchError, setSearchError] = useState(false)
  const searchBarRef = useRef(null)

  useImperativeHandle(ref, () => ({
    resetCheckboxSelection() {
      resetTableSelection()
    },
    resetSearchData() {
      resetSearch()
    }
  }))

  const resetTableSelection = () => {
    resetSelection()
    assetsSearchTableRef?.current?.toggleAll(false)
  }

  const { selectedRows, handleSelectRow, handleSelectAll, resetSelection } = useCheckBoxSelection({
    data: searchedAssets || assets,
    onReset: () => setSelectedAssets([]),
    onSelectAllCallback: setSelectedAssets,
    onSelectRowCallback: setSelectedAssets
  })

  const handleFilterOpen = () => {
    setIsFilterOpen(true)
  }
  const { filter, setFilter } = useSoftwareUpdateContext()

  const handleFilters = (newFilters) => {
    resetSearch()
    resetSelection()
    setSelectedFilters(newFilters)
    setFilter(newFilters)
  }

  const disableFilterItem = (filter) => {
    return filter.type === "modality"
  }

  const filterSearchData = (value, _data) => {
    const filteredResults = globalSearch({
      data: _data,
      includeKeys: ["friendlyName", "ipAddress", "serialNumber"],
      searchString: value
    })

    if (filteredResults.length === 0) {
      setSearchError(true)
      return null
    } else {
      return filteredResults
    }
  }

  const handleSearchSubmit = (value, closeAction) => {
    resetTableSelection()
    setSearchInput(value)
    if (closeAction) {
      resetSearch()
      return false
    }
    if (value.length >= 3) {
      const res = filterSearchData(value, assets)
      setSearchedAssets(res)
      setSearchError(!res)
    } else {
      setSearchError(true)
      setSearchedAssets(null)
    }
  }

  const resetSearch = () => {
    setSearchInput(null)
    setSearchError(false)
    setSearchedAssets(null)
    searchBarRef.current?.reset()
  }

  const assetSelectionText = useMemo(
    () => getAssetSelectionText(searchedAssets, selectedAssets, searchInput, t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchedAssets, selectedAssets, searchInput]
  )

  const displaySelectionText = useMemo(
    () => getCanDisplaySelectionText(selectedAssets, searchError, searchInput),
    [selectedAssets, searchError, searchInput]
  )

  const isAssetFetching = useIsFetching({ queryKey })

  return (
    <>
      <div className={ styles.filtersSection }>
        <div className={ styles.pageContentLeft }>
          <div>
            <SingleSelectDropdown
              defaultOption={ 1 }
              options={ CONFIG_DEPLOY_DROPDOWN_OPTIONS.SITE_VIEW_GROUP_BY }
              optionBy={ GROUP_BY }
              value={ 1 }
              disabled
            />
          </div>
          { Object.keys(filter).length > 0 && (
            <div className={ styles.filterBlock }>
              <SelectedFilters
                filters={ filter }
                setSelectedFilterItem={ handleFilters }
                selectedFilterItem={ Object.values(filter) }
                disableFilter={ disableFilterItem }
                stateCarryForward={ stateCarryForward }
                isPageLoading={ isAssetFetching }
              />
            </div>
          ) }
          { displaySelectionText && (
            <span className={ styles.searchResultsText }>
              { searchError && searchInput?.length >= 3
                ? t("noSearchResult")
                : assetSelectionText }
            </span>
          ) }
        </div>
        <div className={ styles.pageContentRight }>
          <IconButton aria-label="Filter Button" onClick={ () => handleFilterOpen() } className={ styles.filterButton }>
            <Icon icon={ Object.keys(filter).length > 0 ? ICON_NAME.filterBadge : ICON_NAME.filter } />
          </IconButton>
          <SearchBar
            ref={ searchBarRef }
            type={ SEARCH_INSTANCES.centralConfigDeployment }
            onHandleSubmit={ handleSearchSubmit }
          />
        </div>
      </div>
      <div className={ styles.assetsTable }>
        { searchError && <SearchErrorPage /> }
        { !searchError &&
          <DataTable
            selectableRows
            displayRowColorBar
            internalSelectAll
            rowHoverEffect
            highlightSelectedRows
            disableRowSelection={ (row) => {
              const swStatus = formatStatus(row.swStatus)
              return row.mute ||
                swStatus === formatStatus(ALERT_SW_STATUS.INPROGRESS.TEXT) ||
                swStatus.includes(formatStatus(ALERT_SW_STATUS.INPROGRESSSTEP1.TEXT)) ||
                swStatus.includes(formatStatus(ALERT_SW_STATUS.INPROGRESSSTEP2.TEXT)) ||
                swStatus.includes(formatStatus(ALERT_SW_STATUS.INPROGRESSSTEP3.TEXT))
            } }
            queryKey={ queryKey }
            tableData={ searchedAssets || assets }
            selectedRows={ selectedRows }
            columnsData={ ColumnConfiguration() }
            onSelectAllCallBack={ (checked, selectedRows) => handleSelectAll(checked, selectedRows) }
            onSelectRowCallBack={ ({ checked, data }) => handleSelectRow(checked, data) }
            type={ TABLE_INSTANCES.SW_DEPLOYMENT }
            ref={ assetsSearchTableRef }
          />
        }
      </div>
    </>
  )
})

DeploymentAssetsSummary.propTypes = {
  assets: PropTypes.array,
  queryKey: PropTypes.array,
  selectedAssets: PropTypes.array,
  setIsFilterOpen: PropTypes.func,
  setSelectedAssets: PropTypes.func,
  setSelectedFilters: PropTypes.func,
  stateCarryForward: PropTypes.any
}

export default DeploymentAssetsSummary
