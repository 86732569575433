/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types"
import React, { useContext, useEffect, useMemo, useReducer, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import { BREADCRUMB_ROUTE } from "../../components/Breadcrumb/BreadcrumbUtils"
import {
  GROUP_BY_TYPES,
  SUB_FLEET_ACTION_TYPES,
  SUB_FLEET_VIEW_STATE,
  subFleetViewReducer,
  transformAssets,
  transformFilterData,
  transformSubFleetStatistics
} from "../../pages/SubFleetView/SubFleetUtils"
import { fleetGetSVC, fleetSVCKeys } from "../../services/reactQueries/fleetsvc"
import { QUERY_KEYS } from "../../utils/Constants/Queries"
import { useLazyQuery, useQuery } from "../../utils/CustomHooks/reactQuery"
import { ROUTE_CONFIG } from "../../utils/GlobalConstants"
import { useBreadcrumbContext } from "../breadcrumb/breadcrumb"

const SubFleetView = React.createContext({})

export const SubFleetViewProvider = ({ children }) => {
  const navigate = useNavigate()
  const { updateBreadcrumbData } = useBreadcrumbContext()
  const queryParams = useParams()
  const siteId = queryParams[ROUTE_CONFIG.SITE.param]
  const subFleetId = queryParams[ROUTE_CONFIG.SUB_FLEET.param]
  const assetsQueryKey = [QUERY_KEYS.SUB_FLEET_ID, subFleetId]

  const assetsSummaryRef = useRef(null)
  const ṣearchInputRef = useRef(null)

  const { t } = useTranslation(["subFleet"])
  const [state, dispatch] = useReducer(subFleetViewReducer, SUB_FLEET_VIEW_STATE)

  const onDataLoadCompleted = (data) => {
    if (state.searchInput) {
      const searchData = data?.data?.assets
      if (searchData?.length === 1) {
        const asset = searchData?.[0]
        updateBreadcrumbData({
          id: asset?.id,
          key: BREADCRUMB_ROUTE.ASSET,
          name: asset?.friendlyName
        })
        const path = window.location.pathname.concat(`/${ROUTE_CONFIG.ASSET.routeName}/${asset?.id}`)
        navigate(path)
      }
    }
  }

  const { data: fleetStatistics } = useQuery([fleetSVCKeys.GET_FLEET_LEVEL_STATISTICS, subFleetId], () =>
    fleetGetSVC.getFleetStatisticsByFleetId(subFleetId)
  )

  const [getGroupByDetails, getApiQuery] = useLazyQuery(
    [fleetSVCKeys.SUB_FLEET_VIEW_GROP_BY, subFleetId],
    () =>
      fleetGetSVC.getSubFleetGroupBy(
        siteId,
        subFleetId,
        GROUP_BY_TYPES?.[state.groupBySelectedValue]?.serviceName,
        state.searchInput,
        state.selectedFilters
      ),
    { onSuccess: onDataLoadCompleted }
  )

  const subFleetViewFilterData = transformFilterData()

  useEffect(() => {
    if (GROUP_BY_TYPES[state.groupBySelectedValue] && !state.searchError) getGroupByDetails()
  }, [subFleetId, state.groupBySelectedValue, state.searchInput, state.searchError, state.selectedFilters])

  const handleSearchAssets = (value, closeAction) => {
    dispatch({
      payload: { closeAction, value },
      type: SUB_FLEET_ACTION_TYPES.SEARCH_INPUT
    })
    assetsSummaryRef?.current?.resetSelection()
  }

  const handleMoveAssetPopup = (payload) => {
    dispatch({ payload, type: SUB_FLEET_ACTION_TYPES.MOVE_ASSET_POPUP })
  }

  const handleConfirmPopup = (payload) => {
    dispatch({ payload, type: SUB_FLEET_ACTION_TYPES.CONFIRM_POPUP })
  }

  const toggleView = () => {
    dispatch({ type: SUB_FLEET_ACTION_TYPES.TOGGLE_VIEW })
  }

  const setSelectedAssets = (payload) => {
    dispatch({ payload, type: SUB_FLEET_ACTION_TYPES.SET_SELECTED_ASSETS })
  }

  const handleFilterInput = (value) => {
    dispatch({ payload: value, type: SUB_FLEET_ACTION_TYPES.FILTER_INPUT })
    ṣearchInputRef?.current?.reset()
    handleSearchError("", false)
    assetsSummaryRef?.current?.resetSelection()
  }

  const handleFilterState = (payload) => {
    dispatch({ payload, type: SUB_FLEET_ACTION_TYPES.FILTER_POPUP_STATE })
  }

  const handleSearchError = (value, hasError) => {
    dispatch({
      payload: { hasError, value },
      type: SUB_FLEET_ACTION_TYPES.SEARCH_ERROR
    })
  }

  const onMoveToSuccess = (payload) => {
    dispatch({ payload, type: SUB_FLEET_ACTION_TYPES.CONFIRM_POPUP })
    assetsSummaryRef?.current?.resetSelection()
  }

  let devices = getApiQuery?.data?.data?.assets
  const assets = useMemo(() => {
    if (devices) {
      return transformAssets(devices)
    } else {
      return []
    }
  }, [devices])

  let assetNumberOfElement = getApiQuery?.data?.data?.pagination?.numberOfElement

  const { distributionData } = transformSubFleetStatistics(fleetStatistics?.data || {})

  const SubFleetValue = useMemo(() => ({
    assetNumberOfElement,
    assets: assets ?? [],
    assetsQueryKey,
    assetsSummaryRef,
    distributionData,
    getApiQuery,
    handleConfirmPopup,
    handleFilterInput,
    handleFilterState,
    handleMoveAssetPopup,
    handleSearchAssets,
    onMoveToSuccess,
    setSelectedAssets,
    siteId,
    state,
    subFleetViewFilterData,
    t,
    toggleView,
    ṣearchInputRef
  }))

  return (
    <SubFleetView.Provider value={ SubFleetValue }>
      { children }
    </SubFleetView.Provider>
  )
}

SubFleetViewProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const useSubFletViewContext = () => {
  return useContext(SubFleetView)
}
