import Button from "@mui/material/Button"
import PropTypes from "prop-types"
import React from "react"
import { useIsFetching } from "react-query"

import AuthGuard from "../../../../components/AuthGuard/AuthGuard"
import SingleSelectDropdown from "../../../../components/Dropdown/SingleSelectDropdown"
import Icon from "../../../../components/Icon/Icon"
import SearchBar from "../../../../components/SearchBar/SearchBar"
import SelectedFilters from "../../../../components/SelectedFilterItem/SelectedFilters"
import { useFleetViewContext } from "../../../../contexts/fleetView/FleetView"
import { SubFleetAuthElement } from "../../../../utils/Constants/Auth/sub-fleet"
import { QUERY_KEYS } from "../../../../utils/Constants/Queries"
import { SEARCH_INSTANCES } from "../../../../utils/Constants/Search"
import { GROUP_BY_CHANGE_METHOD } from "../../../../utils/Constants/SiteView"
import useAccess from "../../../../utils/CustomHooks/useAccess"
import { DROPDOWN_OPTIONS, GROUP_BY, ICON_NAME } from "../../../../utils/GlobalConstants"
import { FLEET_ACCESS } from "../../FleetUtils"
import style from "../../FleetView.module.scss"

const HeadSection = ({
  assetsList,
  setOpenFilter,
  setSelectedFilter,
  handleChangeGroupByOption,
  handleNewFleetClicked,
  setIsSearch
}) => {
  const {
    fleetViewState: state,
    setGroupBySelectedValue,
    setGroupByChangeMethod,
    translation,
    toggleView,
    ṣearchInputRef,
    disableToggle,
    handleOpenMoveFleet
  } = useFleetViewContext()

  const isFetchingData = useIsFetching({
    queryKey: [QUERY_KEYS.FLEET_VIEW_GROUP_BY, state.fleetId]
  })

  const isMoveTo = useAccess(FLEET_ACCESS.moveAsset) && state?.selectedAssets.length > 0

  const searchResultText = () => {
    return state.searchResults?.length === 0
      ? translation("noSearchResult")
      : `${translation("headSection.assetsSearchedCountText", {
        searchCount: state?.searchResults?.length
      })}`
  }

  const handleChangeGroupBy = (value) => {
    setGroupBySelectedValue(value)
    setGroupByChangeMethod(value == 1 ? GROUP_BY_CHANGE_METHOD.DEFAULT : GROUP_BY_CHANGE_METHOD.DROPDOWN)
    handleChangeGroupByOption(value)
    handleNewFleetClicked(false)
    if (state?.groupBySelectedValue === 7) setSelectedFilter([])
  }

  const onAddFleetClick = () => {
    handleNewFleetClicked(true)
  }

  const isGroupByDisabled = state?.searchResults || state?.searchError || !state?.enableGroupingOption

  return (
    <div className={ style.headSection }>
      <div className={ style.leftSection }>
        <div>
          <SingleSelectDropdown
            options={ DROPDOWN_OPTIONS.FLEET_VIEW_GROUP_BY }
            optionBy={ GROUP_BY }
            value={ state.groupBySelectedValue }
            onChange={ handleChangeGroupBy }
            disabled={ isGroupByDisabled || Boolean(isFetchingData) }
          />
        </div>
        { Object.keys(state.selectedFilters).length > 0 && (
          <div className={ style.filterBlock }>
            <SelectedFilters
              handleClearAll={ () => setSelectedFilter([]) }
              filters={ state.selectedFilters }
              setSelectedFilterItem={ setSelectedFilter }
              selectedFilterItem={ Object.values(state.selectedFilters) }
              filterDisabled={ false }
              isPageLoading={ Boolean(isFetchingData) }
            />
          </div>
        ) }
      </div>
      <div className={ style.rightSection }>
        { state?.searchResults ? (
          <span role={ "contentinfo" } className={ style.assetsCountText }>
            { state?.selectedAssets?.length > 0
              ? `${translation("headSection.assetsSelectedCountText", {
                searchCount: state.searchResults?.length,
                selectedCount: state.selectedAssets?.length
              })}`
              : searchResultText() }
          </span>
        ) : (
          state?.selectedAssets?.length > 0 && (
            <span className={ style.selectedCountText }>
              { `${translation("headSection.selectedCountText", {
                selectedCount: state.selectedAssets?.length
              })}` }
            </span>
          )
        ) }
        { isMoveTo && (
          <Button
            sx={ { marginLeft: "2%" } }
            variant="text"
            color="secondary"
            startIcon={ <Icon icon={ ICON_NAME.moveTo } /> }
            onClick={ () => handleOpenMoveFleet(true) }
          >
            { translation("move-to") }
          </Button>
        ) }
        <AuthGuard actionType={ SubFleetAuthElement.CreateSubFleet }>
          { (!state.searchResults || state.selectedAssets?.length > 0) && !state.searchError && (
            <Button
              variant="text"
              color="secondary"
              startIcon={ <Icon icon={ ICON_NAME.addFleet } /> }
              onClick={ onAddFleetClick }
            >
              { translation("add-subFleet") }
            </Button>
          ) }
        </AuthGuard>
        { state?.groupBySelectedValue === 7 && (
          <button
            aria-label="Filter"
            className={ style.iconButton }
            onClick={ () => setOpenFilter(true) }
            disabled={ Boolean(
              (assetsList?.length <= 0 || state.searchError) && Object.keys(state.selectedFilters)?.length == 0
            ) }
          >
            {
              <Icon
                icon={ Object.keys(state.selectedFilters)?.length > 0 ? ICON_NAME.filterBadge : ICON_NAME.filter }
                iconStyles={ Object.keys(state.selectedFilters)?.length > 0 && style.iconStyles }
              />
            }
          </button>
        ) }
        <button
          aria-label="Toggle View"
          disabled={ disableToggle }
          className={ !state.isCardView ? style.toggleButtonList : style.toggleButtonCard }
          onClick={ () => toggleView() }
        >
          { <Icon icon={ ICON_NAME.gridView } /> }
        </button>

        <span className={ style.searchBar }>
          <SearchBar
            ref={ ṣearchInputRef }
            isDisabled={ Boolean(
              !state?.enableGroupingOption ||
                ((!state?.searchInput ||
                  state?.searchInput == null) &&
                  assetsList?.length < 1 &&
                  state.assetsNotInSubFleet.assets.length < 1 &&
                  state.groupBySelectedValue === 1)
            ) }
            type={ SEARCH_INSTANCES.fleetView }
            onHandleSubmit={ setIsSearch }
          />
        </span>
      </div>
    </div>
  )
}

HeadSection.propTypes = {
  assetsList: PropTypes.array,
  disableGroupByDropDown: PropTypes.bool,
  handleChangeGroupByOption: PropTypes.func,
  handleNewFleetClicked: PropTypes.func,
  setIsSearch: PropTypes.func,
  setOpenFilter: PropTypes.func,
  setSelectedFilter: PropTypes.func
}

export default HeadSection
