import { fileDetails } from "../../config/serverConfig"
import { ICON_NAME } from "../../utils/GlobalConstants"

export const FILE_PATH = "/assets/docs/"

export const USER_MANUAL_FILE_FORMATS = [".pdf", ".doc", ".docx"]

export const HELP_DATA = [
  {
    fileId: fileDetails?.quickgGuideDocId,
    id: 1,
    label: "manual",
    link: "manualLink",
    name: "ReadyFix_UserManual_FM"
  },
  {
    fileId: fileDetails?.faqDocId,
    id: 2,
    label: "faq",
    link: "faqLink",
    name: "ReadyFix_FAQs_FM"
  }
]

export const HELP_CONTACT_US = [
  {
    icon: ICON_NAME.mailIconGrey,
    id: 1,
    label: "infoEmail"
  },
  {
    icon: ICON_NAME.phoneIconGrey,
    id: 2,
    label: "infoPhone"
  }
]

export const helpReducer = (newState, action) => {
  switch (action.type) {
  case TYPES.closePopup:
    newState.onClose()
    return { ...newState, ...action.payload }
  case TYPES.displayHelpPopup:
    return { ...newState, ...{ isOpen: true }, ...action.payload }
  case TYPES.setDownloadDocId:
    return { ...newState, downloadFileId: action.payload }
  default:
    return newState
  }
}

export const TYPES = {
  closePopup: "closePopup",
  displayHelpPopup: "displayHelpPopup",
  setDownloadDocId: "setDownloadDocId"
}

export const HELP_INITIAL_STATE = {
  downloadFileId: "",
  isOpen: false,
  onClose: () => {
    return
  },
  type: ""
}

export const quickStartFileName = "ReadyFix_QuickGuide_FM"
