import axiosClient from "../axios/axios"

export const resourceGETSvc = {
  getAccessAssociatedToUser: (userId) =>
    axiosClient.get(`/resourcesvc/v1/users/${userId}/authorized-facilities`),
  getAccessAssociatedToUserGroup: (userGroupId) =>
    axiosClient.get(
      `/resourcesvc/v1/usergroups/${userGroupId}/authorized-facilities`
    ),
  getGroupsMembers: (
    organizationId,
    userGroupId,
    pageNo = "1",
    sortBy = "",
    sortDirection = "ASC"
  ) => {
    const url = `/usersvc/v1/userGroups/${userGroupId}/users`
    let query = `?page=${pageNo}&size=1000&sortBy=${sortBy}&sortDirection=${sortDirection}`
    return axiosClient.get(url + query)
  },
  getManageAccessAssociatedToUser: (userId) =>
    axiosClient.get(
      `/resourcesvc/v1/users/${userId?.queryKey?.[1]}/facilities`
    ),
  getManageAccessAssociatedToUserGroup: (userGroupId) =>
    axiosClient.get(
      `/resourcesvc/v1/usergroups/${userGroupId?.queryKey?.[1]}/facilities`
    ),
  getSiteNavigator: () => axiosClient.get("/resourcesvc/v1/users/loggedInUser/facilities"),
  getUserGroupsByOrgId: (
    organizationId,
    pageNo = "0",
    sortBy = "",
    sortDirection = "ASC"
  ) => {
    const url = "/usersvc/v1/userGroups"
    let query = `?page=${pageNo}&size=10000&sortBy=${sortBy}&sortDirection=${sortDirection}`
    return axiosClient.get(url + query)
  },
  getUsersByOrgId: (
    organizationId,
    pageNo = "1",
    sortBy = "",
    sortDirection = "ASC"
  ) => {
    const url = "/usersvc/v1/users"
    let query = `?page=${pageNo}&size=1000&sortBy=${sortBy}&sortDirection=${sortDirection}`
    return axiosClient.get(url + query)
  }
}

export const resourcePOSTSvc = {
  saveManageAccessAssociatedToUser: (payload) =>
    axiosClient.post(
      `/resourcesvc/v1/users/${payload.userId}/authorized-facilities`,
      payload
    ),
  saveManageAccessAssociatedToUserGroup: (payload) =>
    axiosClient.post(
      `/resourcesvc/v1/usergroups/${payload.userGroupId}/authorized-facilities`,
      payload
    )
}

export const resourceSVCKeys = {
  getGroupAccess: "getUserGroupAuthorizedFacilities",
  getGroupsMembers: "getGroupsMembers",
  getManageAccessGroup: "getManageAccessGroup",
  getManageAccessUser: "getManageAccessUser",
  getSiteNavigator: "Sites_Navigator",
  getUserAccess: "getUserAuthorizedFacilities",
  getUserGroupsByOrgId: "getUserGroupsByOrgId",
  getUsersByOrgId: "getUsersByOrgId"
}
