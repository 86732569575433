import { useContext } from "react"

import { SoftwareUpdateContext } from "../../contexts/swUpdate/SoftwareUpdateContext"
import { AssetFaultsContext } from "../../pages/AssetFaults/Context/AssetFaultsContext"
import { ConfigurationContext } from "../../pages/Configuration/Context/ConfigurationContext"
import { CONFIG_CONTEXT_TYPE } from "../GlobalConstants"

const useConfigFilterContext = (type) => {
  let context
  switch (type) {
  case CONFIG_CONTEXT_TYPE.ASSET_FAULTS:
    context = AssetFaultsContext
    break
  case CONFIG_CONTEXT_TYPE.CENTRAL_CONFIG:
    context = ConfigurationContext
    break
  case CONFIG_CONTEXT_TYPE.CENTRAL_SOFTWARE:
    context = SoftwareUpdateContext
    break
    /* istanbul ignore next */
  default:
    context = ConfigurationContext
  }
  return useContext(context)
}

export default useConfigFilterContext
