/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types"
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from "react"
import { Draggable } from "react-beautiful-dnd"
import { useIsFetching } from "react-query"

import AssetsNotInFleetColumnConfig from "./AssetsNotInFleetColumnConfig"
import styles from "./AssetsNotInFleetContainer.module.scss"

import AssetCard from "../../../../components/AssetCard/AssetCard"
import AssetsNotInFleet from "../../../../components/AssetsNotInFleet/AssetsNotInFleet"
import DataTable from "../../../../components/DataTable/DataTable"
import ErrorPanel from "../../../../components/DataTable/internals/ErrorPanel/ErrorPanel"
import NoRows from "../../../../components/DataTable/internals/NoRows/NoRows"
import Loading from "../../../../components/Loading/Loading"
import { useSiteViewContext } from "../../../../contexts/siteView/SiteView"
import { CommonAuthElement } from "../../../../utils/Constants/Auth/common"
import { TABLE_INSTANCES } from "../../../../utils/Constants/DataTable"
import useAccess from "../../../../utils/CustomHooks/useAccess"
import useCheckBoxSelection from "../../../../utils/CustomHooks/useCheckBoxSelection"
import { CREATE } from "../../../../utils/GlobalConstants"
import { StorageKeys, clearSession, getSession, setSession } from "../../../../utils/SessionHelper/session"
import { SITE_ACCESS } from "../../SiteUtils"
import { getItemStyle } from "../fleetsDndStyles"

// eslint-disable-next-line react/display-name
const AssetsNotInFleetContainer = forwardRef(
  ({ assetsNotInFleetData: { assets, distributionData, ...assetsInformation }, fleetCount, isCardView }, ref) => {
    const hasSelectAccess = useAccess(CommonAuthElement.Any)
    const hasDragAccess = useAccess(SITE_ACCESS.moveAsset)
    const { siteViewState, setSelectedAssets, setIsEdit, siteId, toggleAccordionExpand } = useSiteViewContext()

    const tableRef = useRef(null)
    const {
      allRowsSelected,
      selectedRows: selectedAssets,
      handleSelectRow,
      handleSelectAll,
      resetSelection,
      setDefaultSelectedRows
    } = useCheckBoxSelection({
      data: assets,
      onReset: () => setSelectedAssets([]),
      onSelectAllCallback: (data) => {
        setSelectedAssets(data)
        if (data.length === 0) {
          tableRef?.current?.toggleAll?.(false)
        } else {
          tableRef?.current?.toggleAll?.(true)
        }
      },
      onSelectRowCallback: (selectedRows) => setSelectedAssets(selectedRows)
    })

    useImperativeHandle(ref, () => ({
      resetSelection() {
        tableRef?.current?.toggleAll(false)
        resetSelection()
      }
    }))

    useEffect(() => {
      const siteGroupByCreateFleet = getSession(StorageKeys.SITE_CREATE_FLEET)
      if (siteGroupByCreateFleet) {
        const cachedAssets = getSession(StorageKeys.SITE_GROUP_SELECTED_ASSETS)
        const groupBySelectedAssets = cachedAssets ? JSON.parse(cachedAssets) : null
        if (groupBySelectedAssets) {
          setDefaultSelectedRows(groupBySelectedAssets)
          tableRef.current?.toggleRowSelect(groupBySelectedAssets)
        }
        setIsEdit({
          isOpen: true,
          name: "",
          type: {
            action: CREATE,
            assets: groupBySelectedAssets,
            id: siteId,
            type: "fleetName"
          }
        })
        setSession(StorageKeys.SITE_CREATE_FLEET_REF, JSON.stringify(true))
      } else {
        setDefaultSelectedRows(siteViewState.selectedAssets)
        tableRef.current?.toggleRowSelect(siteViewState.selectedAssets)
      }
      clearSession(StorageKeys.SITE_CREATE_FLEET)
      clearSession(StorageKeys.SITE_GROUP_SELECTED_ASSETS)
    }, [])

    let parentCheckboxState = ""
    if (allRowsSelected) parentCheckboxState = "checked"
    else if (selectedAssets.length > 0) parentCheckboxState = "indeterminate"

    const nowRows = useCallback(Boolean(assets?.length === 0))
    const isFetchingAssets = useIsFetching({ queryKey: siteViewState?.assetsQueryKey })

    const getAssetsCard = () => {
      const queryKey = siteViewState?.assetsQueryKey
      if (queryKey && isFetchingAssets) {
        return <Loading customStyles={ styles.loader } />
      } else if (siteViewState?.isAssetError) {
        return <ErrorPanel type={ TABLE_INSTANCES.ASSETS_NOT_IN_FLEET } queryKey={ queryKey } />
      } else if (nowRows) {
        return <NoRows type={ TABLE_INSTANCES.ASSETS_NOT_IN_FLEET } />
      } else {
        return (
          <div className={ styles.notInFleetCardContainer }>
            { assets?.map((asset, index) => (
              <Draggable index={ index } key={ asset.id } draggableId={ asset.id }>
                { (provided, snapshot) => (
                  <>
                    <div
                      ref={ provided.innerRef }
                      { ...provided.draggableProps }
                      style={ getItemStyle(snapshot.isDragging, provided.draggableProps.style) }
                    >
                      <AssetCard
                        isSelectable
                        assetDetails={ asset }
                        isSelected={ selectedAssets.some(({ id }) => asset.id === id) }
                        key={ asset.id }
                        dndProvided={ Boolean(fleetCount > 0) && provided }
                        onChange={ handleSelectRow }
                        status={ asset.status }
                      />
                    </div>
                    { snapshot.isDragging && (
                      <div className={ styles.draggingAssetPlaceHolder }>
                        <AssetCard isSelectable assetDetails={ asset } key={ asset.id } status={ asset.status } />
                      </div>
                    ) }
                  </>
                ) }
              </Draggable>
            )) }
          </div>
        )
      }
    }

    return (
      <AssetsNotInFleet
        isSelectable={ hasSelectAccess }
        isExpanded={ siteViewState.isAssetsNotInFleetExpanded }
        isChecked={ parentCheckboxState }
        onChange={ toggleAccordionExpand }
        onSelect={ handleSelectAll }
        displayBars={ true }
        displayLabelText={ true }
        assetsInformation={ {
          ...assetsInformation,
          label: assetsInformation.name
        } }
        distributionData ={ distributionData }
        customClass={ styles.accordionCustomClass }
      >
        <div className={ styles.notInFleetContainer }>
          { isCardView ? (
            getAssetsCard()
          ) : (
            <DataTable
              displayRowColorBar
              internalSelectAll
              rowHoverEffect
              type={ TABLE_INSTANCES.ASSETS_NOT_IN_FLEET }
              selectableRows={ hasSelectAccess }
              draggableRows={ Boolean(fleetCount > 0) && hasDragAccess }
              highlightSelectedRows
              tableData={ assets }
              selectedRows={ selectedAssets }
              ref={ tableRef }
              columnsData={ AssetsNotInFleetColumnConfig() }
              onSelectRowCallBack={ ({ checked, data }) => handleSelectRow(checked, data) }
              onSelectAllCallBack={ (checked) => handleSelectAll(checked) }
              isError={ siteViewState?.isAssetError }
              queryKey={ siteViewState?.assetsQueryKey }
            />
          ) }
        </div>
      </AssetsNotInFleet>
    )
  }
)

AssetsNotInFleetContainer.propTypes = {
  assetsNotInFleetData: PropTypes.shape({
    assets: PropTypes.array,
    distributionData: PropTypes.array
  }),
  fleetCount: PropTypes.number,
  isCardView: PropTypes.bool,
  setSelectedAssets: PropTypes.func
}

const arePropsEqual = (prevProps, nextProps) => {
  return (
    JSON.stringify(prevProps.assetsNotInFleetData) === JSON.stringify(nextProps.assetsNotInFleetData) &&
    prevProps.fleetCount === nextProps.fleetCount &&
    prevProps.isCardView === nextProps.isCardView
  )
}

export default React.memo(AssetsNotInFleetContainer, arePropsEqual)
