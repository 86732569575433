import { Tooltip } from "@mui/material"
import { flexRender } from "@tanstack/react-table"
import { PropTypes } from "prop-types"
import React, { useEffect, useRef, useState } from "react"

import style from "./DataTable.module.scss"
import ColumnResizer from "./internals/ColumnResizer/ColumnResizer"

import { ICON_NAME } from "../../utils/GlobalConstants"
import { classNames } from "../../utils/styles/helper"
import Icon from "../Icon/Icon"

const GridHeaderCell = ({ header, tableProps }) => {
  const [showInfoTooltip, setShowInfoTooltip] = useState(false)

  const textElementRef = useRef(null)
  const columnTextRef = useRef(null)

  const headerFullSize = header.getSize()
  const getCanResize = header.column?.getCanResize()
  const isResizing = tableProps.getState().columnSizingInfo.isResizingColumn

  useEffect(() => {
    compareSize()
  }, [isResizing, headerFullSize])

  useEffect(() => {
    compareSize()
  }, [])

  const compareSize = () => {
    setTimeout(() => {
      const compare =
        columnTextRef?.current?.scrollWidth >
        columnTextRef?.current?.clientWidth
      setShowInfoTooltip(compare)
    }, 0)
  }

  const view = flexRender(header.column.columnDef.header, header.getContext())
  return (
    <>
      { /*  eslint-disable-next-line jsx-a11y/click-events-have-key-events */ }
      <div
        ref={ textElementRef }
        { ...{
          className: classNames(
            style.columnHeaderParent,
            !header.column.getCanSort() ? "select-none" : "",
            header?.column?.columnDef?.id === "selection" && "justify-right",
            !header?.column?.columnDef?.header && "justify-center"
          ),
          onClick: header.column.getToggleSortingHandler(),
          onKeyDown: header.column.getToggleSortingHandler()
        } }
      >
        <Tooltip
          title={ <span className={ style.tooltipTitle }>{ view }</span> }
          interactive="true"
          disableHoverListener={ !showInfoTooltip }
          arrow
          placement="bottom-start"
        >
          <span
            ref={ columnTextRef }
            className={ getCanResize && style.headerCell }
          >
            { view }
          </span>
        </Tooltip>

        { header.column.getCanSort()
          ? {
            asc: (
              <Icon
                icon={ ICON_NAME.sortArrowUp }
                iconStyles={ style.sortIconStyles }
              />
            ),
            desc: (
              <Icon
                icon={ ICON_NAME.sortArrowUp }
                iconStyles={ style.rotateSortIconStyles }
              />
            )
          }[header.column?.getIsSorted()] ?? (
            <Icon
              icon={ ICON_NAME.sortIdle }
              iconStyles={ style.sortIconStyles }
            />
          )
          : null }
        { getCanResize && (
          <ColumnResizer header={ header } isResizing={ isResizing } />
        ) }
      </div>
    </>
  )
}

GridHeaderCell.propTypes = {
  header: PropTypes.any,
  tableProps: PropTypes.any
}

export default GridHeaderCell
