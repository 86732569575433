/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"

const useEscClickHandler = ({ onClickEsc }) => {
  useEffect(() => {
    const escFunction = (event) => {
      if (event.key === "Escape") {
        onClickEsc()
      }
    }
    document.addEventListener("keydown", escFunction)
    return () => {
      document.removeEventListener("keydown", escFunction)
    }
  }, [])
}

export default useEscClickHandler
