import i18n from "i18next"
import React from "react"

import Icon from "../../../../components/Icon/Icon"
import { ICON_NAME } from "../../../../utils/GlobalConstants"
import styles from "../../UserManagement.module.scss"

export const ManageAccessColumnConfig = () => [
  {
    accessorKey: "temp",
    cell: ({ row }) => {
      return row?.original?.fleets?.length > 0 ? (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div
          { ...{
            onClick: () => row.toggleExpanded()
          } }
        >
          { row.getIsExpanded() ? (
            <Icon iconStyles={ styles.subRowExpandIconStyles } icon={ ICON_NAME.collapseArrow } />
          ) : (
            <Icon iconStyles={ styles.subRowExpandIconStyles } icon={ ICON_NAME.expandArrow } />
          ) }
        </div>
      ) : null
    },
    disableTooltip: true,
    enableResizing: false,
    enableSorting: false,
    header: "",
    maxSize: 30,
    minSize: 20,
    size: 30
  },
  {
    accessorFn: (row) => row?.aliasName ?? row?.name,
    accessorKey: "name",
    header: i18n.t("userManagement:table.access.site.name")
  },
  {
    accessorKey: "fleetCount",
    header: i18n.t("userManagement:table.access.site.fleets")
  },
  {
    accessorKey: "city",
    header: i18n.t("userManagement:table.access.site.city")
  },
  {
    accessorKey: "province",
    header: i18n.t("userManagement:table.access.site.province")
  }
]

export const ManageAccessFleetColumnConfig = () => [
  {
    accessorKey: "temp",
    cell: ({ row }) => {
      return row?.original?.subFleets?.length > 0 ? (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div
          { ...{
            onClick: () => row.toggleExpanded()
          } }
        >
          { row.getIsExpanded() ? (
            <Icon iconStyles={ styles.subRowExpandIconStyles } icon={ ICON_NAME.collapseArrow } />
          ) : (
            <Icon iconStyles={ styles.subRowExpandIconStyles } icon={ ICON_NAME.expandArrow } />
          ) }
        </div>
      ) : null
    },
    enableResizing: false,
    enableSorting: false,
    header: "",
    maxSize: 30,
    minSize: 20,
    size: 30
  },
  {
    accessorKey: "name",
    header: i18n.t("userManagement:table.access.fleet.name")
  },
  {
    accessorKey: "assetCount",
    header: i18n.t("userManagement:table.access.fleet.assets")
  }
]

export const ManageAccessSubFleetColumnConfig = () => [
  {
    accessorKey: "name",
    header: i18n.t("userManagement:table.access.subFleet.name")
  },
  {
    accessorKey: "assetCount",
    header: i18n.t("userManagement:table.access.subFleet.assets")
  }
]
