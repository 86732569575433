import i18n from "i18next"
const MembersDetailsColumnConfig = () => [
  {
    accessorKey: "userName",
    defaultCanSort: true,
    header: i18n.t("userManagement:table.members.headerLabels.name"),
    id: "userName"
  },
  {
    accessorFn: (row) => row?.emails?.[0],
    accessorKey: "email",
    header: i18n.t("userManagement:table.members.headerLabels.email")
  }
]

export default MembersDetailsColumnConfig
