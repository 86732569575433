import PropTypes from "prop-types"
import React, { createContext, useContext, useMemo, useReducer, useState } from "react"

import { APP_INITIAL_STATE, TYPES, appReducer } from "./AppContextUtils"

const AppContext = createContext(null)

export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, APP_INITIAL_STATE)
  const [showSiteNavigator, setShowSiteNavigator] = useState(false)

  const setAppError = (payload) => {
    dispatch({ payload, type: TYPES.SET_APP_ERROR })
  }

  const AppValue = useMemo(() => ({
    app: state,
    setAppError,
    setShowSiteNavigator,
    showSiteNavigator
  }), [state, showSiteNavigator])

  return (
    <AppContext.Provider value={ AppValue }>
      { children }
    </AppContext.Provider>
  )
}

AppProvider.propTypes = {
  children: PropTypes.any
}

export const useApp = () => {
  return useContext(AppContext)
}
