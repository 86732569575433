/* eslint-disable sort-keys */

import colors from "../../styles/jsExports/colorExport.module.scss"
import margins from "../../styles/jsExports/marginExport.module.scss"

export const ToggleSwitch = {
  styleOverrides: {
    root: {
      height: margins["marginInPixel-px26"],
      padding: 0,
      width: margins["marginInPixel-px42"],
      "& .MuiSwitch-switchBase": {
        margin: margins["marginInPixel-px2"],
        padding: 0,
        transitionDuration: "300ms",
        "&.Mui-checked": {
          color: colors["white"],
          transform: "translateX(16px)",
          "& + .MuiSwitch-track": {
            backgroundColor: colors["toggleSwitch-switchBackground"],
            border: 0,
            opacity: 1
          },
          "&.Mui-disabled + .MuiSwitch-track": {
            opacity: 0.5
          }
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
          border: `${margins["marginInPixel-px6"]} solid ${colors["white"]}`,
          color: colors["white"]
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
          color: colors["white"]
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 1
        }
      },
      "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        height: margins["marginInPixel-px22"],
        width: margins["marginInPixel-px22"]
      },
      "& .MuiSwitch-track": {
        backgroundColor: colors["toggleSwitch-switchThumb"],
        borderRadius: 26 / 2,
        opacity: 1
      }
    }
  }
}
