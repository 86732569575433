import PropTypes from "prop-types"
import React, { forwardRef, useImperativeHandle, useRef } from "react"

import styles from "./ExportCSV.module.scss"
import { getExportConfig } from "./ExportCSVUtils"

// eslint-disable-next-line react/display-name
export const ExportCSV = forwardRef(
  ({ asyncDownload, type, fileName, data: initialData, includeColumns, excludeColumns, isDisabled, children }, ref) => {
    const linkRef = useRef(null)

    useImperativeHandle(ref, () => ({
      downloadNewData: (newData) => {
        exportToCSV(
          getExportConfig({
            data: newData,
            excludeColumns,
            fileName,
            includeColumns,
            type
          })
        )
      }
    }))

    const handleDownloadCSV = (event) => {
      if (isDisabled) {
        event.preventDefault()
        return
      }
      if (!linkRef.current.download) {
        event.preventDefault()
        const checkForNewData = () => {
          if (!asyncDownload) {
            exportToCSV(
              getExportConfig({
                data: initialData,
                excludeColumns,
                fileName,
                includeColumns,
                type
              })
            )
          }
        }
        checkForNewData()
      }
    }

    const processRow = function (row, headers) {
      let finalVal = ""

      headers.forEach((element, index) => {
        let innerValue = ""
        if (typeof element.accessor == "function") {
          innerValue = element.accessor(row)
        } else {
          innerValue = row[element.accessor] === null ? "" : row[element.accessor]?.toString()
        }
        if (row[element.accessor] instanceof Date) {
          innerValue = row[element.accessor]?.toLocaleString()
        }
        if (row[element.accessor] instanceof Object) {
          innerValue = JSON.stringify(row[element?.accessor])
        }
        let result = innerValue?.replace(/"/g, "\"\"")
        if (result?.search(/("|,|\n)/g) >= 0) result = "\"" + result + "\""
        if (index > 0) finalVal += ","
        finalVal += result || ""
      })
      return finalVal + "\n"
    }

    const processHeaders = function (headers) {
      let headerRow = ""
      headers.forEach((element, index) => {
        let innerValue = element.header === null ? "" : element.header?.toString()

        let result = innerValue?.replace(/"/g, "\"\"")
        if (result.search(/("|,|\n)/g) >= 0) result = "\"" + result + "\""
        if (index > 0) headerRow += ","
        headerRow += result
      })
      headerRow += "\n"
      return headerRow
    }

    const exportToCSV = ({ fileName, headers, rows }) => {
      if (!headers) {
        return false
      }
      let csvRows = ""
      csvRows = processHeaders(headers)

      for (const element of rows) {
        csvRows += processRow(element, headers)
      }
      const blob = new Blob([csvRows], { type: "text/csv;charset=utf-8;" })
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, fileName)
      } else {
        const url = URL?.createObjectURL?.(blob)
        if (linkRef?.current) {
          linkRef.current.href = url
          linkRef.current.download = fileName
          linkRef.current.click()
          linkRef.current.download = ""
        }
      }
    }

    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a className={ styles.removeLinkStyles } ref={ linkRef } target="_self" 
        href={ "" } onClick={ handleDownloadCSV }>
        { children }
      </a>
    )
  }
)

ExportCSV.propTypes = {
  asyncDownload: PropTypes.bool,
  children: PropTypes.element,
  data: PropTypes.array,
  excludeColumns: PropTypes.array,
  fileName: PropTypes.string,
  includeColumns: PropTypes.array,
  isDisabled: PropTypes.bool,
  type: PropTypes.string.isRequired
}

ExportCSV.defaultProps = {
  data: [],
  excludeColumns: [],
  includeColumns: [],
  isDisabled: false
}
