/* eslint-disable max-len */
import { useQuery } from "react-query"

import { GET_GROUPBY_VALUE_BY_ID } from "../../pages/AssetFaults/AssetFaultUtils"
import { AssetStatus } from "../../utils/Constants/AssetFaultsFilterData"
import { DATE_FORMAT } from "../../utils/Constants/TimeZones"
import { COMPARE_VALUE } from "../../utils/GlobalConstants"
import { arrayGroupBy, convertToServerTimeZone } from "../../utils/helper"
import axiosClient from "../axios/axios"

const PAGE_NO = 1
const PAGE_SIZE = 1000

export const faultSVC = {
  acknowledgeFaults: async ({ faults }) => {
    return axiosClient.patch("/faultsvc/v1/faults", faults)
  },
  getAssetFaultsBysiteUcmId: ({
    siteUcmId,
    filters,
    groupBy,
    dateRange,
    searchString,
    searchError
  }) => {
    const url = `/faultsvc/v1/sites/${siteUcmId}/faults`
    let query = `?groupBy=${
      GET_GROUPBY_VALUE_BY_ID[groupBy]
    }&size=${PAGE_SIZE}&page=${PAGE_NO}&startDate=${convertToServerTimeZone(
      dateRange.startDate,
      DATE_FORMAT.date
    )}&endDate=${convertToServerTimeZone(dateRange.endDate, DATE_FORMAT.date)}${
      searchString && !searchError ? `&searchString=${searchString}` : ""
    }`
    if (filters && filters?.length > 0) {
      Object.entries(arrayGroupBy(filters, "type")).forEach((eachFilter) => {
        if (eachFilter[0] === COMPARE_VALUE.acknowledgement) {
          const ackFilters = eachFilter[1]
          query += `&${eachFilter[0]}=${
            ackFilters[0]?.label === AssetStatus.ACKNOWLEDGED
          }`
        } else {
          query += `&${eachFilter[0]}=${eachFilter[1]
            .map((each) => each.label)
            .join(",")}`
        }
      })
    }
    return axiosClient.get(url + query)
  },
  getAssetFaultsStatistics: (siteId) => {
    if (!siteId) return
    return axiosClient.get(`/faultsvc/v1/sites/${siteId}/faultStatistics`)
  },
  // eslint-disable-next-line sort-keys
  getAssetFaultsByAssetSerialNumber: (
    assetSerialNumber,
    startDate,
    endDate,
    sortingObject
  ) => {
    const url = `/faultsvc/v1/assets/${assetSerialNumber}/faults`
    const date = `?startDate=${startDate}&endDate=${endDate}`
    let query = `&size=${PAGE_SIZE}&page=0`
    if (sortingObject) {
      query += `&sortBy=${sortingObject.id}&sortDirection=${
        sortingObject.desc ? "DESC" : "ASC"
      }`
    }
    return axiosClient.get(url + date + query)
  },
  getAssetFaultsNotesByFaultID: (faultId) => {
    const url = `/faultsvc/v1/faults/${faultId}`
    const query = `/notes?size=${PAGE_SIZE}`
    return axiosClient.get(url + query)
  },
  postAssetFaultsNotesByFaultID: ({ faultId, note }) => {
    const url = `/faultsvc/v1/faults/${faultId}/notes`
    return axiosClient.post(url, { note })
  }
}

export const GetAssetFaultsFile = (
  assetSerialNumber,
  assetFaultsDateRange,
  sortingObject
) => {
  const startDate = convertToServerTimeZone(
    assetFaultsDateRange.startDate,
    DATE_FORMAT.date
  )
  const endDate = convertToServerTimeZone(
    assetFaultsDateRange.endDate,
    DATE_FORMAT.date
  )
  return useQuery(["assetFaultsByAssetSerialNumber", assetSerialNumber], () =>
    faultSVC.getAssetFaultsByAssetSerialNumber(
      assetSerialNumber,
      startDate,
      endDate,
      sortingObject
    )
  )
}
