import PropTypes from "prop-types"
import React from "react"


import BarChart from "../../../../components/ChartStatisticCard/BarChart"
import DistributionStatisticsCard from "../../../../components/DistributionStatisticsCard/DistributionStatisticsCard"

const CentralSoftwareUpdateStatistics = ({ distributionData, chartData }) => {
  return (
    <>
      { distributionData.map((eachDistribution) => (
        <DistributionStatisticsCard key={ eachDistribution.type } { ...eachDistribution } />
      )) }
      {
        chartData.map((chart) => {
          const { type, value, title } = chart
          return <BarChart key={ type } value={ value } type={ type } title={ title }/>
        })
      }
    </>
  )
}

CentralSoftwareUpdateStatistics.propTypes = {
  chartData: PropTypes.array.isRequired,
  distributionData: PropTypes.array.isRequired
}

export default CentralSoftwareUpdateStatistics
