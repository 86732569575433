import colors from "../../styles/jsExports/colorExport.module.scss"
import fonts from "../../styles/jsExports/fontExport.module.scss"
import margins from "../../styles/jsExports/marginExport.module.scss"

export const Buttons = {
  styleOverrides: {
    root: {
      "&.MuiButton-contained.Mui-disabled": {
        backgroundColor: colors["buttons-disabledBackground"],
        color: colors["buttons-disabled"]
      },
      "&.MuiButton-contained.btn-disabled": {
        backgroundColor: colors["buttons-disabledBackground"],
        color: colors["buttons-disabled"]
      },
      "&.MuiButton-containedPrimary": {
        backgroundColor: colors["buttons-primary"],
        fontWeight: fonts["weight-bolder"]
      },
      "&.MuiButton-containedPrimary:focus": {
        backgroundColor: colors["buttons-contentPrimaryfocus"]
      },
      "&.MuiButton-containedPrimary:hover": {
        backgroundColor: colors["buttons-contentPrimaryhover"]
      },
      "&.MuiButton-containedSecondary": {
        backgroundColor: colors["buttons-secondary"],
        fontWeight: fonts["weight-bolder"]
      },
      "&.MuiButton-outlined.Mui-disabled": {
        border: `${margins["marginInPixel-px1"]} solid ${colors["buttons-outlinedDisabled"]}`,
        color: colors["buttons-disabled"]
      },
      "&.MuiButton-outlinedPrimary": {
        border: `${margins["marginInPixel-px1"]} solid ${colors["buttons-primary"]}`,
        color: colors["buttons-primary"],
        minWidth: margins["marginInPixel-px81"]
      },
      "&.MuiButton-outlinedPrimary:focus": {
        backgroundColor: colors["buttons-focus"]
      },
      "&.MuiButton-outlinedPrimary:hover": {
        backgroundColor: colors["buttons-textPrimaryBackground"]
      },
      "&.MuiButton-outlinedSecondary": {
        border: `${margins["marginInPixel-px1"]} solid ${colors["buttons-secondary"]}`,
        color: colors["buttons-secondary"]
      },
      "&.MuiButton-text.Mui-disabled": {
        color: colors["buttons-disabled"]
      },
      "&.MuiButton-textPrimary": {
        backgroundColor: colors["buttons-textPrimaryBackground"],
        color: colors["buttons-primary"],
        minWidth: margins["marginInPixel-px81"]
      },
      "&.MuiButton-textSecondary": {
        color: colors["buttons-primary"]
      },
      "&.MuiButton-textSecondary:hover": {
        backgroundColor: colors["buttons-textPrimaryBackground"]
      },
      "&.MuiButtonBase-root": {
        "& .MuiButton-startIcon": {
          marginLeft: 0,
          marginRight: 0
        },
        borderRadius: margins["marginInPixel-px8"],
        gap: margins["marginInPixel-px8"]
      },
      "&.createButton": {
        border: `${margins["marginInPixel-px1"]} solid ${colors["buttons-label"]}`,
        color: colors["buttons-label"]
      },
      "&.createButton:hover": {
        backgroundColor: "none"
      },

      height: margins["marginInPixel-px34"],
      textTransform: "none",
      whiteSpace: "nowrap"
    }
  }
}

export const IconButton = {
  styleOverrides: {
    root: {
      "&.AboutCancelIcon:hover": {
        backgroundColor: colors["buttons-contentPrimaryhover"]
      },
      "&.helpIcon: hover": {
        backgroundColor: colors["activeUserBackground"],
        borderRadius: margins["marginInPixel-px8"]
      }
    }
  }
}
