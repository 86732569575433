import PropTypes from "prop-types"
import React from "react"

import styles from "../Chart.module.scss"

const PieChartTooltip = ({ t, active, payload }) => {
  if (active && payload?.length) {
    return (
      <div className={ styles.pieChartTooltip }>
        <span className={ styles.pieChartTooltipLabel }>
          { `${t(payload?.[0]?.payload?.label)} : ${payload?.[0]?.payload?.count}` }
        </span>
      </div>
    )
  }
  return null
}

PieChartTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  t: PropTypes.any
}

export default PieChartTooltip
