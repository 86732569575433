import PropTypes from "prop-types"
import React from "react"
import { DragDropContext, Droppable } from "react-beautiful-dnd"

import { BREADCRUMB_ROUTE } from "../../../components/Breadcrumb/BreadcrumbUtils"
import PageLink from "../../../components/PageLink/PageLink"
import SubFleetExpandedCard from "../../../components/SubFleetExpandedCard/SubFleetExpandedCard"
import { useFleetViewContext } from "../../../contexts/fleetView/FleetView"
import { ROUTE_CONFIG } from "../../../utils/GlobalConstants"
import { classNames } from "../../../utils/styles/helper"
import style from "../FleetView.module.scss"
import AssetsNotInSubFleetContainer from "../SubFleetsSummary/AssetsNotInSubFleetContainer/AssetsNotInSubFleetContainer"
import { getSubFleetListStyle } from "../SubFleetsSummary/fleetViewStyles"
import cssStyles from "../SubFleetsSummary/SubFleetsSummary.module.scss"

const SubFleetView = ({ handleAddSubFleet }) => {
  const {
    fleetViewState: state,
    handleConfirmPopup,
    handleOnDragEnd,
    assetsNotInSubfleetContainerRef,
    currentURL,
    handleCreateUpdatePopup
  } = useFleetViewContext()

  return (
    <DragDropContext onDragEnd={ handleOnDragEnd }>
      <div className={ classNames(style.fleets, cssStyles.groupByCards) }>
        <div>
          { state.subFleets?.map((subFleet) => (
            <Droppable key={ subFleet.id } droppableId={ subFleet[state.groupByMappingObject.idKey] }>
              { (provided, snapshot) => (
                <div
                  key={ subFleet.id }
                  { ...provided.droppableProps }
                  ref={ provided.innerRef }
                  style={ getSubFleetListStyle(snapshot.isDraggingOver) }
                >
                  <PageLink
                    className={ cssStyles.removeLinkStyles }
                    to={ currentURL.concat(
                      `/${ROUTE_CONFIG.SUB_FLEET.routeName}/${subFleet[state.groupByMappingObject.idKey]}`
                    ) }
                    type={ BREADCRUMB_ROUTE.SUB_FLEET }
                    pageName={ subFleet[state.groupByMappingObject.titleKey] }
                  >
                    <SubFleetExpandedCard
                      idKey={ state.groupByMappingObject.idKey }
                      titleKey={ state.groupByMappingObject.titleKey }
                      setIsEdit={ handleCreateUpdatePopup }
                      fleetDetails={ subFleet }
                      setIsConfirmPopUp={ handleConfirmPopup }
                    />
                    <div style={ { display: "none" } }>{ provided.placeholder }</div>
                  </PageLink>
                </div>
              ) }
            </Droppable>
          )) }
        </div>
        <Droppable
          isCombineEnabled={ false }
          isDropDisabled={ true }
          key={ "assetsNotInSubFleet" }
          droppableId="assetsNotInSubFleet"
        >
          { (provided) => (
            <>
              <div { ...provided.droppableProps } ref={ provided.innerRef }>
                <AssetsNotInSubFleetContainer
                  isDefaultExpanded={ state.isAssetsNotInFleetExpanded }
                  assetsNotInFleetData={ state.assetsNotInSubFleet }
                  ref={ assetsNotInSubfleetContainerRef }
                  handleAddSubFleet={ handleAddSubFleet }
                />
              </div>
              { provided.placeholder }
            </>
          ) }
        </Droppable>
      </div>
    </DragDropContext>
  )
}

SubFleetView.propTypes = {
  handleAddSubFleet: PropTypes.func
}

export default SubFleetView
