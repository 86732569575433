/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types"
import React, { useContext, useMemo, useReducer } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import { BREADCRUMB_ROUTE } from "../../components/Breadcrumb/BreadcrumbUtils"
import Error from "../../components/Error/Error"
import Loading from "../../components/Loading/Loading"
import {
  ASSET_ACTION_TYPES,
  ASSET_VIEW_STATE,
  assetViewReducer,
  transformAssetDetails
} from "../../pages/AssetView/AssetUtils"
import { fleetGetSVC } from "../../services/reactQueries/fleetsvc"
import { useQuery } from "../../utils/CustomHooks/reactQuery"
import { EDITABLE_FIELD_TYPE, ROUTE_CONFIG, UPDATE } from "../../utils/GlobalConstants"
import { useBreadcrumbContext } from "../breadcrumb/breadcrumb"

export const AssetView = React.createContext({})

export const AssetViewProvider = ({ children }) => {
  const [state, dispatch] = useReducer(assetViewReducer, ASSET_VIEW_STATE)

  const queryParams = useParams()
  const { t } = useTranslation(["asset"])
  const { updateBreadcrumbData } = useBreadcrumbContext()
  const assetId = queryParams[ROUTE_CONFIG.ASSET.param]
  const assetQueryKey = ["deviceDetails-byDeviceID", assetId]
  const { isLoading, data, error, isError } = useQuery(
    assetQueryKey,
    () => fleetGetSVC.getAssetDetailsByAssetId(assetId),
    {
      onSuccess: (data) => {
        const deviceRes = data?.data
        updateBreadcrumbData({
          id: assetId,
          key: BREADCRUMB_ROUTE.ASSET,
          name: deviceRes?.friendlyName
        })
      }
    }
  )

  const device = !isLoading && data?.data
  const { assetDetails } = transformAssetDetails(device)

  const openEditAssetPopup = () => {
    dispatch({
      payload: {
        isOpen: true,
        name: assetDetails.name,
        notes: "",
        type: { action: UPDATE, id: assetDetails.assetId, type: EDITABLE_FIELD_TYPE.ASSET_NAME }
      },
      type: ASSET_ACTION_TYPES.OPEN_EDIT_ASSET_POPUP
    })
  }

  const closeEditAssetPopup = () => {
    dispatch({ type: ASSET_ACTION_TYPES.CLOSE_EDIT_ASSET_POPUP })
  }

  const AssetViewValue = useMemo(() => ({
    assetDetails: {
      ...assetDetails,
      serialNumber: assetDetails?.id
    },
    assetQueryKey,
    closeEditAssetPopup,
    openEditAssetPopup,
    state,
    t
  }))

  if (isLoading) return <Loading />
  if (isError) return <Error error={ error } />

  return (
    <AssetView.Provider value={ AssetViewValue }>
      { children }
    </AssetView.Provider>
  )
}

AssetViewProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const useAssetViewContext = () => {
  return useContext(AssetView)
}
