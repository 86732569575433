import React from "react"

import ReportsView from "./ReportsView"

import { ReportsProvider } from "../../contexts/reports/reports"

const Reports = () => {
  return (
    <ReportsProvider>
      <ReportsView/>
    </ReportsProvider>
  )
}

export default Reports
