import PropTypes from "prop-types"
import { useState } from "react"

const useExpandCollapse = (defaultState) => {
  const [isExpanded, setIsExpanded] = useState(defaultState)

  const handleCustomLogic = (value) => {
    setIsExpanded(value)
  }

  return [isExpanded, handleCustomLogic]
}

useExpandCollapse.propTypes = {
  defaultState: PropTypes.bool
}

useExpandCollapse.defaultProps = {
  defaultState: false
}

export default useExpandCollapse
