/* eslint-disable react-hooks/exhaustive-deps */
import { FormControl, IconButton, ListItemText, MenuItem, TextField } from "@mui/material"
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { format } from "date-fns"
import PropTypes from "prop-types"
import React, { useEffect, useMemo, useRef, useState } from "react"

import styles from "./Dropdown.module.scss"

import { getFormatedTimeAMPMString, getListOfTimeArray, validateDeployTime } from "../../utils/Common/common"
import useEscClickHandler from "../../utils/CustomHooks/useEscClickHandler"
import useOutsideClickHandler from "../../utils/CustomHooks/useOutsideClickHandler"
import { ICON_NAME } from "../../utils/GlobalConstants"
import { classNames } from "../../utils/styles/helper"
import Icon from "../Icon/Icon"

const TimePickerDropdown = ({
  updatedDate,
  defaultDateTime,
  width,
  disabled,
  required,
  onChange,
  hasError
}) => {
  const dropdownRef = useRef(null)
  const scrollRef = useRef()
  const timePickerButtonRef = useRef(null)
  const timePickerIconRef = useRef(null)
  const timeInputRef = useRef(null)
  const options = useMemo(() => getListOfTimeArray(), [])
  const [updatedOptions, setUpdatedOptions] = useState(options)
  const [selectedTime, setSelectedTime] = useState(defaultDateTime)
  const [showTimePickerPopper, setShowTimePickerPopper] = useState(false)
  const [error, setError] = useState(false)
  const selectedTimeRef = useRef()

  const setTime = (time) => {
    selectedTimeRef.current = time
    setSelectedTime(time)
  }

  useEffect(() => {
    setTime(defaultDateTime)
  }, [defaultDateTime])

  useEffect(() => {
    setUpdatedOptions(options)
  }, [options])

  useEffect(() => {
    if (hasError) setError(true)
  }, [hasError])

  useEffect(() => {
    setTimePickerOptions(selectedTime ?? defaultDateTime)
  }, [updatedDate])

  const setTimePickerOptions = (_selectedTime) => {
    const value = new Date()
    const currentTimeFormated = getFormatedTimeAMPMString(value)
    const indexToSplit = options.indexOf(currentTimeFormated)
    const temp = options.slice(indexToSplit)
    const isValid = validateDeployTime(updatedDate, _selectedTime, timeInputRef?.current?.value)
    setError(!isValid)
    if (updatedDate?.getDate() === value?.getDate()) {
      setUpdatedOptions(temp)
    } else {
      setUpdatedOptions(options)
    }
  }

  const handleChange = (value) => {
    setError(false)
    selectedTimeRef.current = value
    onChange?.(value, timeInputRef?.current?.value)
  }

  const handleBlur = (e) => {
    const time24HR = e?.target?.value
    setTime(selectedTimeRef.current)
    const isValid = validateDeployTime(updatedDate, selectedTimeRef.current, time24HR)
    setError(!isValid)
  }
  
  const handleTimeClick = (value) => {
    const date = new Date()
    const hours = value.split(":")[0]
    const minutes = value.split(":")[1]
    date.setHours(hours)
    date.setMinutes(minutes)
    const isValid = validateDeployTime(updatedDate, date, value)
    if (!isValid) setTimePickerOptions(date)
    setError(!isValid)
    setTime(date)
    onChange?.(date, value)
    toggleTimePickerClick()
  }

  const toggleTimePickerClick = () => {
    if (!showTimePickerPopper) {
      setTimeout(() => {
        scrollRef?.current?.scrollIntoView({
          block: "center"
        })
      })
    }
    setShowTimePickerPopper(!showTimePickerPopper)
  }

  useOutsideClickHandler({ onClickOutside: (target) => {
    if (target !== timePickerButtonRef?.current && target !== timePickerIconRef?.current) setShowTimePickerPopper(false)
  }, ref: dropdownRef })
  useEscClickHandler({ onClickEsc: () => setShowTimePickerPopper(false) })

  return (
    <FormControl
      sx={ { width: { width } } }
      disabled={ disabled }
      required={ required }
    >
      <LocalizationProvider dateAdapter={ AdapterDateFns }>
        <DesktopTimePicker
          value={ selectedTime }
          onChange={ handleChange }
          ampm={ false }
          disableOpenPicker
          className={ error ? "ErrorField" : "" }
          inputRef={ timeInputRef }
          renderInput={ (params) => 
            <TextField
              onBlur={ handleBlur }
              { ...params }
              autoComplete="off"
              inputProps={ {
                ...params.inputProps,
                placeholder: "HH:MM"
              } }
            /> }
        />
        <div className={ styles.timePickerWrapper }>
          <IconButton
            className={ classNames(styles.timePickerButton, showTimePickerPopper && styles.timeActive) }
            disableRipple
            onClick={ toggleTimePickerClick }
            ref={ timePickerButtonRef }
            aria-label="TimePickerList"
          >
            <Icon icon={ ICON_NAME.clock } ref={ timePickerIconRef } />
          </IconButton>
        </div>
        { showTimePickerPopper && (
          <div className={ styles.timePickerPopper } ref={ dropdownRef }>
            { updatedOptions?.map((item) => {
              return (
                <MenuItem
                  id={ item }
                  key={ item }
                  value={ item }
                  className={
                    classNames(
                      "DropdownBorder", 
                      (selectedTime &&
                      selectedTime != "Invalid Date" &&
                      item === format(selectedTime, "HH:mm")) &&
                      "active")
                  }
                  onClick={ () => handleTimeClick(item) }
                >
                  <ListItemText
                    primary={ item }
                    ref={
                      (selectedTime &&
                      selectedTime != "Invalid Date" &&
                      item === format(selectedTime, "HH:mm"))
                        ? scrollRef
                        : null
                    }
                  />
                </MenuItem>
              )
            }) }
          </div>
        ) }
      </LocalizationProvider>
    </FormControl>
  )
}

TimePickerDropdown.propTypes = {
  customStyles: PropTypes.string,
  defaultDateTime: PropTypes.instanceOf(Date),
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  menuheight: PropTypes.object,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  updatedDate: PropTypes.instanceOf(Date),
  width: PropTypes.number
}

export default TimePickerDropdown
