/* eslint-disable react/jsx-indent */
import { IconButton, Tooltip } from "@mui/material"
import { PropTypes } from "prop-types"
import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import styles from "./Action.module.scss"
import InProgressActionMenu from "./InProgressActionMenu"

import AuthGuard from "../../../../components/AuthGuard/AuthGuard"
import Icon from "../../../../components/Icon/Icon"
import { TABLE_ACTION } from "../../../../utils/Constants/AssetsConfig"
import { SoftwareAuthElement } from "../../../../utils/Constants/Auth/software"
import { ICON_NAME } from "../../../../utils/GlobalConstants"
import { DEPLOY_BUTTON_STATUSES, INPROGRESS_STATUSES, SCHEDULED_STATUS } from "../SoftwarePackagesUtils"

function Action({ row, handleRowClickActions, muted }) {
  const { t } = useTranslation(["asset"])
  const [selected, setSelected] = useState(false)

  const showDeployButton = DEPLOY_BUTTON_STATUSES.includes(row?.original?.status?.toLowerCase())
  const isStatusScheduled = row?.original?.status?.toLowerCase() === SCHEDULED_STATUS
  const showInProgressActions = INPROGRESS_STATUSES.includes(row?.original?.status?.toLowerCase())

  const deployButtonTooltipTitle = useMemo(() => {
    if (!muted) {
      return t("softwarePackages.tooptipText.deploy")
    } else {
      return ""
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row, muted])

  return (
    <div className={ styles.actionsCell }>
      <Tooltip arrow title={ t("softwarePackages.tooptipText.info") }>
        <IconButton
          aria-label={ row?.original?.id + "-" + "info" }
          onClick={ () => handleRowClickActions(TABLE_ACTION.INFO, row?.original) }
        >
          <Icon icon={ ICON_NAME.swPackageDetails } iconStyles={ styles.actionIcon } />
        </IconButton>
      </Tooltip>

      <AuthGuard actionType={ SoftwareAuthElement.DeployAssetsSoftware }>
        { showDeployButton && !showInProgressActions && !isStatusScheduled && (
          <Tooltip arrow title={ deployButtonTooltipTitle }>
            <span>
              <IconButton
                aria-label={ row?.original?.id + "-deploy" }
                onClick={ () => handleRowClickActions(TABLE_ACTION.DEPLOY, row?.original) }
                disabled={ muted }
              >
                <Icon icon={ muted ? ICON_NAME.swDeployDisabled : ICON_NAME.swDeploy } 
                  iconStyles={ styles.actionIcon } />
              </IconButton>
            </span>
          </Tooltip>
        ) }
      </AuthGuard>

      <AuthGuard actionType={ SoftwareAuthElement.CancelAssetsSoftware }>
        { showInProgressActions && (
          <Tooltip arrow title={ !muted ? t("softwarePackages.tooptipText.cancel") : "" }>
            <span>
              <IconButton
                aria-label={ row?.original?.id + "-cancel" }
                onClick={ () => handleRowClickActions(TABLE_ACTION.CANCEL_DEPLOY, row?.original) }
                disabled={ muted }
              >
                <Icon icon={ ICON_NAME.cancelCircle } 
                  iconStyles={ muted ? styles.actionIconOpacity : styles.actionIcon } />
              </IconButton>
            </span>
          </Tooltip>
        ) }
      </AuthGuard>

      { isStatusScheduled && (
        <span className={ "FleetMenu" }>
          <InProgressActionMenu
            id={ row?.original?.id }
            muted={ muted }
            // isPaused={ isStatusPaused }
            isStatusScheduled={ isStatusScheduled }
            selected={ selected }
            setSelected={ setSelected }
            onClick={ (type) => handleRowClickActions(type, row?.original) }
          />
        </span>
      ) }
    </div>
  )
}

Action.propTypes = {
  handleRowClickActions: PropTypes.any,
  muted: PropTypes.bool,
  row: PropTypes.any
}

export default Action
