/* eslint-disable max-len */
import { ACKNOWLEDGED, UNACKNOWLEDGED } from "./AssetFaults"
import { DATE_FORMAT } from "./TimeZones"

import { formatDate } from "../helper"

export const EXPORT_CSV_TYPES = {
  ASSET_FAULTS: "ASSET_FAULTS",
  ASSET_PROPERTIES: "ASSET_PROPERTIES",
  ASSET_STATUS: "ASSET_STATUS",
  CENTRAL_FAULTS: "CENTRAL_FAULTS",
  REPORTS_CONFIG: "REPORTS_CONFIG",
  REPORTS_SW: "REPORTS_SW"
}

export const EXPORT_HEADERS = {
  ASSET_FAULTS: [
    {
      accessor: "severity",
      header: "Severity"
    },
    {
      accessor: (row) =>
        " " + formatDate(row.detectedOn, DATE_FORMAT.dateTime24),
      header: "Detected on"
    },
    {
      accessor: "code",
      header: "Code"
    },
    {
      accessor: "description",
      header: "Description"
    },
    {
      accessor: "suggestions",
      header: "Suggestions"
    },
    {
      accessor: "notes",
      header: "Note"
    },
    {
      accessor: (row) =>
        `${row.lastUpdatedBy ? row.lastUpdatedBy + " | " : ""}${
          " " + formatDate(row.updatedOn, DATE_FORMAT.dateTime24)
        }`,
      header: "Last updated"
    },
    {
      accessor: (row) => (row?.isAcknowledge ? ACKNOWLEDGED : UNACKNOWLEDGED),
      header: "Status"
    }
  ],
  ASSET_PROPERTIES: [
    {
      accessor: "displayName",
      header: "Name"
    },
    {
      accessor: "value",
      header: "Value"
    },
    {
      accessor: (row) =>
        " " + formatDate(row.updatedOn, DATE_FORMAT.dateTime24),
      header: "Last updated"
    }
  ],
  ASSET_STATUS: [
    {
      accessor: "displayName",
      header: "Name"
    },
    {
      accessor: "value",
      header: "Value"
    },
    {
      accessor: (row) =>
        " " + formatDate(row.updatedOn, DATE_FORMAT.dateTime24),
      header: "Last updated"
    }
  ],
  CENTRAL_FAULTS: [
    {
      accessor: "severity",
      header: "Severity"
    },
    {
      accessor: (row) =>
        " " + formatDate(row.detectedOn, DATE_FORMAT.dateTime24),
      header: "Detected on"
    },
    {
      accessor: "assetName",
      header: "Asset name"
    },
    {
      accessor: "assetSerialNumber",
      header: "Serial number"
    },
    {
      accessor: "code",
      header: "Code"
    },
    {
      accessor: "description",
      header: "Description"
    },
    {
      accessor: "suggestions",
      header: "Suggestions"
    },
    {
      accessor: "notes",
      header: "Note"
    },
    {
      accessor: (row) =>
        `${row.lastUpdatedBy ? row.lastUpdatedBy + " | " : ""}${
          " " + formatDate(row.lastUpdatedOn, DATE_FORMAT.dateTime24)
        }`,
      header: "Last updated"
    },
    {
      accessor: (row) => (row?.isAcknowledge ? ACKNOWLEDGED : UNACKNOWLEDGED),
      header: "Status"
    }
  ],
  REPORTS_CONFIG: [
    {
      accessor: (row) =>
        " " + formatDate(row.deploymentDate, DATE_FORMAT.dateTime24),
      header: "Deployment date"
    },
    {
      accessor: "configFile",
      header: "Name"
    },
    {
      accessor: "assetName",
      header: "Asset name"
    },
    {
      accessor: "serialNumber",
      header: "Serial number"
    },
    {
      accessor: "description",
      header: "Description"
    },
    {
      accessor: (row) =>
        " " + formatDate(row.lastUpdated, DATE_FORMAT.dateTime24),
      header: "Last updated"
    },
    {
      accessor: "user",
      header: "User"
    },
    {
      accessor: "status",
      header: "Status"
    }
  ],
  REPORTS_SW: [
    { 
      accessor: (row) =>
        " " + formatDate(row.initiatedOn, DATE_FORMAT.dateTime24),
      header: "Initiated on"
    },
    {
      accessor: "name",
      header: "Name"
    },
    {
      accessor: "description",
      header: "Description"
    },
    {
      accessor: "version",
      header: "Version"
    },
    {
      accessor: "assetName",
      header: "Asset name"
    },
    {
      accessor: "serialNumber",
      header: "Serial Number"
    },
    {
      accessor: "status",
      header: "Status"
    },
    {
      accessor: (row) =>
        " " + formatDate(row.lastUpdated, DATE_FORMAT.dateTime24),
      header: "Last updated"
    },
    {
      accessor: "user",
      header: "User"
    },
    {
      accessor: "notes",
      header: "Notes"
    }
  ]
}
