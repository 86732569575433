import Button from "@mui/material/Button"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import Contact from "./internal/Contact"
import Features from "./internal/Features"
import Navbar from "./internal/Navbar"
import Solution from "./internal/Solution"
import styles from "./Login.module.scss"

import AppHeader from "../../components/AppHeader/AppHeader"
import Icon from "../../components/Icon/Icon"
import { fileDetails } from "../../config/serverConfig"
import { useToaster } from "../../contexts/ToastContext"
import { loginUser } from "../../services/idam/login"
import { fileSVC, fileSVCKeys } from "../../services/reactQueries/filesvc"
import { FooterItem } from "../../utils/Constants/Login"
import { useLazyQuery } from "../../utils/CustomHooks/reactQuery"
import { ICON_NAME } from "../../utils/GlobalConstants"
import { StorageKeys, clearSession } from "../../utils/SessionHelper/session"

const Login = () => {
  const { t } = useTranslation(["login"])
  const [showButton, setShowButton] = useState(false)
  const navRef = useRef()
  const scrollRef = useRef()

  const { displayMediumErrorToast } = useToaster()

  const [fetchDocument] = useLazyQuery(
    [fileSVCKeys.DOWNLOAD_FILE_FROM_S3],
    () =>
      fileSVC.downloadFilesFromS3({
        documentId: fileDetails.quickgGuideDocId,
        offline: true
      }),
    {
      onError: () => displayMediumErrorToast(t("downloadS3FileFail")),
      onSuccess: (data) => downloadFile(data?.data?.url),
      retry: false
    }
  )

  useEffect(() => {
    clearSession(StorageKeys.AUTH_STATE)
    clearSession(StorageKeys.CODE_VERIFIER)
  }, [])

  const onSubmit = async () => {
    loginUser()
  }

  const handleScroll = () => {
    setShowButton(scrollRef.current.scrollTop > 300)
  }

  const downloadFile = (url) => {
    try {
      const filename = url.substring(url.lastIndexOf("/") + 1)
      const a = document.createElement("a")
      a.href = url
      a.target = "_blank"
      a.download = filename
      a.click()
    } catch (error) {
      displayMediumErrorToast(t("downloadS3FileFail"))
    }
  }

  const handleDownload = () => {
    fetchDocument()
  }

  return (
    <>
      <AppHeader isSignedIn={ false } handleSignIn={ onSubmit } helpIcon={ false } />

      <div
        className={ styles.fleetManagerWrapper }
        ref={ scrollRef }
        onScroll={ handleScroll }
        data-testid="loginpageContent"
      >
        <div id="top" className={ styles.fleetManagerContainer }>
          <div className={ styles.fleetManagerCont }>
            <span className={ styles.fleetManagerTitle }>
              { t("fleetManager.title") }
            </span>
            <span className={ styles.fleetManagerDesc }>
              { t("fleetManager.description") }
            </span>
            <span className={ styles.buttonSection }>
              <span className={ styles.signButton }>
                <Button
                  variant="contained"
                  className={ styles.edgeGatewayButton }
                  onClick={ onSubmit }
                  aria-label="SignIn"
                >
                  { t("fleetManager.button") }
                </Button>
              </span>
              <span className={ styles.accountButton }>
                <Button variant="outlined" className="createButton" aria-label="createButton" disabled>
                  { t("fleetManager.createAccount") }
                </Button>
              </span>
            </span>
            <span className={ styles.quickGuideSection }>
              <Link
                className={ styles.link }
                onClick={ () => handleDownload() }
                target="_self"
              >
                <Icon
                  icon={ ICON_NAME.download }
                  iconStyles={ styles.downloadIcon }
                />
                <span className={ styles.quickStart }>
                  { t("fleetManager.quickStart") }
                </span>
              </Link>
            </span>
          </div>
        </div>

        <Navbar ref={ navRef } t={ t } />
        <Solution id="solution" />
        <Features id="features" />
        <Contact id="contact_us" />

        <div className={ styles.footer }>
          <p className={ styles.footerTitle }>&copy; { t("footer.footer") }</p>
          <span className={ styles.footerCont }>
            { FooterItem.map((footer) => (
              <span key={ footer.id } className={ styles.footerLabel }>
                { footer.label }
              </span>
            )) }
          </span>
        </div>
        { showButton && (
          <a
            href="#top"
            className={ styles.backToTop }
            onClick={ () => navRef?.current?.resetLinkSelection() }
          >
            <Icon icon={ ICON_NAME.upArrow } iconStyles={ styles.backIcon }></Icon>
          </a>
        ) }
      </div>
    </>
  )
}

export default Login
