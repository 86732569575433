export const transformConfigurations = (data) => {
  const configs = []
  for (const config of data) {
    configs.push({
      assetName: config.assetName,
      configFile: config.fileName,
      deploymentDate: config.deploymentDate,
      description: config.description,
      lastUpdated: config.lastUpdatedDate,
      serialNumber: config.serialNumber,
      status: config.status,
      user: config.userName
    })
  }
  return configs
}
