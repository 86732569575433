import { Tooltip } from "@mui/material"
import { PropTypes } from "prop-types"
import React, { useEffect, useId, useRef, useState } from "react"

import styles from "./IconLabel.module.scss"

import { classNames } from "../../utils/styles/helper"
import AssetNameTooltip from "../AssetNameTooltip/AssetNameTooltip"

const IconLabel = ({
  displayLabelText,
  iconName,
  label,
  iconStyles,
  labelStyles,
  note,
  showDivider,
  displayTooltip,
  displayIconTooltip,
  iconTooltipLabel,
  tooltipStyles,
  tooltipPopperProps,
  displayAssetNameTooltip
}) => {
  const id = useId()
  const [showInfoTooltip, setShowInfoTooltip] = useState(false)

  const textElementRef = useRef()
  const compareSize = () => {
    const compare =
      textElementRef?.current?.scrollWidth >
      textElementRef?.current?.clientWidth
    setShowInfoTooltip(compare)
  }

  useEffect(() => {
    compareSize()
    window.addEventListener("resize", compareSize)
  }, [label])

  useEffect(
    () => () => {
      window.removeEventListener("resize", compareSize)
    },
    []
  )

  const displayAssetNameWithTooltip = () => {
    if (displayAssetNameTooltip) {
      return (
        <AssetNameTooltip
          assetName={ label }
          assetNote={ note }
          customStyles={ "assetNameTooltipLabel" }
          tooltipPlacement={ "bottom-start" }
        >
          <label
            ref={ textElementRef }
            id={ id }
            className={ classNames(labelStyles) }
          >
            { label }
          </label>
        </AssetNameTooltip>
      )
    } else if (displayLabelText) {
      return (
        <label ref={ textElementRef } id={ id } className={ classNames(labelStyles) }>
          { label }
        </label>
      )
    } else {
      return <></>
    }
  }

  return (
    <>
      { iconName && (
        <Tooltip
          arrow
          placement="bottom"
          title={ displayIconTooltip && iconTooltipLabel }
        >
          <img
            aria-labelledby={ id }
            src={ `/assets/icons/${iconName}.svg` }
            alt={ label }
            className={ classNames(styles.Icon, iconStyles) }
          />
        </Tooltip>
      ) }
      { showDivider && <div className={ styles.divider }></div> }
      { displayLabelText && displayTooltip ? (
        <Tooltip
          title={ <div className={ tooltipStyles }>{ label }</div> }
          arrow
          placeholder="bottom-start"
          interactive="true"
          disableHoverListener={ !showInfoTooltip }
          PopperProps={ { ...tooltipPopperProps } }
        >
          <label
            ref={ textElementRef }
            id={ id }
            className={ classNames(labelStyles) }
          >
            { label }
          </label>
        </Tooltip>
      ) : (
        displayAssetNameWithTooltip()
      ) }
    </>
  )
}

IconLabel.propTypes = {
  displayAssetNameTooltip: PropTypes.bool,
  displayIconTooltip: PropTypes.bool,
  displayLabelText: PropTypes.bool,
  displayTooltip: PropTypes.bool,
  iconName: PropTypes.string,
  iconStyles: PropTypes.any,
  iconTooltipLabel: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelStyles: PropTypes.any,
  note: PropTypes.string,
  showDivider: PropTypes.bool,
  tooltipPopperProps: PropTypes.any,
  tooltipStyles: PropTypes.any
}

IconLabel.defaultProps = {
  displayAssetNameTooltip: false,
  displayLabelText: true,
  displayTooltip: false,
  showDivider: false
}

export default IconLabel
