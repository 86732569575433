import PropTypes from "prop-types"
import React, { createContext, useContext, useMemo, useReducer } from "react"
import { useTranslation } from "react-i18next"

import {
  HELP_INITIAL_STATE,
  TYPES,
  helpReducer
} from "../../components/Help/HelpUtils"
import { fileSVC, fileSVCKeys } from "../../services/reactQueries/filesvc"
import { useLazyQuery } from "../../utils/CustomHooks/reactQuery"
import { useToaster } from "../ToastContext"

export const HelpContext = createContext(null)

export const HelpProvider = ({ children }) => {
  const { displayMediumErrorToast } = useToaster()
  const { t } = useTranslation(["help"])
  const { t: loginT } = useTranslation(["login"])
  const [state, dispatch] = useReducer(helpReducer, HELP_INITIAL_STATE)

  const [fetchDocument] = useLazyQuery(
    [fileSVCKeys.DOWNLOAD_FILE_FROM_S3],
    () => fileSVC.downloadFilesFromS3({ documentId: state.downloadFileId }),
    {
      onError: () => displayMediumErrorToast(loginT("downloadS3FileFail")),
      onSettled: () => dispatch({ payload: "", type: TYPES.setDownloadDocId }),
      onSuccess: (data) => downloadFile(data?.data?.url),
      retry: false
    }
  )

  const downloadFile = (url) => {
    try {
      const filename = url.substring(url.lastIndexOf("/") + 1)
      const a = document.createElement("a")
      a.href = url
      a.target = "_blank"
      a.download = filename
      a.click()
    } catch (error) {
      displayMediumErrorToast(loginT("downloadS3FileFail"))
    }
  }

  const displayHelpPopup = () => {
    dispatch({
      type: TYPES.displayHelpPopup
    })
  }

  const handleDownload = (fileDetails) => {
    dispatch({ payload: fileDetails.fileId, type: TYPES.setDownloadDocId })
    setTimeout(() => {
      fetchDocument()
    }, 0)
  }

  const helpValue = useMemo(
    () => ({
      dispatch,
      displayHelpPopup,
      handleDownload,
      state,
      t
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  )

  return (
    <HelpContext.Provider value={ helpValue }>{ children }</HelpContext.Provider>
  )
}

HelpProvider.propTypes = {
  children: PropTypes.any
}

export const useHelp = () => {
  return useContext(HelpContext)
}
