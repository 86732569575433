/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"

import NavigatorLabelAccordion from "./NavigatorLabelAccordion/NavigatorLabelAccordion"
import styles from "./SiteNavigator.module.scss"

import { setSiteUCMID } from "../../../../pages/CustomerView/CustomerUtils"
import { resourceGETSvc, resourceSVCKeys } from "../../../../services/reactQueries/resourcesvc"
import useEscClickHandler from "../../../../utils/CustomHooks/useEscClickHandler"
import useOutsideClickHandler from "../../../../utils/CustomHooks/useOutsideClickHandler"
import { ROUTE_CONFIG } from "../../../../utils/GlobalConstants"
import { classNames } from "../../../../utils/styles/helper"
import IconLabel from "../../../IconLabel/IconLabel"
import Loading from "../../../Loading/Loading"
import PageLink from "../../../PageLink/PageLink"
// import SearchBar from "../../../SearchBar/SearchBar"
import { BREADCRUMB_ROUTE, expandNavigatorData, transformNavigatorData } from "../../BreadcrumbUtils"

const SiteNavigator = ({ breadcrumbs, handleClose }) => {
  const { t } = useTranslation(["common"])
  const navigatorRef = useRef(null)
  const queryParams = useParams()
  const [navigatorData, setNavigatorData] = useState(null)
  const [isCustomerDisabled, setIsCustomerDisabled] = useState(false)

  useEffect(() => {
    const isCustomerDisabled = breadcrumbs?.find(item => item?.key === BREADCRUMB_ROUTE.CUSTOMER)?.disabled
    setIsCustomerDisabled(isCustomerDisabled)
  }, [])

  useOutsideClickHandler({ onClickOutside: handleClose, ref: navigatorRef })
  useEscClickHandler({ onClickEsc: handleClose })

  const {
    isFetching,
    isError,
    data
  } = useQuery([resourceSVCKeys.getSiteNavigator], resourceGETSvc.getSiteNavigator)

  const siteNavigatorData = data?.data

  const transformedData = useCallback(
    transformNavigatorData(siteNavigatorData, queryParams),
    [siteNavigatorData]
  )
  const defaultNavigatorData = useCallback(
    expandNavigatorData(transformedData, queryParams),
    [transformedData]
  )

  useEffect(() => {
    if (defaultNavigatorData) setNavigatorData(defaultNavigatorData)
  }, [defaultNavigatorData])

  if (isFetching) return (
    <div className={ styles.navigatorPanel }>
      <Loading customStyles={ styles.navigatorLoading } />
    </div>
  )
  if (isError) throw new Error(t("errorMessage"))

  const handleLabelClick = (siteUcmId) => {
    setSiteUCMID(siteUcmId)
    handleClose()
  }

  const getCustomerRoute = () => {
    return (
      <>
        <IconLabel
          displayTooltip={ true }
          tooltipStyles={ styles.tooltip }
          tooltipPopperProps={ {
            className: "SiteNavigator"
          } }
          iconStyles={ styles.icon }
          label={ navigatorData?.customerAliasName ?? navigatorData?.customerName ?? "" }
          labelStyles={ classNames(styles.customerName, isCustomerDisabled && styles.disabledCustomerName) }
        />
            &nbsp;
        <span>{ navigatorData?.assetCount ? `(${navigatorData?.assetCount})` : null }</span>
      </>
    )
  }

  return (
    <div className={ styles.navigatorPanel } ref={ navigatorRef }>
      <div className={ styles.customerLabel }>
        { isCustomerDisabled ?
          <span className={ styles.customerLink }>{ getCustomerRoute() }</span> :
          <PageLink
            className={ styles.customerLink }
            to={ `${ROUTE_CONFIG.CUSTOMER.routeName}/${queryParams[ROUTE_CONFIG.CUSTOMER.param]}` }
            onClick={ () => handleLabelClick("") }
            type={ BREADCRUMB_ROUTE.CUSTOMER }
            pageName={ navigatorData?.customerAliasName ?? navigatorData?.customerName }
          >
            { getCustomerRoute() }
          </PageLink>
        }
      </div>
      <div className={ styles.sitePanel }>
        {  navigatorData?.sites?.map(site => (
          <NavigatorLabelAccordion
            key={ site?.id }
            id={ site?.id }
            label={ site?.aliasName ?? site?.name }
            itemCount={ site?.assetCount }
            showExpandButton={ site?.fleets?.length > 0 }
            handleLabelClick={ () => handleLabelClick(site.ucmId) }
            navLabelStyles={ styles.siteLabel }
            breadcrumbType={ BREADCRUMB_ROUTE.SITE }
            { ...site }
          >
            { site?.fleets?.map(fleet => (
              <div key={ fleet?.id } className={ styles.fleetAccordion }>
                <NavigatorLabelAccordion
                  id={ fleet?.id }
                  label={ fleet?.name }
                  itemCount={ fleet?.assetCount }
                  showExpandButton={ fleet?.subFleets?.length > 0 }
                  handleLabelClick={ () => handleLabelClick(site.ucmId) }
                  navLabelStyles={ styles.fleetLabel }
                  breadcrumbType={ BREADCRUMB_ROUTE.FLEET }
                  parentSite={ site }
                  { ...fleet }
                >
                  {  fleet?.subFleets?.map(subFleet => (
                    <div key={ subFleet?.id } className={ styles.subFleetAccordion }>
                      <NavigatorLabelAccordion
                        id={ subFleet?.id }
                        label={ subFleet?.name }
                        itemCount={ subFleet?.assetCount }
                        showExpand={ false }
                        handleLabelClick={ () => handleLabelClick(site.ucmId) }
                        navLabelStyles={ styles.subFleetLabel }
                        breadcrumbType={ BREADCRUMB_ROUTE.SUB_FLEET }
                        parentSite={ site }
                        parentFleet={ fleet }
                        { ...subFleet }
                      >
                      </NavigatorLabelAccordion>
                    </div>
                  )) }
                </NavigatorLabelAccordion>
              </div>
            )) }
          </NavigatorLabelAccordion>
        )) }
      </div>
    </div>
  )
}

SiteNavigator.propTypes = {
  breadcrumbs: PropTypes.array,
  handleClose: PropTypes.func
}

export default SiteNavigator
