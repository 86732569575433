import React from "react"

import styles from "./Action.module.scss"

import { useSoftwareUpdateContext } from "../../../../contexts/swUpdate/SoftwareUpdateContext"

const EmptySoftwarePackagesListing = () => {
  const { t } = useSoftwareUpdateContext()

  return (
    <div className={ styles.noConfigFileContainer }>
      <div className={ styles.heading }>{ t("noRows.heading") }</div>
    </div>
  )
}

export default EmptySoftwarePackagesListing
