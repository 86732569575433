import PropTypes from "prop-types"
import React, { createContext, useContext, useMemo, useReducer } from "react"

import { TOASTER_INITIAL_STATE, toasterReducer } from "../components/Toaster/ToasterUtils"
import { ToasterConfig } from "../utils/GlobalConstants"

export const ToastContext = createContext(null)

export const ToastProvider = ({ children }) => {
  const [state, dispatch] = useReducer(toasterReducer, TOASTER_INITIAL_STATE)

  const displaySuccessToast = (message) => {
    dispatch({
      payload: { message, type: ToasterConfig.TYPE.SUCCESS },
      type: "addToast"
    })
  }

  const displayInfoToast = (message) => {
    dispatch({
      payload: { message, type: ToasterConfig.TYPE.INFO },
      type: "addToast"
    })
  }

  const displayLowErrorToast = (message) => {
    dispatch({
      payload: { message, type: ToasterConfig.TYPE.LOW },
      type: "addToast"
    })
  }

  const displayMediumErrorToast = (message) => {
    dispatch({
      payload: { message, type: ToasterConfig.TYPE.MEDIUM },
      type: "addToast"
    })
  }

  const displayCriticalErrorToast = (message) => {
    dispatch({
      payload: { message, type: ToasterConfig.TYPE.CRITICAL },
      type: "addToast"
    })
  }

  const context = useMemo(
    () => ({
      dispatch,
      displayCriticalErrorToast,
      displayInfoToast,
      displayLowErrorToast,
      displayMediumErrorToast,
      displaySuccessToast,
      state
    }),
    [state]
  )

  return <ToastContext.Provider value={ context }>{ children }</ToastContext.Provider>
}

ToastProvider.propTypes = {
  children: PropTypes.any
}

export const useToaster = () => {
  return useContext(ToastContext)
}
