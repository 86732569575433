import Tooltip from "@mui/material/Tooltip"
import PropTypes from "prop-types"
import React from "react"

import { ICON_NAME } from "../../../utils/GlobalConstants"
import Icon from "../../Icon/Icon"
import styles from "../InputField.module.scss"

const InputErrorPanel = ({ error }) => {
  return (
    <Tooltip
      title={ <span className={ styles.tooltipTitle }>{ error }</span> }
      arrow
      placement="bottom"
      open={ !!error }
    >
      <span
        role="button"
        tabIndex={ 0 }
        onClick={ e => e.preventDefault() }
        onKeyDown={ (event) => {
          if (event.key === "Enter" || event.key === " ") 
            event.preventDefault()
        } }
        className={ styles.infoIcon }
      >
        <Icon icon={ ICON_NAME.formError } iconStyles={ styles.icon } />
      </span>
    </Tooltip>
  )
}

InputErrorPanel.propTypes = {
  error: PropTypes.string
}

export default InputErrorPanel
