import { CommonAuthElement } from "../../utils/Constants/Auth/common"
import { UsersAuthElement } from "../../utils/Constants/Auth/user"
import { ROUTE_CONFIG } from "../../utils/GlobalConstants"

const UserMenuLabel = {
  ABOUT: "about",
  HELP: "help",
  MANAGE_USER: "manageUsers",
  PROFILE: "viewProfile",
  SCANQRCODE: "scanQRCode",
  SIGN_OUT: "signOut"
}

export const userSettingsMenuItems = [
  { authType: CommonAuthElement.Any, icon: "viewProfile", label: UserMenuLabel.PROFILE },
  {
    authType: [
      UsersAuthElement.ReadUser,
      UsersAuthElement.ReadUserGroup
    ],
    icon: "manageUsers",
    label: UserMenuLabel.MANAGE_USER
  },
  { authType: CommonAuthElement.Any, icon: "help", label: UserMenuLabel.HELP },
  { authType: CommonAuthElement.Any, icon: "about", label: UserMenuLabel.ABOUT },
  { authType: CommonAuthElement.Any, icon: "scanQRCode", label: UserMenuLabel.SCANQRCODE },
  { authType: CommonAuthElement.Any, icon: "signOut", label: UserMenuLabel.SIGN_OUT }
]

export const UserMenuRouteMap = {
  [`/${ROUTE_CONFIG.USER.routeName}/${ROUTE_CONFIG.VIEW_PROFILE.routeName}`]: UserMenuLabel.PROFILE,
  [`/${ROUTE_CONFIG.USER.routeName}/${ROUTE_CONFIG.MANAGE_USERS.routeName}`]: UserMenuLabel.MANAGE_USER
}

export const headerData = {
  headerLogo: "geLogo",
  headerName: "fleetManager",
  signInButtonLabel: "signIn",
  userIcon: "user",
  userName: "User Name"
}
