/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, Tooltip } from "@mui/material"
import {
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table"
import PropTypes from "prop-types"
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from "react"
import { useIsFetching } from "react-query"
import { useVirtual } from "react-virtual"

import style from "./DataTable.module.scss"
import GridBody from "./GridBody"
import GridHeader from "./GridHeader"
import ErrorPanel from "./internals/ErrorPanel/ErrorPanel"
import NoRows from "./internals/NoRows/NoRows"
import TableRowCustomSelection from "./internals/TableRowCustomSelection"

import {
  TABLE_COLUMN_SPECIAL_ID_ACCESSORS,
  selectableColumnWidth
} from "../../utils/Constants/DataTable"
import { CHECKBOX_STATE_ICONS } from "../../utils/GlobalConstants"
import {
  customSortingFn,
  severityCustomSortingOrder
} from "../../utils/helper"
import { classNames } from "../../utils/styles/helper"
import Icon from "../Icon/Icon"
import Loading from "../Loading/Loading"
import { useDataTableContext } from "../NestedDataTable/NestedDataTableContext"

// eslint-disable-next-line react/display-name
const DataTable = forwardRef(
  ({ columnsData: columns, tableData: data, ...props }, ref) => {
    const {
      onRowClickCallback,
      draggableRows,
      selectedRows,
      selectableRows,
      internalSelectAll,
      onSelectRowCallBack,
      onSelectAllCallBack,
      type,
      customNoRowsMessage,
      displayRowColorBar,
      uniqueKey,
      isError,
      queryKey,
      nestedRows
    } = props

    const { rowRefs } = useDataTableContext()

    const toggleChildTableRowSelection = (value) => {
      if (rowRefs?.current?.length > 0) {
        for (const element of rowRefs.current) {
          element?.toggleAll(value)
        }
      }
    }

    const handleSelectAllRows = (event) => {
      const { checked } = event.target
      if (checked && (selectedRows?.length === 0 || nestedRows)) {
        const selectedValidRows = rows
          .filter((each) => each.getCanSelect())
          .map((each) => each.original)
        onSelectAllCallBack?.(checked, selectedValidRows)
        toggleAllRowsSelected(checked)
        toggleChildTableRowSelection(checked)
      } else {
        onSelectAllCallBack?.(false, [])
        toggleAllRowsSelected(false)
        tableProps.setState((prevState) => {
          return {
            ...prevState,
            rowSelection: getState().customDefaultSelectedRows
          }
        })
        toggleChildTableRowSelection(false)
      }
    }

    const onRowClick = (row) => {
      onRowClickCallback?.(row.original)
      tableProps.setState((prevState) => {
        return { ...prevState, activeRow: row }
      })
    }

    const handleCustomCheckBoxSelection = () => {
      const checked = tableProps.getIsAllRowsSelected()
      const indeterminate = tableProps.getIsSomeRowsSelected()
      if (!checked && !indeterminate) {
        if (
          Object.values(customDefaultInterminentRows || {}).some(
            (each) => each.indeterminate
          )
        ) {
          return { indeterminate: true }
        }
      }
      return {}
    }

    const addDefaultColumns = useMemo(() => {
      let alteredColumns = [
        ...columns.map((eachColumn) => {
          if (
            ![TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_SEVERITY].includes(
              eachColumn.id
            )
          ) {
            if (!eachColumn.minSize) {
              eachColumn.minSize = 48
            }
          }
          if (
            eachColumn.id === TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_SEVERITY
          ) {
            eachColumn.enableResizing = false
          }
          return eachColumn
        })
      ]
      let isAlteredColumns = false
      columns.forEach(() => {
        if (selectableRows || displayRowColorBar || draggableRows) {
          isAlteredColumns = true
          const { minSize, size, maxSize } = selectableColumnWidth({
            displayRowColorBar,
            draggableRows,
            selectableRows
          })

          const addSelectionColumn = () => {
            let selectableColumnConfigurationIndex = alteredColumns.findIndex(
              (each) => each.id === "selection"
            )
            if (selectableColumnConfigurationIndex >= 0) {
              alteredColumns[selectableColumnConfigurationIndex] = {
                accessorKey: "temp",
                enableResizing: false,
                enableSorting: false,
                header: "",
                id: "selection",
                maxSize:
                  alteredColumns[selectableColumnConfigurationIndex].maxSize ||
                  maxSize,
                minSize:
                  alteredColumns[selectableColumnConfigurationIndex].minSize ||
                  minSize,
                size:
                  alteredColumns[selectableColumnConfigurationIndex].size ||
                  size
              }
              return alteredColumns
            } else {
              return [
                {
                  accessorKey: "temp",
                  disableTooltip: true,
                  enableResizing: false,
                  enableSorting: false,
                  header: "",
                  id: "selection",
                  maxSize,
                  minSize,
                  size
                },
                ...alteredColumns
              ]
            }
          }

          alteredColumns = addSelectionColumn()
        }
      })
      if (isAlteredColumns) {
        alteredColumns = alteredColumns.map((eachColumn) => {
          if (
            eachColumn.id == TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_SEVERITY &&
            !eachColumn.sortingFn
          ) {
            eachColumn.sortingFn = severityCustomSortingOrder
          } else if (
            [
              TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_IP_ADDRESS,
              TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_SOFTWARE_VERSION
            ].includes(eachColumn.id)
          ) {
            eachColumn.sortingFn = (rowA, rowB, ...rest) =>
              customSortingFn(rowA, rowB, rest)
          }
          if (eachColumn.id == "selection") {
            if (!eachColumn.header && internalSelectAll && selectableRows) {
              eachColumn["header"] = (rest) => {
                const handleChecked = (table) => {
                  if (
                    Object.values(
                      table?.table?.getState?.()?.currentRowState || {}
                    ).some((each) => each.indeterminate)
                  ) {
                    return { indeterminate: true }
                  }
                }
                return (
                  <>
                    { isAllRowsDisabled && props?.rowSelectionTooltipText && (
                      <Tooltip arrow title={ props.rowSelectionTooltipText }>
                        <div className={ style.disabledCheckboxTooltip }></div>
                      </Tooltip>
                    ) }
                    <Checkbox
                      type="checkbox"
                      size="small"
                      aria-label="internalSelectParentTag"
                      inputProps={ {
                        "aria-label": "internalSelectAll"
                      } }
                      { ...{
                        checked: tableProps.getIsAllRowsSelected(),
                        indeterminate: tableProps.getIsSomeRowsSelected()
                      } }
                      { ...handleCustomCheckBoxSelection() }
                      onChange={ handleSelectAllRows }
                      className={ classNames(
                        "customCheckbox",
                        "internalSelectAll",
                        displayRowColorBar && "selectAllWithColorBar"
                      ) }
                      checkedIcon={
                        <Icon
                          iconStyles={ style.checkboxStateIcons }
                          icon={
                            isAllRowsDisabled
                              ? CHECKBOX_STATE_ICONS.disableChecked
                              : CHECKBOX_STATE_ICONS.checked
                          }
                        />
                      }
                      icon={
                        <Icon
                          iconStyles={ style.checkboxStateIcons }
                          icon={
                            isAllRowsDisabled
                              ? CHECKBOX_STATE_ICONS.disableUnchecked
                              : CHECKBOX_STATE_ICONS.unChecked
                          }
                        />
                      }
                      indeterminateIcon={
                        <Icon
                          iconStyles={ style.checkboxStateIcons }
                          icon={ CHECKBOX_STATE_ICONS.indeterminate }
                        />
                      }
                      disabled={ isAllRowsDisabled }
                      { ...handleChecked(rest) }
                    />
                  </>
                )
              }
            }
            if (!eachColumn.cell) {
              eachColumn["cell"] = (tableProp) => (
                <TableRowCustomSelection
                  selectableRows={ selectableRows }
                  draggableRows={ draggableRows }
                  clickHandler={ onSelectRowCallBack }
                  tableProps={ tableProp }
                  displayRowColorBar={ displayRowColorBar }
                  componentProps={ props }
                />
              )
            }
          }
          return eachColumn
        })
      } else {
        return alteredColumns
      }

      return alteredColumns
    })

    const [currentRowState, setCurrentRowState] = useState({})
    const [customDefaultInterminentRows, setCustomDefaultInterminentRows] =
      useState({})
    const [customDefaultSelectedRows, setCustomDefaultSelectedRows] = useState(
      {}
    )

    let otherOptions = {}
    otherOptions.enableRowSelection = props?.selectableRows || false
    if (props.disableRowSelection) {
      otherOptions.enableRowSelection = (row) =>
        !props?.disableRowSelection?.(row.original)
    }

    const tableProps = useReactTable({
      columnResizeDirection: "ltr",
      columnResizeMode: "onChange",
      columns: addDefaultColumns,
      data,
      debugTable: false,
      defaultColumn: {
        size: 120
      },
      enableColumnResizing: true,
      enableSortingRemoval: false,
      getCoreRowModel: getCoreRowModel(),
      getExpandedRowModel: getExpandedRowModel(),
      getSortedRowModel: getSortedRowModel(),
      state: {
        columnVisibility: columns.reduce((acc, column) => {
          if (column.show === false) {
            acc[column.id || column.accessorKey] = false
          }
          return acc
        }, {}),
        currentResizingColumnHover: "",
        currentRowState,
        customDefaultInterminentRows,
        customDefaultSelectedRows
      },
      ...otherOptions
    })

    const columnSizeVars = React.useMemo(() => {
      const headers = tableProps.getFlatHeaders()
      const colSizes = {}
      for (let header of headers) {
        colSizes[`--header-${header.id}-size`] = header.getSize()
        colSizes[`--col-${header.column.id}-size`] = header.column.getSize()
      }
      return colSizes
    }, [tableProps.getState().columnSizingInfo])

    const rows = useMemo(() => tableProps.getRowModel().rows)

    const isAllRowsDisabled = useMemo(() => {
      const state = Object.values(currentRowState)
      return rows?.every((item) => !item?.getCanSelect()) ||
        (state.length > 0 && state.length === rows.length)
        ? Object.values(currentRowState).every((each) => each.disabled)
        : false
    }, [currentRowState, rows])

    const disabledRowsPresent = useMemo(
      () => selectableRows && rows.some((eachRow) => !eachRow.getCanSelect()),
      [rows]
    )

    const { getState, toggleAllRowsSelected } = tableProps

    useImperativeHandle(ref, () => ({
      toggleAll: (value) => {
        if (value) {
          toggleAllRowsSelected(value)
        } else {
          toggleAllRowsSelected(false)
          if (!disabledRowsPresent) {
            setCustomDefaultSelectedRows({})
          }
          tableProps.setState((prevState) => {
            return {
              ...prevState,
              rowSelection: getState().customDefaultSelectedRows
            }
          })
        }
        toggleChildTableRowSelection(value)
      },
      toggleRowSelect: (_rows) => {
        setDefaultRowSelection(_rows)
      }
    }))

    const recursiveNestedTables = () => {
      return rows.map((eachRow) => {
        if (eachRow.getIsSelected()) {
          eachRow.original.assigned = true
        } else {
          eachRow.original.assigned = false
        }
        if (currentRowState?.[eachRow.id]?.selectedrows) {
          eachRow.original = {
            ...eachRow.original,
            ...currentRowState[eachRow.id].selectedrows
          }
        }
        return eachRow.original
      })
    }

    useEffect(() => {
      if (props?.parentTableProps?.toggleRowSelection) {
        const selectedFlatRows = rows.map((eachRow) => {
          if (eachRow.getIsSelected()) {
            eachRow.original.assigned = true
          } else {
            eachRow.original.assigned = false
          }
          if (currentRowState[eachRow.id]?.selectedrows) {
            eachRow.original = {
              ...eachRow.original,
              ...currentRowState[eachRow.id].selectedrows
            }
          }
          return eachRow.original
        })

        const isSomeSelected =
          tableProps.getIsSomeRowsSelected() ||
          Object.values(currentRowState).some((each) => each.indeterminate)
        props?.parentTableProps?.toggleRowSelection?.(
          tableProps.getIsAllRowsSelected(),
          isSomeSelected,
          {
            [props.customSelectedRowsKeyName]: selectedFlatRows
          },
          isAllRowsDisabled
        )
      }
    }, [getState().rowSelection, currentRowState])

    const setDefaultRowSelection = (_selectedRows) => {
      if (_selectedRows) {
        let rowSelection = rows?.reduce((acc, eachRow) => {
          if (
            _selectedRows?.find(
              (eachSelectedRow) =>
                eachRow.original[uniqueKey] === eachSelectedRow[uniqueKey]
            )
          ) {
            acc[eachRow.id] = true
          }
          return acc
        }, {})
        tableProps.setState((prevState) => {
          return { ...prevState, rowSelection }
        })
      }
      if (props.showActiveRow) {
        tableProps.setState((prevState) => {
          return { ...prevState, activeRow: rows[0] }
        })
      }
    }

    useEffect(() => {
      // DEFAULT SORTING
      const defaultSortingColumn = columns.find(
        (eachColumn) => eachColumn.defaultCanSort
      )
      if (defaultSortingColumn) {
        tableProps.setSorting([
          {
            desc: defaultSortingColumn.sortDesc || false,
            id: defaultSortingColumn.id ?? defaultSortingColumn.accessorKey
          }
        ])
      }

      // Make Default row selecttion into state
      if (rows?.length > 0) {
        setDefaultRowSelection(selectedRows)

        if (disabledRowsPresent && selectableRows) {
          let rowSelection = rows?.reduce((acc, eachRow) => {
            if (
              selectedRows?.find(
                (eachSelectedRow) =>
                  eachRow.original[uniqueKey] === eachSelectedRow[uniqueKey] &&
                  !eachRow.getCanSelect()
              )
            ) {
              acc[eachRow.id] = true
            }
            return acc
          }, {})
          setCustomDefaultSelectedRows(rowSelection)
        }
      }
    }, [])

    useEffect(() => {
      props?.parentTableProps?.defaultInderminateAndDisabled?.(
        tableProps.getIsAllRowsSelected(),
        tableProps.getIsSomeRowsSelected(),
        rows.every((each) => !each.getCanSelect()),
        Object.values(customDefaultInterminentRows).some(
          (each) => each.indeterminate
        )
      )
    }, [customDefaultInterminentRows])

    useEffect(() => {
      props?.parentTableProps?.defaultInderminateAndDisabled?.(
        tableProps.getIsAllRowsSelected(),
        tableProps.getIsSomeRowsSelected(),
        rows.every((each) => !each.getCanSelect())
      )
    }, [])

    useEffect(() => {
      if (!props.parentTableProps && props.selectedRowsWatcher) {
        const selectedRecords = getAllNestedSelectedRows()
        props.selectedRowsWatcher?.(selectedRecords)
      }
    }, [currentRowState, getState().rowSelection])

    const getAllNestedSelectedRows = () => {
      return recursiveNestedTables()
    }
    const isFetchingAssets = useIsFetching({ queryKey })

    useEffect(() => {
      if (!isFetchingAssets) {
        const sortObject = getState().sorting?.[0]
        props.onSortingChange?.(
          {
            ...sortObject,
            id: sortObject?.id?.replace(
              TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_SEVERITY,
              "severity"
            )
          },
          tableProps.getSortedRowModel()
        )
      }
    }, [getState().sorting, getState().rowSelection, isFetchingAssets])

    const parentRef = React.useRef()
    const rowVirtualizer = useVirtual({
      overscan: 5,
      parentRef,
      size: rows.length
    })

    const { virtualItems: virtualRows, totalSize } = rowVirtualizer
    const paddingTop = useMemo(
      () => (virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0),
      [virtualRows]
    )
    const paddingBottom = useMemo(
      () =>
        virtualRows.length > 0
          ? totalSize - virtualRows?.[virtualRows.length - 1]?.end
          : 0,
      [virtualRows]
    )

    const nowRows = useMemo(() => Boolean(rows.length === 0), [rows])

    const disableVirtualization = nestedRows && selectableRows

    const getGridRows = useMemo(() => {
      if (queryKey && isFetchingAssets) {
        return (
          <div className={ classNames(style.loaderContainer, "tableLoader") }>
            <Loading customStyles={ style.loader } />
          </div>
        )
      } else if (isError) {
        return <ErrorPanel type={ type } queryKey={ queryKey } />
      } else if (nowRows) {
        return <NoRows type={ type } customMessage={ customNoRowsMessage } />
      } else {
        return (
          <GridBody
            disableVirtualization={ disableVirtualization }
            virtualRows={ virtualRows }
            rows={ rows }
            onRowClick={ onRowClick }
            state={ getState }
            tableProps={ tableProps }
            componentProps={ props }
            setCurrentRowState={ setCurrentRowState }
            setCustomDefaultInterminentRows={ setCustomDefaultInterminentRows }
          />
        )
      }
    })

    const getGridBody = useMemo(() => (
      <div role="rowgroup" className={ classNames(style.tbody, "customTbody") }>
        { !disableVirtualization && paddingTop > 0 && (
          <div className={ style.tableRow }>
            <div
              className={ style.columnCellStyles }
              style={ { height: `${paddingTop}px` } }
            />
          </div>
        ) }

        { getGridRows }

        { !disableVirtualization && paddingBottom > 0 && (
          <div className={ style.tableRow }>
            <div
              className={ style.columnCellStyles }
              style={ { height: `${paddingBottom}px` } }
            />
          </div>
        ) }
      </div>
    ))

    //special memoized wrapper for our table body that we will use during column resizing
    const memoizedTableBody = useMemo(
      () => getGridBody,
      [
        tableProps.options.data,
        tableProps.getState().columnSizingInfo.isResizingColumn
      ]
    )

    return (
      <div
        ref={ parentRef }
        className={ classNames(
          style.tableContainerStyles,
          "customTableContainer",
          nowRows && "noRows",
          nowRows && style.noRows
        ) }
        data-testid="table-scrollContainer"
      >
        <div
          role="table"
          style={ {
            ...columnSizeVars, //Define column sizes on the <table> element
            minWidth: "100%",
            tableLayout: "fixed",
            width: tableProps.getTotalSize()
          } }
          data-testid="table-assetsNotInFleet"
          className={ classNames(style.tableStyles, "customTable") }
        >
          <div role="rowgroup" className={ style.headerContainerStyles }>
            <GridHeader tableProps={ tableProps } />
          </div>
          { tableProps.getState().columnSizingInfo.isResizingColumn
            ? memoizedTableBody
            : getGridBody }
        </div>
      </div>
    )
  }
)

DataTable.propTypes = {
  columnsData: PropTypes.array,
  customNoRowsMessage: PropTypes.any,
  customSelectedRowsKeyName: PropTypes.string,
  disableRowSelection: PropTypes.any,
  displayRowColorBar: PropTypes.bool,
  draggableRows: PropTypes.bool,
  highlightSelectedRows: PropTypes.bool,
  internalSelectAll: PropTypes.bool,
  isError: PropTypes.bool,
  nestedRows: PropTypes.bool,
  onRowClickCallback: PropTypes.func,
  onSelectAllCallBack: PropTypes.func,
  onSelectRowCallBack: PropTypes.func,
  onSortingChange: PropTypes.func,
  parentTableProps: PropTypes.object,
  queryKey: PropTypes.array,
  rowHoverEffect: PropTypes.bool,
  rowSelectionTooltipText: PropTypes.string,
  selectableRows: PropTypes.bool,
  selectedRows: PropTypes.array,
  selectedRowsWatcher: PropTypes.func,
  showActiveRow: PropTypes.bool,
  tableData: PropTypes.array.isRequired,
  type: PropTypes.string,
  uniqueKey: PropTypes.string
}

DataTable.defaultProps = {
  selectedRows: [],
  uniqueKey: "id"
}

export default DataTable
