/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import HeadSection from "./internals/HeadSection"
import styles from "./Properties.module.scss"
import columns from "./PropertiesTableColumnConfig"

import DataTable from "../../../components/DataTable/DataTable"
import Error from "../../../components/Error/Error"
import Loading from "../../../components/Loading/Loading"
import { useAssetViewContext } from "../../../contexts/assetView/assetView"
import { propertySVC } from "../../../services/reactQueries/propertysvc"
import { TABLE_INSTANCES } from "../../../utils/Constants/DataTable"
import { useLazyQuery } from "../../../utils/CustomHooks/reactQuery"
import useCheckBoxSelection from "../../../utils/CustomHooks/useCheckBoxSelection"
import { SEARCH_DEFAULT_MIN_CHARACTERS } from "../../../utils/GlobalConstants"
import { transformPropertiesSectionAttributes } from "../AssetUtils"

const Properties = () => {
  const {
    assetDetails: { muted, serialNumber, name }
  } = useAssetViewContext()

  const { t } = useTranslation(["asset"])
  const tableRef = useRef(null)
  const searchBarRef = useRef(null)

  const exportRef = useRef(null)
  const [exportClicked, setExportClicked] = useState(false)
  const [sortingObject, setSortingObject] = useState()

  const queryKey = ["Device_Properties_By Serial_Number", serialNumber]
  const [searchInput, setSearchInput] = useState(null)
  const [searchClose, setSearchClose] = useState(false)
  const [searchError, setSearchError] = useState(false)
  const [searchResult, setSearchResult] = useState(null)

  const onDataLoadCompleted = (data) => {
    if (searchInput) {
      const searchData = data?.data?.properties
      setSearchResult(searchData)
    }
  }

  const [getProperties, { isLoading, data, isError, refetch }] = useLazyQuery(
    queryKey,
    () =>
      propertySVC.getPropertiesByAssetSerialNumber(serialNumber, searchInput, sortingObject),
    { onSuccess: onDataLoadCompleted }
  )
  const properties = data?.data?.properties

  const assetProperties = useCallback(
    transformPropertiesSectionAttributes(properties),
    [properties]
  )

  useEffect(() => {
    getProperties()
  }, [])

  useEffect(() => {
    if (searchInput?.length >= SEARCH_DEFAULT_MIN_CHARACTERS || searchClose) {
      resetTable()
      getProperties()
    }
  }, [searchInput, searchClose])

  const {
    selectedRows: selectedProperties,
    handleSelectRow,
    handleSelectAll,
    resetSelection
  } = useCheckBoxSelection({
    data: assetProperties,
    uniqueKey: "id"
  })
  const refreshProperties = () => {
    if (searchInput || searchError) {
      resetSearch()
    } else {
      refetch()
    }
    resetTable()
  }

  const resetTable = () => {
    tableRef?.current?.toggleAll(false)
    resetSelection()
  }

  const resetSearch = () => {
    setSearchInput(null)
    setSearchResult(null)
    setSearchError(false)
    setSearchClose(true)
    searchBarRef.current?.reset()
  }

  const handleSearch = (value, closeAction, hasError) => {
    setSearchClose(closeAction)
    setSearchInput(value)
    if (
      (closeAction && !hasError) ||
      (!closeAction && value.length >= SEARCH_DEFAULT_MIN_CHARACTERS)
    ) {
      setSearchResult(null)
      setSearchError(false)
    } else {
      setSearchError(true)
      setSearchResult(null)
    }
  }

  const handleSortChange = (sortObject, sortedRows) => {
    if (sortObject) setSortingObject(sortObject)
    if (sortObject && exportClicked) {
      if (selectedProperties.length === 0) {
        exportRef.current.downloadNewData(sortedRows.flatRows.map((each) => each.original))
      }
      setExportClicked(false, {})
    }
  }

  const onExportClick = () => {
    if (selectedProperties.length === 0) {
      setExportClicked(true)
      refetch()
    } else {
      exportRef.current.downloadNewData(selectedProperties)
    }
  }

  if (isLoading)
    return (
      <div className={ styles.loader }>
        <Loading />
      </div>
    )

  if (isError)
    return (
      <Error
        error={ {
          message: searchInput
            ? t("properties.searchApiError")
            : t("properties.apiError")
        } }
      />
    )

  return (
    <>
      <div className={ styles.topSection }>
        <HeadSection
          t={ t }
          deviceName={ name }
          assetProperties={
            (searchInput && searchInput?.length < SEARCH_DEFAULT_MIN_CHARACTERS) || searchError
              ? []
              : assetProperties
          }
          selectedProperties={ selectedProperties }
          handleRefresh={ refreshProperties }
          handleSearch={ handleSearch }
          searchError={ searchError }
          searchResult={ searchResult }
          searchInput={ searchInput }
          searchBarRef={ searchBarRef }
          muted={ muted }
          getProperties={ getProperties }
          allProperties={ assetProperties }
          onExportClick={ onExportClick }
          exportRef={ exportRef }
        />
      </div>
      <div className={ styles.propertiesTable }>
        <DataTable
          disableCellTooltip
          selectableRows
          internalSelectAll
          columnsData={ columns(muted) }
          highlightSelectedRows
          rowHoverEffect
          tableData={
            (searchInput && searchInput?.length < SEARCH_DEFAULT_MIN_CHARACTERS) || searchError
              ? []
              : assetProperties
          }
          selectedRows={ selectedProperties }
          onSelectRowCallBack={ ({ checked, data }) =>
            handleSelectRow(checked, data)
          }
          onSelectAllCallBack={ handleSelectAll }
          queryKey={ queryKey }
          type={ TABLE_INSTANCES.ASSET_PROPERTIES }
          customNoRowsMessage={
            !searchInput && !searchError ? t("properties.noRows.initialLoad") : ""
          }
          ref={ tableRef }
          onSortingChange={ handleSortChange }
        />
      </div>
    </>
  )
}

export default Properties
