import Button from "@mui/material/Button"
import PropTypes from "prop-types"
import React from "react"

import SingleSelectDropdown from "../../../components/Dropdown/SingleSelectDropdown"
import Icon from "../../../components/Icon/Icon"
import SearchBar from "../../../components/SearchBar/SearchBar"
import SelectedFilters from "../../../components/SelectedFilterItem/SelectedFilters"
import { useSubFletViewContext } from "../../../contexts/subFleetView/subFleetView"
import { SEARCH_INSTANCES } from "../../../utils/Constants/Search"
import useAccess from "../../../utils/CustomHooks/useAccess"
import { DROPDOWN_OPTIONS, GROUP_BY, ICON_NAME } from "../../../utils/GlobalConstants"
import { SUB_FLEET_ACCESS } from "../SubFleetUtils"
import style from "../SubFleetView.module.scss"

const HeadSection = ({ handleFilterInput }) => {
  const {
    state,
    t,
    assetNumberOfElement,
    getApiQuery,
    handleMoveAssetPopup,
    toggleView,
    ṣearchInputRef,
    handleSearchAssets,
    handleFilterState
  } = useSubFletViewContext()

  const isMoveButtonOn = useAccess(SUB_FLEET_ACCESS.moveAsset) && state?.selectedAssets.length > 0 > 0

  const searchResultText = () => {
    return assetNumberOfElement === 0
      ? t("headSection.noResultSearch")
      : t("headSection.assetsSearchedCountText", {
        count: assetNumberOfElement
      })
  }

  return (
    <div className={ style.headSection }>
      <div className={ style.leftSection }>
        <div>
          <SingleSelectDropdown
            options={ DROPDOWN_OPTIONS.SUB_FLEET_VIEW_GROUP_BY }
            optionBy={ GROUP_BY }
            value={ state?.groupBySelectedValue }
            disabled
          />
        </div>
        { Object.keys(state?.selectedFilters).length > 0 && (
          <div className={ style.filterBlock }>
            <SelectedFilters
              handleClearAll={ () => handleFilterInput([]) }
              filters={ state?.selectedFilters }
              setSelectedFilterItem={ handleFilterInput }
              selectedFilterItem={ Object.values(state?.selectedFilters) }
              filterDisabled={ false }
            />
          </div>
        ) }
      </div>
      <div className={ style.rightSection }>
        { state?.searchInput && !state?.searchError && !getApiQuery?.isFetching ? (
          <span role={ "contentinfo" } className={ style.assetsCountText }>
            { state?.selectedAssets?.length > 0
              ? t("headSection.assetsSelectedCountText", {
                searchCount: assetNumberOfElement,
                selectedCount: state?.selectedAssets?.length
              })
              : searchResultText() }
          </span>
        ) : (
          state?.selectedAssets?.length > 0 && (
            <span className={ style.selectedCountText }>
              { t("headSection.selectedCountText", {
                selectedCount: state?.selectedAssets?.length
              }) }
            </span>
          )
        ) }
        { isMoveButtonOn && (
          <Button
            sx={ { marginLeft: "2%" } }
            variant="text"
            color="secondary"
            startIcon={ <Icon icon={ ICON_NAME.moveTo } /> }
            onClick={ handleMoveAssetPopup }
            aria-label={ t("move-to") }
          >
            { t("move-to") }
          </Button>
        ) }

        <button
          className={ style.iconButton }
          onClick={ () => handleFilterState(true) }
          disabled={
            (assetNumberOfElement <= 0 || state?.searchError) && Object.keys(state?.selectedFilters).length == 0
          }
        >
          {
            <Icon
              icon={ Object.keys(state?.selectedFilters).length > 0 ? ICON_NAME.filterBadge : ICON_NAME.filter }
              iconStyles={ Object.keys(state?.selectedFilters).length > 0 && style.iconStyles }
            />
          }
        </button>

        <button
          aria-label="Toggle View"
          className={ !state?.isCardView ? style.toggleButtonList : style.toggleButtonCard }
          onClick={ toggleView }
          disabled={ Boolean(assetNumberOfElement <= 0) || state?.searchError }
        >
          { <Icon icon={ ICON_NAME.gridView } /> }
        </button>
        <span className={ style.searchBar }>
          <SearchBar
            type={ SEARCH_INSTANCES.subFleet }
            onHandleSubmit={ handleSearchAssets }
            ref={ ṣearchInputRef }
            isDisabled={ (!state?.searchInput || state?.searchInput == null) && Boolean(assetNumberOfElement <= 0) }
          />
        </span>
      </div>
    </div>
  )
}

HeadSection.propTypes = {
  handleFilterInput: PropTypes.func
}

export default HeadSection
