/* eslint-disable react-hooks/exhaustive-deps */
import IconButton from "@mui/material/IconButton"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import style from "./Profile.module.scss"
import { PROFILE_FIELDS, USER_PROFILE, transformProfileDetails } from "./ProfileConstants"

import ConfirmationPopup from "../../components/ConfirmationPopup/ConfirmationPopup"
import SingleSelectDropdown from "../../components/Dropdown/SingleSelectDropdown"
import Icon from "../../components/Icon/Icon"
import { useAuthContext } from "../../contexts/Auth/auth"
import { CANCEL, ICON_NAME, ROUTE_CONFIG, SAVE } from "../../utils/GlobalConstants"
import { StorageKeys, getSession } from "../../utils/SessionHelper/session"

const Profile = () => {
  const backURL = `/${ROUTE_CONFIG.CUSTOMER.routeName}/${getSession(StorageKeys.CUSTOMER_ID)}`
  const navigate = useNavigate()
  const { getTokenData } = useAuthContext()
  const { t } = useTranslation(["Profile"])
  const { preference } = USER_PROFILE
  const userData = useMemo(() => getTokenData(), [])
  const userProfileData = transformProfileDetails(userData)

  return (
    <>
      <div className={ style.pageContainer }>
        <div className={ style.iconLabel }>
          <IconButton aria-label="Back button" onClick={ () => navigate(backURL) }>
            <Icon icon={ ICON_NAME.arrowLeft } />
          </IconButton>
          <label className={ style.labelStyles }>{ t("userProfile") }</label>
        </div>
        <div className={ style.container }>
          <div className={ style.section }>
            <div className={ style.sectionTitle }>{ t("preference") }</div>
            <div className={ style.elementContainer }>
              <span className={ style.fieldLabel }>{ t("language") }</span>
              <span className={ style.languageDropdown }>
                <SingleSelectDropdown
                  options={ preference.language }
                  value={ 1 }
                  width={ 200 }
                  disabled
                />
              </span>
            </div>
          </div>
          <div className={ style.divider } />
          <div className={ style.section }>
            <div className={ style.sectionTitle }>{ t("profile") }</div>
            { Object.entries(PROFILE_FIELDS).map((profileDetails) => (
              <div className={ style.elementContainer } key={ profileDetails[0] }>
                <label id={ profileDetails[1] } className={ style.fieldLabel }>
                  { profileDetails[0] }
                </label>
                <input
                  aria-labelledby={ profileDetails[1] }
                  disabled
                  className={ style.input }
                  value={ userProfileData[profileDetails[1]] }
                />
              </div>
            )) }
          </div>
        </div>
      </div>
      <ConfirmationPopup
        heading={ t("popup.heading") }
        confirm={ SAVE }
        cancel={ CANCEL }
        popupStyles={ style.confirmationPopup }
      >
        <span className={ style.confirmationPopupContent }>{ t("popup.message") }</span>
      </ConfirmationPopup>
    </>
  )
}

export default Profile
