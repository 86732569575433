import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import styles from "./AssetFaults.module.scss"
import AssetFaultsColumnConfig from "./AssetFaultsColumnConfig"
import { intialState, transformAssetFaults } from "./AssetFaultsUtils"
import HeadSection from "./internals/HeadSection"

// eslint-disable-next-line max-len
import AcknowledgeUnAcknowledgePopUp from "../../../components/AcknowledgeUnAcknowledgePopUp/AcknowledgeUnAcknowledgePopUp"
import DataTable from "../../../components/DataTable/DataTable"
import Error from "../../../components/Error/Error"
import Loading from "../../../components/Loading/Loading"
import NotesPopUp from "../../../components/NotesPopUp/NotesPopUp"
import { useAssetViewContext } from "../../../contexts/assetView/assetView"
import { GetAssetFaultsFile } from "../../../services/reactQueries/faultsvc"
import { DEFAULT_DATE_RANGE } from "../../../utils/Constants/AssetFaults"
import { TABLE_ACTION } from "../../../utils/Constants/AssetsConfig"
import { TABLE_INSTANCES } from "../../../utils/Constants/DataTable"
import useCheckBoxSelection from "../../../utils/CustomHooks/useCheckBoxSelection"
import { ACKNOWLEDGE, COMPARE_VALUE, GLOBAL_THROTTLE_TIME, UNACKNOWLEDGE } from "../../../utils/GlobalConstants"
import { throttle } from "../../../utils/helper"

const AssetFaults = () => {
  const {
    assetDetails: { muted, serialNumber, name }
  } = useAssetViewContext()

  const { t } = useTranslation(["asset"])
  const queryKey = ["assetFaultsByAssetSerialNumber", serialNumber]
  const tableRef = useRef(null)
  const exportRef = useRef(null)
  const [exportClicked, setExportClicked] = useState(false)
  const [sortingObject, setSortingObject] = useState()

  const [assetFaultsDateRange, setAssetFaultsDateRange] = useState({
    endDate: DEFAULT_DATE_RANGE.endDate,
    startDate: DEFAULT_DATE_RANGE.startDate
  })
  const [acknowledgeUnAcknowledgePopUp, setAcknowledgeUnAcknowledgePopUp] = useState(intialState)
  const [notesPopUpState, setNotesPopUpState] = useState({
    faultId: "",
    isOpen: false
  })

  const { isLoading, data, isError, refetch } = GetAssetFaultsFile(serialNumber, assetFaultsDateRange, sortingObject)
  const assetFaultsRaw = !isLoading && data?.data?.faults
  const assetFaults = useMemo(
    () => transformAssetFaults(JSON.parse(JSON.stringify(assetFaultsRaw || []))),
    [assetFaultsRaw]
  )
  const hasSomeAckFaults = assetFaults?.some((item) => item.isAcknowledge)
  const hasSomeUnAckFaults = assetFaults?.some((item) => !item.isAcknowledge)

  const {
    selectedRows: selectedAssetFaults,
    handleSelectRow,
    handleSelectAll,
    resetSelection
  } = useCheckBoxSelection({
    data: assetFaults
  })

  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetFaultsDateRange])

  const handleRowClickActions = (actionType, payload) => {
    switch (actionType) {
    case COMPARE_VALUE.acknowledge:
      setAcknowledgeUnAcknowledgePopUp({
        faults: [payload],
        isOpen: true,
        message: "",
        type: ACKNOWLEDGE
      })
      break
    case COMPARE_VALUE.unAcknowledge:
      setAcknowledgeUnAcknowledgePopUp({
        faults: [payload],
        isOpen: true,
        message: t("assetFaults.unAcknowledgeMsg"),
        type: UNACKNOWLEDGE
      })
      break
    case TABLE_ACTION.NOTES:
      setNotesPopUpState({
        ...notesPopUpState,
        faultId: payload?.id,
        isOpen: true
      })
      break
      /* istanbul ignore next */
    default:
      break
    }
  }

  const handleSubmit = () => {
    setAcknowledgeUnAcknowledgePopUp(intialState)
    tableRef?.current?.toggleAll(false)
    resetSelection()
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttleActions = useCallback(throttle(handleRowClickActions, GLOBAL_THROTTLE_TIME), [])

  const handleCloseNotesPopUp = () => {
    setNotesPopUpState({ ...notesPopUpState, faultId: "", isOpen: false })
  }

  const handleSortChange = (sortObject, sortedRows) => {
    if (sortObject) setSortingObject(sortObject)
    if (sortObject && exportClicked) {
      if (selectedAssetFaults.length === 0) {
        exportRef.current.downloadNewData(sortedRows.flatRows.map((each) => each.original))
      }
      setExportClicked(false, {})
    }
  }

  const onExportClick = () => {
    if (selectedAssetFaults.length === 0) {
      setExportClicked(true)
      refetch()
    } else {
      exportRef.current.downloadNewData(selectedAssetFaults)
    }
  }

  if (isLoading)
    return (
      <div className={ styles.loader }>
        <Loading />
      </div>
    )

  if (isError) return <Error error={ { message: t("assetFaultsError.errorMessage") } } />

  return (
    <>
      <div className={ styles.topSection }>
        <HeadSection
          assetFaults={ assetFaults }
          selectedAssetFaults={ selectedAssetFaults }
          assetFaultsDateRange={ assetFaultsDateRange }
          setAssetFaultsDateRange={ setAssetFaultsDateRange }
          fileName={ `${name}-asset-faults` }
          ackUnackState={ setAcknowledgeUnAcknowledgePopUp }
          muted={ muted }
          onExportClick={ onExportClick }
          exportRef={ exportRef }
        />
      </div>
      <div className={ styles.assetFaultsTable }>
        <DataTable
          ref={ tableRef }
          selectedRows={ selectedAssetFaults }
          rowHoverEffect
          highlightSelectedRows
          selectableRows
          internalSelectAll
          tableData={ assetFaults }
          columnsData={ AssetFaultsColumnConfig(throttleActions, muted, hasSomeAckFaults, hasSomeUnAckFaults) }
          type={ TABLE_INSTANCES.ASSET_FAULTS }
          disableCellTooltip
          onSelectRowCallBack={ ({ checked, data }) => handleSelectRow(checked, data) }
          onSelectAllCallBack={ handleSelectAll }
          queryKey={ queryKey }
          onSortingChange={ handleSortChange }
        />
      </div>
      <AcknowledgeUnAcknowledgePopUp
        message={ acknowledgeUnAcknowledgePopUp.message }
        type={ acknowledgeUnAcknowledgePopUp.type }
        isOpen={ acknowledgeUnAcknowledgePopUp.isOpen }
        handleCancel={ () => setAcknowledgeUnAcknowledgePopUp(intialState) }
        handleSubmit={ handleSubmit }
        queryKey={ queryKey }
        { ...acknowledgeUnAcknowledgePopUp }
      />
      { notesPopUpState?.faultId && (
        <NotesPopUp
          className={ styles.notesPopUp }
          notesPopUpState={ notesPopUpState }
          setNotesPopUpState={ setNotesPopUpState }
          handleClose={ handleCloseNotesPopUp }
          queryKey={ queryKey }
        />
      ) }
    </>
  )
}

export default AssetFaults
