import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

import DistributionData from "./internals/DistributionData.jsx"

import { DISTRIBUTION_CONFIG } from "../../utils/GlobalConstants"

const StatusData = ({ distributionValues, type }) => {
  const { t } = useTranslation(["statistics"])

  const setBarWidth = (count) => {
    return count.map(function (d) {
      return ((100 * d) / count.reduce((a, b) => a + b, 0)) | 0
    })
  }
  return (
    <>
      { distributionValues.map((value, i) => {
        const title = t(`statisticsData.${DISTRIBUTION_CONFIG[type].bars[i].barTitle}`)
        return (
          <DistributionData
            key={ title }
            data-testid={ type }
            title={ title }
            value={ value }
            color={ DISTRIBUTION_CONFIG[type].bars[i].color }
            barWidth={ setBarWidth(distributionValues)[i] }
          />
        )
      }) }
    </>
  )
}

StatusData.propTypes = {
  distributionValues: PropTypes.array,
  type: PropTypes.oneOf([
    "assets",
    "alerts",
    "updates",
    "faults",
    "swpackages",
    "swpackagesactivity",
    "swpackagesupdate",
    "assets_in_config",
    "configs",
    "config_activities"
  ]).isRequired
}

export default StatusData
