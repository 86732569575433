import { flexRender } from "@tanstack/react-table"
import i18n from "i18next"
import React from "react"

import { DATE_FORMAT } from "./TimeZones"

import AlertConfigStatus from "../../components/AlertConfigStatus/AlertConfigStatus"
import AlertSoftwareStatus from "../../components/AlertSoftwareStatus/AlertSoftwareStatus"
import AlertSeverity from "../../components/AssetAlertsSeverity/AlertSeverity"
import AssetName from "../../components/AssetName/AssetName"
import RowRedirection from "../../components/DataTable/internals/RowRedirection/RowRedirection"
import SWVersionWithUpdates from "../../components/SWVersionWithUpdates/SWVersionWithUpdates"
import { ROUTE_CONFIG } from "../GlobalConstants"
import { formatDate } from "../helper"

export const SELECTION_ROW_DEFAULT_WIDTH = 100

export const EMPTY_CELL_DEFAULT_VALUE = "--"

export const selectableColumnWidth = ({ displayRowColorBar }) => {
  let minSize = 29
  let maxSize = 29
  let size = 29
  if (displayRowColorBar) {
    minSize = 51
    size = 51
    maxSize = 51
  }
  return { maxSize, minSize, size }
}

export const TABLE_INSTANCES = {
  ASSETS_NOT_IN_FLEET: "ASSETS_NOT_IN_FLEET",
  ASSETS_NOT_IN_SUB_FLEET: "ASSETS_NOT_IN_SUB_FLEET",
  ASSET_CONFIGS: "ASSET_CONFIGS",
  ASSET_CONFIGS_INITIAL: "ASSET_CONFIGS_INITIAL",
  ASSET_FAULTS: "ASSET_FAULTS",
  ASSET_LOGS: "ASSET_LOGS",
  ASSET_PROPERTIES: "ASSET_PROPERTIES",
  ASSET_STATUS: "ASSET_STATUS",
  CENTRAL_CONFIG_NO_DATA: "CENTRAL_CONFIG_NO_DATA",
  CENTRAL_CONFIG_SEARCH: "CENTRAL_CONFIG_SEARCH",
  CONFIG_DEPLOYMENT: "CONFIG_DEPLOYMENT",
  GROUP_BY_ASSETS_FLEET_VIEW: "ASSETS_NOT_IN_FLEET",
  GROUP_BY_ASSETS_SUB_FLEET_VIEW: "ASSETS_NOT_IN_FLEET",
  REPORTS_CONFIG: "REPORTS_CONFIG",
  REPORTS_CONFIG_NO_DATA: "REPORTS_CONFIG_NO_DATA",
  REPORTS_SWPACKAGE: "REPORTS_SWPACKAGE",
  REPORTS_SWPACKAGE_NO_DATA: "REPORTS_SWPACKAGE_NO_DATA",
  REPORT_CONFIG_SEARCH: "REPORT_CONFIG_SEARCH",
  REPORT_SWPACKAGE_SEARCH: "REPORT_SWPACKAGE_SEARCH",
  SITE_ASSETS_FAULTS: "SITE_ASSETS_FAULTS",
  SOFTWARE_PACKAGES: "SOFTWARE_PACKAGES",
  SUB_FLEET_VIEW: "SUB_FLEET_VIEW",
  SW_DEPLOYMENT: "SW_DEPLOYMENT",
  USER_MANAGEMENT_NO_ACCESS: "USER_MANAGEMENT_NO_ACCESS",
  USER_MANAGEMENT_NO_MEMBERS: "USER_MANAGEMENT_NO_MEMBERS",
  USER_MANAGEMENT_NO_USERS: "USER_MANAGEMENT_NO_USERS",
  USER_MANAGEMENT_NO_USER_GROUP: "USER_MANAGEMENT_NO_USER_GROUP"
}

export const REDIRECTABLE_TABLE_INSTANCES = [
  TABLE_INSTANCES.ASSETS_NOT_IN_FLEET,
  TABLE_INSTANCES.ASSETS_NOT_IN_SUB_FLEET,
  TABLE_INSTANCES.CONFIG_DEPLOYMENT,
  TABLE_INSTANCES.SITE_ASSETS_FAULTS,
  TABLE_INSTANCES.SUB_FLEET_VIEW,
  TABLE_INSTANCES.SW_DEPLOYMENT
]

export const NO_ROWS_FOUND_MESSAGE = () => ({
  ASSETS_NOT_IN_FLEET: {
    HEADING: i18n.t("dataTable:noRowsAssetsNotInFleet"),
    MESSAGE: ""
  },
  ASSETS_NOT_IN_SUB_FLEET: {
    HEADING: i18n.t("dataTable:noRowsAssetsNotInFleet"),
    MESSAGE: ""
  },
  ASSET_CONFIGS: {
    HEADING: i18n.t("asset:configurationTab.noRows.heading"),
    MESSAGE: i18n.t("asset:configurationTab.noRows.message")
  },
  ASSET_CONFIGS_INITIAL: {
    HEADING: i18n.t("asset:configurations.noRows.heading"),
    MESSAGE: i18n.t("asset:configurations.noRows.message")
  },
  ASSET_FAULTS: {
    HEADING: i18n.t("asset:assetFaults.noRows.heading"),
    MESSAGE: i18n.t("asset:assetFaults.noRows.message")
  },
  ASSET_LOGS: {
    HEADING: i18n.t("asset:logs.noRows.heading"),
    MESSAGE: i18n.t("asset:logs.noRows.message")
  },
  ASSET_PROPERTIES: {
    HEADING: i18n.t("asset:properties.noRows.heading"),
    MESSAGE: i18n.t("asset:properties.noRows.message")
  },
  ASSET_STATUS: {
    HEADING: i18n.t("asset:status.noRows.heading"),
    MESSAGE: i18n.t("asset:status.noRows.message")
  },
  CENTRAL_CONFIG_NO_DATA: {
    HEADING: i18n.t("configuration:noData.heading")
  },
  CENTRAL_CONFIG_SEARCH: {
    HEADING: i18n.t("configuration:noConfigSearchFile.heading"),
    MESSAGE: i18n.t("configuration:noConfigSearchFile.message")
  },
  CONFIG_DEPLOYMENT: {
    HEADING: i18n.t("dataTable:noRowsAssetsNotInFleet"),
    MESSAGE: ""
  },
  DEFAULT: {
    HEADING: i18n.t("dataTable:noRowsMessage"),
    MESSAGE: ""
  },
  REPORTS_CONFIG: {
    HEADING: i18n.t("reports:configurationTab.noRows.heading"),
    MESSAGE: i18n.t("reports:configurationTab.noRows.message")
  },
  REPORTS_CONFIG_NO_DATA: {
    HEADING: i18n.t("reports:configurationTab.noData.heading"),
    MESSAGE: i18n.t("reports:configurationTab.noData.message")
  },
  REPORTS_SWPACKAGE: {
    HEADING: i18n.t("reports:swPackageTab.noRows.heading"),
    MESSAGE: i18n.t("reports:swPackageTab.noRows.message")
  },
  REPORTS_SWPACKAGE_NO_DATA: {
    HEADING: i18n.t("reports:swPackageTab.noData.heading"),
    MESSAGE: i18n.t("reports:swPackageTab.noData.message")
  },
  REPORT_CONFIG_SEARCH: {
    HEADING: i18n.t("reports:configurationTab.noConfigSearchFile.heading"),
    MESSAGE: i18n.t("reports:configurationTab.noConfigSearchFile.message")
  },
  REPORT_SWPACKAGE_SEARCH: {
    HEADING: i18n.t("reports:swPackageTab.noSWPackageSearchFile.heading"),
    MESSAGE: i18n.t("reports:swPackageTab.noSWPackageSearchFile.message")
  },
  SOFTWARE_PACKAGES: {
    HEADING: i18n.t("asset:softwarePackages.noRows.heading"),
    MESSAGE: ""
  },
  SUB_FLEET_VIEW: {
    HEADING: i18n.t("dataTable:noRowsSubFleetView"),
    MESSAGE: ""
  },
  SW_DEPLOYMENT: {
    HEADING: i18n.t("software:noRows.heading"),
    MESSAGE: ""
  },
  USER_MANAGEMENT_NO_ACCESS: {
    HEADING: i18n.t("userManagement:noAccess.heading"),
    MESSAGE: i18n.t("userManagement:noAccess.message")
  },
  USER_MANAGEMENT_NO_MEMBERS: {
    HEADING: i18n.t("userManagement:noMember.heading"),
    MESSAGE: i18n.t("userManagement:noMember.message")
  },
  USER_MANAGEMENT_NO_USERS: {
    HEADING: i18n.t("userManagement:noUsers.heading"),
    MESSAGE: i18n.t("userManagement:noUsers.message")
  },
  USER_MANAGEMENT_NO_USER_GROUP: {
    HEADING: i18n.t("userManagement:noUserGroup.heading"),
    MESSAGE: i18n.t("userManagement:noUserGroup.message")
  }
})

export const TABLE_ROW_REDIRECTION = (row) => ({
  ASSETS_NOT_IN_FLEET: {
    URL: `${window.location.pathname}/${ROUTE_CONFIG.ASSET.routeName}/${row.id}`
  },
  ASSETS_NOT_IN_SUB_FLEET: {
    URL: `${window.location.pathname}/${ROUTE_CONFIG.ASSET.routeName}/${row.id}`
  },
  CONFIG_DEPLOYMENT: {
    URL: `${window.location.pathname}/${ROUTE_CONFIG.ASSET.routeName}/${row.id}`
  },
  SITE_ASSETS_FAULTS: {
    URL: `${window.location.pathname}/${ROUTE_CONFIG.ASSET.routeName}/${row.assetSerialNumber}`
  },
  SUB_FLEET_VIEW: {
    URL: `${window.location.pathname}/${ROUTE_CONFIG.ASSET.routeName}/${row.id}`
  },
  SW_DEPLOYMENT: {
    URL: `${window.location.pathname}/${ROUTE_CONFIG.ASSET.routeName}/${row.id}`
  }
})

export const TABLE_ERROR_MESSAGE = () => ({
  ASSETS_NOT_IN_FLEET: {
    MESSAGE: i18n.t("dataTable:assetsNotInFleetError")
  },
  ASSETS_NOT_IN_SUB_FLEET: {
    MESSAGE: i18n.t("dataTable:assetsNotInFleetError")
  },
  CONFIG_DEPLOYMENT: {
    MESSAGE: i18n.t("dataTable:assetsNotInFleetError")
  },
  DEFAULT: {
    MESSAGE: i18n.t("dataTable:defaultErrorMessage")
  },
  GET_MANAGE_ACCESS: {
    MESSAGE: i18n.t("dataTable:getManageAccess")
  },
  SUB_FLEET_VIEW: {
    MESSAGE: i18n.t("dataTable:assetsNotInSubFleetError")
  },
  USER_MANAGEMENT_NO_ACCESS: {
    MESSAGE: i18n.t("dataTable:accessError")
  },
  USER_MANAGEMENT_NO_MEMBERS: {
    MESSAGE: i18n.t("dataTable:memberTabError")
  }
})

export const TABLE_COLUMN_SPECIAL_ID_ACCESSORS = {
  ASSET_CONFIG_STATUS: "configStatus",
  ASSET_FLEET: "fleet",
  ASSET_ID: "deviceId",
  ASSET_IP_ADDRESS: "ipAddress",
  ASSET_LAST_CONNECTED: "lastConnected",
  ASSET_MODALITY: "modality",
  ASSET_MODEL: "model",
  ASSET_NAME: "customAssetName",
  ASSET_SEVERITY: "assetSeverity",
  ASSET_SOFTWARE_STATUS: "swStatus",
  ASSET_SOFTWARE_VERSION: "softwareVersion",
  ASSET_SUB_FLEET: "subFleet",
  SELECTION: "selection"
}

export const AssetsTableColumnConfig = ({
  row,
  dndProvider,
  cell,
  type,
  isRedirectableCell,
  isResizing
}) => {
  const prepareCell = () => {
    const muteClassName = row.original.mute ? "opacityo5" : ""
    const commonJSX = (
      <RowRedirection
        isResizing={ isResizing }
        cell={ cell }
        isRedirectableCell={ isRedirectableCell }
        data={ row.original }
        type={ type }
        classes={ muteClassName }
      >
        { cell.getValue() }
      </RowRedirection>
    )
    switch (cell?.column?.id) {
    case TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_NAME:
      return (
        <RowRedirection
          isResizing={ isResizing }
          cell={ cell }
          data={ row.original }
          type={ type }
          isRedirectableCell={ isRedirectableCell }
        >
          <AssetName
            isMuted={ row?.original?.mute }
            name={ cell.getValue() }
            { ...row?.original }
          />
        </RowRedirection>
      )
    case TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_IP_ADDRESS:
      return commonJSX
    case TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_SEVERITY:
      return (
        <RowRedirection
          isResizing={ isResizing }
          cell={ cell }
          isRedirectableCell={ isRedirectableCell }
          data={ row.original }
          type={ type }
          classes={ muteClassName }
        >
          <AlertSeverity isGridView severity={ cell.getValue() } />
        </RowRedirection>
      )

    case TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_SOFTWARE_VERSION:
      return (
        <RowRedirection
          isResizing={ isResizing }
          cell={ cell }
          isRedirectableCell={ isRedirectableCell }
          data={ row.original }
          type={ type }
          classes={ muteClassName }
        >
          <SWVersionWithUpdates
            isResizing={ isResizing }
            cell={ cell }
            version={ cell.getValue() }
            availableUpdates={ row?.original?.availableUpdates }
          />
        </RowRedirection>
      )

    case TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_CONFIG_STATUS:
      return (
        <RowRedirection
          isResizing={ isResizing }
          cell={ cell }
          isRedirectableCell={ isRedirectableCell }
          data={ row.original }
          type={ type }
          classes={ muteClassName }
        >
          <AlertConfigStatus
            failedReason={
              row?.original?.configFailedReason || row?.original?.failedReason
            }
            isGridView
            configStatus={ cell.getValue() }
          />
        </RowRedirection>
      )
    case TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_SOFTWARE_STATUS:
      return (
        <RowRedirection
          isResizing={ isResizing }
          cell={ cell }
          data={ row.original }
          isRedirectableCell={ isRedirectableCell }
          type={ type }
          classes={ muteClassName }
        >
          <AlertSoftwareStatus
            isGridView
            swStatus={ cell.getValue() }
            scheduledTime={ row?.original?.scheduledTime }
            type={ type }
            failedReason={
              row?.original?.swFailedReason || row?.original?.failedReason
            }
          />
        </RowRedirection>
      )

    case TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_LAST_CONNECTED:
      return (
        <RowRedirection
          isResizing={ isResizing }
          cell={ cell }
          isRedirectableCell={ isRedirectableCell }
          data={ row.original }
          type={ type }
          classes={ muteClassName }
        >
          { formatDate(
            cell.getValue(),
            DATE_FORMAT.dateTime,
            EMPTY_CELL_DEFAULT_VALUE
          ) }
        </RowRedirection>
      )
    case TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_FLEET:
      return commonJSX
    default:
      if (isRedirectableCell) {
        return (
          <RowRedirection
            isResizing={ isResizing }
            cell={ cell }
            isRedirectableCell={ isRedirectableCell }
            data={ row.original }
            type={ type }
          >
            { flexRender(cell.column.columnDef.cell, {
              ...cell.getContext(),
              ...dndProvider
            }) }
          </RowRedirection>
        )
      } else {
        return (
          <>
            { flexRender(cell.column.columnDef.cell, {
              ...cell.getContext(),
              ...dndProvider
            }) }
          </>
        )
      }
    }
  }
  return prepareCell()
}

export const getRedirectioURLFromGroupByToAsset = (
  type,
  queryParams,
  assetData
) => {
  const isIncludesTypes = [
    TABLE_INSTANCES.SITE_ASSETS_FAULTS,
    TABLE_INSTANCES.CONFIG_DEPLOYMENT,
    TABLE_INSTANCES.SW_DEPLOYMENT
  ].includes(type)
  if (isIncludesTypes) {
    return returnPreparedURL(queryParams, assetData)
  }
  return null
}

const returnPreparedURL = (queryParams, data) => {
  let url = ""
  if (queryParams[ROUTE_CONFIG.CUSTOMER.param]) {
    url =
      url +
      `/${ROUTE_CONFIG.CUSTOMER.routeName}/${
        queryParams[ROUTE_CONFIG.CUSTOMER.param]
      }`
  }
  if (queryParams[ROUTE_CONFIG.SITE.param]) {
    url =
      url +
      `/${ROUTE_CONFIG.SITE.routeName}/${queryParams[ROUTE_CONFIG.SITE.param]}`
  }
  if (queryParams[ROUTE_CONFIG.SITE_MODALITY.param]) {
    url =
      url +
      `/${ROUTE_CONFIG.SITE_MODALITY.routeName}/${
        queryParams[ROUTE_CONFIG.SITE_MODALITY.param]
      }`
  }
  if (queryParams[ROUTE_CONFIG.SITE_ASSET_TYPE.param]) {
    url =
      url +
      `/${ROUTE_CONFIG.SITE_ASSET_TYPE.routeName}/${
        queryParams[ROUTE_CONFIG.SITE_ASSET_TYPE.param]
      }`
  }
  if (queryParams[ROUTE_CONFIG.SITE_CONNECTION.param]) {
    url =
      url +
      `/${ROUTE_CONFIG.SITE_CONNECTION.routeName}/${
        queryParams[ROUTE_CONFIG.SITE_CONNECTION.param]
      }`
  }
  if (queryParams[ROUTE_CONFIG.FLEET.param]) {
    url =
      url +
      `/${ROUTE_CONFIG.FLEET.routeName}/${
        queryParams[ROUTE_CONFIG.FLEET.param]
      }`
  }
  if (queryParams[ROUTE_CONFIG.FLEET_MODALITY.param]) {
    url =
      url +
      `/${ROUTE_CONFIG.FLEET_MODALITY.routeName}/${
        queryParams[ROUTE_CONFIG.FLEET_MODALITY.param]
      }`
  }
  if (queryParams[ROUTE_CONFIG.FLEET_ASSET_TYPE.param]) {
    url =
      url +
      `/${ROUTE_CONFIG.FLEET_ASSET_TYPE.routeName}/${
        queryParams[ROUTE_CONFIG.FLEET_ASSET_TYPE.param]
      }`
  }
  if (queryParams[ROUTE_CONFIG.FLEET_CONNECTION.param]) {
    url =
      url +
      `/${ROUTE_CONFIG.FLEET_CONNECTION.routeName}/${
        queryParams[ROUTE_CONFIG.FLEET_CONNECTION.param]
      }`
  }
  if (queryParams[ROUTE_CONFIG.SUB_FLEET.param]) {
    url =
      url +
      `/${ROUTE_CONFIG.SUB_FLEET.routeName}/${
        queryParams[ROUTE_CONFIG.SUB_FLEET.param]
      }`
  }
  return (
    url +
    `/${ROUTE_CONFIG.ASSET.routeName}/${data.assetSerialNumber ?? data.id}`
  )
}
