import { IconButton, Tooltip } from "@mui/material"
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

import styles from "./AlertConfigStatus.module.scss"

import { ALERT_CONFIG_STATUS, ICON_NAME } from "../../utils/GlobalConstants"
import { formatStatus } from "../../utils/helper"
import Icon from "../Icon/Icon"

const AlertConfigStatus = ({ configStatus, isGridView, isCardView, failedReason }) => {
  const { t } = useTranslation(["asset"])
  const transFormConfigStatus = formatStatus(configStatus)
  const configStatusMappingObject = ALERT_CONFIG_STATUS[transFormConfigStatus]
    ? ALERT_CONFIG_STATUS[transFormConfigStatus]
    : ""
  if (configStatusMappingObject && isGridView) {
    return (
      <div className={ styles.gridContainer }>
        <div
          className={ styles.gridRootIndicator }
          style={ {
            background: configStatusMappingObject.BG_COLOR,
            color: configStatusMappingObject.COLOR ? configStatusMappingObject.COLOR : "inherit"
          } }
        >
          <img
            id="alert-config-status"
            className={ styles.icons }
            src={ configStatusMappingObject.ICON }
            alt={ t("alertConfigStatus") }
          />
          { configStatusMappingObject.TEXT }
        </div>
        { configStatusMappingObject.INFO_ICON && failedReason && (
          <Tooltip arrow title={ failedReason }>
            <IconButton aria-label={ "info" } disableRipple>
              <Icon icon={ ICON_NAME.swInfo } iconStyles={ styles.actionIcon } />
            </IconButton>
          </Tooltip>
        ) }
      </div>
    )
  } else if (configStatusMappingObject && isCardView) {
    return (
      <Tooltip arrow placement="bottom" title={ configStatusMappingObject.TOOLTIP }>
        <div className={ styles.cardRootIndicator } style={ { background: configStatusMappingObject.BG_COLOR } }>
          <img
            id="alert-config-status"
            className={ styles.icons }
            src={ configStatusMappingObject.ICON }
            alt={ t("alertConfigStatus") }
          />
        </div>
      </Tooltip>
    )
  } else return null
}

AlertConfigStatus.propTypes = {
  configStatus: PropTypes.any
}

AlertConfigStatus.defaultProps = {
  configStatus: "",
  isCardView: true
}

AlertConfigStatus.propTypes = {
  failedReason: PropTypes.string,
  isCardView: PropTypes.bool,
  isGridView: PropTypes.bool
}

export default AlertConfigStatus
