import colors from "../../styles/jsExports/colorExport.module.scss"
import fonts from "../../styles/jsExports/fontExport.module.scss"
import margins from "../../styles/jsExports/marginExport.module.scss"

export const Chip = {
  styleOverrides: {
    root: {
      "&.CountChip": {
        "&>.MuiChip-label": {
          color: colors["buttons-label"],
          fontSize: fonts["size-px16"],
          fontWeight: fonts["weight-bolder"],
          padding: `0 ${margins["marginInPixel-px7"]}`
        },
        height: margins["marginInPixel-px21"]
      },
      "&.LogsTab": {
        "&>.MuiChip-label": {
          color: colors["buttons-chipLabel"],
          fontSize: fonts["size-px14"],
          fontWeight: fonts["weight-normal"],
          lineHeight: margins["marginInPixel-px18"],
          padding: 0
        },
        borderRadius: margins["marginInPixel-px6"],
        gap: margins["marginInPixel-px4"],
        height: margins["marginInPixel-px20"],
        padding: `0 ${margins["marginInPixel-px8"]}`
      },
      "&.MuiChip-colorPrimary": {
        backgroundColor: colors["buttons-primary"]
      },
      "&.MuiChip-colorSecondary": {
        backgroundColor: colors["buttons-secondary"]
      },
      "&.RoleChip": {
        "&>.MuiChip-label": {
          color: colors["buttons-label"],
          fontSize: fonts["size-px14"],
          padding: `0 ${margins["marginInPixel-px8"]}`
        }
      }
    }
  }
}
