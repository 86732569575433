import i18n from "i18next"
import React from "react"

import Action from "./internals/Action"

import { FaultsAuthElement } from "../../../utils/Constants/Auth/faults"
import { TABLE_COLUMN_SPECIAL_ID_ACCESSORS } from "../../../utils/Constants/DataTable"
import { DATE_FORMAT } from "../../../utils/Constants/TimeZones"
import { formatDate } from "../../../utils/helper"
import margins from "../../../utils/styles/jsExports/marginExport.module.scss"

const AssetFaultsColumnConfig = (handleRowClickActions, muted, hasSomeAckFaults, hasSomeUnAckFaults) => [
  {
    accessorKey: "severity",
    className: muted && "opacityo5",
    header: "",
    id: TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_SEVERITY,
    maxSize: 40,
    size: 40
  },
  {
    accessorKey: "detectedOn",
    cell: ({ cell }) => formatDate(cell.getValue(), DATE_FORMAT.dateTime),
    className: muted && "opacityForText",
    defaultCanSort: true,
    header: i18n.t("asset:assetFaults.tableHeaders.detectedOn"),
    sortDesc: true,
    width: margins["marginInPercentage-per13o6"]
  },
  {
    accessorKey: "code",
    className: muted && "opacityForText",
    header: i18n.t("asset:assetFaults.tableHeaders.code"),
    width: margins["marginInPercentage-per9o1"]
  },
  {
    accessorKey: "description",
    className: muted && "opacityForText",
    header: i18n.t("asset:assetFaults.tableHeaders.description"),
    sortingFn: "alphanumeric",
    width: margins["marginInPercentage-per15o7"]
  },
  {
    accessorKey: "suggestions",
    className: muted && "opacityForText",
    header: i18n.t("asset:assetFaults.tableHeaders.suggestions"),
    sortingFn: "alphanumeric",
    width: margins["marginInPercentage-per15o7"]
  },
  {
    accessorKey: "latestNote",
    className: muted && "opacityForText",
    header: i18n.t("asset:assetFaults.tableHeaders.note"),
    sortingFn: "alphanumeric",
    width: margins["marginInPercentage-per15o7"]
  },
  {
    accessorKey: "updatedOn",
    cell: ({ cell, row }) =>
      `${row?.original?.lastUpdatedBy ? row?.original?.lastUpdatedBy + " | " : ""}${formatDate(
        cell.getValue(),
        DATE_FORMAT.dateTime
      )}`,
    className: muted && "opacityForText",
    header: i18n.t("asset:assetFaults.tableHeaders.lastUpdated"),
    width: margins["marginInPercentage-per16o7"]
  },
  {
    accessorKey: "action",
    cell: ({ row }) => (
      <Action
        row={ row }
        handleRowClickActions={ handleRowClickActions }
        ackAccessKey={ FaultsAuthElement.AckAssetFault }
        unAckAccessKey={ FaultsAuthElement.UnkAssetFault }
        hasSomeAckFaults={ hasSomeAckFaults }
        hasSomeUnAckFaults={ hasSomeUnAckFaults }
      />
    ),
    disableTooltip: true,
    enableResizing: false,
    enableSorting: false,
    header: i18n.t("asset:assetFaults.tableHeaders.action"),
    width: margins["marginInPercentage-per12o5"]
  }
]

export default AssetFaultsColumnConfig
