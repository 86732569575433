import PropTypes from "prop-types"
import React from "react"

import styles from "./TabPanel.module.scss"

import { classNames } from "../../utils/styles/helper"

const TabPanel = ({ children, value, className, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      className={ classNames(styles.tabPanel, className) }
      hidden={ value !== index }
      id={ `tabpanel-${index}` }
      aria-labelledby={ `tab-${index}` }
      { ...other }
    >
      { value === index && <>{ children }</> }
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

export default TabPanel
