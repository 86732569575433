import addDays from "date-fns/addDays"
import subDays from "date-fns/subDays"

const todaysDate = new Date()
export const ACKNOWLEDGED = "Acknowledged"
export const UNACKNOWLEDGED = "Un-acknowledged"

export const DEFAULT_DATE_RANGE = {
  endDate: addDays(todaysDate, 0),
  maxDate: addDays(todaysDate, 0),
  minDate: subDays(todaysDate, 89),
  startDate: subDays(todaysDate, 13)
}
