import { IconButton } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import styles from "./NoUsers.module.scss"

import Icon from "../../../../components/Icon/Icon"
import { ICON_NAME, ROUTE_CONFIG } from "../../../../utils/GlobalConstants"
import { StorageKeys, getSession } from "../../../../utils/SessionHelper/session"

const NoUsers = () => {
  const backURL = `/${ROUTE_CONFIG.CUSTOMER.routeName}/${getSession(StorageKeys.CUSTOMER_ID)}`
  const navigate = useNavigate()
  const { t } = useTranslation(["userManagement"])
  return (
    <>
      <div className={ styles.title }>
        <IconButton aria-label="Back button" onClick={ () => navigate(backURL) }>
          <Icon icon={ ICON_NAME.back } />
        </IconButton>
        <span className={ styles.iconLabel }>{ t("manageUsers") }</span>
      </div>
   
      <div className={ styles.noUsersDetails }>
        <span className={ styles.heading }>{ t("noData.heading") }</span>
        <span className={ styles.message }>{ t("noData.message") }</span>
      </div>
    </>
  )
}
export default NoUsers
