/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import AssetSummary from "./AssetSummary/AssetSummary"
import styles from "./SiteGroupAssetView.module.scss"

import { BREADCRUMB_ROUTE } from "../../../components/Breadcrumb/BreadcrumbUtils"
import ConfirmMoveAsset from "../../../components/ConfirmMoveAsset/ConfirmMoveAsset"
import Error from "../../../components/Error/Error"
import Loading from "../../../components/Loading/Loading"
import MoveFleet from "../../../components/MoveFleet/MoveFleet"
import { useBreadcrumbContext } from "../../../contexts/breadcrumb/breadcrumb"
import {
  fleetGetSVC,
  fleetSVCKeys
} from "../../../services/reactQueries/fleetsvc"
import { SiteViewGroupFilterData } from "../../../utils/Constants/FilterData"
import { QUERY_KEYS } from "../../../utils/Constants/Queries"
import { useLazyQuery, useQuery } from "../../../utils/CustomHooks/reactQuery"
import {
  COMPARE_VALUE,
  ROUTE_CONFIG,
  SEARCH_DEFAULT_MIN_CHARACTERS
} from "../../../utils/GlobalConstants"
import SiteSummaryStatistics from "../SiteSummaryStatistics/SiteSummaryStatistics"
import { transformAssets, transformSiteStatistics } from "../SiteUtils"

const SiteGroupAssetView = () => {
  const { t } = useTranslation(["site"])
  const queryParamIds = useParams()
  const { updateBreadcrumbData } = useBreadcrumbContext()
  const navigate = useNavigate()
  const assetsSummaryRef = useRef(null)
  const [filterConfigData, setFilterConfigData] = useState(SiteViewGroupFilterData)
  const [groupOption, setGroupByOption] = useState(7)
  const [movePopup, setMovePopup] = useState(false)
  const [selectedAsset, setSelectedAsset] = useState([])
  const [searchInput, setSearchInput] = useState(null)
  const [selectedFilters, setSelectedFilters] = useState([])
  const [searchError, setSearchError] = useState(false)
  const [searchClose, setSearchClose] = useState(false)
  const [searchResult, setSearchResult] = useState(null)
  const [filterAPIError, setFilterAPIError] = useState(false)
  const [confirmPopUp, setConfirmPopUp] = useState({
    heading: "",
    isOpen: false,
    message: "",
    onCancel: false,
    onSubmit: false,
    type: "",
    warning: ""
  })

  const siteId = queryParamIds[ROUTE_CONFIG.SITE.param]
  const modality = queryParamIds[ROUTE_CONFIG.SITE_MODALITY.param]
  const model = queryParamIds[ROUTE_CONFIG.SITE_ASSET_TYPE.param]
  const connectionType = queryParamIds[ROUTE_CONFIG.SITE_CONNECTION.param]
  const assetsQueryKey = [QUERY_KEYS.SITE_GROUP_BY_PAGE, siteId]

  useEffect(() => {
    const filterData = [...filterConfigData]
    const modIdx = filterData.findIndex(item => item.key === COMPARE_VALUE.modality)
    const modelIdx = filterData.findIndex(item => item.key === COMPARE_VALUE.model)
    if (modIdx > -1) filterData[modIdx].disabled = (!!modality || !!model)
    if (modelIdx > -1) filterData[modelIdx].disabled = !!model
    setFilterConfigData(filterData)
  }, [modality, model])

  const onDataLoadCompleted = (data) => {
    if (searchInput) {
      if (data?.data?.assets?.length === 1) {
        const id = data?.data?.assets?.[0]?.id
        const path = window.location.pathname.concat(
          `/${ROUTE_CONFIG.ASSET.routeName}/${id}`
        )
        navigate(path)
      } else {
        setSearchResult(data?.data?.assets)
      }
    }
    const siteConnectionType = connectionType
      ? BREADCRUMB_ROUTE.SITE_CONNECTION
      : null
    const siteModel = model
      ? BREADCRUMB_ROUTE.SITE_ASSET_TYPE
      : siteConnectionType
    const siteType = modality ? BREADCRUMB_ROUTE.SITE_MODALITY : siteModel
    updateBreadcrumbData({
      key: siteType,
      name: modality ?? model ?? connectionType
    })
  }

  const [
    getAssetsData,
    { isError, data: deviceData, isFetched: fetchDeviceData }
  ] = useLazyQuery(
    [fleetSVCKeys.SITE_VIEW_GROUP_BY_PAGES, siteId],
    () =>
      fleetGetSVC.getSiteDevicesByGroupBy(
        siteId,
        modality,
        model,
        connectionType,
        searchInput,
        selectedFilters
      ),
    { onSuccess: onDataLoadCompleted }
  )

  useEffect(() => {
    if (modality) {
      const selectedFilter = {
        disabled: true,
        label: modality,
        type: "modality",
        value: 0
      }
      setSelectedFilters([selectedFilter])
    } else if (model) {
      getModalityByModel()
    } else if (connectionType) {
      getAssetsData()
    }
  }, [modality, model, connectionType])

  const onModelDataLoadCompleted = (data) => {
    const modality = data?.data?.modality
    const selectedFilter = [
      {
        disabled: true,
        label: modality,
        type: "modality",
        value: 0
      },
      {
        disabled: true,
        label: model,
        type: "model",
        value: 0
      }
    ]
    setSelectedFilters(selectedFilter)
  }

  const [getModalityByModel, { isFetching: isFetchingModalityName }] =
    useLazyQuery(
      [fleetSVCKeys.MODALITY_BY_MODEL, model],
      () => fleetGetSVC.getModalityByModel(model),
      {
        onError: () => {
          setFilterAPIError(true)
        },
        onSuccess: onModelDataLoadCompleted
      }
    )

  useEffect(() => {
    if (selectedFilters?.length > 0 || connectionType) getAssetsData()
  }, [selectedFilters])

  useEffect(() => {
    if (searchClose || searchInput?.length >= SEARCH_DEFAULT_MIN_CHARACTERS)
      getAssetsData()
  }, [searchInput])

  const { isFetched: fetchedFleetData, data: siteFleets } = useQuery(
    [fleetSVCKeys.GET_ALL_FLEETS_BY_SITE_ID, siteId],
    () => fleetGetSVC.getAllFleetsBySiteId(siteId)
  )

  const allFleets = fetchedFleetData ? siteFleets?.data?.fleets : []

  const isLoading = !fetchDeviceData
  const devices = fetchDeviceData && deviceData?.data?.assets

  const { data: siteStatistics } = useQuery(
    [fleetSVCKeys.GET_STATISTICS_BY_SITE_ID, siteId],
    () => 
      fleetGetSVC.getSiteStatistics(siteId)
  )
  const siteStatisticsData = siteStatistics?.data?.statistics

  const { distributionData } = useCallback(transformSiteStatistics(siteStatisticsData), [siteStatistics?.data])

  const assets = useMemo(() => {
    if (devices) {
      return transformAssets(devices)
    } else {
      return []
    }
  }, [devices])

  const handleOnSubmit = (dataIN) => {
    setConfirmPopUp(dataIN)
    assetsSummaryRef?.current?.resetSelection()
  }

  const handleSearchSubmit = (value, closeAction, hasError) => {
    setSearchInput(value)
    setSearchClose(closeAction)
    if (hasError || closeAction) {
      setSearchResult(null)
      setSearchError(hasError)
    } else {
      assetsSummaryRef?.current?.resetSelection()
      setSearchError(false)
    }
  }

  const getFilterAssets = (filters) => {
    setSelectedFilters(filters)
    assetsSummaryRef?.current?.resetSelection()
  }

  if (isLoading && !isError && !filterAPIError) return <Loading />
  if (isError || filterAPIError)
    return (
      <Error
        error={ {
          message: filterAPIError
            ? t("filterOptionsError")
            : t("groupByErrorMessage")
        } }
      />
    )

  return (
    <>
      <div className={ styles.staticCards }>
        <SiteSummaryStatistics distributionData={ distributionData } />
      </div>
      <div className={ styles.subFleetsSummary }>
        <AssetSummary
          setGroupByOption={ setGroupByOption }
          groupOption={ groupOption }
          assets={ assets }
          assetsQueryKey={ assetsQueryKey }
          handleMoveFleet={ () => setMovePopup(true) }
          setSelectedAsset={ setSelectedAsset }
          handleSearch={ handleSearchSubmit }
          getFilterAssets={ getFilterAssets }
          searchResult={ searchResult }
          fleetCount={ allFleets?.length }
          ref={ assetsSummaryRef }
          searchError={ searchError }
          siteViewGroupFilterData={ filterConfigData }
          setFilterAPIError={ setFilterAPIError }
          filterAPIError={ filterAPIError }
          selectedFilters={ selectedFilters }
          setSelectedFilters={ setSelectedFilters }
          isFetchingModalityName={ isFetchingModalityName }
        />
      </div>
      { movePopup && (
        <MoveFleet
          isPopupOpen={ movePopup }
          onCancel={ () => setMovePopup(false) }
          setIsConfirmPopUp={ setConfirmPopUp }
        />
      ) }
      <ConfirmMoveAsset
        popupStyles={ styles.confirmationPopUp }
        onSubmit={ handleOnSubmit }
        selectedDevice={ selectedAsset }
        isOpen={ confirmPopUp.isOpen }
        onCancel={ () => setConfirmPopUp({ isOpen: false }) }
        { ...confirmPopUp }
      />
    </>
  )
}

export default SiteGroupAssetView
