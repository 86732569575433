import { decodeURIString, encodeURIString } from "../../../utils/Common/common"
import { arrayGroupBy } from "../../../utils/helper"

export const transformConfigData = (data) => {
  let configData = JSON.parse(JSON.stringify(data || []))

  let data1 = arrayGroupBy(configData, "model")

  const dataArray = []
  Object.entries(data1).forEach((item) => {
    const { configurations } = item[1][0]
    if (configurations?.length > 0) {
      const filteredConfigs = configurations?.filter((item) => item.promoted)
      filteredConfigs.forEach((item) => (item.latestNote = decodeURIString(item.latestNote)))
      if (filteredConfigs?.length > 0) {
        dataArray.push({
          assetName: item[0],
          assets: filteredConfigs
        })
      }
    }
  })
  return dataArray
}

const getInConfigStatistics = (configs) => {
  return {
    countValue: configs?.total,
    distribution: [configs?.inInstall, configs?.inDownload, configs?.failed],
    type: "assets_in_config"
  }
}

const getConfigActivitiesStatistics = (configs) => {
  return {
    countValue: configs?.total,
    distribution: [configs?.inInstall, configs?.inDownload, configs?.failed],
    type: "config_activities"
  }
}

const getModalityChartStatistics = (data) => {
  const chartData = []
  Object.entries(data).forEach(([key, value]) => {
    chartData.push({
      label: key,
      value: value
    })
  })
  return [
    {
      title: "Successful activities by modality",
      type: "sucessModality",
      value: chartData
    }
  ]
}

const getDistributionData = (assetsInConfig, configActivities) => {
  return [
    getInConfigStatistics(assetsInConfig),
    getConfigActivitiesStatistics(configActivities)
  ]
}

export const transformStatistics = (data) => {
  const { assetsInConfig, configActivities, byModality } = data ?? {}
  return {
    distributionData: getDistributionData(assetsInConfig, configActivities),
    modalityChartData: getModalityChartStatistics(byModality ?? {})
  }
}

export const getPromoteDemoteFilePayload = (assetSerialNumber, configFileId, promoted, note) => {
  return {
    assetSerialNumber,
    configFileId,
    variables: { note: encodeURIString(note), promoted }
  }
}

export const getDeleteFilePayload = (assetSerialNumber, configFileId, deleted) => {
  return {
    assetSerialNumber,
    configFileId,
    variables: { deleted }
  }
}

export const intialState = {
  fileDetails: "",
  isOpen: false,
  message: "",
  name: "",
  type: ""
}

export const configFilterData = [
  {
    defaultValue: "",
    key: "modality",
    label: "Modality",
    multiple: false,
    options: []
  },
  {
    defaultValue: [],
    key: "model",
    label: "Model",
    multiple: true,
    options: []
  }
]
