/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from "@mui/material"
import Checkbox from "@mui/material/Checkbox"
import IconButton from "@mui/material/IconButton"
import { PropTypes } from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

import styles from "./Actions.module.scss"

import AuthGuard from "../../../../components/AuthGuard/AuthGuard"
import Icon from "../../../../components/Icon/Icon"
import { TABLE_ACTION } from "../../../../utils/Constants/AssetsConfig"
import { ConfigAuthElement } from "../../../../utils/Constants/Auth/configuration"
import useAccess from "../../../../utils/CustomHooks/useAccess"
import { ICON_NAME } from "../../../../utils/GlobalConstants"

const Actions = ({
  tableProps: { row }, handleRowClickActions, muted,
  hasSomePromotedFiles,
  hasSomeDemotedFiles,
  hasSomeLockedFiles,
  hasSomeUnLockedFiles
}) => {
  const { t } = useTranslation(["asset"])
  const hasPromoteDemoteAccess = useAccess(
    row?.original?.promoted ? ConfigAuthElement.DemoteAssetConfig : ConfigAuthElement.PromoteAssetConfig
  )
  const hasPromoteScopeAccess = useAccess(ConfigAuthElement.PromoteAssetConfig)
  const hasDemoteScopeAccess = useAccess(ConfigAuthElement.DemoteAssetConfig)
  const showPromoteDemoteBlankDiv = (hasPromoteScopeAccess && hasSomeDemotedFiles) ||
                                    (hasDemoteScopeAccess && hasSomePromotedFiles)

  const hasLockUnlockAccess = useAccess(
    row?.original?.locked ? ConfigAuthElement.UnlockAssetConfig : ConfigAuthElement.LockAssetConfig
  )
  const hasLockScopeAccess = useAccess(ConfigAuthElement.LockAssetConfig)
  const hasUnlockScopeAccess = useAccess(ConfigAuthElement.UnlockAssetConfig)
  const showLockUnlockBlankDiv = (hasLockScopeAccess && hasSomeUnLockedFiles) ||
                              (hasUnlockScopeAccess && hasSomeLockedFiles)

  const handleDeploy = () => {
    handleRowClickActions(TABLE_ACTION.DEPLOY, {
      fileName: row?.original?.configFile,
      heading: `${t("configurations.popup.deploy.heading")}`,
      isOpen: true,
      message: `${t("configurations.popup.deploy.message", {
        fileName: row?.original?.configFile
      })}`,
      selectedFileId: row?.original?.id,
      type: "deploy"
    })
  }

  let lockTooltipText = ""
  if (row?.original?.locked) {
    lockTooltipText = t("configurations.tableActionTooltip.unlock")
  } else {
    lockTooltipText = t("configurations.tableActionTooltip.lock")
  }

  return (
    <div className={ styles.actionsCell }>
      <AuthGuard actionType={ ConfigAuthElement.DeployAssetConfig }>
        <Tooltip 
          arrow 
          classes={ { tooltip: "configActionTooltip" } } 
          title={ t("configurations.tableActionTooltip.deploy") }
        >
          <span>
            <IconButton className={ muted && styles.mutedActions }
              onClick={ handleDeploy } 
              aria-label={ `deploy-icon-${row?.original?.id}` }
              disabled={ muted }
            >
              <Icon icon={ ICON_NAME.deploy } iconStyles={ styles.icon } />
            </IconButton>
          </span>
        </Tooltip>
      </AuthGuard>
      { hasPromoteDemoteAccess && (
        <Tooltip
          arrow
          classes={ { tooltip: "configActionTooltip" } }
          title={
            row?.original?.promoted
              ? t("configurations.tableActionTooltip.demote")
              : t("configurations.tableActionTooltip.promote")
          }
        >
          <IconButton
            className={ styles.iconButton }
            onClick={ () =>
              handleRowClickActions(row?.original?.promoted ? TABLE_ACTION.DEMOTE : TABLE_ACTION.PROMOTE, row?.original)
            }
          >
            <Checkbox
              size="small"
              inputProps={ {
                "aria-label":
                row?.original?.configFile + "-" + `${row?.original?.promoted ? "demote config" : "promote config"}`
              } }
              checked={ row?.original?.promoted }
              checkedIcon={ <Icon icon={ ICON_NAME.promote } iconStyles={ styles.icon } /> }
              icon={ <Icon icon={ ICON_NAME.demote } iconStyles={ styles.icon } /> }
            />
          </IconButton>
        </Tooltip>
      ) }
      { !hasPromoteDemoteAccess && showPromoteDemoteBlankDiv && <div className={ styles.emptyButtonDiv }></div> }
      { hasLockUnlockAccess && (
        <Tooltip arrow title={ lockTooltipText } classes={ { tooltip: "configActionTooltip" } }>
          <IconButton
            className={ styles.iconButton }
            onClick={ () => handleRowClickActions(TABLE_ACTION.LOCK, row?.original) }
          >
            <Checkbox
              size="small"
              inputProps={ {
                "aria-label":
                row?.original?.configFile + "-" + `${row?.original?.locked ? "unlock config" : "lock config"}`
              } }
              checked={ row?.original?.locked }
              checkedIcon={ <Icon icon={ ICON_NAME.lock } iconStyles={ styles.icon } /> }
              icon={ <Icon icon={ ICON_NAME.unlock } iconStyles={ styles.icon } /> }
            />
          </IconButton>
        </Tooltip>
      ) }
      { !hasLockUnlockAccess && showLockUnlockBlankDiv && <div className={ styles.emptyButtonDiv }></div> }
      <AuthGuard actionType={ ConfigAuthElement.DeleteAssetConfig }>
        <Tooltip
          arrow
          classes={ { tooltip: "configActionTooltip" } }
          title={ !row?.original?.locked ? t("configurations.tableActionTooltip.delete") : "" }
        >
          <IconButton
            aria-label={ `delete-icon-${row?.original?.id}` }
            onClick={ () => handleRowClickActions(TABLE_ACTION.DELETE, row?.original) }
            className={ styles.iconButton }
            disabled={ row?.original?.locked }
          >
            <Icon icon={ ICON_NAME.trashBlue } iconStyles={ styles.icon } />
          </IconButton>
        </Tooltip>
      </AuthGuard>
    </div>
  )
}

Actions.propTypes = {
  handleRowClickActions: PropTypes.any,
  hasSomeDemotedFiles: PropTypes.bool,
  hasSomeLockedFiles: PropTypes.bool,
  hasSomePromotedFiles: PropTypes.bool,
  hasSomeUnLockedFiles: PropTypes.bool,
  muted: PropTypes.bool,
  tableProps: PropTypes.any
}

export default Actions
