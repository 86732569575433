import Button from "@mui/material/Button"
import PropTypes from "prop-types"
import React from "react"

import AuthGuard from "../../../../components/AuthGuard/AuthGuard"
import { ExportCSV } from "../../../../components/ExportCSV/ExportCSV"
import Icon from "../../../../components/Icon/Icon"
import SearchBar from "../../../../components/SearchBar/SearchBar"
import { MetricsAssetAuthElement } from "../../../../utils/Constants/Auth/metrics"
import { EXPORT_CSV_TYPES } from "../../../../utils/Constants/ExportCSVConstants"
import { SEARCH_INSTANCES } from "../../../../utils/Constants/Search"
import { ICON_NAME } from "../../../../utils/GlobalConstants"
import style from "../Status.module.scss"

const HeadSection = ({
  t,
  assetStatusProperties,
  deviceName,
  selectedStatusProperties,
  handleRefresh,
  handleSearch,
  searchBarRef,
  searchInput,
  searchResult,
  muted,
  onExportClick,
  exportRef
}) => {
  const searchResultWithMultipleText = () => {
    return searchResult.length > 1
      ? t("status.headSection.propSearchedCountText", {
        searchCount: searchResult.length
      })
      : t("status.headSection.singlePropSearchedCountText", {
        searchCount: searchResult.length
      })
  }

  const searchResultText = () => {
    return searchResult.length === 0 ? t("status.headSection.noResultSearch") : searchResultWithMultipleText()
  }

  const getSelectedTranslationMessage = () => {
    return t(
      selectedStatusProperties?.length === 1
        ? "status.headSection.singlePropSelectedCountText"
        : "status.headSection.propSelectedCountText",
      {
        searchCount: searchResult.length,
        selectedCount: selectedStatusProperties?.length
      }
    )
  }

  return (
    <>
      { searchResult ? (
        <span role={ "contentinfo" } className={ style.assetsCountText }>
          { selectedStatusProperties?.length > 0 ? getSelectedTranslationMessage() : searchResultText() }
        </span>
      ) : (
        selectedStatusProperties?.length > 0 && (
          <span className={ style.selectedCountText }>
            { t(
              selectedStatusProperties?.length === 1
                ? "status.headSection.singleSelectedCountText"
                : "status.headSection.selectedCountText",
              {
                selectedCount: selectedStatusProperties?.length
              }
            ) }
          </span>
        )
      ) }
      <Button
        variant="text"
        color="secondary"
        data-testid="refreshButton"
        aria-label={ t("properties.buttons.refresh") }
        onClick={ handleRefresh }
        startIcon={ <Icon icon={ muted ? ICON_NAME.refreshDisabled : ICON_NAME.refresh } /> }
        disabled={ muted }
      >
        { t("properties.buttons.refresh") }
      </Button>
      <AuthGuard actionType={ MetricsAssetAuthElement.ExpAssetMetrics }>
        <ExportCSV
          fileName={ `${deviceName}-metrics` }
          type={ EXPORT_CSV_TYPES.ASSET_STATUS }
          isDisabled={ assetStatusProperties?.length === 0 }
          asyncDownload
          ref={ exportRef }
        >
          <Button
            onClick={ onExportClick }
            variant="text"
            color="secondary"
            data-testid="exportButton"
            disabled={ assetStatusProperties?.length === 0 }
            startIcon={
              <Icon icon={ assetStatusProperties?.length === 0 ? ICON_NAME.exportDisabled : ICON_NAME.export } />
            }
          >
            { t("properties.buttons.export") }
          </Button>
        </ExportCSV>
      </AuthGuard>
      <div className={ style.searchField }>
        <SearchBar
          ref={ searchBarRef }
          type={ SEARCH_INSTANCES.assetStatus }
          data-testid="search-input"
          isDisabled={ !searchInput && searchInput == null && assetStatusProperties?.length === 0 }
          onHandleSubmit={ handleSearch }
        />
      </div>
    </>
  )
}

HeadSection.propTypes = {
  allStatusProperties: PropTypes.array,
  assetStatusProperties: PropTypes.array,
  deviceName: PropTypes.string,
  exportRef: PropTypes.any,
  getStatuses: PropTypes.func,
  handleRefresh: PropTypes.func,
  handleSearch: PropTypes.func,
  muted: PropTypes.bool,
  onExportClick: PropTypes.func,
  searchBarRef: PropTypes.any,
  searchInput: PropTypes.string,
  searchResult: PropTypes.array,
  selectedStatusProperties: PropTypes.array,
  t: PropTypes.func
}

export default HeadSection
