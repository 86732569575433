import { IconButton, Tooltip } from "@mui/material"
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

import styles from "./AlertSoftwareStatus.module.scss"

import { ALERT_SW_STATUS, ICON_NAME } from "../../utils/GlobalConstants"
import { formatStatus } from "../../utils/helper"
import { classNames } from "../../utils/styles/helper"
import Icon from "../Icon/Icon"

const AlertSoftwareStatus = ({ swStatus, isCardView, isGridView, scheduledTime, failedReason }) => {
  const { t } = useTranslation(["asset"])
  const transFormSWStatus = formatStatus(swStatus)
  const swStatusMappingObject = ALERT_SW_STATUS[transFormSWStatus] ? ALERT_SW_STATUS[transFormSWStatus] : ""
  const isScheduledState = ALERT_SW_STATUS.SCHEDULED.TEXT === swStatusMappingObject.TEXT
  const statusToShowToolipOnHover = ["INPROGRESSSTEP1", "INPROGRESSSTEP2", "INPROGRESSSTEP3", "SCHEDULED"]

  if (swStatusMappingObject && isGridView) {
    return (
      <Tooltip
        disableHoverListener={ !statusToShowToolipOnHover.includes(transFormSWStatus) }
        arrow
        placement="bottom"
        title={
          isScheduledState && scheduledTime
            ? swStatusMappingObject?.DATE_TIME?.(scheduledTime)
            : swStatusMappingObject.TOOLTIP
        }
      >
        <div className={ classNames(styles.gridContainer) }>
          <div
            className={ styles.gridRootIndicator }
            style={ {
              background: swStatusMappingObject.BG_COLOR,
              color: swStatusMappingObject.COLOR ? swStatusMappingObject.COLOR : "inherit"
            } }
          >
            <img
              id="alert-sw-status"
              className={ styles.icons }
              src={ swStatusMappingObject.ICON }
              alt={ t("alertSwStatus") }
            />
            { swStatusMappingObject.TEXT }
          </div>
          { swStatusMappingObject.STEP_ICON && (
            <img
              id="alert-sw-status-inprogress-step"
              className={ styles.stepsIcon }
              src={ swStatusMappingObject.STEP_ICON }
              alt={ t("alertSwInprogressSteps") }
            />
          ) }
          { swStatusMappingObject.INFO_ICON && failedReason && (
            <Tooltip arrow title={ failedReason }>
              <IconButton aria-label={ "info" } disableRipple>
                <Icon icon={ ICON_NAME.swInfo } iconStyles={ styles.actionIcon } />
              </IconButton>
            </Tooltip>
          ) }
        </div>
      </Tooltip>
    )
  } else if (swStatusMappingObject && isCardView) {
    return (
      <Tooltip arrow placement="bottom" title={ swStatusMappingObject.TOOLTIP }>
        <div className={ styles.cardRootIndicator } style={ { background: swStatusMappingObject.BG_COLOR } }>
          <img
            id="alert-sw-status"
            className={ styles.icons }
            src={ swStatusMappingObject.ICON }
            alt={ t("alertSwStatus") }
          />
        </div>
      </Tooltip>
    )
  }
  return null
}

AlertSoftwareStatus.propTypes = {
  failedReason: PropTypes.string,
  isCardView: PropTypes.bool,
  isGridView: PropTypes.bool,
  scheduledTime: PropTypes.any,
  swStatus: PropTypes.any
}

AlertSoftwareStatus.defaultProps = {
  isCardView: true,
  swStatus: ""
}

export default AlertSoftwareStatus
