export const classNames = (...styles) => {
  let classes = ""
  for (const style of styles) if (style) classes = classes + (classes ? " " : "") + style
  return classes
}

export const DEVICE_RESOLUTION_KEYS = {
  DEFAULT: "1280",
  EXTRA_LARGE: "1920",
  LARGE: "1440",
  MEDIUM: "1280"
}

export const currentDeviceResolution = () => {
  if (window.matchMedia("(min-width: 1280px)").matches && window.matchMedia("(max-width:1439px)").matches) {
    return DEVICE_RESOLUTION_KEYS.MEDIUM
  } else if (window.matchMedia("(min-width: 1440px)").matches && window.matchMedia("(max-width: 1919px)").matches) {
    return DEVICE_RESOLUTION_KEYS.LARGE
  } else if (window.matchMedia("(min-width: 1920px)").matches) {
    return DEVICE_RESOLUTION_KEYS.EXTRA_LARGE
  } else {
    return DEVICE_RESOLUTION_KEYS.DEFAULT
  }
}
