import PropTypes from "prop-types"
import React, { useContext, useMemo, useReducer } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"

import {
  BREADCRUMB_ACTION_TYPES,
  BREADCRUMB_ROUTE,
  breadcrumbReducer
} from "../../components/Breadcrumb/BreadcrumbUtils"
import { setSiteUCMID } from "../../pages/CustomerView/CustomerUtils"
import { fleetGetSVC, fleetSVCKeys } from "../../services/reactQueries/fleetsvc"
import { ROUTE_CONFIG } from "../../utils/GlobalConstants"

const BREADCRUMB_STATE = {
  breadcrumbData: [],
  isCustomerDisabled: true
}

const BreadcrumbContext = React.createContext(null)

export const BreadcrumbProvider = ({ children }) => {
  const [state, dispatch] = useReducer(breadcrumbReducer, BREADCRUMB_STATE)
  const queryParams = useParams()
  const siteId = queryParams[ROUTE_CONFIG.SITE.param]

  useQuery([fleetSVCKeys.GET_SITES], fleetGetSVC.getSites, {
    onSuccess: (data) => {
      const customerRes = data?.data?.customer
      handleUpdateBreadcrumb({
        key: BREADCRUMB_ROUTE.CUSTOMER,
        name: customerRes?.aliasName ?? customerRes?.name
      })
      const sites = data?.data?.sites
      if (sites?.length === 1) {
        handleSetIsCustomerDisabled(true)
      } else {
        handleSetIsCustomerDisabled(false)
      }
      const siteIdx = sites?.findIndex(site => site.id === siteId)
      if (siteIdx > -1) {
        handleUpdateBreadcrumb({
          key: BREADCRUMB_ROUTE.SITE,
          name: sites[siteIdx]?.siteAliasName ?? sites[siteIdx]?.siteName
        })
        setSiteUCMID(sites[siteIdx]?.siteUcmId)
      }
    },
    staleTime: 5000
  })

  const handleUpdateBreadcrumb = (data) => {
    dispatch({ payload: data, type: BREADCRUMB_ACTION_TYPES.UPDATE_BREADCRUMB })
  }

  const handleSetBreadcrumb = (data) => {
    dispatch({ payload: data, type: BREADCRUMB_ACTION_TYPES.SET_BREADCRUMB })
  }

  const handleSetIsCustomerDisabled = (value) => {
    dispatch({ payload: value, type: BREADCRUMB_ACTION_TYPES.UPDATE_CUSTOMER_ACCESS })
  }

  const BreadcrumbValue = useMemo(() => ( {
    ...state,
    setBreadcrumbData: handleSetBreadcrumb,
    setIsCustomerDisabled: handleSetIsCustomerDisabled,
    updateBreadcrumbData: handleUpdateBreadcrumb
  }), [state])

  return (
    <BreadcrumbContext.Provider value={ BreadcrumbValue }>
      { children }
    </BreadcrumbContext.Provider>
  )
}

BreadcrumbProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const useBreadcrumbContext = () => {
  return useContext(BreadcrumbContext)
}
