import OutlinedInput from "@mui/material/OutlinedInput"
import PropTypes from "prop-types"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { useParams } from "react-router-dom"

import style from "./CreateUpdateLabelPopup.module.scss"
import ErrorInfo from "./Internal/ErrorInfo/ErrorInfo"

import { useToaster } from "../../contexts/ToastContext"
import {
  fleetMutationSVC,
  fleetSVCKeys
} from "../../services/reactQueries/fleetsvc"
import {
  ALL_DUPLICATE_NAME_ERROR_CODES,
  FLEET_SERVICE_ERRORS
} from "../../utils/Constants/ErrorCodes"
import { useMutationWithHandlers } from "../../utils/CustomHooks/reactQuery"
import {
  CANCEL,
  COMPARE_VALUE,
  CREATE,
  CREATE_ACTION_TITLE,
  EDIT_OVERLAY_CHAR_LENGTH,
  EDIT_OVERLAY_DEFAULT_MIN_CHARACTERS,
  EDIT_OVERLAY_ERROR_MESSAGE,
  EDIT_OVERLAY_GENERAL_INFO,
  ERROR_MESSAGE,
  POPUP_TITLE,
  SAVE,
  SEARCH_DEFAULT_MIN_CHARACTERS,
  UPDATE,
  specialCharactersNotAllowed
} from "../../utils/GlobalConstants"
import { checkForCreateAction, checkForUpdateAction } from "../../utils/helper"
import { removeEmoji } from "../../utils/InputValidator/validator"
import { classNames } from "../../utils/styles/helper"
import {
  CREATE_PAYLOAD_DATA,
  PAYLOAD_DATA
} from "../EditableLabel/EditableLabelUtils"
import PopupButtonGroup from "../PopupButtonGroup/PopupButtonGroup"

const UPDATE_MUTATIONS = {
  customerName: fleetMutationSVC.editCustomerName,
  fleetName: fleetMutationSVC.editFleetSubFleetName,
  siteName: fleetMutationSVC.editSiteName,
  subfleet: fleetMutationSVC.editFleetSubFleetName
}

const REFETCH_QUERIES = {
  customerName: { key: fleetSVCKeys.GET_SITES },
  siteName: { key: fleetSVCKeys.GET_SITES }
}

const CreateUpdateLabelPopup = ({
  dataId,
  isEditPopupOpen,
  setIsEditPopupOpen,
  popupStyles,
  inputStyle,
  handleNewFleetSubmitted,
  ignoreRefetch,
  ...props
}) => {
  const queryClient = useQueryClient()
  const { siteId, fleetId } = useParams()
  const { t } = useTranslation(["search"])
  const { name, isOpen, type: getType } = isEditPopupOpen
  const { action, type } = getType
  const [inputValue, setInputValue] = useState(name)
  const [error, setError] = useState(null)
  const { displaySuccessToast, displayMediumErrorToast } = useToaster()
  let fetchQueryKey = []
  let fetchAssetsQueryKey = []
  const inputRef = useRef(null)

  const UPDATE_ACTION = useMemo(
    () => checkForUpdateAction({ error, inputValue, name }),
    [name, inputValue, error]
  )

  const CREATE_ACTION = useMemo(
    () => checkForCreateAction({ CREATE, action, error, inputValue, name }),
    [action, name, inputValue, error]
  )

  useEffect(() => {
    setInputValue(name)
    inputRef?.current?.focus()
  }, [name])

  if (type === "fleetName") {
    fetchAssetsQueryKey = [
      { id: siteId, key: fleetSVCKeys.GET_DESCRETE_ASSETS_BY_SITE_ID }
    ]
  } else if (type === "subfleet") {
    fetchAssetsQueryKey = [
      { id: fleetId, key: fleetSVCKeys.GET_DESCRETE_ASSETS_BY_FLEET_ID }
    ]
  } else {
    fetchQueryKey = [REFETCH_QUERIES[type]]
  }

  const refetchFleetData = () => {
    let fleetRefetchKey = []
    if (type === "fleetName") {
      fleetRefetchKey = [fleetSVCKeys.SITE_VIEW_GROUP_BY, siteId]
    } else if (type === "subfleet") {
      fleetRefetchKey = [fleetSVCKeys.FLEET_VIEW_GROUP_BY, fleetId]
    }
    queryClient.refetchQueries({
      exact: true,
      queryKey: fleetRefetchKey,
      type: "active"
    })
  }

  const handleCancel = () => {
    setInputValue(name)
    setIsEditPopupOpen({ isOpen: false })
  }

  const onMutationComplete = () => {
    refetchFleetData()
    displaySuccessToast(`${name} is updated to ${inputValue}.`)
    setError(null)
    setIsEditPopupOpen({ isOpen: false })
  }

  const onMutationError = (err) => {
    if (ALL_DUPLICATE_NAME_ERROR_CODES.includes(err?.data?.error?.code)) {
      setError({ error: true, message: ERROR_MESSAGE[type].uniqueName })
    } else if (
      FLEET_SERVICE_ERRORS.FORBIDDEN_OR_INSUFFICIENT_PERMISSIONS.includes(
        err?.data?.error?.code
      )
    ) {
      setError({ error: true, message: ERROR_MESSAGE[type].accessError })
    } else {
      const errorMessage =
        action === CREATE
          ? ERROR_MESSAGE[type].createError
          : ERROR_MESSAGE[type].updateError
      displayMediumErrorToast(errorMessage)
      setIsEditPopupOpen({ isOpen: false })
    }
  }

  const { requestMutation: updateField, isLoading } = useMutationWithHandlers({
    onCompletedCallback: onMutationComplete,
    onErrorCallback: onMutationError,
    queryFn: UPDATE_MUTATIONS[type],
    refetchQueries: fetchQueryKey
  })

  const onCreateMutationComplete = (data) => {
    const newFleet = data?.data?.fleet
    const selectedAssets = isEditPopupOpen?.type?.assets
    if (selectedAssets?.length > 0) {
      const assets = []
      for (const asset of selectedAssets) {
        assets.push(asset.id)
      }
      handleAddAssets(assets, newFleet.id)
    } else {
      if (!ignoreRefetch) refetchFleetData()
      displaySuccessToast(
        `Successfully created new ${type === "subfleet" ? "sub-" : ""}fleet ${
          newFleet.name
        }.`
      )
      handleNewFleetSubmitted?.(true)
    }
    setError(null)
    setIsEditPopupOpen({ isOpen: false })
  }

  const handleAddAssets = (assets, newFleetId) => {
    addAssets({
      payload: {
        fleetId: newFleetId,
        variables: { assetIds: assets }
      },
      showToast: false
    })
  }

  const onAssetMutationComplete = () => {
    const selectedAssets = isEditPopupOpen?.type?.assets
    displaySuccessToast(
      `${inputValue} is created with ${
        selectedAssets?.length > 1
          ? selectedAssets?.length + " assets"
          : selectedAssets[0]?.name
      }.`
    )
    setError(null)
    setIsEditPopupOpen({ isOpen: false })
    handleNewFleetSubmitted?.(true)
    if (ignoreRefetch) return
    refetchFleetData()
  }

  const { requestMutation: createFleetOrSubFleet, isLoading: isCreatingFleet } =
    useMutationWithHandlers({
      onCompletedCallback: onCreateMutationComplete,
      onErrorCallback: onMutationError,
      queryFn: fleetMutationSVC.createFleetSubFleet
    })

  const { requestMutation: addAssets } = useMutationWithHandlers({
    onCompletedCallback: onAssetMutationComplete,
    onErrorCallback: onMutationError,
    queryFn: fleetMutationSVC.addAssets,
    refetchQueries: fetchAssetsQueryKey
  })

  const handleSubmit = (value, type, isEnter) => {
    const trimmedValue = value
      .split(" ")
      .filter((e) => e.replaceAll(" ", ""))
      .join(" ")
    if (
      trimmedValue.length <=
      (props.minCharacters || EDIT_OVERLAY_DEFAULT_MIN_CHARACTERS)
    ) {
      setError({
        error: true,
        message: EDIT_OVERLAY_ERROR_MESSAGE[type].emptyText
      })
    } else if (
      trimmedValue
        .split("")
        .map((ele) => specialCharactersNotAllowed.includes(ele))
        .includes(true)
    ) {
      setError({
        error: true,
        message: `${t("searchPopupInfo.splCharNotAllowed")}`
      })
    } else if (value.length > EDIT_OVERLAY_CHAR_LENGTH[type]) {
      setError({
        error: true,
        message: EDIT_OVERLAY_ERROR_MESSAGE[type].characterLength
      })
    } else {
      setError(null)
      if (!checkUpdateButtonEnableState() && getCanSave(isEnter)) {
        handleSave(trimmedValue)
      }
    }
  }

  const handleSave = (value) => {
    const trimmedValue = value
      .split(" ")
      .filter((e) => e.replaceAll(" ", ""))
      .join(" ")
    setInputValue(trimmedValue)
    if (action === UPDATE) {
      updateField({
        payload: {
          id: dataId,
          variables: PAYLOAD_DATA[type](trimmedValue)
        },
        showToast: false
      })
    } else if (action === CREATE) {
      createFleetOrSubFleet({
        payload: {
          variables: CREATE_PAYLOAD_DATA[type](trimmedValue, fleetId, siteId)
        },
        showToast: false
      })
    }
  }

  const handleOnChange = (event) => {
    const value = event.target.value
    handleSubmit(value, type)
    let msg = removeEmoji(value)
    setInputValue(msg)
  }

  const checkCreateButtonEnableState = () => {
    return action === CREATE
      ? inputValue.trim().length < SEARCH_DEFAULT_MIN_CHARACTERS ||
          name === inputValue.trim() ||
          error?.error
      : false
  }

  const checkUpdateButtonEnableState = () => {
    return (
      inputValue.trim().length < SEARCH_DEFAULT_MIN_CHARACTERS ||
      name === inputValue.trim() ||
      error?.error
    )
  }

  const getCanSave = (isEnterKeyPressed) =>
    isEnterKeyPressed && !(action === UPDATE ? UPDATE_ACTION : CREATE_ACTION)

  return (
    <>
      <span className={ style.overlay }></span>
      <PopupButtonGroup
        popupStyles={ popupStyles }
        isOpen={ isOpen }
        cancel={ CANCEL }
        isLoading={ isLoading || isCreatingFleet }
        confirm={ SAVE }
        isConfirmEnable={
          action === UPDATE
            ? checkUpdateButtonEnableState()
            : checkCreateButtonEnableState()
        }
        onCancel={ handleCancel }
        onSubmit={ () => handleSave(inputValue) }
        heading={
          action === UPDATE
            ? `Edit ${POPUP_TITLE[type]}`
            : CREATE_ACTION_TITLE[type]
        }
      >
        <span className={ style.inputContainer }>
          <OutlinedInput
            className={ classNames("overlay", error ? "error" : "noError") }
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={ true }
            inputRef={ inputRef }
            inputProps={ {
              "aria-label": "InputField",
              maxLength: EDIT_OVERLAY_CHAR_LENGTH[type] + 1
            } }
            sx={ { ...inputStyle } }
            value={ inputValue }
            onChange={ handleOnChange }
            onKeyUp={ () => handleSubmit(inputValue, type) }
            onKeyDown={ (e) =>
              e.key === COMPARE_VALUE.enter &&
              handleSubmit(e.target.value, type, true)
            }
          />
          <span className={ style.infoContainer }>
            { error?.error ? (
              <ErrorInfo message={ error.message } />
            ) : (
              EDIT_OVERLAY_GENERAL_INFO[type].split(".").join(".\n")
            ) }
          </span>
        </span>
      </PopupButtonGroup>
    </>
  )
}

CreateUpdateLabelPopup.propTypes = {
  dataId: PropTypes.string,
  handleNewFleetSubmitted: PropTypes.func,
  ignoreRefetch: PropTypes.bool,
  inputStyle: PropTypes.string,
  isClose: PropTypes.func,
  isEditPopupOpen: PropTypes.object,
  minCharacters: PropTypes.number,
  popupStyles: PropTypes.string,
  setIsEditPopupOpen: PropTypes.func
}

export default CreateUpdateLabelPopup
