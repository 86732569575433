import PropTypes from "prop-types"
import React, { forwardRef, useImperativeHandle, useRef } from "react"

import AssetCard from "../../../../components/AssetCard/AssetCard"
import DataTable from "../../../../components/DataTable/DataTable"
import { useSiteViewContext } from "../../../../contexts/siteView/SiteView"
import { CommonAuthElement } from "../../../../utils/Constants/Auth/common"
import { TABLE_INSTANCES } from "../../../../utils/Constants/DataTable"
import useAccess from "../../../../utils/CustomHooks/useAccess"
import style from "../../SiteView.module.scss"
import GroupByAssetsFleetColumnsData from "../internals/GroupByAssetsFleetColumnsData"

// eslint-disable-next-line react/display-name
const GroupByAsset = forwardRef(({ handleSelectAll, handleSelectRow, tableData }, ref) => {
  const hasSelectAccess = useAccess(CommonAuthElement.Any)
  const { siteViewState } = useSiteViewContext()
  const tableRef = useRef(null)

  useImperativeHandle(ref, () => ({
    resetSelection() {
      tableRef?.current?.toggleAll(false)
    }
  }))

  if (siteViewState?.isCardView) {
    return (
      <div className={ style.cardViewContainer }>
        { tableData?.map((asset) => (
          <div key={ asset.id }>
            <AssetCard
              isSelectable
              assetDetails={ asset }
              isSelected={ siteViewState?.selectedAssets.some(({ id }) => asset.id === id) }
              onChange={ handleSelectRow }
              status={ asset.status }
            />
          </div>
        )) }
      </div>
    )
  } else {
    return (
      <div className={ style.groupByAssetsTable }>
        <DataTable
          ref={ tableRef }
          displayRowColorBar
          rowHoverEffect
          highlightSelectedRows
          selectedRows={ siteViewState?.selectedAssets }
          selectableRows={ siteViewState?.contents?.length > 0 && hasSelectAccess }
          internalSelectAll
          tableData={ tableData }
          columnsData={ GroupByAssetsFleetColumnsData() }
          onSelectAllCallBack={ handleSelectAll }
          onSelectRowCallBack={ ({ checked, data }) => handleSelectRow(checked, data) }
          type={ TABLE_INSTANCES.ASSETS_NOT_IN_FLEET }
        />
      </div>
    )
  }
})

GroupByAsset.propTypes = {
  handleSelectAll: PropTypes.func,
  handleSelectRow: PropTypes.func,
  tableData: PropTypes.array
}

export default GroupByAsset
