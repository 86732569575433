import i18n from "i18next"

const UserGroupsColumnConfig = () => [
  {
    accessorKey: "name",
    defaultCanSort: true,
    header: i18n.t("userManagement:table.userGroups.headerLabels.name")
  },
  {
    accessorKey: "description",
    header: i18n.t("userManagement:table.userGroups.headerLabels.description")
  }
]

export default UserGroupsColumnConfig
