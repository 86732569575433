/* eslint-disable max-len */
import { EXPORT_CSV_TYPES, EXPORT_HEADERS } from "../../utils/Constants/ExportCSVConstants"

export const getExportConfig = ({ type, fileName, data, includeColumns, excludeColumns }) => {
  if (!fileName) fileName = ""
  const date = `${new Date().getMonth() < 10 ? "0" + (new Date().getMonth() + 1) : new Date().getMonth() + 1}${
    new Date().getDate() < 10 ? "0" + new Date().getDate() : new Date().getDate()
  }${new Date().getFullYear()}-${new Date().getHours() < 10 ? "0" + new Date().getHours() : new Date().getHours()}${
    new Date().getMinutes() < 10 ? "0" + new Date().getMinutes() : new Date().getMinutes()
  }${new Date().getSeconds() < 10 ? "0" + new Date().getSeconds() : new Date().getSeconds()}`

  let configObject = {}

  switch (type) {
  case EXPORT_CSV_TYPES.ASSET_PROPERTIES:
    configObject.headers = EXPORT_HEADERS.ASSET_PROPERTIES
    configObject.fileName = `${fileName}-${date}.csv`
    configObject.rows = data
    break
  case EXPORT_CSV_TYPES.ASSET_STATUS:
    configObject.headers = EXPORT_HEADERS.ASSET_STATUS
    configObject.fileName = `${fileName}-${date}.csv`
    configObject.rows = data
    break
  case EXPORT_CSV_TYPES.ASSET_FAULTS:
    configObject.headers = EXPORT_HEADERS.ASSET_FAULTS
    configObject.fileName = `${fileName}-${date}.csv`
    configObject.rows = data
    break
  case EXPORT_CSV_TYPES.CENTRAL_FAULTS:
    configObject.headers = EXPORT_HEADERS.CENTRAL_FAULTS
    configObject.fileName = `${fileName}-${date}.csv`
    configObject.rows = data
    break
  case EXPORT_CSV_TYPES.REPORTS_CONFIG:
    configObject.headers = EXPORT_HEADERS.REPORTS_CONFIG
    configObject.fileName = `${fileName}-${date}.csv`
    configObject.rows = data
    break
  case EXPORT_CSV_TYPES.REPORTS_SW:
    configObject.headers = EXPORT_HEADERS.REPORTS_SW
    configObject.fileName = `${fileName}-${date}.csv`
    configObject.rows = data
    break
  }

  if (includeColumns && includeColumns.length > 0) {
    configObject.headers = configObject.headers.filter((each) => includeColumns.includes(each.accessor))
  }

  if (excludeColumns && excludeColumns.length > 0) {
    configObject.headers = configObject.headers.filter((each) => !excludeColumns.includes(each.accessor))
  }

  return configObject
}
