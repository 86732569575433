import { Checkbox } from "@mui/material"
import { PropTypes } from "prop-types"
import React, { useId } from "react"

import style from "./CheckBoxLabel.module.scss"

import { CHECKBOX_STATE_ICONS } from "../../utils/GlobalConstants"
import { classNames } from "../../utils/styles/helper"
import Icon from "../Icon/Icon"

const CheckBoxLabel = ({ displayLabelText, label, iconStyles, labelStyles, onSelect, isChecked, ...rest }) => {
  const id = useId()

  return (
    <>
      <Checkbox
        aria-labelledby={ id }
        type="checkbox"
        checked={ isChecked == "checked" || (typeof isChecked == "boolean" && isChecked) }
        data-testid="checkboxClick"
        className={ classNames(iconStyles, style.customCheckbox) }
        onChange={ onSelect }
        onClick={ (e) => {
          e.stopPropagation()
        } }
        checkedIcon={
          <Icon
            iconStyles={ style.icons }
            icon={ rest.disabled ? CHECKBOX_STATE_ICONS.disableChecked : CHECKBOX_STATE_ICONS.checked }
          />
        }
        icon={ <Icon iconStyles={ style.icons } icon={ CHECKBOX_STATE_ICONS.unChecked } /> }
        indeterminateIcon={ <Icon iconStyles={ style.icons } icon={ CHECKBOX_STATE_ICONS.indeterminate } /> }
        indeterminate={ isChecked == "indeterminate" }
        inputProps={ { "aria-label": id } }
        size="small"
        { ...rest }
      />

      { displayLabelText && (
        <label id={ id } className={ labelStyles }>
          { label }
        </label>
      ) }
    </>
  )
}

CheckBoxLabel.propTypes = {
  displayLabelText: PropTypes.bool,
  iconStyles: PropTypes.any,
  isChecked: PropTypes.any,
  label: PropTypes.string,
  labelStyles: PropTypes.any,
  onSelect: PropTypes.func
}

CheckBoxLabel.defaultProps = {
  displayLabelText: true
}

export default CheckBoxLabel
