/* eslint-disable react-hooks/exhaustive-deps */
import IconButton from "@mui/material/IconButton"
import React, { useEffect, useRef } from "react"
import { useQueryClient } from "react-query"
import { useLocation, useParams } from "react-router-dom"

import styles from "./Breadcrumb.module.scss"
import BreadcrumbItems from "./internals/BreadcrumbItems"
import SiteNavigator from "./internals/SiteNavigator/SiteNavigator"

import { useApp } from "../../contexts/app/AppContext"
import { useBreadcrumbContext } from "../../contexts/breadcrumb/breadcrumb"
import { getBreadcrumb } from "../../services/reactQueries/breadcrumb"
import useBreadcrumbs from "../../utils/CustomHooks/useBreadcrumbs"
import { ICON_NAME } from "../../utils/GlobalConstants"
import { StorageKeys, StorageType, getSession } from "../../utils/SessionHelper/session"
import Icon from "../Icon/Icon"

const Breadcrumb = () => {
  const queryParams = useParams()
  const location = useLocation()
  const queryClient = useQueryClient()
  const navigatorBtnRef = useRef()
  const cardElRef = useRef()
  const breadcrumbs = useBreadcrumbs()

  const { setBreadcrumbData } = useBreadcrumbContext()

  const { setShowSiteNavigator, showSiteNavigator } = useApp()

  useEffect(() => {
    fetchAndSetBreadcrumbData()
  }, [])

  const fetchAndSetBreadcrumbData = async () => {
    let breadcrumbData = []
    try {
      const data = JSON.parse(getSession(StorageKeys.BREADCRUMB, StorageType.SESSION_STORAGE))
      if (data) setBreadcrumbData(data)
      breadcrumbData = await getBreadcrumbData(data)
    } catch (e) {
      breadcrumbData = await getBreadcrumbData(null)
    }
    setBreadcrumbData(breadcrumbData)
  }

  const getBreadcrumbData = async (sessionBreadcrumbs) => {
    return await queryClient.fetchQuery(["Breadcrumb"], () => getBreadcrumb(sessionBreadcrumbs, queryParams, location))
  }

  const handleClick = () => {
    setShowSiteNavigator(!showSiteNavigator)
  }

  const handleSiteNavigatorClose = (target) => {
    if (!navigatorBtnRef.current.contains(target)) {
      setShowSiteNavigator(false)
    }
  }

  return (
    <span className={ styles.Card } ref={ cardElRef }>
      <div className={ styles.navigatorBtn }>
        <IconButton
          disableRipple
          aria-label="Open Navigator"
          ref={ navigatorBtnRef }
          onClick={ handleClick }
          className={ showSiteNavigator ? styles.activeSiteNavigator : "" }
        >
          <Icon icon={ ICON_NAME.breadcrumb } iconStyles={ styles.BreadcrumbIcon } />
        </IconButton>
      </div>
      { breadcrumbs &&
        breadcrumbs.map((item, index) => (
          <BreadcrumbItems
            key={ item.id }
            name={ item.name }
            route={ item.route }
            disabled={ item.disabled }
            showSeparator={ true }
            isLastItem={ index === breadcrumbs.length - 1 }
            itemWidth={ cardElRef?.current?.offsetWidth ? cardElRef?.current?.offsetWidth / breadcrumbs?.length : 0 }
          />
        )) }
      { showSiteNavigator && <SiteNavigator breadcrumbs={ breadcrumbs } handleClose={ handleSiteNavigatorClose } /> }
    </span>
  )
}

export default Breadcrumb
