import React from "react"

import SiteViewContainer from "./SiteViewContainer"

import { SiteViewProvider } from "../../contexts/siteView/SiteView"

const SiteView = () => {
  return (
    <SiteViewProvider>
      <SiteViewContainer />
    </SiteViewProvider>
  )
}

export default SiteView
