/* eslint-disable no-case-declarations */
/* eslint-disable sort-keys */
import { fleetGetSVC } from "../../services/reactQueries/fleetsvc"
import { getApplicationRoutes } from "../../utils/Common/common"
import { ROUTE_CONFIG } from "../../utils/GlobalConstants"
import { StorageKeys, StorageType, getSession, setSession } from "../../utils/SessionHelper/session"

export const BREADCRUMB_ROUTE_ID = {
  CUSTOMER: ROUTE_CONFIG.CUSTOMER.param,
  SITE: ROUTE_CONFIG.SITE.param,
  SITE_MODALITY: ROUTE_CONFIG.SITE_MODALITY.param,
  SITE_ASSET_TYPE: ROUTE_CONFIG.SITE_ASSET_TYPE.param,
  SITE_CONNECTION: ROUTE_CONFIG.SITE_CONNECTION.param,
  FLEET: ROUTE_CONFIG.FLEET.param,
  FLEET_MODALITY: ROUTE_CONFIG.FLEET_MODALITY.param,
  FLEET_ASSET_TYPE: ROUTE_CONFIG.FLEET_ASSET_TYPE.param,
  FLEET_CONNECTION: ROUTE_CONFIG.FLEET_CONNECTION.param,
  SUB_FLEET: ROUTE_CONFIG.SUB_FLEET.param,
  ASSET: ROUTE_CONFIG.ASSET.param
}

export const BREADCRUMB_ROUTE = {
  CUSTOMER: "CUSTOMER",
  SITE: "SITE",
  SITE_MODALITY: "SITE_MODALITY",
  SITE_ASSET_TYPE: "SITE_ASSET_TYPE",
  SITE_CONNECTION: "SITE_CONNECTION",
  FLEET: "FLEET",
  FLEET_MODALITY: "FLEET_MODALITY",
  FLEET_ASSET_TYPE: "FLEET_ASSET_TYPE",
  FLEET_CONNECTION: "FLEET_CONNECTION",
  SUB_FLEET: "SUB_FLEET",
  ASSET: "ASSET"
}

export const getBreadcrumbDescription = (breadcrumbs, breadcrumbData, isCustomerDisabled) => {
  for (const item of breadcrumbs) {
    const idx = breadcrumbData?.findIndex(x => x.key === item.key)
    item.name = idx > -1 ? breadcrumbData[idx].name : ""
    item.disabled = false
    if (BREADCRUMB_ROUTE_ID.CUSTOMER === BREADCRUMB_ROUTE_ID[item.key]) {
      item.disabled = isCustomerDisabled
    }
  }
  return breadcrumbs
}

export const transformNavigatorData = (data, queryParams) => {
  const clonedData = JSON.parse(JSON.stringify(data ?? {}))
  return getLinkedNavData(clonedData, queryParams)
}

export const expandNavigatorData = (data, queryParams) => {
  const navData = JSON.parse(JSON.stringify(data ?? {}))
  const breadcrumbs = getApplicationRoutes(queryParams).reverse()
  const { siteIdx, fleetIdx, subFleetIdx } = getNavRouteDataIndex(navData, queryParams)

  for (const breadcrumb of breadcrumbs) {
    if (breadcrumb.key === BREADCRUMB_ROUTE.SITE && siteIdx > -1) {
      navData.sites[siteIdx].active = true
      navData.sites[siteIdx].isExpanded = true
      break
    } else if (breadcrumb.key === BREADCRUMB_ROUTE.FLEET && siteIdx > -1 && fleetIdx > -1) {
      navData.sites[siteIdx].childActive = true
      navData.sites[siteIdx].fleets[fleetIdx].active = true
      navData.sites[siteIdx].isExpanded = true
      navData.sites[siteIdx].fleets[fleetIdx].isExpanded = true
      break
    } else if (breadcrumb.key === BREADCRUMB_ROUTE.SUB_FLEET && siteIdx > -1 && fleetIdx > -1 && subFleetIdx > -1) {
      navData.sites[siteIdx].childActive = true
      navData.sites[siteIdx].fleets[fleetIdx].childActive = true
      navData.sites[siteIdx].fleets[fleetIdx].subFleets[subFleetIdx].active = true
      navData.sites[siteIdx].isExpanded = true
      navData.sites[siteIdx].fleets[fleetIdx].isExpanded = true
      navData.sites[siteIdx].fleets[fleetIdx].subFleets[subFleetIdx].isExpanded = true
      break
    }
  }
  return navData
}

export const getNavRouteDataIndex = (data, queryParams) => {
  let siteIdx = -1
  let fleetIdx = -1
  let subFleetIdx = -1
  siteIdx = data?.sites?.findIndex(site => site.id === queryParams[ROUTE_CONFIG.SITE.param])
  if (siteIdx > -1) {
    fleetIdx = data?.sites[siteIdx]?.fleets?.findIndex(fleet =>
      fleet.id === queryParams[ROUTE_CONFIG.FLEET.param]
    )
    if (fleetIdx > -1) {
      subFleetIdx = data?.sites[siteIdx]?.fleets[fleetIdx]?.subFleets?.findIndex(subFleet =>
        subFleet.id === queryParams[ROUTE_CONFIG.SUB_FLEET.param]
      )
    }
  }
  return {
    siteIdx,
    fleetIdx,
    subFleetIdx
  }
}

export const getLinkedNavData = (data, queryParams) => {
  if (data?.sites) {
    for (const site of data.sites) {
      const siteRoute = ROUTE_CONFIG.CUSTOMER.routeName + "/" + queryParams[ROUTE_CONFIG.CUSTOMER.param] + 
                        "/" + ROUTE_CONFIG.SITE.routeName + "/" + site.id
      site["route"] = siteRoute
      for (const fleet of site.fleets) {
        const fleetRoute = siteRoute + "/" + ROUTE_CONFIG.FLEET.routeName + "/" + fleet.id
        fleet["route"] = fleetRoute
        for (const subFleet of fleet.subFleets) {
          const subFleetRoute = fleetRoute + "/" + ROUTE_CONFIG.SUB_FLEET.routeName + "/" + subFleet.id
          subFleet["route"] = subFleetRoute
        }
      }
    }
  }
  return data
}

export const BREADCRUMB_ACTION_TYPES = {
  SET_BREADCRUMB: "SET_BREADCRUMB",
  UPDATE_BREADCRUMB: "UPDATE_BREADCRUMB",
  UPDATE_CUSTOMER_ACCESS: "UPDATE_CUSTOMER_ACCESS"
}

export const breadcrumbReducer = (newState, action) => {
  switch (action.type) {
  case BREADCRUMB_ACTION_TYPES.SET_BREADCRUMB:
    const setterData = [...action.payload]
    for (const breadcrumb of newState.breadcrumbData) {
      const setIdx = setterData?.findIndex(item => item.key === breadcrumb.key)
      if (setIdx === -1) {
        setterData.push({ id: breadcrumb.id, key: breadcrumb.key, name: breadcrumb.name })
      }
    }
    setSession(StorageKeys.BREADCRUMB, JSON.stringify(setterData), StorageType.SESSION_STORAGE)
    return {
      ...newState,
      ...{
        breadcrumbData: setterData
      }
    }
  case BREADCRUMB_ACTION_TYPES.UPDATE_BREADCRUMB:
    const updateData = [...newState.breadcrumbData]
    const idx = updateData?.findIndex(item => item.key === action.payload.key)
    if (idx > -1) {
      updateData[idx].id = action.payload.id ?? ""
      updateData[idx].name = action.payload.name
    } else {
      updateData.push({ id: action.payload.id ?? "", key: action.payload.key, name: action.payload.name })
    }
    setSession(StorageKeys.BREADCRUMB, JSON.stringify(updateData), StorageType.SESSION_STORAGE)
    return {
      ...newState,
      ...{
        breadcrumbData: updateData
      }
    }
  case BREADCRUMB_ACTION_TYPES.UPDATE_CUSTOMER_ACCESS:
    return {
      ...newState,
      ...{
        isCustomerDisabled: action.payload
      }
    }
    /* istanbul ignore next */
  default:
    return newState
  }
}

export const getAndUpdateAssetBreadcrumb = async (queryClient, assetId, updateBreadcrumbData) => {
  if (assetId) {
    const data = getSession(StorageKeys.BREADCRUMB, StorageType.SESSION_STORAGE)
    if (data) {
      const breadcrumbData = JSON.parse(data)
      const breadcrumbAssetId = breadcrumbData.find(item => item.key === BREADCRUMB_ROUTE.ASSET)?.id
      if (assetId !== breadcrumbAssetId) {
        const { data } = await queryClient.fetchQuery(
          ["deviceDetails-byDeviceID", assetId],
          () => fleetGetSVC.getAssetDetailsByAssetId(assetId)
        )
        updateBreadcrumbData({
          id: data?.id,
          key: BREADCRUMB_ROUTE.ASSET,
          name: data?.friendlyName
        })
      }
    }
  }
}
