import React from "react"

import { BREADCRUMB_ROUTE } from "../../../../components/Breadcrumb/BreadcrumbUtils"
import FleetExpandedCard from "../../../../components/FleetExpandedCard/FleetExpandedCard"
import PageLink from "../../../../components/PageLink/PageLink"
import { useSiteViewContext } from "../../../../contexts/siteView/SiteView"
import { ROUTE_CONFIG } from "../../../../utils/GlobalConstants"
import cssStyles from "../FleetsSummary.module.scss"

const GroupByConnection = () => {
  const currentURL = window.location.pathname
  const { siteViewState } = useSiteViewContext()

  const connections = siteViewState?.contents

  return (
    <div className={ cssStyles.groupByCards }>
      { connections?.map((connection) => {
        return (
          <PageLink
            key={ connection?.[siteViewState.groupByMappingObject.titleKey] }
            className={ cssStyles.removeLinkStyles }
            to={ currentURL.concat(
              `/${ROUTE_CONFIG.SITE_CONNECTION.routeName}/${connection?.[siteViewState.groupByMappingObject.titleKey]}`
            ) }
            type={ BREADCRUMB_ROUTE.SITE_CONNECTION }
            pageName={ connection?.[siteViewState.groupByMappingObject.titleKey] }
          >
            <FleetExpandedCard
              titleKey={ siteViewState.groupByMappingObject.titleKey }
              fleetDetails={ connection }
              isConfirmPopUp={ siteViewState?.confirmPopup }
              showCardAction={ false }
            />
          </PageLink>
        )
      }) }
    </div>
  )
}

export default GroupByConnection
