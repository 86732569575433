/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import HeadSection from "./internals/HeadSection"
import styles from "./Status.module.scss"
import { columns } from "./StatusTableColumnConfig.js"

import DataTable from "../../../components/DataTable/DataTable"
import Error from "../../../components/Error/Error"
import Loading from "../../../components/Loading/Loading"
import { useAssetViewContext } from "../../../contexts/assetView/assetView.js"
import { telemetrySVC } from "../../../services/reactQueries/telemetrysvc.js"
import { TABLE_INSTANCES } from "../../../utils/Constants/DataTable"
import { useLazyQuery } from "../../../utils/CustomHooks/reactQuery.jsx"
import useCheckBoxSelection from "../../../utils/CustomHooks/useCheckBoxSelection"
import { SEARCH_DEFAULT_MIN_CHARACTERS } from "../../../utils/GlobalConstants.js"
import { transformAssetStatus } from "../AssetUtils"

const Status = () => {
  const {
    assetDetails: { muted, serialNumber, name }
  } = useAssetViewContext()

  const { t } = useTranslation(["asset"])
  const tableRef = useRef(null)
  const searchBarRef = useRef(null)

  const exportRef = useRef(null)
  const [exportClicked, setExportClicked] = useState(false)
  const [sortingObject, setSortingObject] = useState()

  const queryKey = ["assetStatusDetails", serialNumber]
  const [searchInput, setSearchInput] = useState(null)
  const [searchClose, setSearchClose] = useState(false)
  const [searchError, setSearchError] = useState(false)
  const [searchResult, setSearchResult] = useState(null)

  const onDataLoadCompleted = (data) => {
    if (searchInput) {
      const searchData = data?.data?.metrics
      setSearchResult(searchData)
    }
  }

  const [getStatuses, { isLoading, data: statusData, isError, refetch }] = useLazyQuery(
    queryKey,
    () => telemetrySVC.getAssetStatusDetails(serialNumber, searchInput, sortingObject),
    { onSuccess: onDataLoadCompleted }
  )

  const assetStatus = statusData?.data.metrics || []

  const assetStatusProperties = useCallback(transformAssetStatus(assetStatus), [assetStatus])

  useEffect(() => {
    getStatuses()
  }, [])

  useEffect(() => {
    if (searchInput?.length >= SEARCH_DEFAULT_MIN_CHARACTERS || searchClose) {
      resetTable()
      getStatuses()
    }
  }, [searchInput, searchClose])

  const {
    selectedRows: selectedStatusProperties,
    handleSelectRow,
    handleSelectAll,
    resetSelection
  } = useCheckBoxSelection({
    data: assetStatusProperties,
    uniqueKey: "id"
  })

  const handleRefresh = () => {
    if (searchInput || searchError) {
      resetSearch()
    } else {
      refetch()
    }
    resetTable()
  }

  const resetTable = () => {
    tableRef?.current?.toggleAll(false)
    resetSelection()
  }

  const resetSearch = () => {
    setSearchInput(null)
    setSearchResult(null)
    setSearchError(false)
    setSearchClose(true)
    searchBarRef.current?.reset()
  }

  const handleSearch = (value, closeAction, hasError) => {
    setSearchClose(closeAction)
    setSearchInput(value)
    if ((closeAction && !hasError) || (!closeAction && value.length >= SEARCH_DEFAULT_MIN_CHARACTERS)) {
      setSearchResult(null)
      setSearchError(false)
    } else {
      setSearchError(true)
      setSearchResult(null)
    }
  }

  const handleSortChange = (sortObject, sortedRows) => {
    if (sortObject) setSortingObject(sortObject)
    if (sortObject && exportClicked) {
      if (selectedStatusProperties.length === 0) {
        exportRef.current.downloadNewData(sortedRows.flatRows.map((each) => each.original))
      }
      setExportClicked(false, {})
    }
  }

  const onExportClick = () => {
    if (selectedStatusProperties.length === 0) {
      setExportClicked(true)
      refetch()
    } else {
      exportRef.current.downloadNewData(selectedStatusProperties)
    }
  }

  if (isLoading)
    return (
      <div className={ styles.loader }>
        <Loading />
      </div>
    )
  if (isError)
    return (
      <Error
        error={ {
          message: searchInput ? t("statusApiError.searchErrorMessage") : t("statusApiError.errorMessage")
        } }
      />
    )

  return (
    <>
      <div className={ styles.topSection }>
        <HeadSection
          t={ t }
          deviceName={ name }
          assetStatusProperties={
            (searchInput && searchInput?.length < SEARCH_DEFAULT_MIN_CHARACTERS) || searchError
              ? []
              : assetStatusProperties
          }
          selectedStatusProperties={ selectedStatusProperties }
          handleRefresh={ handleRefresh }
          handleSearch={ handleSearch }
          searchError={ searchError }
          searchResult={ searchResult }
          searchInput={ searchInput }
          searchBarRef={ searchBarRef }
          muted={ muted }
          getStatuses={ getStatuses }
          allStatusProperties={ assetStatusProperties }
          onExportClick={ onExportClick }
          exportRef={ exportRef }
        />
      </div>
      <div className={ styles.statusTable }>
        <DataTable
          disableCellTooltip
          selectableRows
          internalSelectAll
          columnsData={ columns(muted) }
          tableData={
            (searchInput && searchInput?.length < SEARCH_DEFAULT_MIN_CHARACTERS) || searchError
              ? []
              : assetStatusProperties
          }
          selectedRows={ selectedStatusProperties }
          onSelectRowCallBack={ ({ checked, data }) => handleSelectRow(checked, data) }
          onSelectAllCallBack={ handleSelectAll }
          type={ TABLE_INSTANCES.ASSET_STATUS }
          customNoRowsMessage={ !searchInput && !searchError ? t("status.noRows.initialLoad") : "" }
          queryKey={ queryKey }
          highlightSelectedRows
          rowHoverEffect
          ref={ tableRef }
          onSortingChange={ handleSortChange }
        />
      </div>
    </>
  )
}

export default Status
