import { useParams } from "react-router-dom"

import {
  getBreadcrumbDescription
} from "../../components/Breadcrumb/BreadcrumbUtils"
import { useBreadcrumbContext } from "../../contexts/breadcrumb/breadcrumb"
import { getApplicationRoutes } from "../Common/common"

const useBreadcrumbs = () => {
  const { breadcrumbData, isCustomerDisabled } = useBreadcrumbContext()
  const queryParams = useParams()
  const breadcrumbs = getApplicationRoutes(queryParams)
  return getBreadcrumbDescription(breadcrumbs, breadcrumbData, isCustomerDisabled)
}

export default useBreadcrumbs
