import { decodeURIString } from "../../../utils/Common/common"

export const transformAssetFaults = (assetFaults) => {
  return assetFaults.map((eachFault) => {
    eachFault.name = eachFault.id
    eachFault.detectedOnForExport = eachFault.detectedOn
    eachFault.isAcknowledge = eachFault.acknowledged
    eachFault.notes = decodeURIString(eachFault.latestNote)
    eachFault.latestNote = decodeURIString(eachFault.latestNote)
    eachFault.updatedOn = eachFault.lastUpdatedOn
    return eachFault
  })
}

export const intialState = {
  faults: [],
  isOpen: false,
  message: "",
  type: ""
}
