import { DATE_FORMAT } from "../../utils/Constants/TimeZones"
import { COMPARE_VALUE } from "../../utils/GlobalConstants"
import { arrayGroupBy, convertToServerTimeZone, formatStatus } from "../../utils/helper"
import axiosClient from "../axios/axios"

const PAGE_SIZE = 10000

/* eslint-disable no-unused-vars */
export const softwareSVC = {
  cancelSoftwareDeployment: ({ serialNumber, packageId }) => {
    return axiosClient.delete(
      `/firmwaresvc/v1/assets/${serialNumber}/packages/${packageId}`
    )
  },
  deploySWPackageToAsset: ({ packageId, requestPayload }) => {
    return axiosClient.post(`/firmwaresvc/v1/packages/${packageId}/deploy`, requestPayload)
  },
  getSWPackageReportsBySiteId: ({
    siteId,
    reportsDateRange,
    modality,
    model,
    modalityOptions,
    modelOptions,
    searchInput
  }) => {
    const startDate = convertToServerTimeZone(reportsDateRange.startDate, DATE_FORMAT.date)
    const endDate = convertToServerTimeZone(reportsDateRange.endDate, DATE_FORMAT.date)
    const url = `/firmwaresvc/v1/sites/${siteId}/packageDistribution-report`
    const date = `?startDate=${startDate}&endDate=${endDate}&size=${PAGE_SIZE}&page=0`
    let query = `${searchInput ? "&searchString=" + searchInput : ""}`
    if (modality) {
      query = query + `&modality=${modalityOptions[modality - 1]}`
    }
    if (model?.length > 0) {
      let modelList = ""
      for (const _model of model) {
        modelList = modelList + (modelList ? "," : "") + modelOptions[_model - 1]
      }
      query = query + `&model=${modelList}`
    }
    return axiosClient.get(url + date + query)
  },
  getSoftwareDeploymentAssets: (siteId, packageDetails, filter) => {
    const modality = filter?.find(item => item.type === COMPARE_VALUE.modality)?.label
    const model = filter?.filter(item => item.type === COMPARE_VALUE.model)?.map(item => item.label?.trim())
    const softwareVersions = filter?.filter(item => item.type === COMPARE_VALUE.swVersion)?.map(item => item.label)
    const softwareStatus = filter?.filter(item => item.type === COMPARE_VALUE.swUpdateStatus)?.map(item => {
      return API_STATUS_MAP[formatStatus(item?.label)]
    })
    const requestPayload = {
      metadata: packageDetails?.packageCompatibilityMetaData,
      modality,
      model,
      siteId,
      swStatus: softwareStatus?.length > 0 ? softwareStatus : undefined,
      swVersion: softwareVersions?.length > 0 ? softwareVersions : undefined
    }
    return axiosClient.post("/fleetsvc/v1/packages/getDeviceTargetList", requestPayload)
  },
  getSoftwarePackagesByAssetSerialNumber: (assetSerialNumber, startDate, endDate) => {
    return axiosClient.get(
      `/firmwaresvc/v1/assets/${assetSerialNumber}/packageDetails?startDate=${startDate}&endDate=${endDate}`
    )
  },
  getSoftwarePackagesByCustomerId: (customerId, dateRange, filters) => {
    const url = `/firmwaresvc/v1/sites/${customerId}/packageDetails?page=1&size=100`
    let query = `&startDate=${convertToServerTimeZone(
      dateRange.startDate,
      DATE_FORMAT.date
    )}&endDate=${convertToServerTimeZone(dateRange.endDate, DATE_FORMAT.date)}`
    if (filters?.length > 0) {
      Object.entries(arrayGroupBy(filters, "type")).forEach((eachFilter) => {
        if (eachFilter[0] !== COMPARE_VALUE.modality) {
          query += `&${eachFilter[0]}=${eachFilter[1].map((each) => each.label).join(",")}`
        }
      })
    }
    return axiosClient.get(url + query)
  },
  getSoftwareStatistics: (siteUCMId) => {
    return axiosClient.get(`/firmwaresvc/v1/sites/${siteUCMId}/updateStatistics`)
  },
  reScheduleSoftwareDeployment: ({ serialNumber, packageId, requestPayload }) => {
    return axiosClient.patch(
      `/firmwaresvc/v1/assets/${serialNumber}/packages/${packageId}`, requestPayload
    )
  },
  scheduleDeploySWPackageToAsset: ({ packageId, requestPayload }) => {
    return axiosClient.post(`/firmwaresvc/v1/packages/${packageId}/schedule`, requestPayload)
  }
}

export const softwareSVCKeys = {
  GET_ALL_SOFTWARE_LIST_BY_SITE_ID: "getSoftwareBySiteId",
  GET_CENTRAL_SOFTWARE_STATISTICS: "getCentralSoftwareStatistics",
  GET_SOFTWARE_DEPLOYMENT_ASSETS: "getSoftwareDeploymentAssets",
  GET_SOFTWARE_PACKAGES_BY_SERIAL_NUMBER: "getSoftwarePackgesByAssetSerialNumber",
  GET_SOFTWARE_PACKAGE_BY_ID: "getSoftwarePackageDetailsById"
}

const API_STATUS_MAP = {
  CANCELLED: "canceled",
  FAILED: "failed",
  INPROGRESS: "inProgress",
  PAUSED: "paused",
  REJECTED: "rejected",
  SCHEDULED: "scheduled",
  SUCCESS: "installed"
}
