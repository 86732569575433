import colors from "../../styles/jsExports/colorExport.module.scss"
import fonts from "../../styles/jsExports/fontExport.module.scss"
import margins from "../../styles/jsExports/marginExport.module.scss"

export const Tooltip = {
  styleOverrides: {
    tooltip: {
      "& .MuiTooltip-arrow": {
        "&::before": {
          border: `${margins["marginInPixel-px1"]} solid ${colors["input-border"]}`,
          boxShadow: " 0px 1px 5px 0px #1E1F2052"
        },
        color: colors["tooltip"],
        padding: `0 ${margins["marginInPixel-px16"]} 0 ${margins["marginInPixel-px16"]}`,
        width: margins["marginInPixel-px46"]
      },
      "&.MuiTooltip-tooltip": {
        "&.MuiTooltip-tooltipPlacementBottom": {
          marginTop: margins["marginInPixel-px8"]
        },
        "&.MuiTooltip-tooltipPlacementLeft": {
          marginRight: 0,
          marginTop: margins["marginInPixel-px66"]
        }
      },
      "&.assetNameTooltip": {
        "&.MuiTooltip-tooltipPlacementBottom": {
          marginTop: margins["marginInPixel-px13"]
        },
        width: margins["marginInPixel-px280"]
      },
      "&.assetNameTooltipLabel": {
        "& .MuiTooltip-arrow": {
          left: "8% !important",
          transform: "translate(-50%, 0) !important"
        },
        width: margins["marginInPixel-px280"]
      },
      "&.configActionTooltip": {
        "&.MuiTooltip-tooltipPlacementBottom": {
          marginTop: margins["marginInPixel-px9"]
        }
      },
      "&.editTooltip": {
        "& .MuiTooltip-arrow": {
          transform: "translate(-3px, 0px) !important"
        }
      },
      "&.searchPlaceholderTooltip": {
        "& .MuiTooltip-arrow": {
          left: "50% !important",
          transform: "translate(-50%, 0) !important"
        },
        "&.MuiTooltip-tooltipPlacementBottom": {
          marginTop: margins["marginInPixel-px4"]
        },
        maxWidth: margins["marginInPixel-px205"]
      },
      backgroundColor: colors["tooltip"],
      border: `1px solid ${colors["input-border"]}`,
      borderRadius: margins["marginInPixel-px4"],
      boxShadow: "0px 1px 5px 0px #1E1F2052",
      color: colors["textValue"],
      fontSize: margins["marginInPixel-px14"],
      fontWeight: fonts["weight-normal"],
      gap: margins["marginInPixel-px4"],
      justifyContent: "center",
      lineHeight: margins["marginInPixel-px20"],
      maxWidth: margins["marginInPixel-px376"],
      transform: "matrix(1, 0, 0, -1, 0, 0)"
    }
  }
}
