import React from "react"

import AssetViewContainer from "./AssetViewContainer"

import { AssetViewProvider } from "../../contexts/assetView/assetView"
const AssetView = () => {
  return (
    <AssetViewProvider>
      <AssetViewContainer />
    </AssetViewProvider>
  )
}

export default AssetView
