import PropTypes from "prop-types"
import React from "react"

import { DataTableProvider } from "./NestedDataTableContext"

import DataTable from "../DataTable/DataTable"

const NestedDataTable = ({ parentRef, parentRefId, ...props }) => {
  const handleRef = () => {
    if (parentRef)
      return {
        ref: (el) => (parentRef.current[parentRefId] = el)
      }
  }

  return (
    <DataTableProvider dataLength={ props?.tableData?.length }>
      <DataTable nestedRows { ...handleRef() } { ...props } />
    </DataTableProvider>
  )
}

NestedDataTable.propTypes = {
  parentRef: PropTypes.any,
  parentRefId: PropTypes.any,
  tableData: PropTypes.array
}

export default NestedDataTable
