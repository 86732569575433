import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

import Icon from "../../../components/Icon/Icon"
import { FEATURES_DATA } from "../../../utils/Constants/Login"
import styles from "../Login.module.scss"

const Features = ({ id }) => {
  const { t } = useTranslation(["login"])
  return (
    <div id={ id } className={ styles.featuresCont }>
      <p className={ styles.featuresTitle }>{ t("fleetManager.features") }</p>

      <div className={ styles.featuresGridSection }>
        { FEATURES_DATA.map((getStart) => (
          <div key={ getStart.id } className={ styles.featuresGrid }>
            <div className={ styles.subTitleIcon }><Icon icon={ getStart.icon }></Icon></div>
            <div className={ styles.subTitle }>{ getStart.name }</div>
            <div className={ styles.desc }>{ getStart.desc }</div>
          </div>
        )) }
      </div>
    </div>
  )
}

Features.propTypes = {
  id: PropTypes.string
}

export default Features
