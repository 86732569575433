import { Button } from "@mui/material"
import PropTypes from "prop-types"
import React from "react"

import style from "./PopupButtonGroup.module.scss"

import { ICON_NAME } from "../../utils/GlobalConstants"
import { classNames } from "../../utils/styles/helper"
import Icon from "../Icon/Icon"
import Loading from "../Loading/Loading"

function PopupButtonGroup({
  isConfirmEnable,
  isCancelDisabled,
  children,
  heading,
  childrenStyle,
  disableReset,
  onSubmit,
  onCancel,
  onReset,
  popupStyles,
  confirm,
  resetButton,
  cancel,
  reset,
  isLoading,
  isContentLoading,
  customLoadingStyle,
  isSubmit
}) {
  return (
    <span
      className={ classNames(style.popupContent, popupStyles) }
      data-testid="cancelIcon"
    >
      { isContentLoading ? 
        <span className={ classNames(style.contentLoading, customLoadingStyle) }>
          <Loading />
        </span>
        : (
          <>
            { heading && <h2 className={ style.popupHeading }>{ heading }</h2> }
            <span className={ classNames(style.children, childrenStyle) }>
              { children }
            </span>
            { resetButton || isSubmit || cancel ? (
              <span className={ style.popupButton }>
                <span className={ style.leftAlignButton }>
                  { resetButton && (
                    <Button
                      disabled={ disableReset }
                      variant="text"
                      size="large"
                      onClick={ () => onReset?.(false) }
                      data-testid="resetButton"
                    >
                      { reset }
                    </Button>
                  ) }
                </span>
                <span className={ style.rightAlignButton }>
                  { cancel && (
                    <Button
                      disabled={ isCancelDisabled }
                      variant="outlined"
                      size="large"
                      onClick={ () => onCancel?.(false) }
                      data-testid="cancelButton"
                    >
                      { cancel }
                    </Button>
                  ) }
                  { isSubmit && (
                    <span className={ style.buttonContainer }>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={ () => onSubmit?.(true) }
                        data-testid="confirmButton"
                        disabled={ isConfirmEnable }
                        className="OverlayConfirmButton"
                      >
                        { isLoading ? (
                          <Icon
                            icon={ ICON_NAME.btnLoader }
                            iconStyles={ style.loadingIcon }
                          />
                        ) : (
                          confirm
                        ) }
                      </Button>
                    </span>
                  ) }
                </span>
              </span>
            ) : null }
          </>
        )
      }
    </span>
  )
}

PopupButtonGroup.defaultProps = {
  isCancelDisabled: false,
  isConfirmEnable: false,
  isSubmit: true
}

PopupButtonGroup.propTypes = {
  cancel: PropTypes.string,
  children: PropTypes.node,
  childrenStyle: PropTypes.any,
  confirm: PropTypes.string,
  customLoadingStyle: PropTypes.any,
  disableReset: PropTypes.bool,
  heading: PropTypes.string,
  isCancelDisabled: PropTypes.bool,
  isConfirmEnable: PropTypes.bool,
  isContentLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  isSubmit: PropTypes.bool,
  onCancel: PropTypes.func,
  onReset: PropTypes.func,
  onSubmit: PropTypes.func,
  popupStyles: PropTypes.any,
  reset: PropTypes.string,
  resetButton: PropTypes.bool
}

export default PopupButtonGroup
