import React from "react"

import UserGroupsColumnConfig from "./tableConfigurations/UserGroupsColumnConfig"

import DataTable from "../../../components/DataTable/DataTable"
import LabelDataCountAccordion from "../../../components/LabelDataCountAccordion/LabelDataCountAccordion"
import Loading from "../../../components/Loading/Loading"
import SearchErrorPage from "../../../components/SearchErrorPage/SearchErrorPage"
import { useUserManagementContext } from "../../../contexts/userManagement/UserManagement"
import { TABLE_INSTANCES } from "../../../utils/Constants/DataTable"
import { ICON_NAME, SEARCH_DEFAULT_MIN_CHARACTERS } from "../../../utils/GlobalConstants"
import styles from "../UserManagement.module.scss"
import { CURRENTLY_SELECTED_TYPES } from "../UserManagementUtils"

const UserGroups = () => {
  const { userManagementState, userGroupsData, handleClickUserGroup, t } = useUserManagementContext()

  let searchError =
    (typeof userManagementState.searchString == "string" &&
      userManagementState.searchString?.length < SEARCH_DEFAULT_MIN_CHARACTERS) ||
    (userManagementState.searchString?.length >= SEARCH_DEFAULT_MIN_CHARACTERS && 
      (userGroupsData?.groups || []).length == 0)
  return (
    <div>
      <LabelDataCountAccordion
        id="userGroups-accordion"
        label={ t("userGroups") }
        itemCount={ searchError ? 0 : userGroupsData?.groups?.length || 0 }
        icon={ ICON_NAME.userGroups }
        customClass={ styles.accordionCustomClass }
      >
        { userGroupsData?.isUserGroupsFetched ? (
          <div className={ styles.userGroupTable }>
            { searchError ? (
              <div className={ styles.searchErrorContainer }>
                <SearchErrorPage />
              </div>
            ) : (
              <DataTable
                showActiveRow={ [CURRENTLY_SELECTED_TYPES.GROUP].includes(userManagementState?.currentlySelectedType) }
                tableData={ userGroupsData?.groups || [] }
                columnsData={ UserGroupsColumnConfig() }
                disableCellTooltip
                onRowClickCallback={ (row) => handleClickUserGroup(row) }
                type={ TABLE_INSTANCES.USER_MANAGEMENT_NO_USER_GROUP }
                rowHoverEffect
              />
            ) }
          </div>
        ) : (
          <Loading />
        ) }
      </LabelDataCountAccordion>
    </div>
  )
}

export default UserGroups
