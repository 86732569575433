import { SideNavAuthElement } from "../../utils/Constants/Auth/side-navigation"
import { ROUTE_CONFIG } from "../../utils/GlobalConstants"

export const sideNavigationMenu = [
  {
    authType: SideNavAuthElement.ReadCentralAssetPage,
    icon: "/assets/icons/navigation/asset.svg",
    id: 1,
    routePath: `/${ROUTE_CONFIG.CUSTOMER.routeName}`,
    title: "Assets",
    translationKey: "assets"
  },
  {
    authType: SideNavAuthElement.ReadCentalFaults,
    disabledIcon: "/assets/icons/navigation/alertDisabled.svg",
    icon: "/assets/icons/navigation/alert.svg",
    id: 2,
    routePath: `/${ROUTE_CONFIG.ASSET_FAULTS.routeName}`,
    title: "Asset faults",
    translationKey: "assetFaults"
  },
  {
    authType: SideNavAuthElement.ReadCentalConfig,
    disabledIcon: "/assets/icons/navigation/configurationDisabled.svg",
    icon: "/assets/icons/navigation/configuration.svg",
    id: 3,
    routePath: `/${ROUTE_CONFIG.CENTRAL_CONFIG.routeName}`,
    title: "Configurations",
    translationKey: "configurations"
  },
  {
    authType: SideNavAuthElement.ReadCentralSWDist,
    disabledIcon: "/assets/icons/navigation/swUpdateDisabled.svg",
    icon: "/assets/icons/navigation/swUpdate.svg",
    id: 4,
    routePath: `/${ROUTE_CONFIG.SOFTWARE_UPDATE.routeName}`,
    title: "SW packages",
    translationKey: "swUpdate"
  },
  {
    authType: SideNavAuthElement.ReadCentralReport,
    disabledIcon: "/assets/icons/navigation/reportDisabled.svg",
    icon: "/assets/icons/navigation/report.svg",
    id: 5,
    routePath: `/${ROUTE_CONFIG.REPORTS.routeName}`,
    title: "Reports",
    translationKey: "reports"
  }
]
