/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import AssetSummary from "./AssetSummary/AssetSummary"
import styles from "./FleetGroupAssetView.module.scss"

import { BREADCRUMB_ROUTE } from "../../../components/Breadcrumb/BreadcrumbUtils"
import ConfirmMoveAsset from "../../../components/ConfirmMoveAsset/ConfirmMoveAsset"
import Error from "../../../components/Error/Error"
import Loading from "../../../components/Loading/Loading"
import MoveFleet from "../../../components/MoveFleet/MoveFleet"
import { useBreadcrumbContext } from "../../../contexts/breadcrumb/breadcrumb"
import { fleetGetSVC, fleetSVCKeys } from "../../../services/reactQueries/fleetsvc"
import { FleetGroupByFilterData } from "../../../utils/Constants/FilterData"
import { QUERY_KEYS } from "../../../utils/Constants/Queries"
import { useLazyQuery, useQuery } from "../../../utils/CustomHooks/reactQuery"
import { COMPARE_VALUE, ROUTE_CONFIG, SEARCH_DEFAULT_MIN_CHARACTERS } from "../../../utils/GlobalConstants"
import { transformAssets, transformFleetStatistics } from "../FleetUtils"
import SubFleetSummaryStatistics from "../SubFleetSummaryStatistics/SubFleetSummaryStatistics"

const FleetGroupAssetView = () => {
  const { t } = useTranslation(["fleet"])
  const queryParamIds = useParams()
  const navigate = useNavigate()
  const { updateBreadcrumbData } = useBreadcrumbContext()
  const assetsSummaryRef = useRef(null)
  const [filterConfigData, setFilterConfigData] = useState(FleetGroupByFilterData)
  const [groupOption, setGroupByOption] = useState(7)
  const [movePopup, setMovePopup] = useState(false)
  const [selectedAsset, setSelectedAsset] = useState([])
  const [searchInput, setSearchInput] = useState(null)
  const [searchError, setSearchError] = useState(false)
  const [searchClose, setSearchClose] = useState(false)
  const [searchResult, setSearchResult] = useState(null)
  const [selectedFilters, setSelectedFilters] = useState([])
  const [filterAPIError, setFilterAPIError] = useState(false)
  const [confirmPopUp, setConfirmPopUp] = useState({
    heading: "",
    isOpen: false,
    message: "",
    onCancel: false,
    onSubmit: false,
    type: "",
    warning: ""
  })

  const siteId = queryParamIds[ROUTE_CONFIG.SITE.param]
  const fleetId = queryParamIds[ROUTE_CONFIG.FLEET.param]
  const modality = queryParamIds[ROUTE_CONFIG.FLEET_MODALITY.param]
  const model = queryParamIds[ROUTE_CONFIG.FLEET_ASSET_TYPE.param]
  const connectionType = queryParamIds[ROUTE_CONFIG.FLEET_CONNECTION.param]
  const assetsQueryKey = [QUERY_KEYS.FLEET_GROUP_BY_PAGE, fleetId]

  useEffect(() => {
    const filterData = [...filterConfigData]
    const modIdx = filterData.findIndex((item) => item.key === COMPARE_VALUE.modality)
    const modelIdx = filterData.findIndex((item) => item.key === COMPARE_VALUE.model)
    if (modIdx > -1) filterData[modIdx].disabled = !!modality || !!model
    if (modelIdx > -1) filterData[modelIdx].disabled = !!model
    setFilterConfigData(filterData)
  }, [modality, model])

  const onDataLoadCompleted = (_data) => {
    if (searchInput) {
      if (_data?.data?.assets?.length === 1) {
        const id = _data?.data?.assets?.[0]?.id
        const path = window.location.pathname.concat(`/${ROUTE_CONFIG.ASSET.routeName}/${id}`)
        navigate(path)
      } else {
        setSearchResult(_data?.data?.assets)
      }
    }
    const fleetConnectionType = connectionType ? BREADCRUMB_ROUTE.FLEET_CONNECTION : null
    const fleetModel = model ? BREADCRUMB_ROUTE.FLEET_ASSET_TYPE : fleetConnectionType
    const fleetType = modality ? BREADCRUMB_ROUTE.FLEET_MODALITY : fleetModel
    updateBreadcrumbData({
      key: fleetType,
      name: modality ?? model ?? connectionType
    })
  }

  const [getAssetsData, { isError, data: deviceData, isFetched: fetchDeviceData }] = useLazyQuery(
    [fleetSVCKeys.FLEET_VIEW_GROUP_BY_PAGES, fleetId],
    () =>
      fleetGetSVC.getFleetDevicesByGroupBy(fleetId, modality, model, connectionType, searchInput, selectedFilters),
    { onSuccess: onDataLoadCompleted }
  )

  useEffect(() => {
    if (modality) {
      const selectedFilter = {
        disabled: true,
        label: modality,
        type: "modality",
        value: 0
      }
      setSelectedFilters([selectedFilter])
    } else if (model) {
      getModalityByModel()
    } else if (connectionType) {
      getAssetsData()
    }
  }, [modality, model, connectionType])

  const onModelDataLoadCompleted = (data) => {
    const modality = data?.data?.modality
    const selectedFilter = [
      {
        disabled: true,
        label: modality,
        type: COMPARE_VALUE.modality,
        value: 0
      },
      {
        disabled: true,
        label: model,
        type: COMPARE_VALUE.model,
        value: 0
      }
    ]
    setSelectedFilters(selectedFilter)
  }

  const [getModalityByModel, { isFetching: isFetchingModalityName }] = useLazyQuery(
    [fleetSVCKeys.MODALITY_BY_MODEL, model],
    () => fleetGetSVC.getModalityByModel(model),
    {
      onError: () => {
        setFilterAPIError(true)
      },
      onSuccess: onModelDataLoadCompleted
    }
  )

  useEffect(() => {
    if (selectedFilters?.length > 0 || connectionType) getAssetsData()
  }, [selectedFilters])

  useEffect(() => {
    if (searchClose || searchInput?.length >= SEARCH_DEFAULT_MIN_CHARACTERS) getAssetsData()
  }, [searchInput])

  const { isFetched: fetchedFleetData, data: siteFleets } = useQuery(
    [fleetSVCKeys.GET_ALL_FLEETS_BY_SITE_ID, siteId],
    () => fleetGetSVC.getAllFleetsBySiteId(siteId)
  )

  const allFleets = fetchedFleetData && siteFleets?.data?.fleets
  const isLoading = !fetchDeviceData
  const subFleets = fetchDeviceData && deviceData?.data?.assets

  const { data: fleetStatistics } = useQuery(
    [fleetSVCKeys.GET_STATISTICS_BY_FLEET_ID, fleetId],
    () => fleetGetSVC.getFleetStatisticsByFleetId(fleetId)
  )

  const  fleetStatisticsData =  fleetStatistics?.data?.statistics

  const {  distributionData } = transformFleetStatistics(fleetStatisticsData)

  const assets = useMemo(() => {
    if (subFleets) {
      return transformAssets(subFleets)
    } else {
      return []
    }
  }, [subFleets])

  const handleOnSubmit = (dataIN) => {
    setConfirmPopUp(dataIN)
    assetsSummaryRef?.current?.resetSelection()
  }

  const handleSearchSubmit = (value, closeAction, hasError) => {
    setSearchInput(value)
    setSearchClose(closeAction)
    if (hasError || closeAction) {
      setSearchResult(null)
      setSearchError(hasError)
    } else {
      assetsSummaryRef?.current?.resetSelection()
      setSearchError(false)
    }
  }

  const getFilterAssets = (filters) => {
    setSelectedFilters(filters)
    assetsSummaryRef?.current?.resetSelection()
  }

  if (isLoading && !isError && !filterAPIError) return <Loading />
  if (isError || filterAPIError)
    return (
      <Error
        error={ {
          message: filterAPIError ? t("filterOptionsError") : t("groupByErrorMessage")
        } }
      />
    )

  return (
    <>
      <div className={ styles.staticCards }>
        <SubFleetSummaryStatistics distributionData={ distributionData } />
      </div>
      <div className={ styles.subFleetsSummary }>
        <AssetSummary
          setGroupByOption={ setGroupByOption }
          groupOption={ groupOption }
          assets={ assets }
          isError={ isError }
          assetsQueryKey={ assetsQueryKey }
          handleMoveFleet={ () => setMovePopup(true) }
          setSelectedAsset={ setSelectedAsset }
          handleSearch={ handleSearchSubmit }
          searchResult={ searchResult }
          fleetCount={ allFleets?.length }
          ref={ assetsSummaryRef }
          searchError={ searchError }
          fleetViewGroupFilterData={ filterConfigData }
          getFilterAssets={ getFilterAssets }
          setFilterAPIError={ setFilterAPIError }
          isFetchingModalityName={ isFetchingModalityName }
          selectedFilters={ selectedFilters }
          setSelectedFilters={ setSelectedFilters }
        />
      </div>
      { movePopup && (
        <MoveFleet 
          isPopupOpen={ movePopup } 
          onCancel={ () => setMovePopup(false) } 
          setIsConfirmPopUp={ setConfirmPopUp }
        />
      ) }
      <ConfirmMoveAsset
        popupStyles={ styles.confirmationPopUp }
        onSubmit={ handleOnSubmit }
        selectedDevice={ selectedAsset }
        isOpen={ confirmPopUp.isOpen }
        onCancel={ () => setConfirmPopUp({ isOpen: false }) }
        groupByType={ model || modality || connectionType }
        groupByTrigger={ getAssetsData }
        { ...confirmPopUp }
      />
    </>
  )
}

export default FleetGroupAssetView
